import React, { FunctionComponent } from "react";
import {
    Box,
    Typography,
    withStyles,
    createStyles,
    Modal,
    Backdrop
  } from "@material-ui/core";

interface Props {
  title?: string;
  classes: Record<string, string>;
  allValues: any;
  editQueryId: number;
  editModal: boolean;
  editGroup: (patientId: number) => void;
  hideEditAccountModal: () => void;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '599px',
    bgcolor: 'background.paper',
    padding: '18px 40px 24px 9px;',
    borderRadius: "0px 8px 32px 8px",
    minHeight: '224px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

const EditModal: FunctionComponent<Props> = ({ allValues, editModal, editQueryId, editGroup, classes, hideEditAccountModal}) => {
  return (
    <Modal
      open={editModal}
      onClose={hideEditAccountModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Box sx={{ ...style }}>
        <Typography style={{fontSize: '20px', color: '#21355F', textAlign: 'center', marginTop:'50px', marginBottom: '5px'}}>
          {`Are you sure you want to save changes on the Patient: ${allValues?.firstName} ${allValues?.surName}?`}
        </Typography>
        <Box className={classes.buttonAlign}>
          <button onClick={hideEditAccountModal} className={classes.cancelStyle}>
            Back
          </button>
          <button data-test-id="editDataApi" onClick={() => editGroup(editQueryId)} className={classes.acceptButton}>
            Accept
          </button>
        </Box>
      </Box>
    </Modal>
  );
};


const webStyle = () =>  createStyles({
    buttonAlign: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "0px 120px",
        alignContent: 'baseline',
        marginTop: '30px'
      },
      cancelStyle: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#ffffff",
        borderRadius: '8px',
        color: "#0075BF",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      },
      acceptButton: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#0075BF",
        borderRadius: '8px',
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        },
  });
  
  export default withStyles(webStyle)(EditModal);