import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import {
    Box, 
    ListItem, 
    List,
    Typography,
    MenuItem,
    Select, 
    Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import test from "./test.svg"
import inventory from "./inventory.png"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import testImage from "./test_black.png"
import clinicanBlack from "./clinican_black.png"
import clinican from "./clinican.svg"
import inventoryBlack from "./inventory_black.svg"
import LogoutModal from "./LogoutModal"
import { styled } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const CLINICIAN = "clinician"

const useStyles = makeStyles((theme) => ({
    sidebar: {
        width: '100%',
        maxWidth: '216px',
        height: 'calc(100vh - 83px)',
        backgroundColor: 'rgb(0, 117, 191)',
        borderRadius: '0px 0px 20px 0px',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    closeButton: {
        position: 'absolute',
        top: '5px', // Adjust as needed
        left: '5px', // Adjust as needed
        cursor: 'pointer',
    },
   
    sidebarLinkBox: {
        paddingTop: "35px",
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
    },
      select: {
        '& .MuiInputBase-root': {
            '&.MuiSvgIcon-root': {
                color: 'red'
            }
        },
        '& .MuiSelect-select': {
          color: '#fff', // Default text color
          fontWeight: 700,
        },
       
      },
    sidebarLink: {
        gap: '16px',
        color: '#fff',
        fontWeight: 600,
        display: 'flex',
        fontSize: '16px',
        padding: '16px',
        cursor: 'pointer',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        alignItems: 'center',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        transition: '0.2s all ease-in-out',
        '&.active': {
            backgroundColor: '#fff',
            color: 'rgba(33, 53, 95, 1)',
            '& svg path': {
                color: '#000000'
            },
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
        width: "600px",
        backgroundColor: "#fff",
        padding: "18px 40px 24px 9px",
        borderRadius: "0px 8px 32px 8px",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modalBackButton: {
        display: "flex",
        width: "120px",
        height: "56px",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #E1E1E1",
        background: "#FFF",
        fontFamily:'Inter-Bold,sans-serif',
        fontSize: "16px",
        fontWeight: 700,
        color: "#0075BF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "none",
        cursor: "pointer",
    },
    modalAcceptButton: {
        display: "flex",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: '56px',
        border: "none",
        backgroundColor: "#0075BF",
        borderRadius: '8px',
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        textTransform: "none",
    },
    modalTitle: {
        fontWeight: 500, 
        fontSize: "20px", 
        marginTop: "30px",
        fontFamily: "Inter-Medium",
        color: "#21355F"
    },
    modalSubtitle: {
        fontSize: "14px", 
        fontFamily: "Inter-Regular, sans-serif",
        color: "#21355F",
        paddingBottom: "20px",
    },
    modalButtonsGroup: {
        display: "flex", 
        gap: 26, 
        paddingBottom: 24
    },
}));

// Create a styled version of MoreVertIcon
const CustomMoreVertIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
    color: '#fff !important', // Change the color as desired
  }));

const Sidebar = ({ history, isDrawer, handleClose }) => {
    const classes = useStyles();
    const { pathname } = history.location;
    const [open, setOpen] = React.useState(false);
    const [isVisible, setIsVisible] = useState(false)
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false)
    const [potentialInstitute, setPotentialInstitute] = useState({})
    const [nextDropdownValue, setNextDropdownValue] = useState("")
    const [clinicanAccount, setClinicanAccount] = useState({});
    const [hospitalName, setHospitalName] = useState([])
    const[data, setData] = useState({})
    const [instituteId, setInstituteId] = useState('')
    const isClinician = JSON.parse(localStorage.getItem("userType")) === CLINICIAN
    const currentPath = new URL(window.location.href).pathname;
    const isDropdownDisabled = isClinician && (currentPath === '/OrderTest' || currentPath === '/OrderSummary');
    const [dropDown, setDropDown] = useState(localStorage.getItem('dropdownValue'))
    const[instituteNumber, setInstituteNumber] = useState('')
    const [potentialPath, setPotentialPath] = useState("")
    const isProtectedPath = currentPath === '/OrderTest'
    const modalTitle = (isClinician && localStorage.getItem('dropdownValue') === "") ? 
        "Please select an institution" : "You're leaving this page. Changes will not be saved"
    const clinicianWithoutHospital = isClinician && localStorage.getItem('dropdownValue') === "" 
    

    useEffect(() => {
        const result = localStorage.getItem('data')
      const parseData = JSON.parse(result)
      setData(parseData?.data)
      const parseData1 = JSON.parse(result)
      setClinicanAccount(parseData1?.data?.attributes?.common_information)
      setHospitalName(parseData1?.data?.attributes)
      }, [])


    
      const handleCloseModal = () => {
        setOpen(false);
      };

      const handleLogout = () => {
        localStorage.clear()
        history.push('/Login')
      }

      const menuList = [
        {
            title: 'Clinicians',
            icon: <img src={clinican} />,
            activePath: ['/Clinicians', '/EditClinician', '/ClinicianProfile', '/CreateClinician'],
            iconActive: <img src={clinicanBlack} />
        },
        {
            title: 'Patients',
            icon: <PeopleAltOutlinedIcon style={{color: '#fff'}} />,
            iconActive: <PeopleAltOutlinedIcon style={{color: '#000'}} />,
            activePath: ['/Patient', '/CreatePatient', '/PatientView', '/EditPatient']
        },
        {
            title: 'Tests',
            icon: <img src={test} />,
            activePath: ['/Tests', '/OrderTest', '/OrderSummary', '/ResultTest', '/TestEdit'],
            iconActive: <img src={testImage} />
        },
        {
            title: 'Inventory',
            icon: <img src={inventory} />,
            activePath: ['/InventoryManagement'],
            iconActive: <img src={inventoryBlack} />
        },
       
    ]

    const getIcon = (list, isActive) => {
        // state
      
        return isActive ? list.iconActive :list.icon
    }
    const renderTextNIcon = (list, isActive) => {

        return (
            <>
                {getIcon(list, isActive)}
                {list.title}    
            </>
        )
    }

    const filteredMenuList = menuList.filter(item => {
        if (clinicanAccount.user_type !== 'institution' && item.title === 'Clinicians') {
            return false;
        }
        else if(clinicanAccount.user_type !== 'institution' && item.title === 'Inventory'){
            return false;
        }else {
            return true
        }

    });
      const changeDropdownValue = (event) => {
        setNextDropdownValue(event.target.value)
      }

      const saveInstitutionId = (id) => {
        setInstituteId(id)
        localStorage.setItem("institutionId", String(id));
        window.dispatchEvent(new Event('storage'))
      }

      const getInstitutionNumber = (hospitalNumber) => {
        setInstituteNumber(hospitalNumber)
        localStorage.setItem("hospitalNumber", hospitalNumber);
        window.dispatchEvent(new Event('storage1'))
    }

    const onItemClick = (item) => {
        setIsOpenWarningModal(true)
        setPotentialInstitute(item)
    }

    const handleContinueChangeInstitution = () => {
        setDropDown(nextDropdownValue)
        localStorage.setItem("dropdownValue", nextDropdownValue)
        localStorage.removeItem('summaryData')
        saveInstitutionId(potentialInstitute.id)
        getInstitutionNumber(potentialInstitute.attributes.institute_information.institution_number)
        setIsOpenWarningModal(false)
        window.dispatchEvent(new Event('storage'))
    }

    const handleContinue = () => {
        setIsVisible(false)
        history.push(potentialPath)
    }
   
    const handleClick = (list) => {
        const firstActivePath = list.activePath[0];
   
        if (clinicianWithoutHospital || (isProtectedPath && JSON.parse(localStorage.getItem('isDataSelected'))) ) {
            setPotentialPath(firstActivePath);
            setIsVisible(true);
        } else {
            history.push(firstActivePath);
        }
    }

    function handleDisplayInstitutionNumber(institutionNumber) {
        if (!institutionNumber) {
            return ''
        }
    
        return institutionNumber.slice(0, 3) + ' ' + institutionNumber.slice(3, 6) + ' ' + institutionNumber.slice(6)
    }

return (
    <Box className={classes.sidebar}>
        {
            isDrawer &&
            <Box className={classes.closeButton} onClick={() => handleClose(false)}>
                <Close style={{ fontSize: '20px' }} />
            </Box>
        }
        <List className={classes.sidebarLinkBox}>
        <ListItem style={{display: 'flex', flexDirection: 'column'}}
          button
        >
       

            {
                hospitalName?.common_information?.user_type === 'clinician' ?  
                    <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        disableUnderline={true}
                        value={dropDown}
                        disabled={isDropdownDisabled}
                        onChange={changeDropdownValue}
                        className={classes.select}
                        IconComponent={CustomMoreVertIcon}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                marginTop: '30px',
                                },
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            }}
                        >
                            {
                            hospitalName?.clinician_information?.institutes?.data.map((item, index) => {
                                const institutionNumber = item?.attributes?.institute_information?.institution_number
                            return ( 
                            <MenuItem 
                                onClick={() => { onItemClick(item) }} 
                                key={index} value={item?.attributes?.institute_information?.institute_name}>
                                <Typography style={{fontWeight: 700}}>{item?.attributes?.institute_information?.institute_name} </Typography>
                                <Typography style={{fontWeight: 700, textAlign: 'center', marginLeft: '5px'}}>
                                    {handleDisplayInstitutionNumber(institutionNumber)}
                                </Typography>
                            </MenuItem>
                            )
                
                    })

                    }

                    </Select>
                     
                : <Box sx={{textAlign: 'center'}}>
               
               <Typography  style={{color: '#fff', fontWeight: 700}}>{hospitalName?.institute_information?.institute_name}</Typography> 
               <Typography  style={{color: '#fff', textAlign: 'center', fontWeight: 700}}>
                    {handleDisplayInstitutionNumber(hospitalName?.institute_information?.institution_number)}
                </Typography>
             </Box> 
           
             
              }
        
        

      </ListItem>
            <Box style={{display: "flex", flexDirection:"column", paddingTop: "35px", gap: "10px"}}>
                {filteredMenuList.map((list, index) => {
                        const isActive = list.activePath.some(path => pathname.startsWith(path));
                    
                        return (
                            <ListItem key={index}
                                onClick={() => handleClick(list)}
                                className={`${classes.sidebarLink}${isActive ? ' active' : ''}`}
                            >
                            {renderTextNIcon(list,isActive)}
                            </ListItem>
                        )
                })}
            </Box>
        </List>
        <div
            onClick={() => setOpen(true)}
            style={{ 
                display: "flex", 
                alignItems: "center",
                padding: "16px 40px 16px 40px",
                color: "#FFF",
                marginBottom: "15px",
                backgroundColor: "#21355F",
                fontFamily: "Inter-Bold, sans-serif", 
                fontSize: '16px',
                cursor: 'pointer',
                gap: "16px"}}>
            <ExitToAppIcon style={{color: '#fff'}} />
            Logout
        </div>

        <LogoutModal open={open} handleCloseModal={handleCloseModal} handleLogout={handleLogout} />
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(false)}>
            <Box className={classes.modal}>
                <Typography className={classes.modalTitle}>
                    {modalTitle}
                </Typography>
                <Box className={classes.modalButtonsGroup}>
                    {!clinicianWithoutHospital &&  
                        <button onClick={() => setIsVisible(false)} className={classes.modalBackButton}>
                            Back
                        </button>
                    }
                    <button 
                        onClick={clinicianWithoutHospital ? () => setIsVisible(false) : handleContinue} 
                        className={classes.modalAcceptButton}>
                        {clinicianWithoutHospital ? "Ok" : "Continue"} 
                    </button>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={isOpenWarningModal}
            onClose={() => setIsOpenWarningModal(false)}>
            <Box className={classes.modal}>
                <Typography className={classes.modalTitle}>
                    Are you sure you want to change institution?
                </Typography>
                <Typography className={classes.modalSubtitle}>
                    Note: All tests in the basket will be removed
                </Typography>
                <Box className={classes.modalButtonsGroup}>
                    <button onClick={() => setIsOpenWarningModal(false)} className={classes.modalBackButton}>
                        Back
                    </button>
                    <button 
                        onClick={() => handleContinueChangeInstitution()} 
                        className={classes.modalAcceptButton}>
                        Continue
                    </button>
                </Box>
            </Box>
        </Modal>

    </Box>
);
}

export default withRouter(Sidebar);