Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automaticreminders";
exports.labelBodyText = "automaticreminders Body";

exports.btnExampleTitle = "CLICK ME";

exports.getTechnicianTaskListApiContentType = "application/json";
exports.getTechnicianTaskListApiMethod = "GET";
exports.getTechnicianTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.getCategoryListApiContentType = "application/json";
exports.getCategoryListApiMethod = "GET";
exports.getCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_categories";

exports.getSubCategoryListApiContentType = "application/json";
exports.getSubCategoryListApiMethod = "GET";
exports.getSubCategoryListApiEndPoint = "/bx_block_automatedprioritisation/service_sub_categories";

exports.notificationApiContentType = "application/json";
exports.notificationApiMethod = "GET";
exports.notificationApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_assign_notification";
exports.startNotificationApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_started_notification";

exports.changeTaskApiMethod = "GET";
exports.changeTaskApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/starting_task";

exports.completedTaskApiMethod = "GET";
exports.completedTaskEndPoint = "/bx_block_automatedprioritisation/priority_tasks/completed_task";

exports.getTechnicianListApiMethod = "GET";
exports.getTechnicianListApiContentType = "application/json";
exports.getTechnicianListApiEndPoint = "/account_block/accounts?type=technician";

exports.completeTaskApiContentType = "application/json";
exports.completeTaskApiMethod = "GET";
exports.completeTaskApiEndPoint = "/bx_block_automaticreminders/task_reminders/task_completed_notification";

exports.editTaskApiContentType = "application/json";
exports.editTaskApiMethod = "PUT";
exports.editTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.getTaskListApiContentType = "application/json";
exports.getTaskListApiMethod = "GET";
exports.getTaskListApiEndPoint = "/bx_block_automatedprioritisation/priority_tasks/all_task";

exports.addTaskApiContentType = "application/json";
exports.addTaskApiMethod = "POST";
exports.addTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";

exports.deleteTaskApiContentType = "application/json";
exports.deleteTaskApiMethod = "PUT";
exports.deleteTaskApiEndPoint = "/bx_block_repeatingtasks/tasks";
exports.resetPasswordAPiEndPoint = "bx_block_forgot_password/passwords";
exports.verifyTokenApiEndPoint = "bx_block_forgot_password/verify_token"

// Customizable Area End