import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  showInventory: boolean,
  getComplete: [],
  searchdataAns: any[];
  handleSearch: boolean;
  productData: [];
  dropdownDataAns: any[];
  datans: string,
  selectedOptions: any,
  testOptions2: any,
  selectedStock: any,
  selectedDate: any,
  openReorderPopup: boolean;
  openRequestPopup: boolean;
  openSearchBox: boolean;
  requestText: string;
  reorderKey: number;
  testTypeForPopup: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

  // Customizable Area End

export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS


> {
  // Customizable Area Start
  getProductsApiCallId = "";
  getAutoComplete="";
  searchGetData = "";
  searchFindData = "";
  getDropdown = "";
  postRequestApiCallId = "";
  patchRequestApiCallId = "";

  handleReorder(key: number, testType: string) {
    this.setState({
      openReorderPopup: true,
      reorderKey: key,
      testTypeForPopup: testType
    });
  }


  closeReorder() {
    this.setState({
      openReorderPopup: false
    });
  }
  customStyles = {
    //this is to not show options in that particular box
    placeholder: (provided: any) => ({
      ...provided,
      color: '#21355F',
      marginLeft: '10px',
      fontFamily: 'Inter-Regular,sans-serif',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    multiValue: (base: any) => ({
      ...base,
      display: 'none',
    }),
    control: (baseStyles: any, state: { isDisabled: any; }) => ({
      ...baseStyles,
      borderRadius: '20px',
      color: 'black',
      border: "1px solid #DBEAFE",
      backgroundColor: "#DBEAFE",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus,&:focus-within, &:active": {
        cursor: "pointer",
        borderColor: "none",
        boxShadow: "0 0 5px 2px #DBEAFE",
      },
      width: "185px",
      height: '45px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#21355F',
    }),
    option: (provided: any, state: any) => {
      const selectedValues = [
        this.state.selectedOptions?.value,
        this.state.selectedStock?.value,
        this.state.testOptions2?.value,
        this.state.selectedDate?.value,
      ];
      const isSelectedFinal = selectedValues?.includes(state?.data?.value);

      return {
        ...provided,
        marginTop: '5px',
        marginBottom: '5px',
        color: isSelectedFinal ? 'white' : '#21355F',
        fontFamily: 'Inter-Regular,sans-serif',
        fontWeight: "normal",
        backgroundColor: isSelectedFinal ? '#0075BF' : 'white',
        "&:hover": {
          cursor: "pointer",
          backgroundColor: '#0075BF',
          color: 'white'
        },
        overflow: 'auto',
        maxHeight: '100px'
      }
    }
  };
  handleNewRequest() {
    this.setState({
      openRequestPopup: true
    });
  }

  closeRequest() {
    this.setState({
      openRequestPopup: false
    });
  }

  handleRequestText(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = event.target.value;
    this.setState({
      requestText: value
    })
  }

  postRequest() {
    let data = JSON.parse(localStorage.getItem("data") || "{}");

    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: data.meta?.token
    };

    const httpBody = {
      body: this.state.requestText
    };

    const getRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRequestApiCallId = getRequest.messageId;

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postAPIForRequestNewTest
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getRequest.id, getRequest);

    this.setState({
      openRequestPopup: false
    });
  }
  handleSearch() {
    this.setState({ openSearchBox: true })
  }
  handleSearch2(){
    this.setState({openSearchBox:false})
  }
  updateReorder(){
    this.updateReorders();
     /* istanbul ignore next */
    setTimeout(()=>{
       /* istanbul ignore next */
      this.getTheProducts()
    },2000)
  }
  updateReorders() {
    let data = JSON.parse(localStorage.getItem("data") || "{}");

    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: data.meta?.token
    };

    const getReorder = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.patchRequestApiCallId = getReorder.messageId;

    getReorder.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );

    getReorder.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.patchAPIForReorder}?id=${this.state.reorderKey}&requested_amount`
    );

    getReorder.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(getReorder.id, getReorder);

    this.setState({
      openReorderPopup: false
    });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.updateReorder = this.updateReorder.bind(this);
    this.getTheProducts = this.getTheProducts.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      productData: [],
      // Customizable Area Start
      datans: '',
      dropdownDataAns: [],
      selectedOptions: [],
      testOptions2: [],
      selectedStock: [],
      selectedDate: [],
      handleSearch: false,
      searchdataAns: [],
      showInventory: false,
      openReorderPopup: false,
      getComplete: [],
      openRequestPopup: false,
      openSearchBox: false,
      requestText: "",
      reorderKey: 0,
      testTypeForPopup: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getTheProducts();
    this.dropDownData()
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.getProductsApiCallId) {
        this.handleGetProductsResponse(responseJson, errorResponse);
      } else if (this.isSearchGetDataResponse(message, responseJson)) {
        this.handleSearchGetDataResponse(responseJson);
      } else if (this.isSearchFindDataResponse(message, responseJson)) {
        this.handleSearchFindDataResponse(responseJson);
      } else if (this.isDropdownResponse(message, responseJson)) {
        this.handleDropdownResponse(responseJson);
      }
      else if(this.isAutoComplete(message, responseJson)){
          this.handleAutoComplete(responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleGetProductsResponse(responseJson: any, errorResponse: any) {
    if (responseJson.data.length !== 0) {
      this.setState({ productData: responseJson.data });
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  isSearchGetDataResponse(message: Message, responseJson: { errors: any; data: any; }) {
    return (
      this.searchGetData != null &&
      this.searchGetData === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
      !responseJson.errors && responseJson.data
    );
  }

  handleSearchGetDataResponse(responseJson: any) {
    this.setState({ searchdataAns: [...responseJson.data] });
  }

  isSearchFindDataResponse(message: Message, responseJson: { errors: any; data: any; }) {
    return (
      this.searchFindData != null &&
      this.searchFindData === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
      !responseJson.errors && responseJson.data
    );
  }

  handleSearchFindDataResponse(responseJson: { data: any; }) {
    this.setState({ searchdataAns: [...responseJson.data] });
  }
  isAutoComplete(message: Message, responseJson: { errors: any; data: any; }) {
    return (
      this.getAutoComplete != null &&
      this.getAutoComplete === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
      !responseJson.errors && responseJson
    );
  }
  isDropdownResponse(message: Message, responseJson: { errors: any; data: any; }) {
    return (
      this.getDropdown != null &&
      this.getDropdown === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
      !responseJson.errors && responseJson.data
    );
  }

  handleAutoComplete(responseJson: any) {
    this.setState({ getComplete: responseJson});
  }
  handleDropdownResponse(responseJson: { data: any; }) {
    this.setState({ dropdownDataAns: [...responseJson.data] });
  }

  getTheProducts = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
      token: data.meta?.token
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InventoryEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
  };
  dropDownData() {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      /* istanbul ignore next */ token: data.meta?.token
    };
    const getTestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDropdown = getTestData.messageId;

    getTestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editDropdownEndPoint}`
    );

    getTestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)

    );
    getTestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTestData.id, getTestData);
  }
  renderSelectedOption3() {
    const { selectedStock } = this.state;

    if (!selectedStock?.label) {
      return null;
    }
    return (
      <div
        data-test-id="div3"
        key={selectedStock.value}
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: 700,
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
      > <CloseIcon
          data-test-id="checkicon3"
          onClick={this.removeOption3}
          style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedStock.label}
      </div>
    );
  }
  renderSelectedOption4() {
    const { selectedDate } = this.state;

    if (!selectedDate?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div4"
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: 700,
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
        key={selectedDate.value}
      >
        <CloseIcon
          onClick={this.removeOption4}
          data-test-id="checkicon4"
          style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedDate.label}
      </div>
    );
  }
  renderSelectedOption2() {
    const { testOptions2 } = this.state;

    if (!testOptions2?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div2"
        key={testOptions2.value}
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: 700,
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
      >
        <CloseIcon
          data-test-id="checkicon2"
          onClick={this.removeOption2}
          style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
        />
        {testOptions2.label}
      </div>
    );
  }
  removeOption = () => {
    this.setState({ selectedOptions: null }, this.handleDropdownData);
  }
  removeOption2 = () => {
    this.setState({ testOptions2: null }, this.handleDropdownData);
  }
  removeOption3 = () => {
    this.setState({ selectedStock: null }, this.handleDropdownData);
  }
  removeOption4 = () => {
    this.setState({ selectedDate: null }, this.handleDropdownData);
  }
  shouldSetFindSearch = (...selectedOptionsArray: any[]) => {
    return selectedOptionsArray.some(option => {
      if (option?.value != null) {
        if (typeof option.value === 'string' || Array.isArray(option.value)) {
          return option.value.length > 0;
        } else if (typeof option.value === 'number') {
          return option.value > 0;
        }
      }
      return false;
    }) || (this.state.datans?.length > 0);
  }
  handleDropdownData = () => {

    const { selectedOptions, testOptions2, selectedStock, selectedDate, datans } = this.state;

    if (this.shouldSetFindSearch(selectedOptions, testOptions2, selectedStock, selectedDate, datans)) {
      this.setState({ handleSearch: true });
    } else {
      return this.setState({ handleSearch: false });

    }
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const inventoryDataSeach = { "Content-Type": configJSON.validationApiContentType, token: data.meta?.token };
    const getTestData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchFindData = getTestData.messageId;
    const queryParams = [
      ...this.buildDateGeneralParams(selectedOptions, testOptions2, selectedStock, selectedDate, datans)
    ];
    let baseUrl = this.buildBaseUrl(queryParams);
    this.sendApiRequest(getTestData, baseUrl, inventoryDataSeach);
  };
  sendApiRequest = (getTestData: Message, baseUrl: string, inventoryDataSeach: { "Content-Type": any; token: any; }) => {
    getTestData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), baseUrl);
    getTestData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), inventoryDataSeach);
    getTestData.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getTestData.id, getTestData);
  }
  buildBaseUrl = (queryParams: any[]) => {
    let baseUrl = `${configJSON.editDropdownEnd}`;
    if (queryParams?.length > 0) {
      baseUrl += '?' + queryParams?.join('&') + '&type=inventory';
    }
    return baseUrl;
  }

  buildDateGeneralParams = (selectedOptions: { value: string | any[]; }, testOptions2: { value: string | any[]; }, selectedStock: { value: number | any[] }, selectedDate: { value: string | any[] }, datans: string) => {
    let queryParams = [];
    if (datans) queryParams.push(`query=${decodeURIComponent(datans)}`);
    if (selectedOptions?.value?.length > 0) queryParams.push(`sample=${selectedOptions.value}`);
    if (testOptions2?.value?.length > 0) queryParams.push(`test_type=${testOptions2.value}`);
    if (selectedStock?.value) queryParams.push(`stock=${selectedStock.value}`);
    if (selectedDate?.value?.length > 0) queryParams.push(`last_order_date=${selectedDate.value}`);
    return queryParams;
  };
  searchTheData = (eventOrValue: any) => {

    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.handleDropdownData();
      this.autoSuggestionsInventory();
    });
  }
  buildQueryParamsAuto = () => {
    const {
      datans,
    } = this.state;
    let queryParams = [];

    if (datans) {
      queryParams.push(`query=${decodeURIComponent(datans)}`);
    }

    return queryParams;
  };
  autoSuggestionsInventory = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.autoCompleteSearch}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
       token: data.meta?.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutoComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  renderSelectedOption() {
    const { selectedOptions } = this.state;

    if (!selectedOptions?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div1"
        key={selectedOptions.value}
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: 700,
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
      >
        <CloseIcon
          data-test-id="checkicon1"
          onClick={this.removeOption}
          style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOptions.label}
      </div>
    );
  }
  removeAllOptions = () => {
    this.setState({
      selectedOptions: null,
      testOptions2: null,
      selectedStock: null,
      selectedDate: null,
    }, this.handleDropdownData);
  };
  testTypeChange = (selectedOption: any) => {
    this.setState({ testOptions2: selectedOption }, this.handleDropdownData);
  };
  handleChange = (selected: any) => {
    this.setState({ selectedOptions: selected }, this.handleDropdownData);
  };
  stockLevelHandle = (selectedStock: any) => {
    this.setState({ selectedStock: selectedStock }, this.handleDropdownData);
  };
  handleLastDate = (selectedDate: any) => {
    this.setState({ selectedDate: selectedDate }, this.handleDropdownData);
  };




  // Customizable Area End

  // Customizable Area End
}
