import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import * as Yup from "yup";
import { revueLogo } from './assets'
import GenericCard from "../../../components/src/GenericCard.web";
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <GenericCard
      isLoader={this.state.isLoader}
      title={this.state.popupTitle} 
      displayPopup={this.state.displayPopup}
      onClosePopup={this.handleClosePopup}>
      <Formik
        initialValues={{
          // email: "",
          confirmPassword: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            'Password must contain at least one lowercase letter, one uppercase letter, and one numeric digit and minimum 8 character'
            )
            .required("Password is required"),
            confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], "Password do not match")
            .required("Confirm Password is required"),
          })}
          
          data-test-id="resetForm"
          initialTouched={{
          password: false
        }}
        

        onSubmit={(values, actions) => {
          actions.resetForm({
            //@ts-ignore
            password: "",
            confirmPassword: ""
          });
          this.handleResetPassword()
    }}
      >
        {(props) => {
          return(
          <form onSubmit={props.handleSubmit}>
            <Box style={{padding: '20px 50px 50px 50px'}}>
              <Box style={{marginBottom: '30px', textAlign: 'center'}}>
              <img src={revueLogo} />
              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Email</InputLabel>
                <TextField
                  id="email"
                  data-test-id={"email"}
                  style={webStyle.inputField}
                  name="email"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={this.state.email}
                  disabled
                  fullWidth={true}
                  type="text"
                  placeholder="Your Email"
                />
              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Create a password</InputLabel>
                <TextField
                  id="password"
                  type={this.state.enablePasswordField ? "password" : "text"}
                  data-test-id="password"
                  style={webStyle.inputField}
                  name="password"
                  value={props.values.password}
                  onChange={(event) => {
                    this.onValueChange("password", event.target.value);
                    props.setFieldValue("password", event.target.value);
                  }}
                  InputProps={{
                    disableUnderline:true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                           
                        >
                          {this.state.enablePasswordField ? (
                            <VisibilityOffOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}}/>
                          ) : (
                            <RemoveRedEyeOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Your password"
                  fullWidth={true}
                  onBlur={() => props.setFieldTouched('password', true)}
                />
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "password"
                )}
              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Confirm your password</InputLabel>
                <TextField
                  name="confirmPassword"
                  data-test-id="confirmPassword"
                  style={webStyle.inputField}
                  type={this.state.enableConfirmPasswordField ? "password" : "text"}
                  id="confirmPassword"
                  value={props.values.confirmPassword}
                  onChange={(event) => {
                    props.setFieldValue("confirmPassword", event.target.value);
                    this.onValueChange("confirmPassword", event.target.value);
                  }}
                  InputProps={{ disableUnderline:true }}
                  placeholder="Your password"
                  fullWidth={true}
                />
                <div style={{position: "absolute", top: "25px", right: "10px"}}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleConfirmClickShowPassword}
                    edge="end"
                    id="IconButton"
                  >
                    {this.state.enableConfirmPasswordField ? (
                      <VisibilityOffOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />
                    ) : (
                      <RemoveRedEyeOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />
                    )}
                  </IconButton>
                </div>
                
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "confirmPassword"
                )}
              </Box>
            
              <Button
                data-test-id="LoginBtn"
                type="submit"
                style={webStyle.buttonStyle}
              >
                Submit
              </Button>
            </Box>
          </form>
        )}}
      </Formik>
     </GenericCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  label: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  }, 
  inputField: {
    border: '1px solid rgba(203, 213, 225, 1)',
    padding: '10px 10px',
    borderRadius: '8px',
    background: 'none',
    width: "95%",
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },
  inputWrapper: {
    position: "relative",
    marginBottom: '20px',
    marginTop: '15px'
  },
 
  buttonStyle: {
    height: "56px",
    width: "80px",
    border: "none",
    backgroundColor: "#0075BF",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: '8px',
    color: "white",
    fontWeight: 700,
    textTransform: 'none' as const
  },
  
};
// Customizable Area End
