import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import dayjs from 'dayjs';
import { Account, GroupAccount, IClinician } from "./types";
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import { Box, CircularProgress } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  name: string;
  createdAt: string;
  updatedAt: string;
  clinicianViewSearchAns:any[],
  editMode: boolean;
  dataHandle:any,
  popupcancel:boolean,
  popupview:boolean,
  firstDate:string,
  secondDate:string,
  openCalender: boolean,
  getAutoSearchSuggestions:[],
  datans: string,
  dropdownDataAns: any;
  token: string;
  departmentSelected: any,
  selectActivity: any,
  selectDateActivity: { startDate:string, endDate:string },
  roleSelected: any,
  groupList: IClinician[];
  searchdataAns: any[];
  isVisibleModal: boolean;
  openSearchBox: boolean;
  patientSearch: boolean;
  isVisibleErrorModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  accountsData: Account[];
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  firstName: string;
  surname: string;
  email: string;
  institutionNumber: string;
  NMCNumber: string;
  GMCNumber: string;
  role: string;
  department: string;
  orderBy: string
  editQueryId: string
  selectedClinician: IClinician | null
  disabledAccept: boolean
  roleOptions: any
  showClinician: any
  updatedClinician: IClinician | null
  selectedRoleKey: string
  clinicianTests: any
  errorMessage: string
  canceledTestId: number
  isLoader: boolean
  dataFetching: boolean
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClinicianController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getDropdown = "";
  getFilter='';
  getAutoComplete="";
  searchPatientData = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  fetchRolesApiCallId = "";
  getClinicianApiCallId = "";
  fetchClinicianTestsApiCallId = "";
  postResendInvitationApiCalId = "";
  patchCancelTestApiCalId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      dataHandle:[],
      popupcancel:false,
      popupview:false,
     clinicianViewSearchAns:[],
      firstDate:"",
      secondDate:"",
      openCalender: false,
      getAutoSearchSuggestions:[],
      dropdownDataAns: [],
      datans:'',
      departmentSelected: [],
      selectActivity: [],
      selectDateActivity: { startDate: "", endDate: "" },
      roleSelected: [],
      token: "",
      groupList: [],
      searchdataAns: [],
      openSearchBox: false,
      isVisibleModal: false,
      patientSearch: false,
      isVisibleErrorModal: false,
      isVisibleDeleteAccountModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      firstName: "",
      surname: "",
      email: "",
      institutionNumber: "",
      NMCNumber: "",
      GMCNumber: "",
      role: "",
      department: "",
      orderBy: "asc",
      editQueryId: "",
      selectedClinician: null,
      disabledAccept: false,
      roleOptions: [],
      showClinician: null,
      updatedClinician: null,
      selectedRoleKey: "",
      clinicianTests: null,
      errorMessage: "",
      canceledTestId: NaN,
      isLoader: false,
      dataFetching: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.fetchRoles()
    this.dropDownData()
    const searchQuery = this.props.navigation.getParam("id");
    const url = new URL(window.location.href).pathname
    const regex = /^\/ClinicianProfile\/\d+$/;

    this.setState({ editQueryId: searchQuery })

    const result: any = localStorage.getItem("data")
    const parseData = JSON.parse(result)
    /* istanbul ignore next */
    this.setState({
      token: parseData?.meta?.token,
      institutionNumber: parseData?.data?.attributes.clinician_information.institution_number
    }, () => {
      this.fetchClinicians()
      if (searchQuery && regex.test(url)) {
        this.fetchClinicianTests(searchQuery);
      }
      searchQuery && this.getClinician(searchQuery)
    })
  }
  searchPatient2 = (eventOrValue: any) => {
    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.autoSuggestionsPatient2();
    });
  }
  autoSuggestionsPatient2 = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const pathArray = window.location.pathname.split('/');  
    const ids = pathArray[pathArray.length - 1]; 
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.filterPoint2}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    baseUrl+=`&clinician_id=${ids}`
   
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: data.meta?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilter = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleNavigateToCreateClinician = () => {
    this.props.navigation.navigate("CreateClinician")
  };
  handleSearch() {
    this.setState({ openSearchBox: true })
  }
  handleSearch2() {
    this.setState({ openSearchBox: false })
  }
  handlePopup=()=>{
    this.setState({popupcancel:false})
  }
  handlePopupView=()=>{
    this.setState({popupview:false})
  }
  handleNavigateToEditClinician = (clinicianId: number) => {
    this.props.navigation.navigate("EditClinician", {
      id: clinicianId
    })
  }
  placeholders = [
    "Name",
    "Department",
    "Role",
    "Email",
    "Last Activity",
    "Institution ID"
  ];
  clinicianStyles = {
    menu: (base: any) => ({
      ...base,
      width: 'fit-content',
      overflow: 'auto',
      maxHeight: '200px',
      minWidth:'160px',
    }),
    multiValue: (base: any) => ({
      ...base,
      display: 'none',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#21355F',
      marginLeft: '10px',
      fontFamily: 'Inter-Regular,sans-serif',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#21355F',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: '#DBEAFE',
    }),
    option: (provided: any, state: any) => {
      const selectedValues = [
        /* istanbul ignore next */
        this.state.roleSelected?.flat().map((option: { value: any; })=>option.value),
        this.state.departmentSelected?.flat().map((option: { value: any; })=>option.value),
        this.state.selectActivity?.value
      ];
      /* istanbul ignore next */
      const flattenedSelectedValues = selectedValues?.flat();
      /* istanbul ignore next */
      const isSelectedFinal = flattenedSelectedValues?.includes(state?.data?.value);

      return {
        ...provided,
        marginTop: '5px',
        marginBottom: '5px',
        color: isSelectedFinal ? 'white' : '#21355F',
        fontFamily: 'Inter-Regular,sans-serif',
        fontWeight: "normal",
        backgroundColor: isSelectedFinal ? '#0075BF' : 'white',
        "&:hover": {
          cursor: "pointer",
          backgroundColor: '#0075BF',
          color: 'white'
        },
      }
    },
    control: (baseStyles: any, state: { isDisabled: any; }) => ({
      ...baseStyles,
      borderRadius: '20px',
      color: 'black',
      border: "1px solid #DBEAFE",
      backgroundColor: "#DBEAFE",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus,&:focus-within, &:active": {
        cursor: "pointer",
        borderColor: "none",
        boxShadow: "0 0 5px 2px #DBEAFE",
      },
      width: "155px",
      height: '45px',
    }),
  };
  buildQueryParamsAuto = () => {
    const {
      datans,
    } = this.state;
    let queryParams = [];

    if (datans) {
      queryParams.push(`query=${decodeURIComponent(datans)}`);
    }

    return queryParams;
  };
  autoSuggestionsClinician = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.autoCompleteClinicianSearch}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
       token: data.meta?.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutoComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleNavigateToClinicians = () => {
    this.props.navigation.navigate("ClinicianTable")
  };

  handleOpenModal = () => {
    this.setState({ isVisibleModal: true });
  }

  handleCloseModal = () =>  {
    this.setState({ isVisibleModal: false });
  }
  dropDownData() {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const headerData = {
      "Content-Type": configJSON.apiContentType,
      /* istanbul ignore next */ token: data.meta?.token
    };
    const getTestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDropdown = getTestData.messageId;

    getTestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editDropdownEndPoint}`
    );

    getTestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)

    );
    getTestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getTestData.id, getTestData);
}

  removeAllOptions = () => {
    this.setState({
      departmentSelected: null,
      selectActivity: null,
      selectDateActivity:{startDate:'',endDate:''},
      roleSelected: null,
    },this.handleDropdownData);
  };
  renderFinalSelectedOptions() {
    const { departmentSelected, roleSelected } = this.state;
    return (
      <>
        {departmentSelected?.length > 0 && this.renderSelectedOption2()}
        {roleSelected?.length > 0 && this.renderSelectedOption3()}
      </>
    );
  }
  
  renderSelectedOption3() {
    const { roleSelected } = this.state;
    /* istanbul ignore next */
    const flattenedOptions = roleSelected?.flat();
    /* istanbul ignore next */
    if (flattenedOptions?.length === 0) {
      return null;
    }

    return (
      /* istanbul ignore next */
      <div> 
        {flattenedOptions?.map((option:any) => (
          <div
            key={option.value}
            data-test-id="div3"
            style={{
              margin: '5px',
              borderRadius: '20px',
              padding: '10px',
              width: 'fit-content',
              backgroundColor: '#0075BF',
              color: 'white',
              fontWeight: 700,
              overflow: 'hidden',
              fontFamily: 'Inter-Bold,sans-serif',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon
             /* istanbul ignore next */
              onClick={() => this.removeOption3(option.value)}
              data-test-id="checkicon3"
              style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
            />
            {option.label}
          </div>
        ))}
      </div>
    );
  }
  handleDropdownData = () => {
    const {  roleSelected, departmentSelected, selectActivity, selectDateActivity, datans } = this.state;
  
    if (this.shouldSetFindSearch2(departmentSelected,roleSelected)||this.shouldSetFindSearch( selectActivity, datans)) {
      this.setState({ patientSearch: true });
    }
      else {
      return this.setState({ patientSearch: false });
    }
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const clinicianDataSearch = { "Content-Type": configJSON.apiContentType, token: data.meta?.token };
    const getTestData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchPatientData = getTestData.messageId;
    const queryParams = [
      ...this.buildDateGeneralParams( roleSelected, departmentSelected, datans,selectDateActivity),
    ];
    let baseUrl = this.buildBaseUrl(queryParams);
    this.sendApiRequest(getTestData, baseUrl, clinicianDataSearch);
  };


  sendApiRequest = (getTestData: Message, baseUrl: string, clinicianDataSearch: { "Content-Type": any; token: any; }) => {
    getTestData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), baseUrl);
    getTestData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), clinicianDataSearch);
    getTestData.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    runEngine.sendMessage(getTestData.id, getTestData);
  }
  shouldSetFindSearch2 = (...selectedOptionsArray: any[]) => {
       /* istanbul ignore next */
    const hasNonEmptyNestedArray = selectedOptionsArray?.some((item) => 
      Array.isArray(item) && item.length > 0
    );
       /* istanbul ignore next */
    return hasNonEmptyNestedArray || this.state.datans?.length > 0;
  };
  
  shouldSetFindSearch = (...selectedOptionsArray: string[]) => {
    return selectedOptionsArray.some((option: any) => option?.value?.length > 0) || this.state.datans?.length > 0;
  }
  buildBaseUrl = (queryParams: any[]) => {
    let baseUrl = `${configJSON.editDropdownEnd}`;
    if (queryParams?.length > 0) {
      baseUrl += '?' + queryParams?.join('&') + '&type=clinician';
    }
    return baseUrl;
  }

   buildDateGeneralParams = ( roleSelected: any, departmentSelected: any, datans: string,selectDateActivity:any) => {
    const queryParams: string[] = [];
    const addQueryParam2 = (paramName: string, values: (string | number | boolean)[]) => {
         /* istanbul ignore next */
      const queryParamss = values?.map((value) => `${paramName}[]=${encodeURIComponent(value)}`).join('&');
      queryParams.push(queryParamss)
      return queryParamss;
    };
    const transformToQueryParams = (departmentSelected:any) => {
        /* istanbul ignore next */
      const values = departmentSelected?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
      return addQueryParam2('department', values);
    };
    const transformToQueryParams2=(roleSelected:any)=>{
         /* istanbul ignore next */
      const values = roleSelected?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
      return addQueryParam2('role', values);
    }
    const addQueryParam = (key: string, value: string | undefined) => {
      if (value) queryParams.push(`${key}=${value}`);
    };
  
    addQueryParam('query', datans && decodeURIComponent(datans));
    addQueryParam('role', roleSelected?.value);
       /* istanbul ignore next */
    if(selectDateActivity?.startDate && selectDateActivity?.endDate){
    addQueryParam('last_activity',  `${selectDateActivity.startDate}..${selectDateActivity.endDate}`)
    }
    if(departmentSelected){
    transformToQueryParams(departmentSelected);
    }
    if(roleSelected){
      transformToQueryParams2(roleSelected);
    }
    return queryParams;
  };
  

  renderSelectedOption5() {
    const { selectActivity } = this.state;

    if (!selectActivity?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div5"
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: 700,
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
        key={selectActivity.value}
      >
        <CloseIcon
          onClick={this.removeOption5}
          data-test-id="checkicon5"
          style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
        />
        {selectActivity.label}
      </div>
    );
  }
  
renderSelectedOption2() {
  const { departmentSelected } = this.state;
  /* istanbul ignore next */
  const flattenedOptions = departmentSelected?.flat();
  if (flattenedOptions?.length === 0) {
    return null;
  }

  return (
     /* istanbul ignore next */
    <div key="flatter">
      {flattenedOptions?.map((option:any,range:0) => (
        <div
          key={`${option.value}${range+1}`}
          data-test-id="div2"
          style={{
            margin: '5px',
            borderRadius: '20px',
            padding: '10px',
            width: 'fit-content',
            backgroundColor: '#0075BF',
            color: 'white',
            fontWeight: 700,
            overflow: 'hidden',
            fontFamily: 'Inter-Bold,sans-serif',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <CloseIcon
           /* istanbul ignore next */
            onClick={() => this.removeOption2(option.value)}
            data-test-id="checkicon2"
            style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
          />
          {option.label}
        </div>
      ))}
    </div>
  );
}

  removeOption2 = (valueToRemove: any) => {
     /* istanbul ignore next */
    this.setState((prevState) => {
      const updatedDepartments = prevState?.departmentSelected
        .map((group: any[]) => group?.filter((option: { value: any }) => option?.value !== valueToRemove))
        .filter((group: string | any[]) => group.length > 0); 
  
      return { departmentSelected: updatedDepartments };
    }, this.handleDropdownData);
  };
  removeOption3= (valueToRemove: any) => {
     /* istanbul ignore next */
    this.setState((prevState) => {
      const updatedRoles = prevState?.roleSelected
        .map((group: any[]) => group?.filter((option: { value: any }) => option?.value !== valueToRemove))
        .filter((group: string | any[]) => group?.length > 0); 
  
      return { roleSelected: updatedRoles };
    }, this.handleDropdownData);
  };
 
  
  removeOption5 = () => {
    this.setState({  selectActivity: null,selectDateActivity:{startDate:'',endDate:''} },this.handleDropdownData);
  }

  handleDate(date:any, picker:any) {
     /* istanbul ignore next */
    let dates = date?.["$d"];
    let day = dates?.getDate().toString().padStart(2, '0');
    let month = (dates?.getMonth() + 1).toString().padStart(2, '0');
    let year = dates?.getFullYear();
    let finalDate = `${day}-${month}-${year}`;
  
    if (picker === 'first') {
      this.setState({
        firstDate: finalDate
      });
    } else if (picker === 'second') {
      this.setState({
        secondDate: finalDate
      });
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  closePopUp() {
    this.setState({
      openCalender: false
    });
  }
  openPopUp() {
    this.setState({
      openCalender: true
    });
  }
  handleActivity = (selectedActivity:any) => {
    if (selectedActivity.value === 'custom') {
      this.openPopUp()
      return;
    } 

    let startDate='', endDate=''
  
    switch (selectedActivity.value) {
      case 'lastDay':
        startDate = dayjs().subtract(1, 'day').startOf('day').format('DD-MM-YYYY');
        endDate = dayjs().subtract(1, 'day').endOf('day').format('DD-MM-YYYY');
        break;
      case 'lastWeek':
        startDate = dayjs().subtract(1, 'week').startOf('week').format('DD-MM-YYYY');
        endDate = dayjs().subtract(1, 'week').endOf('week').format('DD-MM-YYYY');
        break;
      case 'lastMonth':
        startDate = dayjs().subtract(1, 'month').startOf('month').format('DD-MM-YYYY');
        endDate = dayjs().subtract(1, 'month').endOf('month').format('DD-MM-YYYY');
        break;
      default:
        break;
    }

        this.setState(
          { 
            selectActivity: selectedActivity, 
            selectDateActivity: { startDate, endDate } 
          },
          this.handleDropdownData
        );
  };
  confirmCal() { 
    const { firstDate, secondDate } = this.state;
    const answer={label:`${firstDate}-${secondDate}`,value:`${firstDate}-${secondDate}`}
    if (firstDate && secondDate) {
      this.setState({
        selectActivity: answer,
        selectDateActivity: { startDate: firstDate, endDate: secondDate },
      },this.handleDropdownData);
    } 
    this.setState({openCalender:false})
  }
 
  roleHandle = (selectedStock: any) => {
    this.setState((prevState) => {
      const currentSelections = prevState?.roleSelected || [];
      
      const alreadySelectedRole = currentSelections?.some(
        (option: any[]) => option[0]?.value === selectedStock[0]?.value
      );
      
      if (alreadySelectedRole) {
        return null; 
      }
      
      return {
        roleSelected: [...currentSelections, selectedStock]
      };
    }, this.handleDropdownData);
  };
  departmentTypeChange = (selectedOption: any) => {
    this.setState((prevState) => {
      const currentSelections = prevState?.departmentSelected || [];
      
      const alreadySelected = currentSelections?.some(
        (option: any[]) => option[0]?.value === selectedOption[0]?.value
      );
      
      if (alreadySelected) {
        return null; 
      }
      
      return {
        departmentSelected: [...currentSelections, selectedOption]
      };
    }, this.handleDropdownData);
  };
  clinicianTable=(eventOrValue: any) => {
    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.handleDropdownData();
      this.autoSuggestionsClinician();
    });
  }

  editClinicianTest = (testId: number) => {
    this.props.navigation.navigate(`TestEdit`, {
      id: testId
    })
  }

  handleRoleSelect = (role: string) => {
    this.setState({ role });
  };
  handleRow=(statuss:any,id:any)=>{
    if(statuss.status?.toLowerCase() === "reviewed"){
      this.handleNavigateToResultTest(id)
     }
     else if(statuss.status?.toLowerCase()==='cancelled'){
      this.setState({popupcancel:true})
     }
     else{
      this.setState({dataHandle:statuss})
      this.setState({popupview:true})
     }
    }
  handleOrderByChange = () => {
    this.setState(prevState => ({
      orderBy: prevState.orderBy === "asc" ? "desc" : "asc"
    }));
  };

  setSelectedClinician = (clinician: any) => {
    this.setState({
      isVisibleModal: true,
      selectedClinician: clinician
    })
  }

  handleNavigateToClinicianProfile = (clinicianId: number) => {
    this.props.navigation.navigate("ClinicianProfile", { id: clinicianId })
  }

  findRoleByValue = (value: string) => {
    const role = this.state?.roleOptions?.find((role: { name: string; }) => role.name === value);
    return role?.id 
  };

  openCancelTestPopUp = (testId: number) => {
    this.setState({
      canceledTestId: testId,
      isVisibleModal: true
    })
  }

  renderLoader = () => (
    <Box
      width="100vw"
      height="100vh"
      alignItems="center"
      position="fixed"
      top={0}
      justifyContent="center"
      bgcolor="rgba(0, 117, 191, 0.2)"
      left={0}
      zIndex={15}
      display="flex">
      <CircularProgress size={50} />
    </Box>
  )

  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            fontFamily: 'Inter-Light, sans-serif',
            fontWeight: 300,
            color: "#E7205D",
            fontSize: "14px",
            lineHeight: "22px"
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };
  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  isValidResponse(message: Message, apiCallId: string) {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiCallId != null && apiCallId ===  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  }

  orderTest = () => {
    localStorage.setItem("isOrderTestButtonClicked", "yes")
    this.props.navigation.navigate("Patient")
  }

  handleNavigateToResultTest(testId: number) {
    this.props.navigation.navigate('ResultTest', { id: testId });
  }

  handleDownloadTest = (link: string) => {
    if(link){
     /* istanbul ignore next */
      window.open(link, '_blank')
    }
  };
   formatDate = (date:any) => {
    return date ? dayjs(date).format('DD/MM/YYYY') : "-";
  }
  handleVisibleErrorModal = () => {
    this.setState({
      isVisibleErrorModal: !this.state.isVisibleErrorModal
    })
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const handleResponse2 = (responseJson: any, successCallback: () => void, propertyToCheck: string) => {
    if (!responseJson.errors && responseJson) {
      successCallback();
    }else{
      this.setState({ 
        errorMessage: responseJson.message,
        isVisibleModal: false,
        isVisibleErrorModal: true,
        disabledAccept: false,
       });
     }
    };
    const handleResponse = (responseJson: any, successCallback: () => void, propertyToCheck: string) => {
      if (!responseJson.errors && responseJson[propertyToCheck]) {
        successCallback();
      }else{
        this.setState({ 
          errorMessage: responseJson.message,
          isVisibleModal: false,
          isVisibleErrorModal: true,
          disabledAccept: false,
          dataFetching: false,
          isLoader: false
         });
      }
    };
  
    const handleDataResponse = (responseJson: any, successCallback: () => void) => {
      handleResponse(responseJson, successCallback, "data");
    };
  
    const responseHandlers = [
      {
        isValid: this.isValidResponse(message, this.getAutoComplete),
        handle: () => handleResponse2(responseJson, () => this.setState({ getAutoSearchSuggestions: responseJson }), ""),
      },
      {
        isValid: this.isValidResponse(message, this.getGroupsApiCallId),
        handle: () => handleDataResponse(responseJson, () => this.setState({ groupList: responseJson.data })),
      },
      {
        isValid: this.isValidResponse(message, this.fetchRolesApiCallId),
        handle: () => handleResponse(responseJson, () => this.setState({ roleOptions: responseJson.roles }), "roles"),
      },
      {
        isValid: this.isValidResponse(message, this.getFilter),
        handle: () => handleDataResponse(responseJson, () =>  this.setState({ clinicianViewSearchAns: [...responseJson?.data] })),
      },
      {
        isValid: this.isValidResponse(message, this.fetchClinicianTestsApiCallId),
        handle: () => handleDataResponse(responseJson, () => this.setState({ clinicianTests: responseJson.data, dataFetching: false })),
      },
      {
        isValid: this.isValidResponse(message, this.postGroupApiCallId),
        handle: () =>
          handleDataResponse(responseJson, () => {
            this.setState({ disabledAccept: false, isLoader: false });
            this.fetchClinicians();
            this.handleNavigateToClinicians();
          }),
      },
      {
        isValid: this.isValidResponse(message, this.getDropdown),
        handle: () => handleDataResponse(responseJson, () => this.setState({ dropdownDataAns: responseJson?.data })),
      },
      {
        isValid: this.isValidResponse(message, this.searchPatientData),
        handle: () => handleDataResponse(responseJson, () => this.setState({ searchdataAns: responseJson.data })),
      },
      {
        isValid: this.isValidResponse(message, this.getClinicianApiCallId),
        handle: () =>
          handleDataResponse(responseJson, () => {
            this.setState({
              showClinician: responseJson.data,
              firstName: responseJson.data.attributes.common_information.first_name,
              surname: responseJson.data.attributes.common_information.last_name,
              email: responseJson.data.attributes.common_information.email,
              department: responseJson.data.attributes.clinician_information.department,
              role: this.findRoleByValue(responseJson.data.attributes.clinician_information.role),
              NMCNumber: responseJson.data.attributes.clinician_information.nmc_number,
              GMCNumber: responseJson.data.attributes.clinician_information.gmc_number,
            });
          }),
      },
      {
        isValid: this.isValidResponse(message, this.putGroupApiCallId),
        handle: () =>
          handleDataResponse(responseJson, () => {
            this.setState({ disabledAccept: false, isLoader: false });
            this.fetchClinicians();
            this.handleNavigateToClinicians();
          }),
      },
      {
        isValid: this.isValidResponse(message, this.postRemoveAccountsApiCallId),
        handle: () =>
          handleDataResponse(responseJson, () => {
            this.setState({ isLoader: false })
            this.fetchClinicians();
          }),
      },
      {
        isValid: this.isValidResponse(message, this.postResendInvitationApiCalId),
        handle: () => handleResponse(responseJson, () => this.setState({ disabledAccept: false, isLoader: false }), "message"),
      },
      {
        isValid: this.isValidResponse(message, this.patchCancelTestApiCalId),
        handle: () =>
          handleDataResponse(responseJson, () => {
            this.setState({ disabledAccept: false, isLoader: false });
            this.fetchClinicianTests(this.props.navigation.getParam("id"));
          }),
      },
    ];
  
    responseHandlers.forEach(({ isValid, handle }) => {
      if (isValid) handle();
    });
  };
  

  // Function to fetch roles from API
  fetchRoles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchRolesApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchClinicianTests = (clinicianId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchClinicianTestsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchClinicianTestsEndPoint}?clinician_id=${Number(clinicianId)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      dataFetching: true
    })
  };

  // Function to fetch clinicians from API
  fetchClinicians = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchCliniciansApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClinician = (clinicianId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClinicianApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClinicianApiEndPoint}?id=${clinicianId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFilterSource = () => {
    return this.state.datans ? this.state.clinicianViewSearchAns : this.state.clinicianTests;
  }
  // Function to add new clinician and send it to API
  handleCreateClinician = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const httpBody = {
      data: {
        attributes: {
          first_name: this.state.firstName,
          last_name: this.state.surname,
          email: this.state.email,
          role_id: this.state.role,
          department: this.state.department,
          gmc_number: this.state.GMCNumber,
          nmc_number: this.state.NMCNumber
        }
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createClinicianApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ disabledAccept: true, isLoader: true })
    this.handleCloseModal()
  }

  // Function to edit group and send it to API
  handleEditClinician = (clinicianId: string) => {
    const headers = {
      token: this.state.token
    };

    const {firstName, surname, email, role, department, GMCNumber, NMCNumber} = this.state
    const formData = new FormData();
    formData.append("data[attributes][first_name]", firstName)
    formData.append("data[attributes][last_name]", surname)
    formData.append("data[attributes][email]", email)
    formData.append("data[attributes][role_id]", role)
    formData.append("data[attributes][department]", department)
    formData.append("data[attributes][gmc_number]", GMCNumber)
    formData.append("data[attributes][nmc_number]", NMCNumber)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editClinicianApiEndPoint + `?id=${clinicianId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    this.handleCloseModal()
  }

  // Function to delete clinician
  handleDeleteClinician = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.destroyClinicianApiEndPoint + `?id=${this.state.selectedClinician?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    this.handleCloseModal()
  };

  handleResendInvitation = (clinicianId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const httpBody = { clinician_id: clinicianId };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postResendInvitationApiCalId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendInvitationApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
  };

  cancelClinicianTest = (testId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.patchCancelTestApiCalId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelTestApiEndPoint + `?id=${testId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ disabledAccept: true, isLoader: true })
    this.handleCloseModal();
  };
  // Customizable Area End
}
