import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Breadcrumbs,
  Grid,
  Select,
  MenuItem,
  Tooltip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from '@material-ui/icons';
import { configJSON } from "./AccountGroupsController";
import { info } from './assets'
import { Formik } from "formik"


// Customizable Area End

import PatientController, {
  Props,
} from "./PatientController";
import EditModal from "../../../components/src/EditModal";
import ErrorModal from "./ErrorModal";

export default class EditPatient extends PatientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  

  // Customizable Area End

  render() {
    const { instituteNumber, editQueryId, institutionName, allValues, 
            viewSpecificList, stepperTwoValues, editDataResponse } = this.state;
    const breadcrumbs = [
      <Typography 
        onClick={this.redirectPatientPage} data-test-id="redirectPatientBtn"
        style={{...webStyle.breadcrumbsLink, cursor: "pointer"}} 
        key="1" >
        Patients
      </Typography>,
      <Typography 
        onClick={() => this.viewPatient(editDataResponse?.id)}
        style={{...webStyle.breadcrumbsLink, cursor: "pointer"}}  
        key="2" 
        data-test-id="redirectViewPatientBtn" >
          {viewSpecificList?.first_name} {" "} {viewSpecificList?.last_name}
      </Typography>,
      <Typography
        key="3"
        style={{...webStyle.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>
          Edit
      </Typography>,
    ];
    const formattedInstitutionNumber = this.formatInstitutionDetails(instituteNumber);
    const formattedInstitutionName = this.formatInstitutionDetails(institutionName);

    return (
      // Customizable Area Start
      <Container style={{marginTop: "20px", overflow:'auto', paddingBottom:'55px'}} maxWidth="xl">
        <Breadcrumbs
          style={{margin: '20px 0 30px 0'}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb">
          {breadcrumbs} 
        </Breadcrumbs> 
        <Container style={{ overflow:'auto',paddingBottom:'55px'}} maxWidth="lg">
        {this.state.isLoader && this.showLoader()}
        <Box>
          <Box>
            <Box style={{display: 'flex', alignItems: 'center'}}>
              <Typography style={ webStyle.personalInformation}>1 </Typography>
              <Typography style={{
                fontSize: '24px',
                fontFamily: "Inter-Bold, sans-serif",
                color: 'rgba(33, 53, 95, 1)',
                marginLeft: '15px'
              }}>Personal Information</Typography>
            </Box>
            <Formik
              enableReinitialize
              initialValues={{ 
                ...allValues,
                street1: stepperTwoValues.street1,
                city: stepperTwoValues.city,
                postCode: stepperTwoValues.postCode,
                country: stepperTwoValues.country,
               }}
              data-test-id="createForms"
              validationSchema={this.personalValidationSchema().concat(this.addressValidationSchema())}
              onSubmit={() => { this.editAccountModal() }}>
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box style={{ padding: '0 50px' }}>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>First Name*</InputLabel>
                            <Input
                               style={webStyle.inputField}
                               disableUnderline
                               name="firstName"
                               value={allValues.firstName}
                               onChange={(event) => {
                                 props.setFieldValue("firstName", event.target.value);
                                 this.onValueChange("firstName", event.target.value);
                               }}
                               placeholder="First Name"
                               fullWidth
                            />
                            {this.getErrorMessage(props.touched, props.errors, "firstName")}
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Date of Birth*</InputLabel>
                            <Input
                              value={allValues.dateofBirth}
                              type="date"
                              style={{...webStyle.inputField,height:'56px',paddingBottom:'0px',paddingTop:'0px',
                           }}
                              name="dateofBirth"
                              fullWidth
                              data-test-id="datetest"
                              disableUnderline
                              inputProps={{ max: new Date().toISOString().split("T")[0],  style: { height: '100%', padding: '8px 0', boxSizing: 'border-box' }  }}
                              onChange={(event) => {
                                props.setFieldValue("dateofBirth", event.target.value);
                                this.onValueChange("dateofBirth", event.target.value);
                              }}
                              onClick={(event: any) => {
                                const target = event?.target 
                              
                                if (target?.tagName === 'INPUT' && target?.type === 'date') {
                                  target.focus();
                                  if (typeof target.showPicker === 'function') {
                                    target.showPicker();
                                  }
                                }
                              }}
                              
                              placeholder="Date of Birth"
                            />
                            {this.getErrorMessage(props.touched, props.errors, "dateofBirth")}
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <InputLabel style={webStyle.label}>NHS Number*</InputLabel>
                              <Tooltip arrow disableFocusListener disableTouchListener title={configJSON.infoNHSNumber}>
                                <img style={{ marginLeft: '10px' }} src={info} />
                              </Tooltip>
                            </Box>
                            <Input
                              style={webStyle.inputField}
                              disableUnderline
                              value={allValues.NHSNumber}
                              name="NHSNumber"
                              onChange={(event) => {
                                props.setFieldValue("NHSNumber", event.target.value);
                                this.onValueChange("NHSNumber", event.target.value);
                              }}
                              fullWidth
                              placeholder="NHS Number"
                            />
                            {this.getErrorMessage(props.touched, props.errors, "NHSNumber")}
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Email</InputLabel>
                            <Input
                              name="email"
                              style={webStyle.inputField}
                              disableUnderline={true}
                              value={allValues.email}
                              onChange={(event) => {
                                props.setFieldValue("email", event.target.value);
                                this.onValueChange("email", event.target.value);
                              }}
                              placeholder="Email"
                              fullWidth={true}
                            />
                            {this.getErrorMessage(props.touched, props.errors, "email")}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box style={{ padding: '0 50px' }}>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Last Name*</InputLabel>
                            <Input
                              name="surName"
                              placeholder="Last Name"
                              disableUnderline={true}
                              fullWidth={true}
                              value={allValues.surName}
                              onChange={(event) => {
                                props.setFieldValue("surName", event.target.value);
                                this.onValueChange("surName", event.target.value);
                              }}
                              style={webStyle.inputField}
                            />
                            {this.getErrorMessage(props.touched, props.errors, "surName")}
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Sex at Birth*</InputLabel>
                            <Select
                              style={webStyle.inputField}
                              placeholder="Sex at Birth"
                              name="SexAtBirth"
                              value={allValues.SexAtBirth}
                              onChange={(event: any) => {
                                this.onValueChange("SexAtBirth", event.target.value);
                                props.setFieldValue("SexAtBirth", event.target.value);
                              }}
                              disableUnderline
                              fullWidth
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Sex at Birth
                              </MenuItem>
                              <MenuItem value="Male">Male</MenuItem>
                              <MenuItem value="Female">Female</MenuItem>
                            </Select>
                            {this.getErrorMessage(props.touched, props.errors, "SexAtBirth")}
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Institution Number</InputLabel>
                            <Input
                              name="institutionNumber"
                              disableUnderline={true}
                              readOnly
                              style={{
                                ...webStyle.inputField,
                                backgroundColor: "#EAE8E8",
                                color: "#0F172A",
                                fontSize: "16px",
                                fontFamily: 'Inter-Regular, sans-serif',
                              }}
                              value={formattedInstitutionName || formattedInstitutionNumber}
                              placeholder="Institution Number"
                              fullWidth={true}
                            />
                          </Box>
                          <Box sx={webStyle.inputWrapper}>
                            <InputLabel style={webStyle.label}>Mobile Number*</InputLabel>
                            <Box style={{ display: "flex" }}>
                              <Input
                                name="mobileNumber"
                                data-test-id={"mobileNumber"}
                                style={{
                                  ...webStyle.inputField
                                }}
                                id="mobileNumber"
                                value={allValues.mobileNumber}
                                disableUnderline={true}
                                onChange={(event) => {
                                  const numericValue = event.target.value.replace(/\D/g, '')
                                  this.onValueChange("mobileNumber", numericValue)
                                  props.setFieldValue("mobileNumber", numericValue)
                                }}
                                placeholder="Mobile number with country code"
                                fullWidth={true}
                              />
                            </Box>
                            {this.getErrorMessage(props.touched, props.errors, "mobileNumber")}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={webStyle.personalInformation}>2</Typography>
                        <Typography
                          style={{
                            fontSize: '24px',
                            fontFamily: "Inter-Bold, sans-serif",
                            color: 'rgba(33, 53, 95, 1)',
                            marginLeft: '15px'
                          }}>
                          Address details
                        </Typography>
                      </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box style={{ padding: '0 50px' }}>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Street 1*</InputLabel>
                                <Input
                                  name="street1"
                                  value={stepperTwoValues.street1}
                                  style={webStyle.inputField}
                                  placeholder="Street 1"
                                  disableUnderline={true}
                                  fullWidth={true}
                                  onChange={(event) => {
                                    props.setFieldValue("street1", event.target.value);
                                    this.onValueChange("street1", event.target.value);
                                  }}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "street1")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Town/City*</InputLabel>
                                <Input
                                  name="town"
                                  value={stepperTwoValues.city}
                                  placeholder="Town/City*"
                                  onChange={(event) => {
                                    props.setFieldValue("city", event.target.value);
                                    this.onValueChange("city", event.target.value);
                                  }}
                                  fullWidth={true}
                                  style={webStyle.inputField}
                                  disableUnderline={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "city")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Postcode*</InputLabel>
                                <Input
                                  name="postCode"
                                  placeholder="Postcode"
                                  value={stepperTwoValues.postCode}
                                  onChange={(event) => {
                                    props.setFieldValue("postCode", event.target.value);
                                    this.onValueChange("postCode", event.target.value);
                                  }}
                                  fullWidth={true}
                                  style={webStyle.inputField}
                                  disableUnderline={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "postCode")}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box style={{ padding: '0 50px' }}>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Street 2</InputLabel>
                                <Input
                                  name="street2"
                                  placeholder="Street 2"
                                  value={stepperTwoValues.street2}
                                  style={webStyle.inputField}
                                  fullWidth={true}
                                  onChange={(event) => {
                                    props.setFieldValue("street2", event.target.value);
                                    this.onValueChange("street2", event.target.value);
                                  }}
                                  disableUnderline={true}
                                />
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Country*</InputLabel>
                                <Select
                                  name="country"
                                  value={stepperTwoValues.country}
                                  placeholder="Country"
                                  displayEmpty
                                  disableUnderline
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                      style: {
                                        maxHeight: 220
                                      },
                                    },
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }
                                  }}
                                  style={webStyle.selectStyles}
                                  fullWidth
                                  onChange={(event: any) => {
                                    this.onValueChange("country", event.target.value);
                                    props.setFieldValue("country", event.target.value);
                                  }}>
                                  <MenuItem value="" disabled>
                                    Country
                                  </MenuItem>
                                  {configJSON.countries.map((country: {name: string}) => (
                                    <MenuItem key={country.name} value={country.name}>
                                      {country.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {this.getErrorMessage(props.touched, props.errors, "country")}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box style={webStyle.buttonsWrapper}>
                          <Button
                            style={webStyle.cancelButton}
                            onClick={this.redirectPatientPage}>
                              Cancel
                          </Button>
                          <Button
                            type="submit"
                            style={webStyle.saveInformationButton}
                            variant="contained"
                            color="primary">
                              Save Information
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Container>
        <EditModal 
          data-test-id="editModal" 
          allValues={allValues} 
          hideEditAccountModal={this.hideEditAccountModal} 
          editQueryId={editQueryId} 
          editModal={this.state.editModal}
          editGroup={() => this.editGroup(editQueryId)}
        />
        <ErrorModal
          isVisible={this.state.showErrorModal}
          onAccept={this.handleCloseErrorModal}
          message={this.state.nhsNumberErrorMessage}
          data-test-id="errorModal"
        />
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    marginBottom: '25px',
    position: "relative",
    marginTop: '25px'
  },
  buttonsWrapper: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'end',
  },
  personalInformation: {
    background:'rgba(231, 32, 93, 1)',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    fontFamily: "Inter-Bold, sans-serif",
    height: '40px',
    justifyContent: 'center',
    width: '40px',
    fontSize: '24px',
    alignItems: 'center',
  },
  nonActiveStepper: {
    border:'1px solid #000000',
    borderRadius: '50%',
    color: '#000000',
    display: 'flex',
    fontFamily: 'Inter-Regular, sans-serif',
    fontSize: '24px',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },
  label: {
    color: '#21355F',
    fontFamily: 'Inter-Bold, sans-serif',
    fontSize: "14px",
    marginBottom :'10px',
    marginTop: "8px",
  }, 
  inputField: {
    background: 'none',
    border: '1px solid rgba(203, 213, 225, 1)',
    borderRadius: '8px',
    padding: '10px 10px',
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },
  buttonStyle: {
    backgroundColor: "rgba(231, 32, 93, 1)",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontFamily: 'Inter-Bold, sans-serif',
    height: "45px",
    marginLeft: '30px',
    marginTop: "25px",
    textTransform: 'none' as const,
    width: '167px',
  },
  saveInformationButton: {
    backgroundColor: "rgba(231, 32, 93, 1)",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontFamily: 'Inter-Bold, sans-serif',
    height: "45px",
    marginLeft: '30px',
    marginTop: "25px",
    textTransform: 'none' as const,
  },
  cancelButton: {
    border: "1px solid rgba(0, 117, 191, 1)",
    borderRadius: '8px',
    color: "rgba(0, 117, 191, 1)",
    cursor: "pointer",
    fontFamily: 'Inter-Bold, sans-serif',
    height: "45px",
    marginTop: "25px",
    textTransform: 'none' as const,
    width: "166px",
  },
  breadcrumbsLink: {
    color: "#21355F",
    fontFamily: "Inter-Regular, sans-serif",
    fontSize: "24px", 
  },
  selectStyles: {
    background: "none",
    border: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: "8px",
    color: "#21355F",
    height: "56px",
    padding: "10px 10px",
    width: "100%",
  },
}
// Customizable Area End
