// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine"
import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import dayjs, { Dayjs } from 'dayjs';
import { autoComplete } from "./assets";

export interface Props {
  navigation: any;
  id: string;

}

interface S {
  testData: [];
  dropDowndata: any[],
  getCompleteSuggestions:[],
  selectedOptions: any,
  selectedOptions3: any,
  selectedOptions4: any,
  selectedOptions5: any,
  selectedOptions6: any,
  selectedOptions7: any,
  selectedOptions8: any,
  selectedOptions9: any,
  editTestDataRecord: any;
  currDate: NumberIndexedObject;
  showInventory: boolean,
  popup:boolean,
  popups:boolean,
  datans: string,
  searchdataAns: any[];
  hospitalValue: any,
  institutionId: any
  handleTest: boolean,
  findSearch: boolean;
  setToken: string,
  checkCall: boolean,
  setValue: string,
  newDateData: string,
  openCalender: string,
  openReorderPopup: boolean;
  showTests: string,
  openOptionSuccess: boolean;
  statusTest: string,
  anchorEl: null,
  testId: number,
  patientName: string
  popupTitle: string
  popupTitles:any,
  dataFetching: boolean
  // Customizable Area End
}

interface NumberIndexedObject {
  [key: string]: string;
}
interface SS {
  id: any;
}
export default class TetsController extends BlockComponent<
  Props,
  S,
  SS


> {
  testsProducts = "";
  getAutoComplete="";
  getEditData = "";
  deletetest = "";
  searchFindData = "";
  editTestData = ""
  editDateData = ""
  getDropdown = "";
  handleReorder() {
    this.setState({
      openReorderPopup: true
    });
    this.setState({
      openOptionSuccess: false
    });
    this.setState({ anchorEl: null });
  }
  handleSuccess(event: any, status: any, idd: number, patientname: string) {
    this.setState({
      openOptionSuccess: true,
      statusTest: status,
      testId: idd,
      patientName: patientname
    });
    this.setState(
      { anchorEl: event?.currentTarget },
      () => this.getEditTestData(idd)
    );
  }
  closeSuccess() {
    this.setState({
      openOptionSuccess: false
    });
    this.setState({ anchorEl: null });
  }
  closeReorder() {
    this.setState({
      openReorderPopup: false
    });
  }
  closeTest(testId: any) {
    this.deleteTest(testId);
  }
  handleRow(status: string, id: number) {
    this.getEditTestData(id)
    const showPopup = (popupTitle: string) => {
      this.setState({
        popup: true,
        popupTitle,
      });
    };
    const showPopups= (popupTitle: string,popupTitles: []) => {
      this.setState({
        popups:true,
        popupTitle,
        popupTitles
      });
    };
  setTimeout(()=>{
    switch (status.toLowerCase()) {
      case 'reviewed':
        this.props.navigation.navigate('ResultTest', { id });
        this.getEditTestData(id)
        break;
      case 'cancelled':
        showPopup("Test has been cancelled");
        break;
      default:
        showPopups(`Awaiting for results for below`,this.state.editTestDataRecord?.attributes);
        break;
    }
  },1000)
  }
  handlePopup(){
    this.setState({
      popup: false,
      popups:false,
      popupTitle: '',
      popupTitles:[],
    })
  }
  handleOrderTest() {
    localStorage.setItem("isOrderTestButtonClicked", "yes")
    this.props.navigation.navigate('Patient');
  }
  handleSpecificPatient = (id: any) => {
    this.props.navigation.navigate('PatientView', { id })
  }
  handlePatient = () => {
    this.props.navigation.navigate('Patient')
  }
  handleNavigate = (link: string) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  handleEditTest(id: number) {
    this.props.navigation.navigate(`TestEdit`, {
      id
    })
    this.getEditTestData(id)
  }

  shouldDisableDate = (date: Dayjs) => {
    const day = date.day();
    return day === 0 || day === 5 || day === 6;
  };

  handleResultTest(id: number) {
    this.props.navigation.navigate('ResultTest', { id });
    this.getEditTestData(id)
  }
  dropDownDataFun() {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const headerData = {
      "Content-Type": configJSON.getUserListApiContentType,
      /* istanbul ignore next */ token: data.meta?.token
    };
    const isClinician = data?.data?.attributes?.common_information.user_type === "clinician";

    const endPoint = isClinician && this.state.institutionId ? `${configJSON.editDropdownEndPoint}&institution_id=${this.state.institutionId}` : `${configJSON.editDropdownEndPoint}`
    const getTestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDropdown = getTestData.messageId;

    getTestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getTestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    getTestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );
    runEngine.sendMessage(getTestData.id, getTestData);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      checkCall: false,
      setValue: '',
      findSearch: false,
      setToken: "",
      newDateData: "",
      currDate: {},
      datans: '',
      openCalender: "",
      searchdataAns: [],
      dropDowndata: [],
      getCompleteSuggestions:[],
      handleTest: false,
      showInventory: false,
      testData: [],
      popup:false,
      popups:false,
      popupTitles:[],
      editTestDataRecord: [],
      selectedOptions: [],
      selectedOptions3: [],
      selectedOptions4: [],
      selectedOptions5: [],
      selectedOptions6: [],
      selectedOptions7: [],
      selectedOptions8: [],
      selectedOptions9: [],
      openOptionSuccess: false,
      anchorEl: null,
      hospitalValue: 0,
      institutionId: localStorage.getItem('institutionId') || 'Initial Data',
      openReorderPopup: false,
      showTests: "",
      statusTest: "",
      testId: 0,
      patientName: "",
      popupTitle: "",
      dataFetching: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    const result = localStorage.getItem('data')
    // @ts-ignore
    const parseData = JSON.parse(result)
    /* istanbul ignore next */
    this.setState({ setToken: parseData?.meta?.token, }, () => {
      const { institutionId } = this.state;
      window.addEventListener('storage', this.handleValueChange);
      this.setState({ hospitalValue: institutionId })
      
      this.getEditTestData(this.props.navigation.getParam("id"));
    })

    const searchQuery = this.props.navigation.getParam("id");
    this.setState({ showTests: searchQuery })
  }
  // @ts-ignore
  componentWillUnmount() {
    window.removeEventListener('storage', this.handleValueChange);
  }
  /* istanbul ignore next */
  getUniqueOptions = (array: any[], accessor: any) => {
    const uniqueValues = [...new Set(array.map(accessor).filter(value => value !== null))];
    return uniqueValues.map(value => ({ value, label: value }))
  }

  /* istanbul ignore next */
  handleValueChange = () => {
    this.setState({
      institutionId: localStorage.getItem('institutionId') || 'Initial Data',
    });
  }
  /* istanbul ignore next */
  componentDidUpdate(_: any, prevState: any) {
    if ((prevState.hospitalValue !== this.state.institutionId || this.state.setToken !== prevState.setToken) && this.state.setToken !== "") {
      this.setState({ hospitalValue: this.state.institutionId })
      this.getTestData(this.state.institutionId)
      if(prevState.hospitalValue!=0){
      this.dropDownDataFun()
      this.autoSuggestionsTests()
      }
    }
    if (this.state.checkCall) {
      setTimeout(() => {
        this.getEditTestData(this.props.navigation.getParam("id"));
      }, 1000)
    }
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCall = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (!responseJson?.message) {
        this.handleSuccessfulResponse(apiRequestCall, responseJson);
      } else if(responseJson.message){
        this.setState({
          openReorderPopup: false,
          popup: true,
          popups:true,
          popupTitle: responseJson.message
        })
      }
    }
  }
  
  handleSuccessfulResponse(apiRequestCall: any, responseJson: any) {
    const responseHandlers = {
      [this.testsProducts]: () => {
        if (responseJson?.data) {
          this.setState({ testData: responseJson.data, dataFetching: false });
        }
      },
      [this.searchFindData]: () => {
        if (responseJson?.data) {
          this.setState({ searchdataAns: [...responseJson.data] });
        }
      },
      [this.getEditData]: () => {
        if (responseJson.data) {
          this.setState({ 
            editTestDataRecord: responseJson.data,
            setValue: responseJson.data.attributes?.additional_info 
          });
        }
      },
      [this.getDropdown]: () => {
        if (responseJson.data) {
          this.setState({ dropDowndata: responseJson.data });
        }
      },
      [this.getAutoComplete]: () => {
        if (responseJson) {
          this.setState({ getCompleteSuggestions: responseJson });
        }
      },
      [this.deletetest]: () => {
        if (responseJson) {
          this.getTestData(this.state.institutionId)
          this.setState({
            openReorderPopup: false,
          })
        }
      },
    };
  
    const handleResponse = responseHandlers[apiRequestCall];
    if (handleResponse) handleResponse();
  }

  getDataSource = () => {
    return this.state.findSearch ? this.state.searchdataAns : this.state.testData;
  }
  
  formatDate = (date: Dayjs) => {
    return date ? dayjs(date).format('DD/MM/YYYY') : "-";
  };

  customTestStyles = {
    //this is to not show options in that particular box
    multiValue: (base: any) => ({
      ...base,
      display: 'none',
    }),
    option: (provided: any, state: any) => {
      const { selectProps, data } = state;
      const dropdownId = selectProps?.id;
      let selectedValues;

  switch (dropdownId) {
    case 'selectedOptions':
      selectedValues = [this.state.selectedOptions?.value];
      break;
    case 'selectedOptions3':
      selectedValues = [this.state.selectedOptions3?.value];
      break;
    case 'selectedOptions4':
      selectedValues = [this.state.selectedOptions4?.value];
      break;
    case 'selectedOptions5':
      selectedValues = this.state.selectedOptions5?.flat().map((option: { value: any; }) => option.value);
      break;
    case 'selectedOptions6':
      selectedValues = this.state.selectedOptions6?.flat().map((option: { value: any; }) => option.value);
      break;
    case 'selectedOptions7':
      selectedValues = this.state.selectedOptions7?.flat().map((option: { value: any; }) => option.value);
      break;
    case 'selectedOptions8':
      selectedValues = this.state.selectedOptions8?.flat().map((option: { value: any; }) => option.value);
      break;
    case 'selectedOptions9':
      selectedValues = [this.state.selectedOptions9?.value];
      break;
    default:
      selectedValues = [];
      break;
  }

  const flattenedSelectedValues = selectedValues?.flat();
  const isSelectedFinal = flattenedSelectedValues?.includes(data?.value);

      return {
        ...provided,
        marginTop: '5px',
        marginBottom: '5px',
        color: isSelectedFinal ? 'white' : '#21355F',
        fontFamily: 'Inter-Regular,sans-serif',
        fontWeight: "normal",
        backgroundColor: isSelectedFinal ? '#0075BF' : 'white',
        "&:hover": {
          cursor: "pointer",
          backgroundColor: '#0075BF',
          color: 'white'
        },
        overflow: 'auto',
        maxHeight: '100px'
      };
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#21355F',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: '#DBEAFE',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#21355F',
      fontFamily: 'Inter-Regular,sans-serif',
      fontSize: '14px',
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: '20px',
      color: 'black',
      border: "1px solid #DBEAFE",
      backgroundColor: "#DBEAFE",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus,&:focus-within, &:active": {
        cursor: "pointer",
        borderColor: "none",
        boxShadow: "0 0 5px 2px #DBEAFE",
      },
      width: "127px",
      height: '48px',
      alignItems: 'normal'
    }),
  };
  parseResult(result:any) {
    return result.split('-').map((value:any) => parseFloat(value.trim()));
  }
  
  extractRange(parsedResults:any) {
    return [parsedResults[0], parsedResults[1]];
  }
  renderSelectedOption3() {
    const { selectedOptions3 } = this.state;

    if (!selectedOptions3?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div3"
        key={`${selectedOptions3.value}3`}
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: '700',
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
      >
        <CloseIcon
          data-test-id="checkicon3"
          onClick={this.removeOption3}
          style={{ cursor: 'pointer', fontWeight: '700', height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOptions3.label}
      </div>
    );
  }
  renderSelectedOption() {
    const { selectedOptions } = this.state;

    if (!selectedOptions?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div1"
        key={`${selectedOptions.value}1`}
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: '700',
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
      >
        <CloseIcon
          data-test-id="checkicon1"
          onClick={this.removeOption}
          style={{ cursor: 'pointer', fontWeight: '700', height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOptions.label}
      </div>
    );
  }
  renderSelectedOption4() {
    const { selectedOptions4 } = this.state;

    if (!selectedOptions4?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div4"
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: '700',
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
        key={`${selectedOptions4.value}4`}
      >
        <CloseIcon
          onClick={this.removeOption4}
          data-test-id="checkicon4"
          style={{ cursor: 'pointer', fontWeight: '700', height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOptions4.label}
      </div>
    );
  }
  handleAnswer(answer:any){
  return answer ? answer:'-';
  } 
  checkPriority(answer:any){
  return answer?.toLowerCase() === "urgent" 
  }
  renderSelectedOption5() {
    const { selectedOptions5 } = this.state;
       /* istanbul ignore next */
    const flattenedOptions = selectedOptions5?.flat();
       /* istanbul ignore next */
    if (flattenedOptions?.length === 0) {
      return null;
    }
    return (
      <div key="renderSelectedOption5">
        {flattenedOptions?.map((option:any,range:0) => (
          <div
            key={`${option.value}${range+1}`}
            data-test-id="div5"
            style={{
              margin: '5px',
              borderRadius: '20px',
              padding: '10px',
              width: 'fit-content',
              backgroundColor: '#0075BF',
              color: 'white',
              fontWeight: 700,
              overflow: 'hidden',
              fontFamily: 'Inter-Bold,sans-serif',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon
              onClick={() => this.removeOption5(option.value)}
              data-test-id="checkicon5"
              style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
            />
            {option.label}
          </div>
        ))}
      </div>
    );
  }

  renderSelectedOption6() {
    const { selectedOptions6 } = this.state;
       /* istanbul ignore next */
    const flattenedOptions = selectedOptions6?.flat();
       /* istanbul ignore next */
    if (flattenedOptions?.length === 0) {
      return null;
    }
    return (
      <div key="renderSelectedOption6">
        {flattenedOptions?.map((option:any,range:0) => (
          <div
            key={`${option.value}${range+1}`}
            data-test-id="div6"
            style={{
              margin: '5px',
              borderRadius: '20px',
              padding: '10px',
              width: 'fit-content',
              backgroundColor: '#0075BF',
              color: 'white',
              fontWeight: 700,
              overflow: 'hidden',
              fontFamily: 'Inter-Bold,sans-serif',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon
              onClick={() => this.removeOption6(option.value)}
              data-test-id="checkicon6"
              style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
            />
            {option.label}
          </div>
        ))}
      </div>
    );
  }
  renderSelectedOption7() {
    const { selectedOptions7 } = this.state;
       /* istanbul ignore next */
    const flattenedOptions = selectedOptions7?.flat();
       /* istanbul ignore next */
    if (flattenedOptions?.length === 0) {
      return null;
    }
    return (
      <div key="renderSelectedOption7">
        {flattenedOptions?.map((option:any,range:0) => (
          <div
            key={`${option.value}${range+1}`}
            data-test-id="div7"
            style={{
              margin: '5px',
              borderRadius: '20px',
              padding: '10px',
              width: 'fit-content',
              backgroundColor: '#0075BF',
              color: 'white',
              fontWeight: 700,
              overflow: 'hidden',
              fontFamily: 'Inter-Bold,sans-serif',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon
              onClick={() => this.removeOption7(option.value)}
              data-test-id="checkicon7"
              style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
            />
            {option.label}
          </div>
        ))}
      </div>
    );
  }
  renderSelectedOption8() {
    const { selectedOptions8} = this.state;
       /* istanbul ignore next */
    const flattenedOptions = selectedOptions8?.flat();
       /* istanbul ignore next */
    if (flattenedOptions?.length === 0) {
      return null;
    }
    return (
      <div key="renderSelectedOption8">
        {flattenedOptions?.map((option:any,range:0) => (
          <div
            key={`${option.value}${range+1}`}
            data-test-id="div8"
            style={{
              margin: '5px',
              borderRadius: '20px',
              padding: '10px',
              width: 'fit-content',
              backgroundColor: '#0075BF',
              color: 'white',
              fontWeight: 700,
              overflow: 'hidden',
              fontFamily: 'Inter-Bold,sans-serif',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon
              onClick={() => this.removeOption8(option.value)}
              data-test-id="checkicon8"
              style={{ cursor: 'pointer', fontWeight: 700, height: '16px', position: 'relative', top: '2px' }}
            />
            {option.label}
          </div>
        ))}
      </div>
    );
  }

  renderSelectedOption9() {
    const { selectedOptions9 } = this.state;

    if (!selectedOptions9?.label) {
      return null;
    }

    return (
      <div
        data-test-id="div9"
        style={{
          margin: '5px',
          borderRadius: '20px',
          padding: '10px',
          width: 'fit-content',
          backgroundColor: '#0075BF',
          color: 'white',
          fontWeight: '700',
          overflow: 'hidden',
          fontFamily: 'Inter-Bold,sans-serif'
        }}
        key={`${selectedOptions9.value}9`}
      >
        <CloseIcon
          onClick={this.removeOption9}
          data-test-id="checkicon9"
          style={{ cursor: 'pointer', fontWeight: '700', height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOptions9.label}
      </div>
    );
  }
  autoSuggestionsTests = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician =
      data?.data?.attributes?.common_information.user_type === "clinician";

    const endPoint = isClinician && this.state.institutionId ? this.state.institutionId : null;
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.autoCompleteSearch}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    if (endPoint != null) {
      baseUrl += `&institution_id=${endPoint}`;
    }
    const header = {
      "Content-Type": configJSON.getApiContentType,
      token: data.meta?.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutoComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  buildQueryParamsAuto = () => {
    const {
      datans,
    } = this.state;
    let queryParams = [];

    if (datans) {
      queryParams.push(`query=${decodeURIComponent(datans)}`);
    }

    return queryParams;
  }
  handleTestClick2 = () => {
    this.setState({ handleTest: false })
  }
  handleTestClick = () => {
    this.setState(
      { handleTest: true },
      () => {
        this.dropDownDataFun();
        this.autoSuggestionsTests();
      }
    );
  }
  redirectTestsPage = () => {
    this.props.navigation.navigate("Tests")
  }
  closePopUp() {
    this.setState({
      openCalender: ""
    });
  }
  openPopUp(key: number) {
    this.setState({
      openCalender: key.toString()
    });
  }
  removeOption = () => {
    this.setState({ selectedOptions: null }, this.handleDropdownData);
  }
  removeOption3 = () => {
    this.setState({ selectedOptions3: null }, this.handleDropdownData);
  }
  removeOption4 = () => {
    this.setState({ selectedOptions4: null }, this.handleDropdownData);
  }
  removeOption5 = (valueToRemove: any) => {
    this.setState((prevState) => {
         /* istanbul ignore next */
      const updatedRoles = (prevState?.selectedOptions5 || [])
        .map((group: any[]) => 
           /* istanbul ignore next */
          Array.isArray(group) ? group.filter((option: { value: any }) => option?.value !== valueToRemove) : []
        )
        .filter((group: any[]) => group.length > 0);
  
      return { selectedOptions5: updatedRoles };
   }, this.handleDropdownData);
 };
  removeOption6 = (valueToRemove: any) => {
    this.setState((prevState) => {
         /* istanbul ignore next */
      const updatedRoles = (prevState?.selectedOptions6 || [])
        .map((group: any[]) => 
           /* istanbul ignore next */
          Array.isArray(group) ? group.filter((option: { value: any }) => option?.value !== valueToRemove) : []
        )
        .filter((group: any[]) => group.length > 0);
  
      return { selectedOptions6: updatedRoles };
   }, this.handleDropdownData);
  }
  removeOption7 = (valueToRemove: any) => {
    this.setState((prevState) => {
         /* istanbul ignore next */
      const updatedRoles = (prevState?.selectedOptions7 || [])
        .map((group: any[]) => 
           /* istanbul ignore next */
          Array.isArray(group) ? group.filter((option: { value: any }) => option?.value !== valueToRemove) : []
        )
        .filter((group: any[]) => group.length > 0);
  
      return { selectedOptions7: updatedRoles };
   }, this.handleDropdownData);
  }
  removeOption8 = (valueToRemove:any) => {
    this.setState((prevState) => {
         /* istanbul ignore next */
      const updatedRoles = (prevState?.selectedOptions8 || [])
        .map((group: any[]) => 
           /* istanbul ignore next */
          Array.isArray(group) ? group.filter((option: { value: any }) => option?.value !== valueToRemove) : []
        )
        .filter((group: any[]) => group.length > 0);
  
      return { selectedOptions8: updatedRoles };
   }, this.handleDropdownData);
  }
  removeOption9 = () => {
    this.setState({ selectedOptions9: null }, this.handleDropdownData);
  }



  handleDropdownData = () => {
    const { selectedOptions, selectedOptions3, selectedOptions4, selectedOptions5, selectedOptions6, selectedOptions7, selectedOptions8, selectedOptions9, datans } = this.state;

    if (this.shouldSetFindSearch(selectedOptions, selectedOptions3, selectedOptions4, selectedOptions9, datans)||this.shouldSetFindSearch2( selectedOptions5, selectedOptions6, selectedOptions7, selectedOptions8)) {
      this.setState({ findSearch: true });
    } else {
      return this.setState({ findSearch: false });
    }

    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician = data?.data?.attributes?.common_information.user_type === "clinician";
    const testDataSearch = { "Content-Type": configJSON.getApiContentType, token: data.meta?.token };

    const getTestData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchFindData = getTestData.messageId;

    const queryParams = [
      ...this.buildNameParams(selectedOptions ),
      ...this.buildSamplePriorityParams(selectedOptions9 ),
      ...this.buildDateGeneralParams(selectedOptions3, selectedOptions4, datans),
      ...this.handleMultiSelect(selectedOptions5,selectedOptions6,selectedOptions7, selectedOptions8)
    ];

    let baseUrl = this.buildBaseUrl(queryParams, isClinician);

    this.sendApiRequest(getTestData, baseUrl, testDataSearch);
  };
  shouldSetFindSearch2 = (...selectedOptionsArray: any[]) => {
      /* istanbul ignore next */
 const hasNonEmptyNestedArray = selectedOptionsArray?.some((item) => 
   Array.isArray(item) && item.length > 0
 );
   /* istanbul ignore next */
 return hasNonEmptyNestedArray || this.state.datans?.length > 0;
};
  shouldSetFindSearch = (...selectedOptionsArray: string[]) => {
    return selectedOptionsArray.some((option: any) => option?.value?.length > 0) || this.state.datans?.length > 0;
  }

  buildNameParams = (
    selectedOptions: any
  ) => {
    let queryParams: string[] = [];

    const addNameParams = (selectedOption: { value: string }, paramName: string) => {
      if (selectedOption?.value) {
        const name = selectedOption.value;
        if (name) queryParams.push(`${paramName}=${name}`);
      }
    };
    if (selectedOptions){
      addNameParams(selectedOptions, 'patient_name');
    }
    return queryParams;
  };

  buildSamplePriorityParams = (selectedOptions9: { value: string | any[]; }) => {
    let queryParams = [];
    if (selectedOptions9?.value?.length > 0) queryParams.push(`priority=${selectedOptions9.value}`);
    return queryParams;
  };
  handleMultiSelect=(selectedOptions5:any,selectedOptions6:any,selectedOptions7:any,selectedOptions8:any)=>{
    const queryParams: string[] = [];
    const addQueryParam2 = (paramName: string, values: (string | number | boolean)[]) => {
   const queryParamss = values?.map((value) => `${paramName}[]=${encodeURIComponent(value)}`).join('&');
   queryParams.push(queryParamss)
   return queryParamss;
 };
    const transformToQueryParams2=(selectedOptions6:any)=>{
        /* istanbul ignore next */
    const values = selectedOptions6?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
    return addQueryParam2('clinician_name', values);
    }
    const transformToQueryParams3=(selectedOptions7:any)=>{
        /* istanbul ignore next */
      const values = selectedOptions7?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
      return addQueryParam2('department', values);
      }
      const transformToQueryParams4=(selectedOptions8:any)=>{
          /* istanbul ignore next */
        const values = selectedOptions8?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
        return addQueryParam2('consultant_name', values);
        }
    const transformToQueryParams=(selectedOptions5:any)=>{
      const values = selectedOptions5?.flat()?.map((option: { value: any; }) => option.value)?.filter((value: any) => value);
      return addQueryParam2('sample_type', values);
      }
    if(selectedOptions5?.length>0){
      transformToQueryParams(selectedOptions5);
      }
      if(selectedOptions6?.length>0){
        transformToQueryParams2(selectedOptions6);
      }
      if(selectedOptions7?.length>0){
        transformToQueryParams3(selectedOptions7);
      }
      if(selectedOptions8?.length>0){
        transformToQueryParams4(selectedOptions8);
      }
   return queryParams;
  }
  buildDateGeneralParams = (selectedOptions3: { value: string | any[]; }, selectedOptions4: { value: string | any[]; }, datans: string) => {
    let queryParams = [];

    if (datans) queryParams.push(`query=${decodeURIComponent(datans)}`);
    if (selectedOptions3?.value?.length > 0) queryParams.push(`requested_date=${selectedOptions3.value}`);
    if (selectedOptions4?.value?.length > 0) queryParams.push(`date_received=${selectedOptions4.value}`);

    return queryParams;
  };

  buildBaseUrl = (queryParams: any[], isClinician: boolean) => {
    let baseUrl = `${configJSON.searchArea}`;
    const endPoint = isClinician && this.state.institutionId ? this.state.institutionId : null;

    if (queryParams.length > 0) {
      baseUrl += '?' + queryParams.join('&') + '&type=test_order';
      if (endPoint) baseUrl += '&institution_id=' + endPoint;
    }

    return baseUrl;
  }


  sendApiRequest = (getTestData: Message, baseUrl: string, testDataSearch: { "Content-Type": any; token: any; }) => {
    getTestData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), baseUrl);
    getTestData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), testDataSearch);
    getTestData.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);

    runEngine.sendMessage(getTestData.id, getTestData);
  }
  searchTest = (eventOrValue: any) => {

    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.handleDropdownData();
      this.autoSuggestionsTests();
    });
  }
   SuggestionItem = ({ item, searchText, onClick }:any) => {
    const formattedText = this.formatText(item, searchText);
    const isMatch = this.isTextMatch(item, searchText);
  
    return (
      <div
        data-test-id="testest"
        onClick={onClick}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          margin: '5px',
          fontFamily: 'Inter-Regular, sans-serif',
        }}
      >
        <img src={autoComplete} alt="icon" style={{ marginRight: '10px' }} />
        {isMatch ? (
          <span dangerouslySetInnerHTML={{ __html: formattedText }} />
        ) : (
          <span data-test-id="searchSpan">{item}</span>
        )}
      </div>
    );
  };
  
   formatText = (text:any, searchText:any) => {
    if (typeof searchText !== 'string') return text;
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSearchText, 'i');
    return text.replace(
      regex,
      (match: any) =>
        `<span style="font-weight: 900; color: #000; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">${match}</span>`
    );
  };
  
isTextMatch = (text:any, searchText:any) => {
    if (typeof searchText !== 'string') return false;
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSearchText, 'i');
    return regex.test(text);
  };
  handleChange = (selected: any) => {
    this.setState({ selectedOptions: selected }, this.handleDropdownData);
  };
  handleChange3 = (selected: any) => {
    this.setState({ selectedOptions3: selected }, this.handleDropdownData);
  };
  handleChange4 = (selected: any) => {
    this.setState({ selectedOptions4: selected }, this.handleDropdownData);
  };
  renderFinalSelectedOptions() {
    const { selectedOptions5, selectedOptions6,selectedOptions7,selectedOptions8 } = this.state;
    return (
      <>
        {selectedOptions5?.length > 0 && this.renderSelectedOption5()}
        {selectedOptions6?.length > 0 && this.renderSelectedOption6()}
        {selectedOptions7?.length > 0 && this.renderSelectedOption7()}
        {selectedOptions8?.length > 0 && this.renderSelectedOption8()}
      </>
    );
  }
  handleChange5 = (selectedOptionChange: any) => {
   
    this.setState((prevState) => {
      const currentSelections = prevState?.selectedOptions5 || [];
      
      const alreadySelected5 = currentSelections?.some(
        (option: any[]) => option[0]?.value === selectedOptionChange[0]?.value
      );
      
      if (alreadySelected5) {
        return null; 
      }
      
      return {
        selectedOptions5: [...currentSelections, selectedOptionChange]
      };
    }, this.handleDropdownData);
  };
 
  handleChange6 = (selectedOptionChange: any) => {
    this.setState((prevState) => {
      const currentSelections6 = prevState?.selectedOptions6 || [];
      
      const alreadySelected6 = currentSelections6?.some(
        (option: any[]) => option[0]?.value === selectedOptionChange[0]?.value
      );
      
      if (alreadySelected6) {
        return null; 
      }
      
      return {
        selectedOptions6: [...currentSelections6, selectedOptionChange]
      };
    }, this.handleDropdownData);
  };

  handleChange7 = (selected7: any) => {
    this.setState((prevState) => {
      const currentSelections7 = prevState?.selectedOptions7 || [];
      
      const alreadySelected7 = currentSelections7?.some(
        (option: any[]) => option[0]?.value === selected7[0]?.value
      );
      
      if (alreadySelected7) {
        return null; 
      }
      
      return {
        selectedOptions7: [...currentSelections7, selected7]
      };
    }, this.handleDropdownData);
  };
  handleChange8 = (selectedChange: any) => {
    this.setState((lastState) => {
      const currentChange = lastState?.selectedOptions8 || [];
      
      const alreadySelected = currentChange?.some(
        (option: any[]) => option[0]?.value === selectedChange[0]?.value
      );
      
      if (alreadySelected) {
        return null; 
      }
      
      return {
        selectedOptions8: [...currentChange, selectedChange]
      };
    }, this.handleDropdownData);
  };
  handleChange9 = (selected: any) => {
    this.setState({ selectedOptions9: selected }, this.handleDropdownData);
  };
  removeAllOptions = () => {
    this.setState({
      selectedOptions: null,

      selectedOptions3: null,
      selectedOptions4: null,
      selectedOptions5: null,
      selectedOptions6: null,
      selectedOptions7: null,
      selectedOptions8: null,
      selectedOptions9: null,

    }, this.handleDropdownData);
  };
  getTestData = (instituteId: any) => {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician = data?.data?.attributes?.common_information.user_type === "clinician";

    const headerData = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data.meta?.token
    };

    const getTestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.testsProducts = getTestData.messageId;

    const endPoint = instituteId && isClinician ? `${configJSON.TestsEndPoint}?institution_id=${instituteId}` : configJSON.TestsEndPoint;
    getTestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getTestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    getTestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(getTestData.id, getTestData);
    this.setState({
      dataFetching: true
    })
  };

  handleDate(e: any) {
    let date = e["$d"];
    let finalDate = date?.getDate() + '/' + (date?.getMonth() + 1) + '/' + date?.getFullYear();
    this.setState({
      newDateData: finalDate
    });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  confirmCal() {
    let data = { ...this.state.currDate };
    data[this.state.openCalender] = this.state.newDateData;
    this.setState({
      currDate: { ...data },
      openCalender: ""
    },
      this.editTestDataFun2
    );
  }

  async deleteTest(testId: any) {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const editData = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data.meta?.token
    };
    const deleteTheTest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletetest = deleteTheTest.messageId;

    deleteTheTest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEndPoint}${testId}`
    );

    deleteTheTest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      editData
    );
    deleteTheTest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPatchItemModal
    );
    runEngine.sendMessage(deleteTheTest.id, deleteTheTest);
    
  }

  editTestDataFun2 = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const editData = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data.meta?.token
    };
    const httpBody = {
      test_order: {
        test_date: this.state.currDate[this.state.showTests]
      }
    };

    const getEditTest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editTestData = getEditTest.messageId;

    getEditTest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EditEndPoint}${this.state.showTests}`
    );

    getEditTest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      editData
    );
    getEditTest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );
    getEditTest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getEditTest.id, getEditTest);
    this.setState({ checkCall: true })
  }
  handleInputChange = async (event: any) => {
    const value = event?.target?.value;
    this.setState({ setValue: value })

  };
  editTestDataFun = (event: any) => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const editData = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data.meta?.token
    };

    const httpBody = {
      test_order: {
        additional_info: event.target?.value,
      }
    };
    const getDatafinal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editDateData = getDatafinal.messageId;

    getDatafinal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EditEndPoint}${this.state.showTests}`
    );

    getDatafinal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      editData

    );
    getDatafinal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );
    getDatafinal.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getDatafinal.id, getDatafinal);
    this.setState({ checkCall: true })
  }

  getEditTestData = (idcheck: any) => {
    if (idcheck) {
      const data = JSON.parse(localStorage.getItem("data") || "{}");

      const headerData = {
        "Content-Type": configJSON.getUserListApiContentType,
        token: data.meta?.token
      };

      const getTestData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getEditData = getTestData.messageId;

      getTestData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.EditEndPoint}${idcheck}`
      );

      getTestData.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );
      getTestData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getUserListApiMethod
      );
      this.setState({ checkCall: false })
      runEngine.sendMessage(getTestData.id, getTestData);
    }
  };

}


// Customizable Area End