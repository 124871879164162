import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikTouched, FormikErrors } from "formik";
import React from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean,
  loginApi: any,
  loginStoreData: any
  pageRedirectTermsCondition: any
  loader: boolean,
  hospitalName: any,
  selectHospitalName: any
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  value: string;
  label: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      email: "",
      enablePasswordField: true,
      loginApi: [],
      loginStoreData: [],
      pageRedirectTermsCondition: [],
      loader: false,
      hospitalName: [],
      selectHospitalName: ''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const result = localStorage.getItem('data')
    // @ts-ignore
    const parseData = JSON.parse(result)
    this.setState({hospitalName: parseData?.data?.attributes})
   
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.loginApiCallId !== null &&
      this.loginApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson?.data) {
          this.setState({loginStoreData: responseJson, loader: false, pageRedirectTermsCondition: responseJson?.data?.attributes?.common_information})
          localStorage.setItem('data', JSON.stringify(this.state.loginStoreData))
          localStorage.setItem("userType", JSON.stringify(this.state.loginStoreData.data.attributes.common_information.user_type))
          localStorage.setItem("summaryData", JSON.stringify([]))
          localStorage.setItem("isDataSelected", "false")
          this.navigationPage(responseJson)
        }
        else if (responseJson.errors) {
          this.setState({
            loginApi: 'Incorrect username or password. Please try again or select "Forgot password?" to change it.', 
            loader: false
        })
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  navigationPage = (responseJson: any) => {
    if(responseJson?.data?.attributes?.common_information?.user_type === 'clinician') {
      if(this.state.pageRedirectTermsCondition?.tnc_status) {
        this.props.navigation.navigate('SelectInstitute')
      }
      else {
        this.props.navigation.navigate('TermsConditions') 
      }
    } else if(this.state.pageRedirectTermsCondition?.tnc_status) {
      const data = JSON.parse(localStorage.getItem("data") || "{}");
      localStorage.setItem("institutionId", data.data.id);
      this.props.navigation.navigate('ClinicianTable')
    } else {
      this.props.navigation.navigate('TermsConditions')
    } 
  }

  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            top: "78px",
            position: "absolute",
            fontFamily: 'Inter-Light, sans-serif',
            color: "#E7205D",
            fontSize: "15px",
            lineHeight: "19px",
            fontWeight: 400
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };
  
  forgotPassword = () => {
    this.props.navigation.navigate('ForgotPassword')
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      enablePasswordField: !this.state.enablePasswordField
    }))
  };

  
  componentDidUpdate(prevState: any) {
    if (prevState.selectedValue !== this.state.selectHospitalName) {
      localStorage.setItem('dropdownValue', this.state.selectHospitalName);
    }
  }

  redirectToDashboard = (institutionId: number) => {
    if(institutionId === 0) {
      const data = JSON.parse(localStorage.getItem("data") || "{}");
      localStorage.setItem("institutionId", data.data.id);
    } else {
      localStorage.setItem("institutionId", String(institutionId));
    }

  }

  getInstituteNumber = (instituteNumber: any) => {
    localStorage.setItem("hospitalNumber", instituteNumber);
  }
  

  handleChange = (e: any) =>{
    this.setState({selectHospitalName:  e.target.value},()=>{
      this.props.navigation.navigate('Patient')
    })
  }

  handleLogin = () => {
    this.setState({loader: true})
    const headers = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          password: this.state.password,
          email: this.state.email,
        }
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
