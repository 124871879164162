import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from "@material-ui/core";
import { Error } from '@material-ui/icons';

interface ModalProps {
  isVisible: boolean;
  onAccept: () => void;
  message: string;
}

const ErrorModal: React.FC<ModalProps> = ({ 
  isVisible, 
  onAccept, 
  message, 
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={webStyles.modal}
      open={isVisible}
      onClose={onAccept}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isVisible}>
        <Box sx={webStyles.paper}>
          <Box style={{display: "flex", alignItems: "center"}}>
            <Error style={{color: "red"}} />
            <Typography style={webStyles.modalTitle}>
              {message}
            </Typography> 
          </Box>
          <Box style={webStyles.modalButton}>
            <Button id="errorModalButton" onClick={onAccept} style={webStyles.modalAcceptButton}>
              Ok
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ErrorModal;


const webStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    width: "600px",
    backgroundColor: "#fff",
    padding: "18px 40px 24px 9px",
    borderRadius: "0px 8px 32px 8px",
    border: "none"
  },
  
  modalAcceptButton: {
    display: "flex",
    padding: "16px 40.5px",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: '56px',
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    textTransform: "none" as const,
  },
  modalTitle: {
    fontWeight: 500, 
    fontSize: "20px", 
    padding: "32px 10px",
    fontFamily: "Inter-Medium",
    color: "#21355F"
  },
  modalButton: {
    display: "flex", 
    paddingBottom: 24
  }
}