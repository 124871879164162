import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { debounce } from "@material-ui/core";
import { CSSProperties } from "react";

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
}

interface Marker {
    id: number;
    name: string;
    amount: number;
}

interface Panel {
    id: number;
    name: string;
    description: string;
    markers:[]
}

interface Summary {
    sampleType: string;
    testPanel: number;
    testType:string,
    markers: number[];
    totalSampleData: number;
    patientName: string;
}

interface S {
    activeSampleType: string;
    activeDeviceType:string;
    allDeviceTypeList:[],
    activeTestPanel: string;
    activeTestPanelId: number;
    loadMore: boolean;
    validData:boolean;
    numberOfTimesLoadMoreClicked: number;
    activeCheckbox: number[];
    testPanelList: Panel [];
    markersList: Marker [] ;
    totalSampleData: number; 
    searchText: string;
    addNewTestLength: boolean;
    isPopUpVisible: boolean
    warningMessage: string
    warningSubtitle: string
    activeDeviceId: number
    isEditMode: boolean
    selectedTest: any
    sampleAmount: number
    sampleAmountUnit: string
    showLeavePagePopup: boolean
    potentialPath: string
}

interface SS {   
    id: number;
}

export default class OrderTestController extends BlockComponent<Props, S, SS> {
    private debouncedGetAllDeviceTypeList: (sample_type: string) => void;
    /* istanbul ignore next */
    private debouncedMarkers: () => void;
    private debouncedPanels: () => void;
    

    getTestPanelListApiCallId: string = "";
    getMarkerListApiCallId: string = "";
    getAllDevice:string="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.debouncedGetAllDeviceTypeList = debounce(this.getAllDeviceTypeList.bind(this), 100)
        this.debouncedMarkers=debounce(this.getMarkerList.bind(this),600)
        this.debouncedPanels=debounce(this.getTestPanelList.bind(this),600)
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
    
        this.state = {
            activeSampleType: "",
            allDeviceTypeList:[],
            activeDeviceType:"",
            activeTestPanel: "",
            activeTestPanelId: 0,
            loadMore: false,
            validData:false,
            numberOfTimesLoadMoreClicked: 1,
            activeCheckbox: [],
            testPanelList: [],
            markersList: [], 
            totalSampleData: 0,
            searchText: "",
            addNewTestLength: false,
            isPopUpVisible: false,
            warningMessage: "",
            warningSubtitle: "",
            activeDeviceId: NaN,
            isEditMode: false,
            selectedTest: null,
            sampleAmount: 100,
            sampleAmountUnit: "μL",
            showLeavePagePopup: false,
            potentialPath: "",
        };
    
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }
    
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        if (!responseJson || responseJson.errors) {
            return;
        }
    
        switch (apiRequestCallId) {
            case this.getAllDevice:
                this.handleAllDeviceResponse(responseJson);
                break;
            case this.getTestPanelListApiCallId:
                this.handleTestPanelListResponse(responseJson);
                break;
            case this.getMarkerListApiCallId:
                this.handleMarkerListResponse(responseJson);
                break;
            default:
                break;
        }
    }

    handleTestPanelListResponse(responseJson: any) {
        if (responseJson?.panels?.length>0) {
            this.setState({ testPanelList: responseJson.panels });
        }
        else {
            this.setState({ testPanelList:[]})
        }
    }
    
    handleMarkerListResponse(responseJson: any) {
        if (responseJson?.makers?.length>0) {
            this.setState({ markersList: responseJson.makers });
        }
        else{
            this.setState({markersList:[]})
        }
       
    }
    
    handleAllDeviceResponse(responseJson: any) {
        const { test_kits } = responseJson;
        /* istanbul ignore next */
        const isEditModeActiveString = localStorage.getItem("editModeActive")
         /* istanbul ignore next */
        const editableTestString = localStorage.getItem("editableTest")
         /* istanbul ignore next */
        const isEditModeActive = isEditModeActiveString ? JSON.parse(isEditModeActiveString) : false
        /* istanbul ignore next */
        const editableTest = editableTestString ? JSON.parse(editableTestString) : {}
         /* istanbul ignore next */
        const deviceandsample = localStorage.getItem("deviceandsample")
        if (test_kits?.length) {
            const defaultDevice = test_kits.find((device: any) => device.default) || test_kits[0];
           this.setState({
                allDeviceTypeList: test_kits,
                activeDeviceId: isEditModeActive ?  editableTest.testKitId : defaultDevice.id,
                sampleAmount: defaultDevice.sample_amount,
                sampleAmountUnit: defaultDevice.sample_amount_unit,
                activeDeviceType: isEditModeActive ?  editableTest.testType: defaultDevice.test_type
            }, () => { 
                 /* istanbul ignore next */            
               deviceandsample!="false" && this.getMarkerList();
                /* istanbul ignore next */
               deviceandsample!="false" && this.getTestPanelList();
          });
        } else {
             /* istanbul ignore next */
            this.getMarkerList();
            this.getTestPanelList();
        }
    }

    async componentDidMount() {
        const isEditModeActiveString = localStorage.getItem("editModeActive")
        const editableTestString = localStorage.getItem("editableTest")
        const isEditModeActive = isEditModeActiveString ? JSON.parse(isEditModeActiveString) : false
        const editableTest = editableTestString ? JSON.parse(editableTestString) : {}
        
        if(isEditModeActive) {
            this.setState({
                isEditMode: true,
                selectedTest: editableTest,
                activeSampleType: editableTest.sampleType,
                activeDeviceType: editableTest.testType,
                activeTestPanel: editableTest.testPanelName,
                activeTestPanelId: editableTest.testPanel,
                activeCheckbox: editableTest.markers,
                totalSampleData: editableTest.totalSampleData
            })
        }
        isEditModeActive && this.state.activeSampleType && this.getAllDeviceTypeList(this.state.activeSampleType)
        /* istanbul ignore next */
        !this.state.activeSampleType  && this.getMarkerList()
        !this.state.activeSampleType && this.getTestPanelList()
        this.handleEditUserData()
        this.checkAddMoreTestBtn()

    } 

    componentDidUpdate(prevProps: {}, prevState: any) {
        if (prevState.activeSampleType !== this.state.activeSampleType) {
            this.setState({ activeDeviceType: '' }, () => {
                this.debouncedGetAllDeviceTypeList(this.state.activeSampleType);
                this.debouncedMarkers();
                this.debouncedPanels();
            });
        }
        if(this.isDataSelected()){
            localStorage.setItem("isDataSelected", "true")
        }else{
            localStorage.setItem("isDataSelected", "false")
        }
    }

    async componentWillUnmount(): Promise<void> {
        localStorage.setItem("isOrderTestButtonClicked", "no")
        localStorage.setItem("isDataSelected", "false")
    }

    isDataSelected(): boolean {
        const { activeSampleType, activeTestPanelId, activeCheckbox } = this.state;
        return Boolean(activeSampleType || activeTestPanelId || (activeCheckbox && activeCheckbox.length >= 1))
    }

    getAllDeviceTypeList(sample_type: string) {
        let dataObject = localStorage.getItem("data") as string
        let dataApi = JSON.parse(dataObject)
    
        const PCheaders = {
          "Content-Type": configJSON.getApiContentType,
          token: dataApi.meta?.token
        };
    
        const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAllDevice = getAllData.messageId;
    
        getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSampleTypeApiEndPoint}?search=${sample_type}`);
    
        getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(PCheaders));
    
        getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getMethod
        );
        runEngine.sendMessage(getAllData.id, getAllData);
      }
    

      handleSampleType(data: string) {
       localStorage.setItem("checksample","false")
        this.setState({
            activeSampleType: data,
            activeDeviceType: "",
            activeTestPanel: "",
            activeTestPanelId: 0,
            activeCheckbox: [],
            totalSampleData: 0
        });
    }
    
    getSampleAmount(sampleAmountUnit: string){
        const { totalSampleData, sampleAmount } = this.state

        if(sampleAmountUnit === "μL"){
            return `Sample amount: ${totalSampleData}/${sampleAmount}${sampleAmountUnit}`
        }else {
            return `Sample amount: ${totalSampleData / 1000}/${sampleAmount}${sampleAmountUnit}`
        }
    }

    getSampleAmountProgress(sampleAmountUnit: string){
        const { totalSampleData, sampleAmount } = this.state

        if(sampleAmountUnit === "μL"){
            return `${((totalSampleData / sampleAmount) * 100)}%`
        }else {
            return `${((totalSampleData / sampleAmount) / 10)}%`
        }
    }

    displayAmountAndUnit(sampleAmountUnit: string){
        const { totalSampleData, sampleAmount } = this.state

        if(sampleAmountUnit === "μL"){
            return totalSampleData < sampleAmount
        }else {
            return (totalSampleData / 1000) < sampleAmount
        }
        
    }
           
    handleDeviceType(item: any) {
        this.setState({
            activeDeviceType: item.test_type,
            activeDeviceId: item.id,
            sampleAmount: item.sample_amount,
            sampleAmountUnit: item.sample_amount_unit,
            totalSampleData: 0,
            activeCheckbox: [],
            activeTestPanel: "",
            activeTestPanelId: 0,
        }, () => {
            this.getMarkerList();
            this.getTestPanelList();
        });
    }
    shouldDisable() {
        const answer=this.state.activeSampleType?.length<=0;
        const isCheckboxActive = this.state.activeCheckbox?.length >= 1;
        const isTestPanelInactive = this.state.activeTestPanel?.length < 1;
        return isCheckboxActive && isTestPanelInactive || answer;
    }
    handleTestPanel(panel: any) {
        let panelId: number | null = null;
        for (const dataPanel of this.state.testPanelList) {
            if (dataPanel.name === panel) {
              panelId = dataPanel.id;
              break;
            }
        }
        /* istanbul ignore next */
        const selectedPanel = this.state.testPanelList.find(panel => panel.id === panelId);
        const totalAmountForPanel = selectedPanel?.markers?.reduce((sum, marker: any) => sum + Number(marker.amount), 0) ?? 0
        const totalSampleAmount = this.state.sampleAmountUnit === "μL" ? Number(this.state.sampleAmount) : Number(this.state.sampleAmount) * 1000
        /* istanbul ignore next */
        if (totalAmountForPanel > totalSampleAmount) {
            this.setState({
                validData: true,
                activeTestPanel: "",
                activeTestPanelId: 0,
                activeCheckbox: [],
                totalSampleData: 0
            })
        }else {
            this.setState({
                activeTestPanel: panel,
                activeTestPanelId: panelId || 0,
                activeCheckbox: [],
                totalSampleData: 0
            },
            () => {
                /* istanbul ignore next */
                this.state.testPanelList?.map((item) => {
                    if (item.name === this.state.activeTestPanel && item.id==this.state.activeTestPanelId) {
                        item.markers?.map((answer:any)=>{
                           return this.handleCheckBox(answer.id, Number(answer.amount));
                        })
                    }
                });
            }
        );
        }  
    }

    handleScrollBarForPanel() {
        if((this.state.numberOfTimesLoadMoreClicked * 6) < this.state.testPanelList.length) {
            return "auto";
        } else {
            return "hidden";
        }
    }

    showLoadLess() {
        if((this.state.numberOfTimesLoadMoreClicked * 6) < this.state.testPanelList.length) {
            return "Load more";
        } else {
            return "Show less";
        }
    }
    handleValueCheck(){
        return this.state.testPanelList?.length>6
    }

    handleLoadMore() {
        this.setState({
            loadMore: true
        });
        if((this.state.numberOfTimesLoadMoreClicked * 6) < this.state.testPanelList.length) {
            this.setState((prevState) => ({
                numberOfTimesLoadMoreClicked: prevState.numberOfTimesLoadMoreClicked + 1
            }))
        } else {
            this.setState({
                numberOfTimesLoadMoreClicked: 1
            })
        }
    }

    handleHeightOnLoadMoreClick() {
        if(this.state.numberOfTimesLoadMoreClicked === 1) {
            return 120
        } else {
            return this.state.numberOfTimesLoadMoreClicked * 120
        }
    } 

    handleSearch(value: string) {
        this.setState({
            searchText: value
        });
    }

    handleCheckBox(data: number, sampleNumber: number) {
        const { totalSampleData, sampleAmount, sampleAmountUnit } = this.state
        const isMicroliters = sampleAmountUnit === "μL"
         /* istanbul ignore next */
        const condition = isMicroliters ? 
            totalSampleData <= sampleAmount && (totalSampleData + Number(sampleNumber)) <= sampleAmount :
            (totalSampleData / 1000) <= sampleAmount && ((totalSampleData / 1000) + Number(sampleNumber / 1000)) <= sampleAmount
        
         /* istanbul ignore next */
        if(this.state.activeCheckbox.includes(data)) {
            this.setState((prevState) => {
                const newActiveCheckbox = prevState.activeCheckbox.filter((item) => item !== data);
                const newTotalSampleData = prevState.totalSampleData - Number(sampleNumber);
                return {
                    activeCheckbox: newActiveCheckbox,
                    totalSampleData: newTotalSampleData
                };
            });
        } else if(condition) {
            this.setState((prevState) => {
                const newActiveCheckbox = [...prevState.activeCheckbox, data];
                const newTotalSampleData = prevState.totalSampleData + Number(sampleNumber);
                return {
                    activeCheckbox: newActiveCheckbox,
                    totalSampleData: newTotalSampleData
                }
            })} 
        else if(totalSampleData + Number(sampleNumber) > sampleAmount){
            this.setState({validData:true})
        }
    }
    handleFix(){
        this.setState({
            validData: false
        })
    }
    handleAddNewTest() {
        localStorage.removeItem("editModeActive")
        localStorage.removeItem("deviceandsample")
        localStorage.setItem("isOrderTestButtonClicked", "no")
        const {
            activeSampleType,
            activeTestPanelId,
            activeCheckbox,
            totalSampleData,
            activeTestPanel,
            activeDeviceType,
            activeDeviceId,
            sampleAmount,
            sampleAmountUnit
        } = this.state
        let newData = {
            testType: activeDeviceType || activeSampleType,
            sampleType: activeSampleType,
            testPanel: activeTestPanelId,
            markers: activeCheckbox,
            totalSampleData: totalSampleData,
            patientName: JSON.parse(localStorage.getItem("fullName") || "{}"),
            testPanelName: activeTestPanel,
            patientId: JSON.parse(localStorage.getItem("patientId")  || "{}"),
            tempId: Date.now(),
            dateOfTest: null,
            clinicianFullName: "",
            additionalInfo: "",
            revueSwitcher: "",
            revueNote: "",
            testId: "",
            testKitId: activeDeviceId,
            sampleAmount: sampleAmount,
            sampleAmountUnit: sampleAmountUnit
        }
        let summaryData: Summary[] = [];
        const ifDataExist = localStorage.hasOwnProperty("summaryData");

        if (!this.selectedSampleType()) {
            if (this.selectedTestPanel() || this.selectedCheckbox()) {
                return this.setState({
                    isPopUpVisible: true,
                    warningMessage: "Select a Sample type"
                });
            } else {
                return this.setState({
                    isPopUpVisible: true,
                    warningMessage: "Select a Sample type and Panel/Markers"
                });
            }
        } else if (!this.selectedTestPanel() && !this.selectedCheckbox()) {
            return this.setState({
                isPopUpVisible: true,
                warningMessage: "Select Panel/Markers"
            });
        }

        if(!ifDataExist) {
            summaryData.push(newData);
        } else {
            const dataFromStorage = localStorage.getItem("summaryData") as string;
            const summaryDataStorage = JSON.parse(dataFromStorage);

            summaryData.push(...summaryDataStorage, newData);
        }
        localStorage.setItem("summaryData", JSON.stringify(summaryData));

        this.resetForm()
        const ifCurrUserExist = localStorage.hasOwnProperty("currUser");
        if(ifCurrUserExist) {
            localStorage.removeItem("currUser");
        }
    }

    handleOpacity = () => {
        return this.handleDisabledButtons() ? '0.5' : '1';
    }

    handleDisabledColor() {
        if(this.state.activeTestPanel || this.state.activeSampleType.length<=0) {
            return "rgba(0, 0, 0, 0.38)";
        } else {
            return "#0F172A";
        }
    }

    handleDisabledSearchIconColor() {
        if(this.state.activeTestPanel) {
            return "rgba(0, 0, 0, 0.38)";
        } else {
            return "#21355F";
        }
    }

    handleDisabledActionIconColor() {
         /* istanbul ignore next */
        if(this.state.activeCheckbox?.length >= 1 && this.state.activeTestPanel?.length<1||this.state.activeSampleType.length<=0) {
            return 0.5;
        } else {
            return 1;
        }
    }

    handleDisabledSearch() {
        if(this.state.activeTestPanel) {
            return "1px solid rgba(0, 0, 0, 0.38)";
        } else {
            return "1px solid #21355F";
        }
    }

    handleDisabledClearButton() {
        if(this.state.activeTestPanel || this.state.activeSampleType.length<=0) {
            return 0.5;
        } else {
            return 1;
        }
    }

    clearRadioButtons() {
        this.setState({
            activeTestPanel: "",
            activeTestPanelId: 0,
            activeCheckbox: [],
            totalSampleData: 0
        });
    }

    clearCheckBoxButtons() {
        this.setState({
            activeCheckbox: [],
            totalSampleData: 0
        });
    }
    filterMarkers = () => {
        const { markersList, searchText } = this.state;
         /* istanbul ignore next */
        if (!markersList) return [];
        /* istanbul ignore next */
        const normalizedSearchText = searchText?.toLowerCase().trim() || "";
         /* istanbul ignore next */
        return markersList.filter(data => {
            const normalizedName = data.name?.toLowerCase().trim() || "";
            return normalizedSearchText === "" || normalizedName.includes(normalizedSearchText);
        });
    };
    clearSampleTypeButtons() {
        this.setState({
            activeSampleType: "",
            activeTestPanel: "",
            activeDeviceType:'',
            activeTestPanelId: 0,
            activeCheckbox: [],
            totalSampleData: 0
        }, () => {
            this.getTestPanelList();
            this.getMarkerList();
        });
    }

    navigateToOrderSummary = () => {
        if(this.isDataSelected()){
            this.setState({
                showLeavePagePopup: true,
                potentialPath: 'OrderSummary'
            })
        }else{
            this.props.navigation.navigate('OrderSummary')
        }
    }

    handleConfirmLeavePage = () => {
        const patientId = localStorage.getItem('patientId') as string
        const navigationId = JSON.parse(patientId)

        this.setState({
            showLeavePagePopup: false,
        })
        this.resetForm()
        if(this.state.potentialPath === 'PatientView'){
            return this.props.navigation.navigate('PatientView', {
                id: navigationId
            })
        }else{
            return this.props.navigation.navigate(this.state.potentialPath)
        }
        
    }

    closeShowLeavePagePopup(){
        return this.setState({
            showLeavePagePopup: false
        })
    }

    async handleConfirm() {
        let selectedTest = { ...this.state.selectedTest }
        const {
            activeSampleType,
            activeTestPanelId,
            activeCheckbox,
            totalSampleData,
            activeTestPanel,
            activeDeviceType,
            activeDeviceId
        } = this.state
        selectedTest = {
            ...selectedTest,
            testType: activeDeviceType || activeSampleType,
            sampleType: activeSampleType,
            testPanel: activeTestPanelId,
            markers: activeCheckbox,
            totalSampleData: totalSampleData,
            testPanelName: activeTestPanel,
            testKitId: activeDeviceId
        };
       
        if(this.selectedSampleType() && (this.selectedCheckbox() || this.selectedTestPanel())){
            if(this.state.isEditMode){
                const dataFromStorage = localStorage.getItem("summaryData") as string
                const summaryData = JSON.parse(dataFromStorage)
                const updatedSummaryData = summaryData.map((item: any) => {
                    if (item.tempId === selectedTest.tempId) {
                        return selectedTest
                    }
                    return item;
                })
                this.resetForm()
                localStorage.setItem("summaryData", JSON.stringify(updatedSummaryData))
                localStorage.setItem("isOrderTestButtonClicked", "no")
                localStorage.removeItem("deviceandsample")
                localStorage.removeItem("editModeActive")
                localStorage.removeItem("editableTest")
            }else{
                this.handleAddNewTest()
                localStorage.removeItem("currUser");
                localStorage.setItem("isOrderTestButtonClicked", "no")
            }
            this.clearSampleTypeButtons();
        }else{
            return this.setState({
                isPopUpVisible: true,
                warningMessage: configJSON.warningTitle,
                warningSubtitle: configJSON.warningSubtitle
            })
        }
    }

    getPanelOrMarker(panel: string){
        return panel ? panel : "Custom Panel"
    }

    resetForm(){
        this.setState({
            activeSampleType: "",
            activeDeviceType:"",
            activeTestPanel: "",
            activeTestPanelId: 0,
            activeCheckbox: [],
            totalSampleData: 0,
            addNewTestLength: true,
            activeDeviceId: NaN,
            isEditMode: false,
            selectedTest: null,
        })
    }

    viewPatient = () => {
        const patientId = localStorage.getItem('patientId') as string
        const navigationId = JSON.parse(patientId)
       
        if(this.isDataSelected()){
            this.setState({
                showLeavePagePopup: true,
                potentialPath: 'PatientView'
            })
        }else{
            this.props.navigation.navigate('PatientView', {
                id: navigationId
            })
        }
    }

    viewPatientScreen = () => {
        if(this.isDataSelected()){
            this.setState({
                showLeavePagePopup: true,
                potentialPath: 'Patient'
            })
        }else{
            this.props.navigation.navigate('Patient')
        }
    }

    handleDisableCheckbox = () => {
        if(this.state.activeTestPanel || this.state.activeSampleType.length<=0) {
            return true;
        } else {
            return false;
        }
    }

    viewPatientName() {
        const patientFullName = localStorage.getItem('fullName') as string;
        const patientFullNameData = JSON.parse(patientFullName);
        return patientFullNameData;
    }

    checkAddMoreTestBtn() {
        const dataFromStorage = localStorage.getItem("summaryData") as string;
        const summaryData = JSON.parse(dataFromStorage);

        const patientData = localStorage.getItem('fullName') as string;
        const patientFullName = JSON.parse(patientData);

        if(summaryData && summaryData.length >= 1 && 
        summaryData.find((item: Summary) => item.patientName === patientFullName)) {
            this.setState({
                addNewTestLength: true
            });
        }
    }

    handleDisabledButtons = () => {
        const patientId = JSON.parse(localStorage.getItem("patientId")  || "{}")
        const dataFromStorage = localStorage.getItem("summaryData") as string;
        const selectedTests = JSON.parse(dataFromStorage)
        const patientTests = selectedTests?.filter((test: any) => Number(test.patientId) === Number(patientId))
        const isSummaryDataEmpty = patientTests && patientTests.length > 0;

        return !(this.selectedSampleAndPanel() || this.selectedSampleAndMarkers() || isSummaryDataEmpty);
    }

    selectedSampleAndPanel = () => {
        return this.selectedSampleType() && this.selectedTestPanel();
    }
    
    selectedSampleAndMarkers = () => {
        return this.selectedSampleType() && this.selectedCheckbox()
    }

    selectedSampleType = () => {
        return this.state.activeSampleType !== "" 
    }

    selectedTestPanel = () => {
        return this.state.activeTestPanel !== "" 
    }

    selectedCheckbox = () => {
        return this.state.activeCheckbox.length >= 1
    }

    openPopUp = () => {
        this.setState({
            isPopUpVisible: true,
        })
    }

    closePopUp = () => {
        this.setState({
            isPopUpVisible: false,
            warningSubtitle: ""
        })
    }

    getButtonStyle = (item: string, activeSampleBtn: CSSProperties, deactiveSampleBtn: CSSProperties) => {
        const { activeSampleType } = this.state;
        
        return activeSampleType === item ? activeSampleBtn : deactiveSampleBtn
    }

    handleEditTest(tempId: number, patientTests: any) {
             /* istanbul ignore next */
        localStorage.setItem("checksample","true")
        const editableTest = patientTests.find((test: any) => test.tempId === tempId)
        localStorage.setItem("deviceandsample", "false")
        localStorage.setItem("editableTest", JSON.stringify(editableTest))
        localStorage.setItem("editModeActive", "true")
        if(editableTest.tempId) {
            this.setState({
                isEditMode: true,
                selectedTest: editableTest,
                activeSampleType: editableTest.sampleType,
                activeDeviceType:editableTest.testType,
                activeTestPanel: editableTest.testPanelName,
                activeTestPanelId: editableTest.testPanel,
                activeCheckbox: editableTest.markers,
                activeDeviceId:editableTest.testKitId,
                totalSampleData: editableTest.totalSampleData
            },()=>{
                 /* istanbul ignore next */
                this.getAllDeviceTypeList(editableTest.sampleType)
                 /* istanbul ignore next */
                this.getMarkerList();
                 /* istanbul ignore next */
                this.getTestPanelList()
            });
        }
    }


    handleEditUserData() {
        const dataFromStorage = localStorage.getItem("summaryData") as string;
        const summaryData = JSON.parse(dataFromStorage);

        const patientData = localStorage.getItem('fullName') as string;
        const patientFullName = JSON.parse(patientData);

        const ifCurrUserExist = localStorage.hasOwnProperty("currUser");
        if(ifCurrUserExist && summaryData && summaryData.length >= 1 && summaryData.find((item: Summary) => item.patientName === patientFullName)) {
            const currUser = localStorage.getItem('currUser') as string
            const currUserId = JSON.parse(currUser);
            
            const filterCurrUser = summaryData.filter((item: Summary) => item.patientName === patientFullName);
            
            const editUser = filterCurrUser[currUserId];
            if(editUser) {
                this.setState({
                    activeSampleType: editUser.sampleType,
                    activeDeviceType:editUser.testType,
                    activeTestPanel: editUser.testPanelName,
                    activeTestPanelId: editUser.testPanel,
                    activeCheckbox: editUser.markers,
                    totalSampleData: editUser.totalSampleData
                });
            }

            const remainedCurrUser = filterCurrUser.filter((item: Summary, dataKey:number) => dataKey !== currUserId);    
            const allUsersOtherThanCurrent = summaryData.filter((item: Summary) => item.patientName !== patientFullName);  
            
            const totalNewData = [...remainedCurrUser, ...allUsersOtherThanCurrent];
        
            localStorage.setItem("summaryData", JSON.stringify(totalNewData));
        }
    }

    getMarkerList() {
         /* istanbul ignore next */
        const answer=localStorage.getItem("checksample")
         /* istanbul ignore next */
        if(answer=='false') {
             /* istanbul ignore next */
            localStorage.removeItem("editModeActive") 
             /* istanbul ignore next */
        if(this.state.activeSampleType!='Blood'){
             /* istanbul ignore next */
            this.setState({activeDeviceType:''})}}  
             /* istanbul ignore next */
        let markers = this.state.activeSampleType;
        let devicetype = this.state.activeDeviceType;
        let dataFromStorage = JSON.parse(localStorage.getItem("data") || "{}");

        const PCheaders = {
            "Content-Type": configJSON.getApiContentType,
            token: dataFromStorage.meta?.token
        };

        const getRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMarkerListApiCallId = getRequest.messageId;
        let queryAns = [];
        let querydevice=[];
        if (markers) queryAns.push(`sample_type=${this.state.activeSampleType}`);
        if (devicetype && markers) querydevice.push(`collection_device=${this.state.activeDeviceType}`);
        let apiPoint = configJSON.getApiForMakersList;
        if (queryAns.length > 0) {
            apiPoint += '?' + queryAns.join('&');
        }
        if(querydevice.length>0){
            apiPoint+='&'+ querydevice
        }
        getRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiPoint
        );

        getRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(PCheaders)
        );
        getRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(getRequest.id, getRequest);
    }

    getTestPanelList() {
        let answer = this.state.activeSampleType;
        let dataFromStorage = JSON.parse(localStorage.getItem("data") || "{}");

        const PCheaders = {
            "Content-Type": configJSON.getApiContentType,
            token: dataFromStorage.meta?.token
        };

        const getRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTestPanelListApiCallId = getRequest.messageId;
        let queryParams = [];
        let querydevice=[];
        if (answer) queryParams.push(`sample_type=${this.state.activeSampleType}`);
        if (this.state.activeDeviceType && answer) querydevice.push(`collection_device=${this.state.activeDeviceType}`);
        let apiEndpoint = configJSON.getApiForTestPanel;
        if (queryParams.length > 0) {
            apiEndpoint += '?' + queryParams.join('&');
        }
        if(querydevice.length>0){
            apiEndpoint+='&'+ querydevice
        }
        getRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndpoint
        );

        getRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(PCheaders)
        );
        getRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(getRequest.id, getRequest);
    }

}
