import React from "react";

// Customizable Area Start
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Card,
  Select as Muicion,
  CardActions,
  Backdrop,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import {
  MoreVert,
  AddCircleOutline,
  Search,
  RemoveRedEyeOutlined,
  EmailOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@material-ui/icons";
import CommonCustomModal from "./CommonCustomModal";
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import dayjs from 'dayjs';
// Customizable Area End

import ClinicianController, {
  configJSON,
  Props,
} from "./ClinicianController"
import { autoComplete } from "./assets";

export default class ClinicianTable extends ClinicianController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getOptions(type: any) {
    const data = this.state.dropdownDataAns?.map((option: any) => option.attributes.clinician_information[type]);
    return [...new Set(data?.filter((value: any) => value !== null))].map((value) => ({ value, label: value }));
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    const staticOptions = [
      { value: 'lastDay', label: 'Last Day' },
      { value: 'lastWeek', label: 'Last Week' },
      { value: 'lastMonth', label: 'Last Month' },
      { value: 'custom', label: 'Custom' }
    ];
    const { isVisibleModal, selectedClinician, institutionNumber } = this.state
    const getOptionsFromState = (stateData: any, mapFn: any) => {
      const mappedValues = stateData?.map(mapFn) || [];
      const filteredValues = mappedValues?.filter((value: null) => value !== null);
      const uniqueValues = [...new Set(filteredValues)];
      return uniqueValues?.map(value => ({ value, label: value }));
    };
    
    const SelectComponent = ({
      options,
      placeholder,
      onChange,
      dataTestId,
       /* istanbul ignore next */
      isMulti = false,
    }: {
      options: any[],
      placeholder: string,
      onChange: (selected: any) => void,
      dataTestId: string,
      isMulti?: boolean,
    }) => (
      <Select
        isMulti={isMulti}  
        isSearchable={false}
        data-test-id={dataTestId}
        value={null}
        onChange={onChange}
        placeholder={placeholder}
        closeMenuOnSelect={true}
        options={options}
        hideSelectedOptions={false}
        styles={this.clinicianStyles}
        menuPortalTarget={document.body}
      />
    );
    
    const dataSource = this.state.patientSearch ? this.state.searchdataAns : this.state.groupList;
    return (
      <Box
        style={{
          width: '100%', marginBottom: '80px', overflow: 'auto', display: 'flex', flexDirection: 'column',
          padding: '20px 20px', gap: '20px'
        }}>
        {this.state.isLoader && this.renderLoader()}
        <Typography
          style={{ fontSize: "24px", fontFamily: 'Inter-Bold, sans-serif', color: "#21355F" }}
          align="left">
          Clinicians
        </Typography>
        <Box style={{
          display: "flex",
          width: "100%",
          alignItems: "baseline",

        }}>
          <TextField
            name="search"
            onClick={() => this.handleSearch()}
            autoComplete="off"
            data-test-id="search"
            style={webStyles.inputField}
            placeholder="Search"
            value={this.state.datans}
            onChange={(event) => { this.clinicianTable(event) }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="searchTry"
                    edge="end"
                    style={{ fontSize: "16px" }}
                  >
                    <Search style={{ color: "rgb(33, 53, 95)", marginRight: "5px" }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth={true}
          />
          <Button
            data-test-id="addClinician"
            onClick={this.handleNavigateToCreateClinician}
            style={webStyles.buttonStyle}
          >
            <AddCircleOutline style={{ marginRight: "10px" }} />
            {configJSON.addClinician}
          </Button>
        </Box>
        <div>
          <Card style={{ zIndex: 100, color: '#FFF' }}>
            {this.state.openSearchBox && (
              <>
                <Card
                  style={{
                    position: 'absolute',
                    maxHeight: '300px',
                    overflow: 'auto',
                      /* istanbul ignore next */
                    zIndex:!this.state.openCalender?'9999':'100',
                    borderRadius: '8px', padding: '12px 10px 14px 10px',
                    gap: '15px', backgroundColor: '#FFFFFF', alignItems: 'center',
                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                  }}>
                  <CloseIcon data-test-id="checkicon" style={{ cursor: 'pointer', height: '16px', position: 'relative', top: '-5px', right: '10px' }} onClick={() => this.handleSearch2()} />
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                    <SelectComponent
                      options={this.getOptions('department')}
                      placeholder={'Department'}
                      onChange={this.departmentTypeChange}
                      dataTestId="selectdata2"
                      isMulti={true}
                    />
                    <SelectComponent
                     isMulti={true}
                      options={this.getOptions('role')}
                      placeholder={'Role'}
                      onChange={this.roleHandle}
                      dataTestId="selectdata3"
                    />
                    <SelectComponent
                      options={staticOptions}
                      placeholder={'Last activity'}
                      onChange={this.handleActivity}
                      dataTestId="selectdata5"
                    />
                  </div>
                  <div style={{ display: 'flex', paddingTop: '15px' }}>  
                   {this.renderFinalSelectedOptions()}
                    {this.renderSelectedOption5()}
                    {/* istanbul ignore next */}
                    {(this.state.departmentSelected?.length > 0 && this.state.departmentSelected||this.state.selectActivity?.label || this.state.roleSelected?.length > 0 && this.state.roleSelected
                      ) && <div
                        onClick={() => { this.removeAllOptions() }}
                        style={{
                          margin: '5px',
                          borderRadius: '20px',
                          padding: '10px',
                          width: 'fit-content',
                          color: '#64748B',
                          textAlign: 'center',
                          overflow: 'hidden',
                          cursor: 'pointer',
                          fontFamily: 'Inter-Bold, sans-serif',
                        }}>
                        Clear all
                      </div>
                    }
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                    {this.state.getAutoSearchSuggestions?.map((item: any, index: any) => {
                      const searchText = typeof this.state.datans === 'string'
                        ? this.state.datans.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                        : '';

                      const regex = new RegExp(searchText, 'i');
                      const isMatch = regex.test(item);
                      const highlightedText = item.replace(regex, (match: any) => `<span style="font-weight: 900; color: #000; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">${match}</span>`);

                      return (
                        <div
                          data-test-id="testest"
                          onClick={() => this.clinicianTable(item)}
                          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '10px', margin: '5px', fontFamily: 'Inter-Regular, sans-serif' }}
                          key={index}
                        >
                          <img src={autoComplete} alt="icon" style={{ marginRight: '10px' }} />
                          {isMatch ? (
                            <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
                          ) : (
                            <span data-test-id="searchSpan">{item}</span>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </Card>
              </>
            )}
          </Card>
        </div>
        {!this.state.dataFetching ? (
          <TableContainer style={webStyles.tableContainerStyle}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {configJSON.tableHead.map((columnName: string, index: number) => (
                    <TableCell
                      style={{
                        backgroundColor: "#21355F",
                        color: "#fff",
                        fontFamily: 'Inter-Bold, sans-serif',
                        fontSize: 14,
                        lineHeight: "22px"
                      }}
                      key={index}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", fontFamily: 'Inter-Bold, sans-serif' }}>
                        {columnName}
                       
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource?.map((clinician, index: number) => {
                  const attributes = clinician.attributes;
                  const iNumber = institutionNumber?.slice(0, 3) + ' ' + institutionNumber?.slice(3, 6) + ' ' + institutionNumber?.slice(6)

                  return (
                    <TableRow data-test-id="item" key={clinician.id}>
                      <TableCell scope="row" style={{ maxWidth: "200px", borderBottom: '1px solid #0075BF' }}>
                        <Typography
                          style={{ color: "#21355F", textDecoration: "underline", cursor: "pointer" }}
                          variant="body2"
                          data-test-id="navigateToProfile"
                          onClick={() => this.handleNavigateToClinicianProfile(Number(clinician.id))}
                        >
                          {attributes.common_information.first_name} {attributes.common_information.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell style={webStyles.dataStyles}>{attributes.clinician_information.department}</TableCell>
                      <TableCell style={webStyles.dataStyles}>{attributes.clinician_information.role}</TableCell>
                      <TableCell style={webStyles.dataStyles}>{attributes.common_information.email}</TableCell>
                      <TableCell style={webStyles.dataStyles} id="lastActivity">
                        {this.formatDate(attributes.updated_at)}
                      </TableCell>
                      <TableCell style={webStyles.dataStyles}>
                        {iNumber}
                      </TableCell>
                      <TableCell style={webStyles.dataStyles}>
                        <Muicion
                          labelId="dropdown-label"
                          id="dropdown"
                          data-test-id={`btnDropdownButton${index}`}
                          disableUnderline={true}
                          IconComponent={MoreVert}
                          style={{ fontFamily: 'Inter-regular, sans-serif' }}>
                          <MenuItem
                            value=""
                            onClick={() => this.handleNavigateToClinicianProfile(Number(clinician.id))}
                            style={{ display: "flex", gap: 8, fontFamily: 'Inter-Regular,sans-serif' }}
                            data-test-id="viewButton">
                            <RemoveRedEyeOutlined fontSize="small" />
                            {configJSON.viewClinician}
                          </MenuItem>
                          <MenuItem
                            data-test-id="resendInvitation"
                            onClick={() => this.handleResendInvitation(Number(clinician.id))}
                            value=""
                            style={{ display: "flex", gap: 8, fontFamily: 'Inter-Regular, sans-serif' }}>
                            <EmailOutlined fontSize="small" />
                            {configJSON.resendInvitation}
                          </MenuItem>
                          <MenuItem
                            data-test-id={`btnEditButton`}
                            value=""
                            onClick={() => this.handleNavigateToEditClinician(Number(clinician.id))}
                            style={{ display: "flex", gap: 8, fontFamily: 'Inter-Regular, sans-serif' }}>
                            <EditOutlined fontSize="small" />
                            {configJSON.editClinician}
                          </MenuItem>
                          <MenuItem
                            data-test-id="deleteClinician"
                            value=""
                            onClick={() => this.setSelectedClinician(clinician)}
                            style={{ display: "flex", gap: 8, fontFamily: 'Inter-Regular, sans-serif' }}>
                            <DeleteOutlined fontSize="small" />
                            {configJSON.deleteClinician}
                          </MenuItem>
                        </Muicion>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
            <CircularProgress />
          </Box>
        )}
        {isVisibleModal && selectedClinician && (
          <CommonCustomModal
            isVisible={this.state.isVisibleModal}
            onClose={this.handleCloseModal}
            title={`Are you sure you want to remove 
                ${selectedClinician.attributes.common_information.first_name} 
                ${selectedClinician.attributes.common_information.last_name} 
                from your institution?`}
            onCancel={this.handleCloseModal}
            onAccept={this.handleDeleteClinician}
            disabled={this.state.disabledAccept}
          />
        )} 
        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openCalender)}>
        {this.state.openCalender && (
          <Card style={{
            width: '600px', height: 'auto', display: 'flex', flexDirection: 'column',
            borderRadius: '8px', padding: '25px 20px', backgroundColor: '#FFFFFF', alignItems: 'center'
          }}>
            <CardContent style={{
              display: 'flex', flexDirection: 'column',
              gap: '35px', alignItems: 'center', height: "auto"
            }}>
              <Typography style={{
                color: '#21355F', height: '24px', width: '512px',
                fontFamily: 'Inter-SemiBold, sans-serif', fontSize: '20px', textAlign: 'center'
              }}>
                Select the Start Date and End Date of Last Activity
              </Typography>
              <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                   data-test-id="datepicker"
                    format="DD/MM/YYYY" 
                    onChange={(e) => this.handleDate(e, 'first')}
                    maxDate={dayjs()}
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPaper-root": { marginTop: "-20px",bottom:'-45px',position:'relative'},
                          ".css-1rtg91x-MuiDateCalendar-root":{width:'auto'}
                        },
                      },
                    }}

                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    data-test-id="datepicker2"
                   format="DD/MM/YYYY" 
                    onChange={(e) => this.handleDate(e, 'second')}
                    maxDate={dayjs()}
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPaper-root": { marginTop: "-20px",bottom:'-45px',position:'relative'},
                          ".css-1rtg91x-MuiDateCalendar-root":{width:'auto'}
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </CardContent>
            <CardActions style={{ display: 'flex', gap: '26px' }}>
              <Button style={{
                width: '90px', height: '43px',
                border: '1px solid #E1E1E1',
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                borderRadius: '8px', background: '#FFFFFF', fontFamily: 'Inter-Bold, sans-serif', fontSize: '14px',
                textAlign: 'center', textTransform: 'none',
              }}
                data-test-id="closebtn"
                onClick={()=>this.closePopUp()}
              > Back
              </Button>
              <Button style={{
                width: '90px', height: '40px',
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                borderRadius: '8px', background: '#0075BF', fontFamily: 'Inter-Bold, sans-serif', fontSize: '14px',
                textAlign: 'center', textTransform: 'none',
              }}
                data-test-id="confirmBtns"
                onClick={()=>this.confirmCal()}
              > Confirm
              </Button>
            </CardActions>
          </Card>
        )}
        </Backdrop>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  inputField: {
    border: "1px solid rgba(33, 53, 95, 1)",
    padding: "5px 0px 5px 0px",
    borderRadius: "98px",
    background: "none",
    "&:before": {
      border: "none !important"
    },
    "&:after": {
      border: "none"
    },
    "&:hover": {
      border: "2px solid #0075BF"
    }
  },
  tableContainerStyle: {
    borderRadius: '8px 8px 0px 0px',
    width: "100%",
    overflow: 'auto'
  },
  buttonStyle: {
    height: "44px",
    fontFamily: 'Inter-Bold, sans-serif',
    width: "180px",
    border: "none",
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    borderRadius: "8px",
    color: "white",
    textTransform: "none" as const,
    marginLeft: "20px",
    fontSize: "14px",
    padding: "6px 10px",
  },
  dataStyles: {
    fontFamily: 'Inter-Regular, sans-serif',
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#21355F",
    borderBottom: '1px solid #0075BF'
  },
  tableCellRow: {
    color: '#FFFFFF',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Inter-Bold, sans-serif',
    fontSize: '14px',
    padding: '8px 24px'
  },
};
// Customizable Area End
