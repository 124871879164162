import React from "react";

import {
  // Customizable Area Start
  InputAdornment,
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  IconButton,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { revueLogo } from './assets'
import * as Yup from "yup";
import GenericCard from "../../../components/src/GenericCard.web";
import { Formik } from "formik";
import LoginController, { Props } from "./LoginController.web";
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <GenericCard>
      {
      this.state.loader &&  <Box 
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0, 117, 191, 0.2)"
          zIndex={9999}
      > <CircularProgress size={50} /></Box> 
    }
      <Formik
        data-test-id="loginForm"
        initialValues={{
          password: "",
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
          password: Yup.string()
            .min(8, "Minimum 8 characters")
            .required("Password is required"),
        })}

        onSubmit={(values, actions) => {
          this.handleLogin()
          actions.resetForm({
            //@ts-ignore
            password: "",
            email: "",
          });
        }}
      >
        {(props) => {
        return(
          <form onSubmit={props.handleSubmit}>
            <Box style={{padding: '5px 50px'}}>
              <Box style={{textAlign: 'center',marginTop:'20px'}}>
              <img src={revueLogo} />
              </Box>
              <Typography variant="h6" align="center" style={webStyle.heading}>
                WELCOME TO REVUE
              </Typography>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Email</InputLabel>
                <TextField
                  data-test-id={"email"}
                  name="email"
                  id="email"
                  InputProps={{
                    disableUnderline: true
                  }}
                  style={webStyle.inputField}
                  placeholder="Your email"
                  value={props.values.email}
                  fullWidth={true}
                  onChange={(event) => {
                    props.setFieldValue("email", event.target.value);
                    this.onValueChange("email", event.target.value);
                  }}
                  type="text"
                />
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "email"
                )}
              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Password</InputLabel>
                <TextField
                  name="password"
                  id="password"
                  data-test-id={"password"}
                  style={webStyle.inputField}
                  type={this.state.enablePasswordField ? "password" : "text"}
                  value={props.values.password}
                  onChange={(event) => {
                    props.setFieldValue("password", event.target.value);
                    this.onValueChange("password", event.target.value);
                  }}
                  InputProps={{
                    disableUnderline:true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end">
                          {!this.state.enablePasswordField ? (
                            <RemoveRedEyeOutlinedIcon style={{ color: 'rgba(148, 163, 184, 1)' }} />
                          ) : (
                            <VisibilityOffOutlinedIcon style={{ color: 'rgba(148, 163, 184, 1)' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Your password"
                  fullWidth={true}
                />
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "password"
                )}
              </Box>
              <Box style={webStyle.errorText}>
                {this.state.loginApi}
              </Box>
              <Box style={{display: 'flex', justifyContent: 'right'}}>
              <Typography style={{fontSize: "14px",
                color: "#21355F",
                fontWeight: "400",
                textAlign: 'right',
                cursor: "pointer",
                fontFamily: 'sans-serif'}} 
                onClick={this.forgotPassword}
                data-test-id={'btnForgotPassword'} 
                variant="h6" align="right">
                 Forgot Password?
              </Typography>
              
            </Box>
              <Button
                type="submit"
                style={webStyle.buttonStyle}
                data-test-id="LoginBtn"
              >
                Log in
              </Button>
            </Box>
          </form>
          )
        }}
      </Formik>
     </GenericCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    marginBottom: '10px',
    marginTop: '30px'
  },
  heading : {
    fontWeight: 'bold', 
    marginBottom: '10px', 
    marginTop: '20px',
    fontSize: '24px',
    color: 'rgb(33, 53, 95)'
  },

  inputField: {
    border: '1px solid rgba(203, 213, 225, 1)',
    padding: '10px 10px',
    borderRadius: '8px',
    background: 'none',
    width: "95%",
    '&:before' :{
      borderBottom: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },

  CustomInput: {
    minHeight: "50px",
    borderRadius: "5px",
    marginTop: "8px",
    marginBottom: "12px",
    border: "1px solid #d6d6d6",
    padding: "15px",
    fontSize: "14px",
  },
  
  buttonStyle: {
    height: "56px",
    width: "80px",
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: 'none' as const,
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  },
  errorText: {
    fontFamily: 'Inter-Light, sans-serif',
    color: "#E7205D",
    fontSize: "15px",
    lineHeight: "19px",
    fontWeight: 400
  }
};
// Customizable Area End
