import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from "@material-ui/core";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  onCancel: () => void;
  onAccept: any
  disabled: boolean
}

const CommonCustomModal: React.FC<ModalProps> = ({ 
  isVisible, 
  onClose, 
  title, 
  onCancel, 
  onAccept,
  disabled,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={webStyles.modal}
      open={isVisible}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isVisible}>
        <Box sx={webStyles.paper}>
          <Typography style={webStyles.modalTitle}>
            {title}
          </Typography> 
          <Box style={webStyles.modalButtonsGroup}>
            <Button onClick={onCancel} style={webStyles.modalBackButton}>
              Back
            </Button>
            <Button id="acceptButton" disabled={disabled} onClick={onAccept} style={webStyles.modalAcceptButton}>
              Accept
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CommonCustomModal;


const webStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    width: "600px",
    backgroundColor: "#fff",
    padding: "18px 40px 24px 9px",
    borderRadius: "0px 8px 32px 8px"
  },
  modalBackButton: {
    display: "flex",
    width: "120px",
    height: "56px",
    padding: "16px 40.5px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid #E1E1E1",
    background: "#FFF",
    fontFamily:'Inter-Bold,sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    color: "#0075BF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none" as const,
  },
  modalAcceptButton: {
    display: "flex",
    padding: "16px 40.5px",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: '56px',
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    textTransform: "none" as const,
  },
  modalTitle: {
    fontWeight: 500, 
    fontSize: "20px", 
    padding: "32px 25px",
    fontFamily: "Inter-Medium",
    color: "#21355F"
  },
  modalButtonsGroup: {
    display: "flex", 
    gap: 26, 
    paddingBottom: 24
  }
}