import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputLabel,
  Breadcrumbs,
  Grid,
  MenuItem,
  Select,
  Input,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorModal from "./ErrorModal";
import CommonCustomModal from "./CommonCustomModal";
// Customizable Area End

import ClinicianController, {
  configJSON,
  Props,
} from "./ClinicianController";


export default class CreateClinician extends ClinicianController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  

  render() {
    // Customizable Area Start

    const { editQueryId, roleOptions, showClinician, institutionNumber } = this.state;
    const isUserPresent = showClinician?.id

    const breadcrumbs = [
      <Typography 
        onClick={this.handleNavigateToClinicians} 
        data-test-id="clinicians"
        style={webStyles.breadcrumbsLink} 
        key="1" >
        {configJSON.clinicians}
      </Typography>,
      editQueryId ? 
      <Typography 
        onClick={() => this.handleNavigateToClinicianProfile(showClinician?.id)}
        style={webStyles.breadcrumbsLink} 
        key="2" 
        data-test-id="clinicianName" >
        {isUserPresent && showClinician.attributes.common_information.first_name} {" "}
        {isUserPresent && showClinician.attributes.common_information.last_name}
      </Typography> : 
      <Typography key="2" style={{...webStyles.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>
        Create
      </Typography>,
      editQueryId && 
      <Typography key="3" style={{...webStyles.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>
        Edit
      </Typography>,
    ]

    const modalTitle = editQueryId ?
      `${configJSON.subtitleEditClinicianModal} ${this.state.firstName} ${this.state.surname}?` : 
      `${configJSON.subtitleAddClinicianModal} ${this.state.firstName} ${this.state.surname}?`

    return (
      <Container style={{marginTop: "20px",overflow:'auto',paddingBottom:'55px',maxWidth:'100%',width:'100%'}} >
        {this.state.isLoader && this.renderLoader()}
        <Breadcrumbs
          style={{marginTop: "20px"}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Container style={{marginBottom: "50px",width:'100%',maxWidth:'100%'}} >
          <Box>
            <Box>
              <Box style={{display: 'flex', alignItems: 'center', marginTop: "50px"}}>
                <Typography style={ webStyles.personalInformation}>1</Typography>
                <Typography style={{
                  fontSize: '24px',
                  fontFamily: 'Inter-Bold, sans-serif',
                  color: 'rgba(33, 53, 95, 1)',
                  marginLeft: '15px'}}>
                  Personal Information
                </Typography>
              </Box>
            <Formik
              enableReinitialize
              data-test-id="createClinicianForm"
              initialValues={{
                firstName: this.state.firstName,
                surname: this.state.surname,
                email: this.state.email,
                institutionNumber: institutionNumber.slice(0, 3) + 
                ' ' + institutionNumber.slice(3, 6) + 
                ' ' + institutionNumber.slice(6),
                NMCNumber: this.state.NMCNumber,
                GMCNumber: this.state.GMCNumber,
                department: this.state.department,
                role: this.state.role
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                .required("This field is required"),
                surname: Yup.string()
                .required("This field is required"),
                role: Yup.string()
                .required("This field is required"),
                department: Yup.string()
                .required("This field is required"),
                email: Yup.string()
                .email("Invalid email format")
                .required("Email is required"),
                NMCNumber: Yup.string()
                .matches(
                  /^\d{2}[A-Z]\d{4}[A-Z]$/, 
                  "The third and last digits are capitalized letters and all the others are numbers (Ex. 99A9999A)"
                )
                .nullable(),
                GMCNumber: Yup.string()
                .matches(/^(?:L\d{6}|\d{7})$/, "This number can consist of 7 numeric or L + 6 numeric")
                .nullable(),
              })}
              onSubmit={(values, actions) => {
                actions.resetForm({
                  // @ts-ignore
                  firstName: "",
                  surname: "",
                  email: "",
                  department: "",
                  NMCNumber: "",
                  GMCNumber: "",
                  role: ""
                });
                this.handleOpenModal()
              }}>
            {(props) => {
              return(
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Box style={{ padding: '0 50px',height:'100%', display: 'flex', flexDirection: 'column' }}>
                      <Box sx={webStyles.inputWrapper2}>
                        <InputLabel style={webStyles.label}>First Name*</InputLabel>
                        <Input
                          data-test-id="firstName"
                          name="firstName"
                          id="firstName"
                          disableUnderline= {true}
                          style={webStyles.inputField}
                          placeholder="First Name"
                          value={props.values.firstName}
                          fullWidth={true}
                          onChange={(event) => {
                            props.setFieldValue("firstName", event.target.value);
                            this.onValueChange("firstName", event.target.value);
                          }}
                          type="text"
                        />
                        {this.getErrorMessage(
                          props.touched,
                          props.errors,
                          "firstName"
                        )}
                      </Box>
                      <Box sx={webStyles.inputWrapper}>
                        <InputLabel style={webStyles.label}>Email*</InputLabel>
                        <Input
                          data-test-id="email"
                          name="email"
                          id="email"
                          disableUnderline= {true}
                          style={webStyles.inputField}
                          placeholder="Email"
                          value={props.values.email}
                          fullWidth={true}
                          onChange={(event) => {
                            const trimmedValue = event.target.value.trim();
                            props.setFieldValue("email", trimmedValue);
                            this.onValueChange("email", trimmedValue);
                          }}
                          type="text"
                        />
                        {this.getErrorMessage(
                          props.touched,
                          props.errors,
                          "email"
                        )}
                      </Box>
                      <Box sx={webStyles.inputWrapper}>
                        <InputLabel style={webStyles.label}>Department*</InputLabel>
                        <Input
                          data-test-id="department"
                          name="department"
                          id="department"
                          disableUnderline= {true}
                          style={webStyles.inputField}
                          placeholder="Department"
                          value={props.values.department}
                          fullWidth={true}
                          onChange={(event) => {
                            props.setFieldValue("department", event.target.value);
                            this.onValueChange("department", event.target.value);
                          }}
                          type="text"
                        />
                        {this.getErrorMessage(
                          props.touched,
                          props.errors,
                          "department"
                        )}
                      </Box>
                      <Box sx={webStyles.inputWrapper}>
                        <InputLabel style={webStyles.label}>NMC Number</InputLabel>
                        <Input
                          data-test-id={"NMCNumber"}
                          name="NMCNumber"
                          id="NMCNumber"
                          disableUnderline= {true}
                          style={webStyles.inputField}
                          placeholder="NMC Number"
                          value={props.values.NMCNumber}
                          fullWidth={true}
                          onChange={(event) => {
                            props.setFieldValue("NMCNumber", event.target.value);
                            this.onValueChange("NMCNumber", event.target.value);
                          }}
                          type="text"
                        />
                        {this.getErrorMessage(
                          props.touched,
                          props.errors,
                          "NMCNumber"
                        )}
                      </Box>
                    </Box>
                  </Grid>
              <Grid item xs={6}>
              <Box style={{padding: '0 50px',height:'100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={webStyles.inputWrapper2}>
                  <InputLabel style={webStyles.label}>Last Name*</InputLabel>
                  <Input
                    data-test-id="surname"
                    name="surname"
                    id="surname"
                    disableUnderline= {true}
                    style={webStyles.inputField}
                    placeholder="Last Name"
                    value={props.values.surname}
                    fullWidth={true}
                    onChange={(event) => {
                      props.setFieldValue("surname", event.target.value);
                      this.onValueChange("surname", event.target.value);
                    }}
                    type="text"
                  />
                  {this.getErrorMessage(
                    props.touched,
                    props.errors,
                    "surname"
                  )}
                </Box>
                <Box sx={webStyles.inputWrapper}>
                  <InputLabel style={webStyles.label}>Role*</InputLabel>
                  <Select
                    id="Role"
                    style={webStyles.selectStyles}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null,
                    }}
                    placeholder="Role"
                    name="role"
                    value={this.state.role}
                    onChange={(event: any) => {
                      this.onValueChange("role", event.target.value);
                      props.setFieldValue("role", event.target.value);
                    }}
                    disableUnderline
                    fullWidth
                    displayEmpty>
                    <MenuItem value="" disabled>
                      Role
                    </MenuItem>
                    {roleOptions.map((role: {id: number, name: string}) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.getErrorMessage(
                    props.touched,
                    props.errors,
                    "role"
                  )}
                </Box>
                <Box sx={webStyles.inputWrapper}>
                  <InputLabel style={webStyles.label}>Institution Number</InputLabel>
                  <Input
                    data-test-id={"institutionNumber"}
                    name="institutionNumber"
                    id="institutionNumber"
                    disableUnderline= {true}
                    readOnly
                    style={{ ...webStyles.inputField, 
                      backgroundColor: "#EAE8E8", color: "#0F172A", fontSize: "16px", fontFamily: 'Inter-Regular, sans-serif' }}
                    value={props.values.institutionNumber}
                    fullWidth={true}
                    type="text"
                  />
                </Box>
                <Box sx={webStyles.inputWrapper}>
                  <InputLabel style={webStyles.label}>GMC Number</InputLabel>
                  <Input
                    data-test-id={"GMCNumber"}
                    name="GMCNumber"
                    id="GMCNumber"
                    disableUnderline= {true}
                    style={webStyles.inputField}
                    placeholder="GMC Number"
                    value={props.values.GMCNumber}
                    fullWidth={true}
                    onChange={(event) => {
                      props.setFieldValue("GMCNumber", event.target.value);
                      this.onValueChange("GMCNumber", event.target.value);
                    }}
                    type="text"
                  />
                  {this.getErrorMessage(
                    props.touched,
                    props.errors,
                    "GMCNumber"
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
            <Box style={{display: 'flex', justifyContent: 'end', alignItems: 'baseline', gap: 30}}>
            <Button
              onClick={this.handleNavigateToClinicians}
              data-test-id="cancelAddingClinician"
              style={webStyles.buttonCancelStyle}>
              Cancel
            </Button>
            <Button
              type="submit"
              data-test-id="AddButton"
              style={webStyles.buttonCreateStyle}>
              {editQueryId ? "Save Information" : "Create Clinician"}
            </Button>
            </Box>
          </form>
          )}}
        </Formik>
      </Box>
    </Box>
    </Container>
      <CommonCustomModal  
        isVisible={this.state.isVisibleModal}
        onClose={this.handleCloseModal}
        title={modalTitle}
        onCancel={this.handleCloseModal}
        onAccept={editQueryId ? () => this.handleEditClinician(editQueryId) : this.handleCreateClinician}
        disabled={this.state.disabledAccept}
        data-test-id="clinicianModal"
      />
      <ErrorModal
        isVisible={this.state.isVisibleErrorModal}
        onAccept={this.handleVisibleErrorModal}
        message={this.state.errorMessage}
        data-test-id="errorModal"
      />
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  personalInformation: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#fff',
    width: '40px', 
    height: '40px', 
    background:'rgba(231, 32, 93, 1)', 
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputWrapper2: {
    position: "relative",
    marginBottom: '15px',
    marginTop: '15px'
  },
  inputWrapper: {
    position: "relative",
    marginBottom: '15px',
  },
  label: {
    fontSize: "14px",
    marginTop: "8px",
    color: "#21355F",
    marginBottom :"10px",
    fontFamily: 'Inter-Bold, sans-serif',
  }, 
  inputField: {
    border: "1px solid rgba(203, 213, 225, 1)",
    padding: "10px 10px",
    borderRadius: "8px",
    color: "#21355F",
    background: "none",
    "&:before" :{
      border: "none !important" 
    },
    "&:after" :{
      border: "none" 
    },
    "&:hover" :{
      border: "2px solid #0075BF"
    }
  },
  selectStyles: {
    border: "1px solid rgba(203, 213, 225, 1)",
    padding: "10px 10px",
    borderRadius: "8px",
    background: "none",
    height: "56px",
    color: "#21355F"
  },
  selectedColor: {
    color: "black"  
  },
  buttonCancelStyle: {
    height: "44px",
    width: "167px",
    border: "1px solid #0075BF",
    backgroundColor: "fff",
    cursor: "pointer",
    marginTop: "0px",
    borderRadius: "8px",
    color: "#0075BF",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: "none" as const,
  },
  buttonCreateStyle: {
    height: "44px",
    width: "167px",
    border: "none",
    backgroundColor: "#E7205D",
    cursor: "pointer",
    marginTop: "0px",
    borderRadius: "8px",
    color: "fff",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: "none" as const,
  },
  formControl: {
    backgroundColor: "#FFF",
    width: 422,
  },
  breadcrumbsLink: {
    fontSize: "24px", 
    fontFamily: "Inter-Regular, sans-serif",
    color: "#21355F"
  },
};
// Customizable Area End
