Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.editDropdownEnd="bx_block_advanced_search/advance_search";
exports.editDropdownEndPoint="bx_block_advanced_search/advance_search?type=inventory";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement";
exports.labelBodyText = "InventoryManagement Body";
exports.InventoryEndPoint = "bx_block_inventory_management/inventories";
exports.postAPIForRequestNewTest = "bx_block_inventory_management/add_new_test";
exports.patchAPIForReorder = "bx_block_inventory_management/request_test";
exports.searchArea="bx_block_advanced_search/search"
exports.btnExampleTitle = "CLICK ME";
exports.autoCompleteSearch="bx_block_advanced_search/autocomplete?type=inventory"
// Customizable Area End
