import React from 'react';
import {
    Box,
    Typography,
    IconButton,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    FormGroup,
    Breadcrumbs,
    Backdrop,
    Card,
    CardActions,
    Container,
    Modal,
    Fade,
    Tooltip
} from '@material-ui/core';
import { NavigateNext, SearchOutlined, Edit } from '@material-ui/icons';
import { plusIcon, actionIcon, warning } from './assets'
import './orderSummary.css'

import OrderTestController, {
    Props,
} from './OrderTestController';

export const configJSON = require("./config.js");


export default class OrderTest extends OrderTestController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        const checkValue=this.handleValueCheck();
        const isDisabled =this.shouldDisable()

        const breadcrumbs = [
            <Typography
                onClick={() => this.viewPatientScreen()}
                data-test-id="navigateToTest"
                style={{ color: "#21355F", fontSize: "24px", cursor: "pointer" }} key="1" >
                Patients
            </Typography>,
            <Typography
                onClick={() => this.viewPatient()}
                style={{ color: "#21355F", fontSize: "24px", cursor: "pointer", textDecoration: "none" }}
                key="2" data-test-id="patientFullName" >
                {`${this.viewPatientName()}`}
            </Typography>,
            <Typography key="3" style={{...webStyle.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>Order Test</Typography>,
        ]
        const dataFromStorage = localStorage.getItem("summaryData") as string;
        const selectedTests = JSON.parse(dataFromStorage)
        const patientId = JSON.parse(localStorage.getItem("patientId") as string)
        const patientTests = selectedTests?.filter((test: any) => Number(test.patientId) === patientId)
        const isBasketEmpty = patientTests?.length === 0

        return (
            <Container style={webStyle.container}>
                <Breadcrumbs
                    style={{marginLeft: "20px", marginBottom: "30px"}}
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                <Box style={{ display: "flex", width: "100%", overflow: 'auto', gap: 30}}>
                    <Box style={{display: "flex", flexDirection: "column", width: "78%"}}>
                        <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <Box style={{display: 'flex', alignItems: "center",marginLeft:'0px'}}>
                                <Typography style={ webStyle.sampleType}>1</Typography>
                                <Typography style={webStyle.title}>
                                    Select a sample type
                                </Typography>
                            </Box>
                            <Button
                                disableRipple
                                data-test-id="clearSampleTypeBtn"
                                style={webStyle.loadMoreButton}
                                onClick={() => this.clearSampleTypeButtons()}> 
                                Clear Selection
                            </Button>
                        </Box>
                        <Box style={{ height: "44px",paddingBottom:'50px', margin: "30px 0 30px 55px", display: "flex", gap: "12px"}}>
                            { configJSON.sample_type.map((item: string) => (
                                    <Button
                                        data-test-id="sampleBtn"
                                        className='sampleTypeBtns'
                                        style={{
                                            ...this.getButtonStyle(item, webStyle.activeSampleBtn, webStyle.deactiveSampleBtn),
                                            textTransform: 'capitalize'
                                        }}
                                        onClick={() => this.handleSampleType(item)}> 
                                        {item}
                                    </Button>
                                ))
                            }
                        </Box>
                        {this.state.activeSampleType === 'Blood' &&
                            <><Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box style={{ display: 'flex', alignItems: "center" }}>
                                    <Typography style={webStyle.sampleType}>1b</Typography>
                                    <Typography style={webStyle.title}>
                                        Select a device type
                                    </Typography>
                                </Box>
                            </Box>
                                <Box style={{ height: "44px", paddingBottom:'50px',margin: "30px 0 30px 55px", display: "flex", gap: "12px" }}>
                                    {this.state.allDeviceTypeList.map((item: any) => (
                                        <Button
                                            id={item.id}
                                            data-test-id="sampleBtn2"
                                            className='sampleTypeBtns'
                                            style={{
                                                ...(
                                                this.state.activeDeviceType === item.test_type ?
                                                    webStyle.activeSampleBtn :
                                                    webStyle.deactiveSampleBtn
                                                ),
                                                textTransform: 'capitalize'
                                            }}
                                            onClick={() => this.handleDeviceType(item)}>
                                            {item.test_type}
                                        </Button>
                                    ))
                                    }
                                </Box>
                            </>
                        }
                        <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "30px"}}>
                            <Box style={{display: 'flex', alignItems: "center"}}>
                                <Typography style={ webStyle.sampleType}>2</Typography>
                                <Typography style={webStyle.title}>
                                    Select a test panel
                                </Typography> 
                            </Box>
                            <Button
                                disableRipple
                                disabled={isDisabled}
                                data-test-id="clearTestPanelBtn"
                                style={{
                                    ...webStyle.loadMoreButton,
                                    opacity: `${this.handleDisabledActionIconColor()}`
                                }}
                                onClick={() => this.clearRadioButtons()}> 
                                Clear Selection
                            </Button>
                        </Box>
                        <Box style={{
                                height: `${this.handleHeightOnLoadMoreClick()}px`, 
                                scrollbarWidth: "thin",
                                display: "flex", 
                                marginLeft: "50px",
                                overflow: `${this.handleScrollBarForPanel()}`
                            }}>
                            {this.state.testPanelList?.length === 0 ? (
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontFamily: "Inter-Regular, sans-serif",
                                        height: "100px",
                                        color: 'rgb(100, 116, 139)',
                                        fontSize: '22px',
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    No Panels are available for the selected Sample type
                                </Box>
                            ) : (
                                <FormControl component="fieldset" style={{ width: "100%" }}>
                                    <RadioGroup
                                        data-test-id="testPanel"
                                        style={webStyle.radioBtnCtn} 
                                        row 
                                        value={this.state.activeTestPanel}
                                        aria-label="testpanel" 
                                        name="testPanel"
                                        onChange={(event) => this.handleTestPanel(event.target.value)}>
                                        {
                                            this.state.testPanelList.map((item) => (
                                            <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}
                                                key={item.name}
                                            >
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <FormControlLabel
                                                            disabled={isDisabled}
                                                            style={webStyle.radioBtn}
                                                            value={item.name}
                                                            control={<Radio color="primary" />}
                                                            label={item.name} />
                                                    </Box>
                                                    <Tooltip 
                                                        placement="top" 
                                                        arrow disableFocusListener 
                                                        disableTouchListener 
                                                        title={item.description}>
                                                         <img
                                                            alt="actionIcon" 
                                                            src={actionIcon}
                                                            style={{ height: "15px", width: "15px",
                                                            opacity: `${this.handleDisabledActionIconColor()}`}} 
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Box>
                        { checkValue && 
                            <Button
                                disableRipple
                                disabled={isDisabled}
                                data-test-id="loadMoreBtn"
                                style={{...webStyle.loadMoreButton, opacity: `${this.handleDisabledActionIconColor()}`}}
                                onClick={() => this.handleLoadMore()}>
                                {`${this.showLoadLess()}`}
                            </Button>
                        }
                        <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "30px 0px"}}>
                            <Typography style={webStyle.title}> 
                                Or select custom markers
                            </Typography>
                            <Button
                                disableRipple
                                disabled={this.handleDisableCheckbox()}
                                data-test-id="clearMarkerBtn"
                                style={{
                                    ...webStyle.loadMoreButton,
                                    opacity: `${this.handleDisabledClearButton()}`
                                }}
                                onClick={() => this.clearCheckBoxButtons()}>
                                    Clear Selection
                             </Button>
                        </Box>
                        <TextField
                            disabled={this.handleDisableCheckbox()}
                            id="search"
                            variant="standard"
                            placeholder='Search'
                            data-test-id="search-bar"
                            onChange={(event) => this.handleSearch(event.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    color: "#21355F", height: "44px", width: "97%",
                                    border: `${this.handleDisabledSearch()}`, outline: "none", margin: "10px 0",
                                    marginLeft: "2%", borderRadius: "98px", padding: "10px 8px",
                                    fontSize: "16px", fontFamily: "Inter-Regular, sans-serif", fontWeight: 400,
                                },
                                startAdornment: (
                                    <IconButton>
                                        <SearchOutlined style={{ color: `${this.handleDisabledSearchIconColor()}` }} />
                                    </IconButton>
                                ),
                            }}
                        />
                        <Typography style={webStyle.sampleAmount}> 
                            {this.getSampleAmount(this.state.sampleAmountUnit)}
                        </Typography>
                        <Box sx={webStyle.progressCtn}>
                            <div
                                style={{
                                    height: "24px", width: this.getSampleAmountProgress(this.state.sampleAmountUnit), backgroundColor: "#E7205D",
                                    borderRadius: "11px", display: "flex", alignItems: "center",
                                    justifyContent: "flex-end", color: "#FFFFFF", 
                                }} >
                                {`${this.state.totalSampleData}μL`}
                            </div>
                            {(this.displayAmountAndUnit(this.state.sampleAmountUnit)) && (
                                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{
                                        display: "flex", color: "#000000", 
                                        opacity: `${this.handleDisabledClearButton()}`
                                    }}>
                                        {`${this.state.sampleAmount}${this.state.sampleAmountUnit}`}
                                    </div>
                                </Box>
                            )}
                        </Box>
                        <Box sx={webStyle.markersWrapper}>
                            {this.filterMarkers().length === 0 ? (
                                <Box
                                    style={{
                                        display: "flex",
                                        fontSize: '22px',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100px",
                                        color: 'rgb(100, 116, 139)',
                                        width: "100%",
                                        fontFamily: "Inter-Regular, sans-serif",
                                        textAlign: "center",
                                    }}
                                >
                                    No Markers are available for the selected Sample type
                                </Box>
                            ) : (
                                <Box key="" style={{ minHeight: "100px", maxHeight: "420px", overflow: 'auto', scrollbarWidth: "thin"}}>
                                <FormControl style={{width: "100%"}}>
                                    <FormGroup  style={webStyle.checkBoxBtnCtn}>
                                        {this.filterMarkers().map((item:any) => (
                                            <Box style={{ display:"flex", alignItems:"center" }} key={item.id}>
                                                <label style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer", width: "100%" }} htmlFor={item.id} >
                                                <Box style={{
                                                    display: 'flex', alignItems: 'center', gap:"10px",
                                                    width: "fit-content", justifyContent: 'space-between'
                                                }}> 
                                                    <input
                                                        id={item.id}
                                                        disabled={this.handleDisableCheckbox()}
                                                        checked={this.state.activeCheckbox.includes(item.id)}
                                                        type="checkbox"
                                                        name={item.name}
                                                        data-test-id="checkBoxBtntest"
                                                        className='checkboxMarkers'
                                                        onChange={(event) => this.handleCheckBox(item.id, item.amount)}
                                                    />
                                                    <span
                                                        style={{
                                                            wordBreak: "break-word",
                                                            fontSize: "16px", width:"340px",
                                                            fontFamily: "Inter-Regular, sans-serif",
                                                            color: `${this.handleDisabledColor()}`
                                                        }} >
                                                        {`${item.name}`}
                                                    </span>
                                                </Box>
                                                {item.amount && 
                                                    <Typography
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        fontSize: "16px", width:"60px",
                                                        fontFamily: "Inter-Regular, sans-serif",
                                                        color: `${this.handleDisabledColor()}`
                                                    }}>
                                                    {`${item.amount} μL`}
                                                </Typography>
                                                }
                                                </label>
                                            </Box>
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </Box>
                            )}
                        </Box>
                    </Box>
                    <Box style={{ width: "22%"}}>
                        <Box style={{  
                            minHeight: '100px',
                            border: "1px solid #E2E8F0",
                            backgroundColor: "#FFF", 
                            padding: "20px",
                            borderRadius: "8px" }}>
                            <Typography
                                data-test-id="selectedTests" 
                                style={webStyle.selectedTestStyles}>
                                Order Summary
                            </Typography>
                            {!isBasketEmpty ? (
                                patientTests?.map((item: any, itemKey: number) => (
                                    <Box key={itemKey} style={webStyle.test}>
                                        <Typography style={webStyle.selectedTest}>
                                            {`Test ${itemKey + 1} - ${item.sampleType}`}
                                            <Edit
                                                data-test-id="editTestButton"
                                                style={webStyle.editButton} 
                                                onClick={() => this.handleEditTest(item.tempId, patientTests)} />
                                        </Typography>
                                        <Typography style={webStyle.selectedTestPanel}>
                                            {this.getPanelOrMarker(item.testPanelName)}
                                        </Typography>
                                    </Box>
                                ))
                                ) : (
                                <Typography 
                                    style={{...webStyle.selectedTest, fontFamily: "Inter-Regular, sans-serif", textTransform: 'none'}}>
                                    {"You have no tests yet"}
                                </Typography>
                                )}
                            {!isBasketEmpty && <Button
                                onClick={()=> this.navigateToOrderSummary()}
                                style={webStyle.buttonStyle}>
                                Checkout
                            </Button>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box style={webStyle.buttonsWrapper}>
                    <Button
                        disabled={this.handleDisabledButtons()}
                        data-test-id="addNewTestBtn"
                        style={{...webStyle.buttonStyles, 
                            background: "#0075BF",
                            opacity: this.handleOpacity()
                        }}
                        onClick={() => this.handleAddNewTest()}>
                        <img style={{ marginRight: '10px' }} src={plusIcon} />
                        Add new test
                    </Button>
                    <Button
                        disabled={this.handleDisabledButtons()}
                        data-test-id="confirmBtn"
                        style={{...webStyle.buttonStyles, 
                            background: "#E7205D",
                            opacity: this.handleOpacity()
                        }}
                        onClick={async () => {
                            await this.handleConfirm();

                        }}>                                   
                        <img style={{ marginRight: '10px' }} src={plusIcon}/>
                        Confirm
                    </Button>
                </Box>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.validData} >
                    {
                        this.state.validData && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',
                                    borderRadius: '0px 8px 33px 8px',
                                    padding: '30px 30px 24px 30px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Regular,sans-serif' , fontWeight: 'bold'}}>
                                        {"Please order a new test by clicking Add new test button"}
                                    </span>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>
                                    <Button
                                        data-test-id='acceptBtn'
                                        onClick={() => this.handleFix()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px', 
                                            fontWeight:'bold',background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Regular,sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
                <Modal
                  style={webStyle.modalWrapper}
                  open={this.state.isPopUpVisible}
                  onClose={this.closePopUp}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{ timeout: 100 }}
                >
                <Fade in={this.state.isPopUpVisible}>
                    <Box sx={webStyle.modalContent}>
                        <Box sx={webStyle.warningMessage}>
                            <img style={{ height: "35px", width: "35px"}}
                                src={warning}
                                alt="warningIcon"
                            />
                            <Typography style={webStyle.popUpTitle}>
                                {this.state.warningMessage}
                            </Typography> 
                            {this.state.warningSubtitle && 
                            <Typography style={webStyle.popUpSubtitle}>
                                {this.state.warningSubtitle}
                            </Typography> }
                        </Box>
                        <Box style={webStyle.popUpButtons}>
                            <Button 
                                onClick={this.closePopUp} 
                                style={this.state.warningSubtitle ? 
                                    webStyle.modalButton : 
                                    {...webStyle.modalButton,  border: "none", background: "#0075BF", color: "white" }}>
                                {this.state.warningSubtitle ? "Back" : "Ok"}
                            </Button>
                            {this.state.warningSubtitle && 
                            <Button 
                                onClick={this.navigateToOrderSummary} 
                                style={{...webStyle.modalButton,  border: "none", background: "#0075BF", color: "white" }}>
                                Continue
                            </Button>}
                        </Box>
                    </Box>
                </Fade>
              </Modal>
                <Modal
                    open={this.state.showLeavePagePopup}
                    onClose={this.closeShowLeavePagePopup}
                    style={webStyle.modalWrapper}>
                    <Box sx={webStyle.modalContent}>
                        <Typography style={webStyle.popUpTitle}>
                            You're leaving this page. Changes will not be saved
                        </Typography>
                        <Box style={webStyle.popUpButtons}>
                            <button
                                data-test-id="leavePageModalBack"
                                onClick={() => this.closeShowLeavePagePopup()} 
                                style={webStyle.modalButton}>
                                Back
                            </button>
                            <button
                                data-test-id="leavePageModalContinue"
                                onClick={() => this.handleConfirmLeavePage()} 
                                style={{...webStyle.modalButton,  border: "none", background: "#0075BF", color: "white" }}>
                                Continue
                            </button>
                        </Box>
                    </Box>
                </Modal>
            </Container>
        )
    }
}

const webStyle = {
    container: {
        marginTop: "20px",
        width:'100%',
        maxWidth:'100%',
        overflow: 'auto', 
        marginBottom:'55px', 
        paddingBottom: "55px", 
        marginLeft: 0
    },
    activeSampleBtn: {
        width: "245px", 
        height: "44px", 
        background: "#E7205D",
        padding: "0px 6px", 
        borderRadius: "8px", 
        fontSize: "16px",
        fontFamily: "Inter-Bold, sans-serif", 
        color: "#FFFFFF",
    },
    deactiveSampleBtn: {
        width: "245px", 
        textTransform: 'capitalize' as const,
        height: "44px", 
        background: "#FFFFFF",
        padding: "0px 6px", 
        borderRadius: "8px", 
        fontSize: "16px",
        fontFamily: "Inter-Bold, sans-serif", 
        color: "#0075BF", 
        border: "1px solid #0075BF"
    },
    sampleType: {
        fontSize: '24px',
        color: '#fff',
        width: '40px', 
        height: '40px', 
        background:'rgba(231, 32, 93, 1)', 
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    radioBtnCtn: {
        display: "grid",
        gridTemplateColumns: "repeat(2, auto)", 
        gridColumnGap: "50px", 
        listStyle: "none",
        paddingLeft: "10px",
        justifyContent: "space-between",
        paddingRight: "50px",
    },
    checkBoxBtnCtn: {
        display: "grid",
        gridTemplateColumns: "repeat(2, auto)", 
        gridColumnGap: "50px", 
        gridRowGap: "10px", 
        listStyle: "none",
        paddingLeft: "10px",
        justifyContent: "space-between",
        paddingRight: "50px"
    },
    radioBtn: {
        color: "#0F172A", 
        fontSize: "16px", 
        fontFamily: "Inter-Regular, sans-serif", 
        width: "350px",
    },
    progressCtn: {
        height: "24px",
        borderRadius: "0px 10px 10px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        border: "1px solid #CACACA",
        fontFamily: "Inter-Bold, sans-serif", 
        fontSize: "14px", 
        margin: "15px 0 30px 30px"
    },
    breadcrumbsLink: {
        fontSize: "24px",
        color: "#21355F"
    },
    clearSelectionStyles: {
        background: "fff",
        fontSize: "16px",
        fontFamily: "Inter-Bold, sans-serif",
        textTransform: "none" as const,
        color: "#0075BF",
        cursor: "pointer",
        border: "none", 
    },
    title: {
        fontSize: '24px',
        fontFamily: "Inter-Bold, sans-serif",
        color: 'rgba(33, 53, 95, 1)',
        lineHeight: "32px",
        marginLeft: '15px'
    },
    markersWrapper: {
        width: "97%", 
        display: "flex",
        flexDirection: "column", 
        marginLeft: "17px"
    },
    buttonsWrapper: {
        width: "78%", 
        display: "flex", 
        gap: "20px", 
        alignItems: 'center', 
        justifyContent: 'flex-end', 
        paddingTop: '25px',
    },
    buttonStyles: {
        minWidth: "185px", 
        height: "44px",         
        padding: "6px 10px", 
        borderRadius: "8px", 
        fontSize: "16px",
        fontFamily: "Inter-Bold, sans-serif", 
        color: "#FFFFFF",
        textTransform: "none" as const, 
        alignSelf: "center"
    },
    selectedTestStyles: {
        fontSize: '20px',
        fontFamily: "Inter-Bold, sans-serif",
        color: 'rgba(33, 53, 95, 1)',
        lineHeight: "28px",
    },
    sampleAmount: {
        height: "22px", 
        padding: "4px 0",
        color: "#64748B", 
        fontFamily: "Inter-Bold,sans-serif", 
        fontSize: "14px", 
        marginLeft: "30px",
        marginTop: "20px"
    },
    selectedTest: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Inter-Bold, sans-serif", 
        fontSize: "16px",
        lineHeight: "24px",
        color: '#1E293B',
        textTransform: "uppercase" as const
    },
    selectedTestPanel: {
        fontFamily: "Inter-Regular, sans-serif", 
        fontSize: "14px",
        color: '#0F172A',
        lineHeight: "22px"
    },
    loadMoreButton: {
        background: "#fff", 
        fontSize: "16px",
        fontFamily: "Inter-Bold, sans-serif", 
        color: "#0075BF",
        border: "none", 
        textTransform: "none" as const,
    },
    modalWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        width: "600px",
        backgroundColor: "#FFF",
        padding: "18px 40px 24px 9px",
        borderRadius: "0px 8px 32px 8px"
    },
    modalButton: {
        display: "flex",
        width: "120px",
        height: "56px",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "none" as const,
        fontFamily:'Inter-Bold, sans-serif',
        fontSize: "16px",
        cursor: "pointer",
        border: "1px solid #E1E1E1",
        background: "#FFF",
        color: "#0075BF",
    },
    warningMessage: {
        display: "flex", 
        gap: 5, 
        flexDirection: "column",
        textAlign: "center" as const,
        alignItems: "center"
    },
    popUpTitle: {
        fontSize: "20px", 
        padding: "10px 10px 20px",
        fontFamily: "Inter-Medium",
        color: "#21355F",
    },
    popUpSubtitle: {
        fontSize: "14px", 
        padding: "0 10px",
        fontFamily: "Inter-Medium",
        color: "#21355F",
        marginBottom: "20px"
    },
    popUpButtons: {
        display: "flex", 
        gap: 26, 
        paddingBottom: 24
    },
    test: {
        borderBottom: "1px solid #E2E8F0",
        paddingTop: "15px",
        paddingBottom: "10px",
    },
    buttonStyle: {
        height: "44px",
        fontFamily: 'Inter-Bold, sans-serif',
        width: "100%",
        border: "none",
        backgroundColor: "rgba(231, 32, 93, 1)",
        cursor: "pointer",
        borderRadius: "8px",
        color: "white",
        textTransform: "none" as const,
        fontSize: "14px",
        padding: "6px 10px",
        marginTop: "17px"
    },
    editButton: {
        marginLeft: "10px", 
        padding: "5px", 
        background: "#E2E8F0",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "#64748B"
    }
}
