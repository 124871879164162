import React, { FunctionComponent, ReactNode } from "react";
import {
    Box,
    Typography,
    withStyles,
    createStyles,
    Modal
  } from "@material-ui/core";

// @ts-ignore
interface Props {
  children: ReactNode;
  title?: string;
  classes: Record<string, string>;
  isVisibleDeleteAccountModal: boolean;
  isVisibleAddAccountModal: boolean;
  open: boolean;
  handleCloseModal: () => void;
  handleLogout: () => void
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '599px',
    bgcolor: 'background.paper',
    padding: '18px 40px 24px 9px;',
    borderRadius: "0px 8px 32px 8px",
    height: '224px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

const LogoutModal: FunctionComponent<Props> = ({ classes, open, handleCloseModal, handleLogout}) => {
  return ( 
<Modal
    open={open}
    onClose={handleCloseModal}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
>
    <Box sx={{ ...style }}>
        <Typography className={classes.title}>
          Are you sure you want to log out?
        </Typography>
        <Typography className={classes.subtitle}>
          Note: All tests in the basket will be removed
        </Typography>
        <Box className={classes.buttonAlign}>
            <button onClick={handleCloseModal} className={classes.cancelStyle}>Back</button>
            <button onClick={handleLogout} className={classes.acceptButton}>Logout</button>
        </Box>
    </Box>
</Modal>
  );
};


const webStyle = () =>  createStyles({
    buttonAlign: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "0px 120px",
        alignContent: 'baseline',
        marginTop: '20px'
      },
      cancelStyle: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#ffffff",
        borderRadius: '8px',
        color: "#0075BF",
        cursor: "pointer",
        fontFamily:'Inter-Bold, sans-serif',
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      },
      acceptButton: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#0075BF",
        borderRadius: '8px',
        color: "white",
        cursor: "pointer",
        fontFamily:'Inter-Bold, sans-serif',
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      },
      title: {
        fontSize: '20px', 
        fontFamily:'Inter-SemiBold, sans-serif',
        color: '#21355F', 
        textAlign: 'center', 
        marginTop:'35px',
        marginBottom: "20px"
      },
      subtitle: {
        fontSize: "14px", 
        padding: "0 10px",
        fontFamily: "Inter-Regular, sans-serif",
        color: "#21355F"
      }
  });
  
  export default withStyles(webStyle)(LogoutModal);