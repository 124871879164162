import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal,
  Breadcrumbs,
  TextField,
  Backdrop,
  Fade
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Error} from '@material-ui/icons';


import { Formik } from "formik";
import * as Yup from "yup";



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});



// Customizable Area End

import Settings2Controller, {
  Props,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {userDetail, notValidOldPassword, isError, isDisabled, successTitle, isLoader} = this.state
    const title = "Are you sure you want to change your password?"

    const breadcrumbs = [
      <Typography style={{color: '#21355F', fontSize: '24px', textTransform: 'capitalize'}} key="1">
        {userDetail?.first_name} {userDetail?.last_name}
      </Typography>,
      <Box
        style={{fontWeight: 700, color: '#21355F', fontSize: '24px'}}
        key="2"
        color="inherit"
      >
        Change Password
      </Box>,

    ];
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={{overflow:'auto'}} maxWidth={"xl"}>
        <Breadcrumbs
        style={{marginTop: '20px'}}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
        <Container maxWidth={"sm"}>
          {isLoader && this.showLoader()}
        <Formik
        enableReinitialize
        initialValues={{
          oldPassword: "",
          passwordConfirmation: "",
          newPassword: "",
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string()
          .required("Old Password is required"),
          newPassword: Yup.string()
          .required("New Password is required")
          .matches(/^(?=.*[A-Z])/, "At least one capital letter")
          .matches(/^(?=.*[a-z])/, "At least one lowercase letter")
          .matches(/^(?=.*\d)/, "At least one number")
          .min(8, "Minimum character length is 8 characters"),
          passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("newPassword")], "Password do not match")
          .required("Confirm Password is required"),
          })}
          data-test-id="changePasswordForm"
        

        onSubmit={(values, actions) => {
          actions.resetForm({
          //@ts-ignore
          oldPassword: "",
          newPassword: "",
          passwordConfirmation: ""
          });
          this.handleOpen()
        }}
      >
        {(props) => {
          return(
          <form onSubmit={props.handleSubmit}>
            <Box style={{padding: '0 50px'}}>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Old Password</InputLabel>
                <TextField
                  id="password"
                  type={this.allCondition(this.state.enableOldPassword, "password", "text")}
                  data-test-id={"password"}
                  style={webStyle.inputField}
                  name="oldPassword"
                  value={props.values.oldPassword}
                  onChange={
                    /* istanbul ignore next */
                    (event) => {
                    this.onValueChange("oldPassword", event.target.value);
                    props.setFieldValue("oldPassword", event.target.value);
                  }}
                  InputProps={{
                    disableUnderline:true,
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickOldShowPassword}
                          edge="end"
                          
                        >
                        {this.allCondition(!this.state.enableOldPassword, <RemoveRedEyeOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />, <VisibilityOffOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}}/> )}

                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Old password"
                  fullWidth={true}
                />
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "oldPassword"
                )}
                <Typography style={{fontSize: '16px', margin: '20px 0 10px 0'}} align="left">Enter a new password for {userDetail?.email}</Typography>

              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>New Password</InputLabel>
                <TextField
                  id="newPassword"
                  type={this.allCondition(this.state.enableNewPasswordField, "password", "text")}
                  data-test-id={"newPassword"}
                  style={webStyle.inputField}
                  name="newPassword"
                  value={props.values.newPassword}
                  onChange={(event) => {
                    this.onValueChange("newPassword", event.target.value);
                    props.setFieldValue("newPassword", event.target.value);
                  }}

                  InputProps={{
                    disableUnderline:true,
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickNewPassword}
                          edge="end"
                          
                        >
                          {this.allCondition(!this.state.enableNewPasswordField, <RemoveRedEyeOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />, <VisibilityOffOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}}/> )}
                        
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Your new password"
                  fullWidth={true}
                />
                 {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "newPassword"
                )}
              <Box>
                <Typography style={{fontSize: '16px', margin: '20px 0 10px 0'}} align="left">
                  Your password must contain
                </Typography>
                <Typography style={webStyle.passwordValidation} align="left">
                  {this.validationConditionShort(
                    props.values.newPassword.match(/^(?=.*[A-Z])/),
                    <CheckCircleIcon style={webStyle.greenTickPasswordIcon} />,
                    props.values.newPassword,
                    <RemoveCircleIcon style={webStyle.removeCircleIcon} />,
                    <CheckCircleIcon style={webStyle.tickPasswordIcon} />
                  )}
                  At least one capital letter
                </Typography>
                <Typography style={webStyle.passwordValidation} align="left">
                  {this.validationConditionShort(
                    props.values.newPassword.match(/^(?=.*[a-z])/),
                    <CheckCircleIcon style={webStyle.greenTickPasswordIcon} />,
                    props.values.newPassword,
                    <RemoveCircleIcon style={webStyle.removeCircleIcon} />,
                    <CheckCircleIcon style={webStyle.tickPasswordIcon} />
                  )}
                  At least one lowercase letter
                </Typography>
                <Typography style={webStyle.passwordValidation} align="left">
                  {this.validationConditionShort(
                    props.values.newPassword.match(/^(?=.*\d)/),
                    <CheckCircleIcon style={webStyle.greenTickPasswordIcon} />,
                    props.values.newPassword,
                    <RemoveCircleIcon style={webStyle.removeCircleIcon} />,
                    <CheckCircleIcon style={webStyle.tickPasswordIcon} />
                  )}
                  At least one number
                </Typography>
                <Typography style={webStyle.passwordValidation} align="left">
                  {this.validationConditionShort(
                    props.values.newPassword.length >= 8,
                    <CheckCircleIcon style={webStyle.greenTickPasswordIcon} />,
                    props.values.newPassword,
                    <RemoveCircleIcon style={webStyle.removeCircleIcon} />,
                    <CheckCircleIcon style={webStyle.tickPasswordIcon} />
                  )}
                  Minimum character length is 8 characters
                </Typography>
              </Box>
              </Box>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Confirm New Password</InputLabel>
                <TextField
                  name="passwordConfirmation"
                  data-test-id={"passwordConfirmation"}
                  style={webStyle.inputField}
                  type={this.allCondition(this.state.enableConfirmPasswordField, "password", "text")}
                  id="passwordConfirmation"
                  value={props.values.passwordConfirmation}
                  onChange={(event) => {
                    props.setFieldValue("passwordConfirmation", event.target.value);
                    this.onValueChange("passwordConfirmation", event.target.value);
                  }}

                  InputProps={{
                    disableUnderline:true,
                    endAdornment: (
                      <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleConfirmClickShowPassword}
                        edge="end"
                        
                      >
                      {this.allCondition(!this.state.enableConfirmPasswordField, <RemoveRedEyeOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}} />, <VisibilityOffOutlinedIcon style={{color: 'rgba(148, 163, 184, 1)'}}/> )}
                      </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Confirm your new password"
                  fullWidth={true}
                />

                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "passwordConfirmation"
                )}
              </Box>
            
              <Button
                type="submit"
                data-test-id="LoginBtn"
                style={webStyle.buttonStyle}
              >
                Set New Password
              </Button>
            </Box>
          </form>
        )}}
      </Formik>
        </Container>
          <Modal
            style={webStyle.modal}
            open={this.state.openModal}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 100 }}
          >
            <Fade in={this.state.openModal}>
              <Box sx={webStyle.paper}>
                <Box style={{display: "flex", alignItems: "center", gap: 5}}>
                  {isError && <Error style={{color: "red"}} />}
                  <Typography style={webStyle.modalTitle}>
                    {isError ? notValidOldPassword : title}
                  </Typography> 
                </Box>
                <Box style={webStyle.modalButtonsGroup}>
                  {!isError && 
                  <Button onClick={this.handleClose} style={webStyle.modalBackButton}>
                    Back
                  </Button>
                  }
                  <Button 
                    disabled={isDisabled} 
                    onClick={isError ? this.handleClose : this.handleChangePassword} 
                    style={webStyle.modalAcceptButton}
                  >
                    {isError ? "Ok" : "Accept"}
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
          <Modal
            style={webStyle.modal}
            open={this.state.openSuccessModal}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 120 }}
          >
            <Fade in={this.state.openSuccessModal}>
              <Box sx={webStyle.paper}>
                <Box style={{display: "flex", alignItems: "center"}}>
                  <Typography style={webStyle.modalTitle}>
                   {successTitle}
                  </Typography> 
                </Box>
                <Box style={webStyle.modalButtonsGroup}>
                  <Button 
                    onClick={ this.handleClose} 
                    style={webStyle.modalAcceptButton}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    marginBottom: '20px',
    marginTop: '30px'
  },
  passwordValidation: {
    color: "rgba(0, 0, 0, 1)",
    fontWeight: 400 ,
    fontSize: '14px',
    display: 'flex',
    alignItems:'center'
  },
  removeCircleIcon: {
    color: '#DC2626',
    fontSize: '14px',
    marginRight: '5px'
  },
  greenTickPasswordIcon: {
    color: '#34D399',
    fontSize: '14px',
    marginRight: '5px'
  },
  tickPasswordIcon: {
    color: '#94A3B8',
    fontSize: '14px',
    marginRight: '5px'
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  }, 
  inputField: {
    border: '1px solid rgba(203, 213, 225, 1)',
    padding: '10px 10px',
    borderRadius: '8px',
    background: 'none',
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
    },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    height: "45px",
    marginBottom:'100px',
    width: "100%",
    border: "none",
    backgroundColor: "#0075BF",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: '8px',
    color: "white",
    fontWeight: 700,
    textTransform: 'none' as const
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    width: "600px",
    backgroundColor: "#fff",
    padding: "18px 40px 24px 9px",
    borderRadius: "0px 8px 32px 8px"
  },
  modalBackButton: {
    display: "flex",
    width: "120px",
    height: "56px",
    padding: "16px 40.5px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid #E1E1E1",
    background: "#FFF",
    fontFamily:'Inter-Bold,sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    color: "#0075BF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none" as const,
  },
  modalAcceptButton: {
    display: "flex",
    padding: "16px 40.5px",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: '56px',
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    textTransform: "none" as const,
  },
  modalTitle: {
    fontWeight: 500, 
    fontSize: "20px", 
    padding: "32px 10px",
    fontFamily: "Inter-Medium",
    color: "#21355F"
  },
  modalButtonsGroup: {
    display: "flex", 
    gap: 26, 
    paddingBottom: 24
  }
};
// Customizable Area End
