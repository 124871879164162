import React, { FunctionComponent, ReactNode, useEffect } from "react";
import {
    Box,
    Typography,
    withStyles,
    createStyles,
    Modal,
    Fade,
    Backdrop,
    CircularProgress
  } from "@material-ui/core";
// @ts-ignore
import Footer from '../../components/src/Footer'
import { logoutButton } from '../../blocks/automaticreminders/src/assets';
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Navigation {
  navigate: (path: string) => void;
}

interface Props extends RouteComponentProps {
  children: ReactNode;
  title?: string;
  classes: Record<string, string>;
  navigation?: Navigation
  displayPopup?: boolean
  onClosePopup?: () => void
  isLoader?: boolean
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '599px',
  bgcolor: 'background.paper',
  padding: '18px 40px 24px 9px;',
  borderRadius: "0px 8px 32px 8px",
  height: '224px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign: 'center'
};

const GenericCard: FunctionComponent<Props> = ({ 
  children, 
  classes, 
  navigation, 
  history, 
  title, 
  displayPopup,
  onClosePopup,
  isLoader
 }) => {
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState('')

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    if (navigation) {
      navigation.navigate('Login');
    }
    localStorage.removeItem('data');
  }

  useEffect(() => {
    const pathname = history.location.pathname;
    // @ts-ignore
    setLogout(pathname)
  }, [history.location.pathname])

  const renderLoader = () => (
    <Box
      width="100vw"
      alignItems="center"
      position="fixed"
      top={0}
      display="flex"
      bgcolor="rgba(0, 117, 191, 0.2)"
      left={0}
      justifyContent="center"
      zIndex={17}
      height="100vh">
      <CircularProgress size={50} />
    </Box>
  )

  return (
    <Box className={classes.wrapper}>
      {isLoader && renderLoader()}
      <Box className={classes.formWrapper}>
        {children}
      </Box>
      {
        logout === '/SelectInstitute' && <Box style={{
          position: 'absolute',
          bottom: '85px',
          left: '20px',
          textAlign: 'center',
          borderRadius: '10px 10px 0 0',
          background: '#fff',
          padding: '20px 10px',
          cursor: 'pointer'
        }}
        onClick={() => setOpen(true)}
        >
        <img src={logoutButton} />
        <Typography
            variant="h6"
            style={{textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,  
              color:'rgba(231, 32, 93, 1)', 
            
          }}
          > 
            Log out
          </Typography>
        </Box>
      }
      

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
      <Box sx={{ ...style }}>
              <Typography style={{fontSize: '20px', fontFamily: 'sans-serif',fontWeight: 500,
          color: '#21355F', textAlign: 'center', marginTop:'50px'}}>Are you sure you want to log out?</Typography>
          <Box className={classes.buttonAlign}>
              <button onClick={handleCloseModal} className={classes.cancelStyle}>Back</button>
              <button onClick={handleLogout} className={classes.acceptButton}>
                Logout</button>
          </Box>
      </Box>
    </Modal>
      <Modal
        className={classes.modalWrapper}
        open={Boolean(displayPopup)}
        onClose={onClosePopup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 100 }}>
        <Fade in={Boolean(displayPopup)}>
          <Box className={classes.modalContent}>
            <Typography className={classes.popUpTitle}>
              {title}
            </Typography> 
            <Box className={classes.popUpButtons}>
              <button 
                onClick={onClosePopup} 
                className={classes.modalButton}>
                Continue
              </button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Footer />
    </Box>
  );
};


const webStyle = () =>  createStyles({
  wrapper: {
    background: "linear-gradient(249deg, #FFF 0%, #0075BF 47.6%, #21355F 100%);",
    display: 'flex',
    justifyContent: 'center',
    placeItems: 'center',
    height: '100%',
    flexDirection: 'column',
    width: '100%',
    position:'relative',
    overflow:'auto'
  },
    formWrapper: {
      padding: "10px",
      position:'relative',
      marginTop:'auto',
      top:'10px',
      width: "542px",
      minHeight: '300px',
      background: "#fff",
      borderRadius: "38px",
    },
    buttonAlign: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: "0px 120px",
      alignContent: 'baseline',
      marginTop: '30px'
    },
    logoutLink: {
      color: '#fff',
      textDecoration: 'none'
    },
    cancelStyle: {
      width: "120px",
      height: '56px',
      marginTop: "25px",
      border: "none",
      backgroundColor: "#ffffff",
      borderRadius: '8px',
      color: "#0075BF",
      cursor: "pointer",
      fontWeight: 700,
      fontSize: '16px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    },
    acceptButton: {
      width: "120px",
      height: '56px',
      marginTop: "25px",
      border: "none",
      backgroundColor: "#0075BF",
      borderRadius: '8px',
      color: "white",
      cursor: "pointer",
      fontWeight: 700,
      fontSize: '16px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      },
      modalWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        width: "600px",
        backgroundColor: "#FFF",
        padding: "24px",
        borderRadius: "0px 8px 32px 8px"
    },
    modalButton: {
        display: "flex",
        width: "120px",
        height: "56px",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "none" as const,
        fontFamily:'Inter-Bold, sans-serif',
        fontSize: "16px",
        cursor: "pointer",
        border: "1px solid #E1E1E1",
        background: "#0075BF",
        color: "FFF",
    },
    popUpTitle: {
        fontSize: "20px", 
        padding: "10px 10px 20px",
        fontFamily: "Inter-Medium",
        color: "#21355F",
        textAlign: "center"
    },
    popUpButtons: {
        display: "flex", 
        gap: 26, 
        paddingBottom: 24
    },
  });
  
  export default withRouter(withStyles(webStyle)(GenericCard));