import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  enableOldPassword: boolean
  enableNewPasswordField: boolean,
  enableConfirmPasswordField: boolean,
  openModal: boolean,
  oldPassword: string,
  newPassword: string,
  passwordConfirmation: string,
  token: string,
  userDetail: any
  notValidOldPassword: string
  isError: boolean
  isDisabled: boolean
  openSuccessModal: boolean
  successTitle: string
  isLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      enableOldPassword: true,
      enableNewPasswordField: true,
      enableConfirmPasswordField: true,
      openModal: false,
      oldPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      userDetail: {},
      token: "",
      notValidOldPassword: "",
      isError: false,
      isDisabled: false,
      openSuccessModal: false,
      successTitle: "",
      isLoader: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    /* istanbul ignore next */
    if (
      
      this.changePasswordApiCallId !== null &&
      this.changePasswordApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.message) {
        this.handleClose()
        this.setState({
          successTitle: responseJson?.message,
          openSuccessModal: true,
          isLoader: false,
          openModal: false,
        })
      } else if(responseJson?.error) {
        this.handleClose()
        this.setState({
          notValidOldPassword: responseJson?.error,
          isError: true,
          openModal: true,
          isDisabled: false,
          isLoader: false
        })
      }
    } 
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const result = localStorage.getItem('data')
    // @ts-ignore
    const parseData = JSON.parse(result)
    this.setState({token: parseData?.meta?.token})
    this.setState({userDetail: parseData?.data?.attributes?.common_information})
  }

  /* istanbul ignore next */
  allCondition = (condition: any, truth: any, falsy: any) => {
    return condition ? truth : falsy
  }
  
/* istanbul ignore next */
  validationConditionShort = (condition: any, truth: any, falsy: any, removeCircleIcon: any, checkCircleIcon: any) => {
    const isFalsyCheck = falsy ? removeCircleIcon : checkCircleIcon;
    return condition ? truth : isFalsyCheck;
  }

  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      errors[value as keyof object] &&
      touched[value as keyof object] &&
       (
        <div
          style={{
            position: 'relative',
            fontFamily: 'Inter-Light, sans-serif',
            color: "#E7205D",
            fontSize: "15px",
            lineHeight: "19px",
            fontWeight: 400
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  handleClickOldShowPassword = () => {
    this.setState((prevState) => ({
      enableOldPassword: !this.state.enableOldPassword
    }))
  };

  handleClickNewPassword = () => {
    this.setState((prevState) => ({
      enableNewPasswordField: !this.state.enableNewPasswordField
    }))
  };

  showLoader = () => (
    <Box
      width="100vw"
      alignItems="center"
      height="100vh"
      top={0}
      position="fixed"
      justifyContent="center"
      left={0}
      bgcolor="rgba(0, 117, 191, 0.2)"
      zIndex={16}
      display="flex">
      <CircularProgress size={50} style={{ color: "#0075BF" }}  />
    </Box>
  )

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleConfirmClickShowPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
   
  };

  handleOpen = () => {
    this.setState({openModal: true});
  } 
  handleClose = () =>  {
    this.setState({
      openModal: false, 
      notValidOldPassword: '',
      isDisabled: false,
      isError: false,
      openSuccessModal: false,
      successTitle: "",
    });
  }
  
  handleChangePassword = () => {
    this.setState({
      isDisabled: true,
      isLoader: true,
      openModal: false,
    })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const httpBody = {
      data: {
        old_password: this.state.oldPassword,
        password: this.state.newPassword,
        password_confirmation: this.state.passwordConfirmation,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );

    this.changePasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
