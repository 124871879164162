import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  message: string;
  userType: string
  email: string
  institutionName: string
  fullName: string
  institutionId: number
  isDisabled: boolean
  successMessage: string
  guide: any
  isLoader: boolean
  dataFetching: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  postDataApiCallId: string = ""
  getGuideApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      message: "",
      userType: "",
      email: "",
      institutionName: "",
      fullName: "",
      institutionId: NaN,
      isDisabled: false,
      successMessage : "",
      guide: null,
      isLoader: false,
      dataFetching: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postDataApiCallId) {
          this.setState({ 
            isDisabled: false,  
            successMessage: "Your message was sent successfully",
            isLoader: false
          });
        }else{
          this.setState({ 
            isLoader: false
          });
        }

        if ((apiRequestCallId === this.getGuideApiCallId) && responseJson.guide) {
          this.setState({ 
            guide: responseJson.guide,
            dataFetching: false
          });
        }else{
          this.setState({ 
            dataFetching: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount()
    this.getGuide()
    const data = JSON.parse(localStorage.getItem("data") || "{}")
    const isClinician = data.data.attributes.common_information.user_type === "clinician"
    const clinicianInstitutionName = isClinician && localStorage.getItem("dropdownValue")
    const getInstitutionId = isClinician && localStorage.getItem("institutionId")
    window.addEventListener('storage', this.handleStorageChange);

    this.setState({ 
      userType: data.data.attributes.common_information.user_type,
      email: data.data.attributes.common_information.email,
      institutionName: isClinician ? clinicianInstitutionName : data.data.attributes.institute_information.institute_name,
      fullName: `${data.data.attributes.common_information.first_name} ${data.data.attributes.common_information.last_name}`,
      institutionId: Number(getInstitutionId),
      token: data.meta.token
    }) 
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener("storage", this.handleStorageChange);
    this.setState({ successMessage: "" });
  }

  handleStorageChange = () => {
    this.setState({
      institutionId: Number(localStorage.getItem('institutionId')),
      institutionName: localStorage.getItem('dropdownValue') || ""
    });
  }

  showLoader = () => (
    <Box
      top={0}
      justifyContent="center"
      alignItems="center"
      left={0}
      zIndex={13}
      display="flex"
      position="fixed"
      height="100vh"
      width="100vw"
      bgcolor="rgba(0, 117, 191, 0.2)">
      <CircularProgress size={50} />
    </Box>
  )

  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            fontFamily: 'Inter-Light, sans-serif',
            color: "#E7205D",
            fontSize: "15px",
            lineHeight: "19px",
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    )
  }

  getBorder(isError: string | undefined){
    return isError ? "1px solid red" : "1px solid rgba(203, 213, 225, 1)"
  }

  getGuide = async () => {
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getGuideApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGuideApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      dataFetching: true
    })
  };

  sendMessage = async () => {
    this.setState({ isDisabled: true, successMessage: "", isLoader: true })
  
    const { fullName, email, institutionId, message, userType, token, institutionName } = this.state
    const isClinician = userType === 'clinician'
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    const clinicianData = {
      contact_us : {
        name: fullName,
        email: email,
        institution_id: institutionId,
        message: message
      }
    }

    const institutionData = {
      contact_us : {
        name: institutionName,
        email: email,
        message: message
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postDataApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendMessageApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(isClinician ? clinicianData : institutionData)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  // Customizable Area End
}
