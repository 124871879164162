// App.js - WEB
import React, { Component, createContext } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Sidebar from "../../components/src/Sidebar";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Patient from "../../blocks/accountgroups/src/Patient.web";
import ManagePatient from "../../blocks/accountgroups/src/ManagePatient.web";
import EditPatient from "../../blocks/accountgroups/src/EditPatient.web";
import PatientView from "../../blocks/accountgroups/src/PatientView.web";
import TestEdit from '../../blocks/customform/src/TestEdit.web';
import ClinicianTable from "../../blocks/accountgroups/src/ClinicianTable.web";
import ManageClinician from "../../blocks/accountgroups/src/ManageClinician.web";
import ClinicianProfile from "../../blocks/accountgroups/src/ClinicianProfile.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cftestidentifieralgorithm1 from "../../blocks/cftestidentifieralgorithm1/src/Cftestidentifieralgorithm1";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail.web";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Login from "../../blocks/automaticreminders/src/Login.web";
import SelectInstitute from "../../blocks/automaticreminders/src/SelectInstitute.web";
import Cflabapiintegration from "../../blocks/cflabapiintegration/src/Cflabapiintegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Tests from "../../blocks/customform/src/Tests.web";
import ResultTest from "../../blocks/customform/src/ResultTest.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ResetPassword from "../../blocks/automaticreminders/src/ResetPassword.web"
import OrderSummary from "../../blocks/customform/src/OrderSummary.web"
import OrderTest from "../../blocks/customform/src/OrderTest.web";
import ContactUs from "../../blocks/navigationmenu/src/ContactUs.web";
import Guide from "../../blocks/navigationmenu/src/Guide.web";






const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Patient:{
  component:Patient,
 path:"/Patient"},

 CreatePatient:{
  component:ManagePatient,
 path:"/CreatePatient"},

 EditPatient:{
  component: EditPatient,
 path:"/EditPatient/:id"},
 PatientView:{
  component:PatientView,
 path:"/PatientView/:id"},
 TestEdit:{
  component:TestEdit,
  path:"/TestEdit/:id"
},
ClinicianTable:{
 component:ClinicianTable,
path:"/Clinicians"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
Login:{
  component:Login,
path:"/Login"},
SelectInstitute:{
  component:SelectInstitute,
path:"/SelectInstitute"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Cftestidentifieralgorithm1:{
 component:Cftestidentifieralgorithm1,
path:"/Cftestidentifieralgorithm1"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ResetPassword:{
  component:ResetPassword,
 path:"/resetPassword"},
Cflabapiintegration:{
 component:Cflabapiintegration,
path:"/Cflabapiintegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Tests:{
  component:Tests,
  path:"/Tests"
},
ResultTest:{
  component:ResultTest,
  path:"/ResultTest/:id"
},
NavigationMenu:{
component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OrderTest:{
  component:OrderTest,
 path:"/OrderTest"},
CreateClinician:{
  component:ManageClinician,
path:"/CreateClinician"},
ClinicianProfile:{
  component:ClinicianProfile,
path:"/ClinicianProfile/:id"},
EditClinician:{
  component:ManageClinician,
path:"/EditClinician/:id"},
OrderSummary:{
  component:OrderSummary,
path:"/OrderSummary"},
ContactUs:{
  component:ContactUs,
path:"/ContactUs"},
Guide:{
  component:Guide,
path:"/Guide"},


  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },

  Login: {
    component: Login,
    path: '/Login',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const url = new URL(window.location.href)
    const result = url.pathname
    const notAllowNav = ["/login", "/", "/forgotpassword", "/resetpassword", "/termsconditions"];
    return (
      <BuilderProvider>
        {
          notAllowNav.includes(result.toLowerCase()) ? "" : <TopNav />
        }
        <div style={{overflow:'hidden'}}>
        <View style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row' }}>
          {/* <Sidebar /> */}       
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>   
         </div>
      </BuilderProvider>
    );
  }
}

export default App;