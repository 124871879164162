import React from "react";

import {
  Box,
  Typography,
  Grid,
  CardContent,

  // Customizable Area Start
  Card,
  CardActions,
  IconButton,
  TextField,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";
import { Products } from "./InventoryTypes";
// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import {
  Search,
} from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import { autoComplete, plusIcon } from './assets'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';

// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController";

export default class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <Box
          style={{
            width: '100%', marginBottom: '20px', paddingBottom: '30px', overflowY: 'auto', display: 'flex', flexDirection: 'column',
            padding: '20px 20px', gap: '20px'
          }}>
          <Typography
            style={{
              width: '128px', height: '40px', color: '#21355F',
              fontFamily: 'Inter-Bold, sans-serif', fontSize: '24px', padding: '4px 8px'
            }}
          >
            Inventory
          </Typography>
          <Box style={{
            display: 'flex', alignItems: 'flex-end', justifyContent: "space-between"
          }}>
           
            <TextField
              name="search"
              value={this.state.datans}
              data-test-id="handlesearch"
              onClick={() => { this.handleSearch() }}
              autoComplete='off'
              style={webStyle.inputField}
              placeholder="Search"
              onChange={(event) => { this.searchTheData(event) }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      edge="end"
                      style={{ fontSize: "16px" }}
                    >
                      <Search style={{ color: "rgb(33, 53, 95)", marginRight: "5px" }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth={true}
            />
            <Button
              data-test-id="requestBtn"
              onClick={() => this.handleNewRequest()}
              variant="contained"
              style={{
                fontFamily: 'Inter-Bold, sans-serif',
                height: "44px",
                width: "300px",
                border: "none",
                backgroundColor: "rgba(231, 32, 93, 1)",
                cursor: "pointer",
                marginTop: "25px",
                borderRadius: "8px",
                color: "white",
                textTransform: "none" as const,
                marginLeft: "20px"
              }}>
              <img
                style={{ marginRight: '10px' }}
                src={plusIcon}
              />
              Request New Tests
            </Button>
            </Box>
          <div>
            <Card style={{ zIndex: 100, color: '#FFF' }}>
              {this.state.openSearchBox && (
                <>
                  <Card
                    style={{
                      position: 'absolute',
                      borderRadius: '8px', padding: '12px 10px 14px 10px',
                      gap: '15px', backgroundColor: '#FFFFFF', alignItems: 'center',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                    <CloseIcon data-test-id="checkicon" style={{ cursor: 'pointer', height: '16px', position: 'relative', top: '-5px', right: '10px' }} onClick={() => this.handleSearch2()} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                      <Select
                        isSearchable={false}
                        data-test-id="selectdata"
                        value={null}
                        onChange={(event) => this.handleChange(event)}
                        key={'Sample Type'}
                        placeholder={'Sample Type'}
                        closeMenuOnSelect={true}
                        components={{
                          /* istanbul ignore next */ IndicatorSeparator: () => null
                        }}
                        options={[
                          ...new Set(
                            this.state.dropdownDataAns?.map((option: any) => option.attributes.sample_type).filter(value => value !== null)
                          )
                        ].map(value => ({ value, label: value }))}

                        hideSelectedOptions={false}
                        styles={this.customStyles}
                        menuPortalTarget={document.body}
                      />
                      <Select
                        isSearchable={false}
                        data-test-id="selectdata2"
                        value={null}
                        onChange={this.testTypeChange}
                        key={'Test Type'}
                        placeholder={'Test Type'}
                        closeMenuOnSelect={true}
                        components={{
                          /* istanbul ignore next */ IndicatorSeparator: () => null
                        }}
                        /* istanbul ignore next */
                        options={[
                          ...new Set(
                            this.state.dropdownDataAns?.map((option: any) => option.attributes.test_type).filter(value => value !== null)
                          )
                        ].map(value => ({ value, label: value }))}

                        hideSelectedOptions={false}
                        styles={this.customStyles}
                        menuPortalTarget={document.body}
                      />
                      <Select
                        value={null}
                        isSearchable={false}
                        data-test-id="selectdata3"
                        onChange={this.stockLevelHandle}
                        key={'Stock Level'}
                        placeholder={'Stock Level'}
                        closeMenuOnSelect={true}
                        components={{
                          /* istanbul ignore next */ IndicatorSeparator: () => null
                        }}
                        /* istanbul ignore next */
                        options={[
                          ...new Set(
                            this.state.dropdownDataAns?.map((option: any) => option.attributes.stock).filter(value => value !== null)
                          )
                        ].map(value => ({ value, label: value }))}
                        hideSelectedOptions={false}
                        styles={this.customStyles}
                        menuPortalTarget={document.body}
                      />

                      <Select
                        value={null}
                        isSearchable={false}
                        data-test-id="selectdata4"
                        key={'Last Order Date'}
                        onChange={this.handleLastDate}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        placeholder={'Last Order Date'}
                        closeMenuOnSelect={true}
                        options={[
                          ...new Set(
                            this.state.dropdownDataAns?.map((option: any) => option.attributes.last_order_date).filter(value => value !== null)
                          )
                        ].map(value => ({ value, label: value }))}

                        hideSelectedOptions={false}
                        styles={this.customStyles}
                        menuPortalTarget={document.body}
                      />
                    </div>
                    <div style={{ display: 'flex', paddingTop: '15px' }}>
                      {this.renderSelectedOption()}
                      {this.renderSelectedOption2()}
                      {this.renderSelectedOption3()}
                      {this.renderSelectedOption4()}

                      {(this.state.selectedOptions?.label || this.state.testOptions2?.label || this.state.selectedStock?.label || this.state.selectedDate?.label)
                        && <div
                          onClick={() => { this.removeAllOptions() }}
                          style={{
                            margin: '5px',
                            borderRadius: '20px',
                            padding: '10px',
                            width: 'fit-content',
                            color: '#64748B',
                            textAlign: 'center',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            fontFamily: 'Inter-Bold, sans-serif',
                          }}>
                          Clear all
                        </div>
                      }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                      {this.state.getComplete?.map((item: any, index: any) => {
                        const searchText = typeof this.state.datans === 'string'
                          ? this.state.datans.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                          : '';

                        const regex = new RegExp(searchText, 'i');
                        const isMatch = regex.test(item);
                        const highlightedText = item.replace(regex, (match: any) => `<span style="font-weight: 900; color: #000; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">${match}</span>`);

                        return (
                          <div
                            data-test-id="testest"
                            onClick={() => this.searchTheData(item)}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '10px', margin: '5px', fontFamily: 'Inter-Regular, sans-serif' }}
                            key={index}
                          >
                            <img src={autoComplete} alt="icon" style={{ marginRight: '10px' }} />
                            {isMatch ? (
                              <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
                            ) : (
                              <span data-test-id="searchSpan">{item}</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </>
              )}
            </Card>
          </div>
          <Typography
            style={{
              width: '81px', height: '40px', color: '#21355F',
              fontFamily: 'Inter-Bold, sans-serif', fontSize: '24px', padding: '4px 8px'
            }}
          >
            Test
          </Typography>
          <TableContainer style={{ borderRadius: '8px 8px 0px 0px', marginBottom: '55px' }}>
            <Table aria-label="simple table">
              <TableHead style={webStyle.tableHead}>
                <TableRow>
                  <TableCell style={{...webStyle.tableCellRow,textAlign:'center'}}>Sample Type</TableCell>
                  <TableCell style={{...webStyle.tableCellRow,textAlign:'center'}} align="left">Test Type</TableCell>
                  <TableCell style={{...webStyle.tableCellRow,textAlign:'center'}} align="left">Stock Level</TableCell>
                  <TableCell style={{...webStyle.tableCellRow,textAlign:'center'}} align="left">Last Order Date</TableCell>
                  <TableCell style={{...webStyle.tableCellRow,textAlign:'center'}} align="left">Reorder Test</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {(this.state.handleSearch ? this.state.searchdataAns : this.state.productData).map((item: any, key) => {
                  function formatDate(dateString: any) {
                    if (!dateString) {
                      return '';
                    }
                    const [year, month, day] = dateString.split('-');

                    return `${day}/${month}/${year}`;
                  }

                  const inputDate = item.attributes.last_order_date;
                  const formattedDate = formatDate(inputDate);
                  return (
                    <TableRow key={key} style={webStyle.tableBody}>
                      <TableCell style={{...webStyle.tableCellBody,textAlign:'center'}} component="th" scope="row">{item.attributes.sample_type}</TableCell>
                      <TableCell style={{...webStyle.tableCellBody,textAlign:'center'}} align="left">{item.attributes.test_type}</TableCell>
                      <TableCell style={{...webStyle.tableCellBody,textAlign:'center'}} align="left">{item.attributes.stock}</TableCell>
                      <TableCell style={{...webStyle.tableCellBody,textAlign:'center'}} align="left">{formattedDate}</TableCell>
                      <TableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                          data-test-id="reorderBtn"
                          style={{...webStyle.reorder, fontFamily: 'Inter-Bold, sans-serif' }}
                          onClick={() => this.handleReorder(item.id, item.attributes.test_type)}
                        >
                          {"Reorder"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.openReorderPopup} >
          {
            this.state.openReorderPopup && (
              <Card
                style={{
                  width: '599px', height: '251px', display: 'flex', flexDirection: 'column',
                  borderRadius: '8px', padding: '22px 0px 24px 0px',
                  gap: '33px', backgroundColor: '#FFFFFF', alignItems: 'center'
                }}>
                <CardContent
                  style={{
                    display: 'flex', flexDirection: 'column',
                    gap: '35px', alignItems: 'center'
                  }}>
                  <Typography
                    style={{
                      color: '#21355F', height: '28px', width: '512px',
                      fontFamily: 'Inter-Bold, sans-serif', fontSize: '20px', textAlign: 'center'
                    }}>
                    Reorder Tests
                  </Typography>
                  <Typography
                    style={{
                      color: '#21355F', height: '39px', width: '497px',
                      fontFamily: 'Inter-Regular, sans-serif', fontSize: '20px', textAlign: 'center'
                    }}>
                    {`Are you sure that you’d like to order additional ${this.state.testTypeForPopup} tests?`}
                  </Typography>
                </CardContent>
                <CardActions style={{ display: 'flex', gap: '26px' }}>
                  <Button
                    style={{
                      width: '120px', height: '56px', border: '1px solid #E1E1E1',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                      borderRadius: '8px', background: '#FFFFFF', textAlign: 'center',
                      fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                      textTransform: 'none',
                    }}
                    data-test-id="closeReorderBtn"
                    onClick={() => this.closeReorder()}
                  >
                    Back
                  </Button>
                  <Button
                    style={{
                      width: '120px', height: '56px', border: '1px solid #E1E1E1',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                      borderRadius: '8px', background: '#0075BF', textAlign: 'center',
                      fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                      textTransform: 'none'
                    }}
                    data-test-id="updateReorderBtn"
                    onClick={() => this.updateReorder()}
                  >
                    Request
                  </Button>
                </CardActions>
              </Card>
            )
          }
        </Backdrop>
        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.openRequestPopup} >
          {
            this.state.openRequestPopup && (
              <Card
                style={{
                  width: '599px', height: 'fit-content', display: 'flex', flexDirection: 'column',
                  borderRadius: '8px', padding: '18px 0px 24px 0px',
                  gap: '15px', backgroundColor: '#FFFFFF', alignItems: 'center'
                }}>
                <CardActions
                  style={{
                    display: 'flex', flexDirection: 'column',
                    gap: '35px', alignItems: 'center'
                  }}>
                  <Typography
                    style={{
                      color: '#21355F', height: '28px', width: '512px',
                      fontFamily: 'Inter-Bold, sans-serif',fontSize: '20px', textAlign: 'center'
                    }}>
                    Request Form
                  </Typography>
                  <Typography
                    style={{
                      color: '#21355F', height: 'fit-content', width: '465px',
                      fontFamily: 'Inter-Regular, sans-serif', fontSize: '20px', textAlign: 'center'
                    }}>
                    Please outline the type of test that you'd like to request from Revue.
                    Please include any specific details which may assist with your request:
                  </Typography>
                </CardActions>
                <CardActions style={{ display: 'flex', gap: '35px' }}>
                  <textarea
                    style={{
                      width: '497px', backgroundColor: '#F1F1F1', resize: 'none',
                      height: '167px', borderRadius: '8px', outline: 'none', border: 'none',
                      fontSize: '18px'
                    }}
                    data-test-id="text-area"
                    onChange={(e) => this.handleRequestText(e)}
                  >
                  </textarea>
                </CardActions>
                <CardActions style={{ display: 'flex', gap: '26px' }}>
                  <Button
                    style={{
                      width: '120px', height: '56px', border: '1px solid #E1E1E1',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                      borderRadius: '8px', background: '#FFFFFF', textAlign: 'center',
                      fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                      textTransform: 'none',
                    }}
                    data-test-id="closeRequestBtn"
                    onClick={() => this.closeRequest()}
                  >
                    Back
                  </Button>
                  <Button
                    style={{
                      width: '120px', height: '56px', border: '1px solid #E1E1E1',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                      borderRadius: '8px', background: '#0075BF', textAlign: 'center',
                      fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                      textTransform: 'none'
                    }}
                    id="postReqBtn"
                    data-test-id="postRequestBtn"
                    onClick={() => this.postRequest()}
                  >
                    Request
                  </Button>
                </CardActions>
              </Card>
            )}
        </Backdrop>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputField: {
    color: "rgb(33, 53, 95) !important",
    border: "1px solid rgba(33, 53, 95, 1)",
    padding: "5px 0px 5px 0px",
    borderRadius: "98px",
    background: "none",
    "&:before": {
      border: "none !important"
    },
    "&:after": {
      border: "none"
    },
    "&:hover": {
      border: "2px solid #0075BF"
    }
  },
  tableHead: {
    backgroundColor: '#21355F',
    borderBottom: '3px solid #CBD5E1'
  },
  tableBody: {
    borderBottom: '3px solid #0075BF',
  },
  tableCellRow: {
    color: '#FFFFFF',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Inter-Bold, sans-serif',
    fontSize: '14px',
    padding: '8px 24px'
  },
  tableCellBody: {
    color: '#21355F',
    width: 'fit-content',
    height: '22px',
    fontFamily: 'Inter-Regular, sans-serif',
    fontSize: '14px',
    padding: '12px 24px'
  },
  reorder: {
    color: '#FFFFFF',
    width: '60px',
    height: '26px',
    fontFamily: 'Inter-Bold, sans-serif',
    fontSize: '12px',
    cursor: 'pointer',
    padding: '0px 13px',
    borderRadius: '17px',
    backgroundColor: '#E7205D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
// Customizable Area End
