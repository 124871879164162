import React from "react";

// Customizable Area Start
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imageClinican, inventoryImage, testImage, patientImage } from "./assets";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
        border: '2px solid rgb(0, 117, 191)',
        background: 'rgb(0, 117, 191)',
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
      }}
      role="presentation"
      data-test-id="toggleDrawer"
    >
      <List style={{height: '90vh', position: 'relative'}}>
        <ListItem style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '30px'}} button>
          <ListItemText style={webStyle.imageText} primary=" King’s College Hospital 262 262 2834" />
        </ListItem>

        <ListItem style={{marginBottom: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} button >
          <ListItemIcon style={{display: 'flex', justifyContent: 'center'}}>
            <img src={imageClinican} style={webStyle.drawerItemIcon} />
          </ListItemIcon>
          <ListItemText style={webStyle.imageText} primary="Clinician" />
        </ListItem>

        <ListItem button style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center', marginBottom: '30px'}}>
          <ListItemIcon style={{display: 'flex', justifyContent: 'center'}}>
            <img src={patientImage} style={webStyle.drawerItemIcon} />
          </ListItemIcon>
          <ListItemText primary="Patients" style={webStyle.imageText} />
        </ListItem>

        <ListItem button style={{marginBottom: '30px', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <ListItemIcon style={{display: 'flex', justifyContent: 'center'}}>
            <img src={testImage} style={webStyle.drawerItemIcon} />
          </ListItemIcon>
          <ListItemText primary="Tests" style={webStyle.imageText} />
        </ListItem>

        <ListItem button style={{display: 'flex', justifyContent: 'center', marginBottom: '30px', flexDirection: 'column'}}>
          <ListItemIcon style={{display: 'flex', justifyContent: 'center'}}>
            <img src={inventoryImage} style={webStyle.drawerItemIcon} />
          </ListItemIcon>
          <ListItemText primary="Inventory" style={webStyle.imageText} />
        </ListItem>

        <ListItem button style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', position:'absolute', bottom: 0}}>
          <ListItemIcon style={{display: 'flex', justifyContent: 'center'}}>
            <img src={patientImage} style={webStyle.drawerItemIcon} />
          </ListItemIcon>
          <ListItemText primary={configJSON.logout} style={webStyle.logout} />
        </ListItem>
      </List>
    </div>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        {this.list()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  
  imageText: {
    color: "#0075BF",
    fontWeight: 'bold'
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "red",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
