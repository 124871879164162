import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Breadcrumbs,
  Select,
  Card,
  CardContent,
  CardActions,
  Backdrop
} from "@material-ui/core";
import { MoreVert, 
  AddCircleOutline, 
  Search, 
  RemoveRedEyeOutlined, 
  EditOutlined,
  GetApp,
  NavigateNext,
  Edit,
  DeleteOutlineOutlined
} from "@material-ui/icons";
import dayjs from 'dayjs';
import ErrorModal from "./ErrorModal";
import CommonCustomModal from "./CommonCustomModal";
// Customizable Area End

import ClinicianController, {
  configJSON,
  Props,
} from "./ClinicianController";


export default class ClinicianProfile extends ClinicianController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  

  render() {
    // Customizable Area Start

    const { showClinician, clinicianTests } = this.state
    const dataSource = this.getFilterSource()
    const breadcrumbs = [
      <Typography 
        onClick={this.handleNavigateToClinicians}
        style={webStyles.breadcrumbLink} key="1">
        {configJSON.clinicians}
      </Typography>,
      <Typography key="2" style={{ fontSize: "22px", fontFamily: 'Inter-Bold, sans-serif', color: "#21355F" }}>
        {showClinician?.attributes?.common_information.first_name} {showClinician?.attributes?.common_information.last_name}
      </Typography>
    ]

    return (
      <Container style={{maxWidth:'100%',overflow:'auto', paddingBottom:'80px', marginBottom:'50px'}} >
        {this.state.isLoader && this.renderLoader()}
        <Breadcrumbs
          style={{marginTop: '20px'}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Box style={webStyles.wrapper}>
          <TextField
            name="passwordConfirmation"
            data-test-id={"passwordConfirmation"}
            style={webStyles.inputField}
            placeholder="Search"
            onChange={
              /* istanbul ignore next */
              (event) => { this.searchPatient2(event) }}
            InputProps={{
              disableUnderline:true,
              startAdornment: (
                <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  style={{fontSize: "16px"}}
                >
                <Search style={{color: "rgba(148, 163, 184, 1)", marginRight: "5px"}} />
                  </IconButton>
                  </InputAdornment>
              )
            }}
              fullWidth={true}
            />    
            <Button
              data-test-id="orderTest"
              style={webStyles.buttonStyle}
              onClick={this.orderTest}
            >
              <AddCircleOutline style={{marginRight: "10px"}} />
              {configJSON.orderTest}
           </Button>
          </Box>
          <Box sx={webStyles.clinicianCard}>
            <Box sx={webStyles.patientBlock}>
              <Typography style={webStyles.cardText}>
                {this.state.firstName} {this.state.surname}
              </Typography>
            </Box>
            <Box sx={webStyles.departmentBlock}>
              <Typography style={{...webStyles.cardText}}>
                {configJSON.department}
              </Typography>
              <Typography style={{...webStyles.cardText, fontFamily: "Inter-Regular, sans-serif"}}>
                {this.state.department}
              </Typography>
            </Box>
            <Box sx={webStyles.roleBlock}>
              <Typography style={webStyles.cardText}>
                {configJSON.role}
              </Typography>
              <Typography style={{...webStyles.cardText, fontFamily: "Inter-Regular, sans-serif"}}>
               {showClinician?.attributes?.clinician_information.role}
              </Typography>
            </Box>
            <Box sx={webStyles.emailBlock}>
              <Typography style={webStyles.cardText}>
                {configJSON.email}
              </Typography>
              <Typography style={{...webStyles.cardText, fontFamily: "Inter-Regular, sans-serif"}}>
                {this.state.email}
              </Typography>
            </Box>
            <Box>
              <CardContent>
                <Select
                  labelId="dropdown-label"
                  id="dropdown"
                  disableUnderline={true}
                  IconComponent={MoreVert}>
                  <MenuItem 
                    value="" 
                    style={{display: "flex", gap: 10}} 
                    data-test-id="navigateToEditClinician"
                    onClick={() => this.handleNavigateToEditClinician(Number(showClinician?.id))} >
                    <EditOutlined style={{fontSize: '16px'}} />
                    {configJSON.editClinician}
                  </MenuItem>
                </Select>
              </CardContent>
            </Box>
          </Box>
          <Typography style={webStyles.tableName}>{configJSON.testsRequested}</Typography>
          <TableContainer style={webStyles.tableContainerStyle}>
            <Table stickyHeader>
              <TableHead>
                <TableRow >
                  {configJSON.clinicianProfileTableHead.map((columnName: string, index: number) => (
                    <TableCell style={{...webStyles.tableCellStyles,textAlign:'center'}} key={index}>
                      {columnName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
            
                {dataSource?.map((test: any) => {
                  return(
                    <TableRow key={test.id}>
                      <TableCell  data-test-id="row1" onClick={() => { this.handleRow(test.attributes, test.id) }} style={{...webStyles.testsDataStyles,textAlign:'center'}} scope="row">
                        {test.attributes.patient?.first_name} {" "}
                        {test.attributes.patient?.last_name}
                      </TableCell>
                      <TableCell data-test-id="row2" onClick={() => { this.handleRow(test.attributes, test.id) }}style={{...webStyles.testsDataStyles,textAlign:'center'}}>{test.attributes.sample_type}</TableCell>
                      <TableCell data-test-id="row3" onClick={() => { this.handleRow(test.attributes, test.id) }} style={{...webStyles.testsDataStyles,textAlign:'center'}}>
                       {dayjs(test.attributes.test_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell data-test-id="row4" onClick={() => { this.handleRow(test.attributes, test.id) }} style={{...webStyles.testsDataStyles,textAlign:'center'}}>
                        {test.attributes.date_received ? dayjs(test.attributes.date_received).format('DD/MM/YYYY') : "-"}
                      </TableCell>
                      <TableCell data-test-id="row5" onClick={() => { this.handleRow(test.attributes, test.id) }} style={{...webStyles.testsDataStyles,textAlign:'center'}}>{test.attributes.department}</TableCell>
                      <TableCell  data-test-id="row6" onClick={() => { this.handleRow(test.attributes, test.id) }}style={{...webStyles.testsDataStyles,textAlign:'center'}}>
                        {test.attributes.marker_count}
                      </TableCell>
                      <TableCell  data-test-id="row7" onClick={() => { this.handleRow(test.attributes, test.id) }}style={{...webStyles.testsDataStyles,textAlign:'center'}}>{test.attributes.status}</TableCell>
                      <TableCell data-test-id="row8"  onClick={() => { this.handleRow(test.attributes, test.id) }} style={test.attributes.priority?.toLowerCase() === "urgent" ? {...webStyles.testsDataStyles,display:'flex',height:'38px',alignItems:'center',justifyContent:'center'}:{...webStyles.testsDataStyles}} align ="center"> 
                     
                        <Typography 
                          style={test.attributes.priority?.toLowerCase() === "urgent" ? 
                            webStyles.urgent : {...webStyles.testsDataStyles, borderBottom: "none"}} >
                          {test.attributes?.priority ? test.attributes?.priority:'-'}
                        </Typography>
                      </TableCell >
                      <TableCell style={webStyles.testsDataStyles} align="right">
                        <Select
                          labelId="dropdown-label"
                          id="dropdown"
                          disableUnderline={true}
                          IconComponent={MoreVert}
                          disabled={test.attributes.status?.toLowerCase() === "cancelled"}>
                          {test.attributes.status?.toLowerCase() === "requested" ? (
                            [
                              <MenuItem
                                key="editTest"
                                value=""
                                data-test-id="editTestButton"
                                style={{ fontSize: '14px' }}
                                onClick={() => this.editClinicianTest(test.id)}>
                                <Edit  fontSize="small" style={{ marginRight: '15px' }} />
                                Edit Test
                              </MenuItem>,
                              <MenuItem
                                key="cancelTest"
                                value=""
                                data-test-id="cancelTestButton"
                                style={{ fontSize: '14px' }}
                                onClick={() => this.openCancelTestPopUp(test.id)}>
                                <DeleteOutlineOutlined fontSize="small" style={{ marginRight: '15px' }} />
                                Cancel Test
                              </MenuItem>
                            ]
                          ) : (
                            [
                              <MenuItem 
                                key="viewResults" 
                                data-test-id="viewResultTestButton"
                                style={{ fontSize: '14px' }} 
                                value= ""
                                onClick={() => this.handleNavigateToResultTest(test.id)}>
                                <RemoveRedEyeOutlined fontSize="small" style={{ marginRight: '15px' }} />
                                View Results
                              </MenuItem>,
                              <MenuItem
                                onClick={() => this.handleDownloadTest(test.attributes.result_pdf)}
                                key="downloadResults" 
                                value=""
                                data-test-id="downloadResultTest"
                                style={{ fontSize: '14px' }}>
                                <GetApp fontSize="small" style={{ marginRight: '15px' }} />
                                Download Results
                              </MenuItem>
                            ]
                          )}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}) 
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popupcancel} >
        {
                        this.state.popupcancel && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',
                                    borderRadius: '0px 8px 33px 8px',
                                    padding: '30px 30px 24px 30px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Bold, sans-serif' }}>
                                        Test has been cancelled
                                    </span>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopup()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
        </Backdrop>
        <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popupview} >
                    {
                        this.state.popupview && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',minHeight:'auto',maxHeight:'481px',
                                    padding: '30px 30px 24px 14px',overflow:'auto',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{ minWidth:'340px',
                                        width: 'min-content',color: '#21355F', height: 'fit-content',
                                        fontSize: '20px', display:'flex',flexDirection:'column'
                                    }}>
                                    {
                                    <span style={{minWidth:'230px', paddingBottom: '15px',fontSize:'22px', fontFamily: 'Inter-Bold, sans-serif', textAlign: 'center', }}>
                                        Awaiting for results for below
                                    </span>
                                    }
                                    
                                    {this.state.dataHandle?.marker_text && <span style={ {textAlign:'center',paddingBottom:'15px',fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Markers'}
                                    </span>}
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${Math.min(Math.max(Math.ceil(this.state.dataHandle?.marker_text?.split(',').length / 2), 2), 3)}, 1fr)`, 
                                            gridAutoRows: 'auto',
                                            gap: '20px',
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'left',
                                            columnGap: '30px',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {this.state.dataHandle?.marker_text?.split(',').map((marker: any, index: any) => (
                                            <span
                                                style={{
                                                    width: 'fit-content',
                                                     paddingLeft: '15px',
                                                    paddingRight: '10px',
                                                    paddingBottom: '3px',
                                                }}
                                                key={index}
                                            >
                                                {marker.trim()}
                                            </span>
                                        ))}
                                    </div>
                                    {this.state.dataHandle?.panel_text && <span style={{ fontSize:'22px',textAlign: 'center', paddingBottom: '15px', fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Panel'}
                                    </span>}
                                    <div
                                        style={{
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'center',
                                        }}>
                                        {this.state.dataHandle?.panel_text?.split(',').map((panel: any, index: any) => (
                                             <span style={{width:'max-content',paddingLeft:'15px', paddingRight: '10px',paddingBottom:'3px' }} key={index}>{panel.trim()}</span>
                                        ))}
                                    </div>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopupView()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
            </Backdrop>
          <CommonCustomModal
            isVisible={this.state.isVisibleModal}
            onClose={this.handleCloseModal}
            title= {configJSON.cancelTestTitle}
            onCancel={this.handleCloseModal}
            onAccept={() => this.cancelClinicianTest(this.state.canceledTestId)}
            disabled={this.state.disabledAccept}
          />
          <ErrorModal
            isVisible={this.state.isVisibleErrorModal}
            onAccept={this.handleVisibleErrorModal}
            message={this.state.errorMessage}
            data-test-id="errorModal"
          />
        </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  wrapper: {
    display: "flex",
    width: "100%",
    alignItems: "baseline",
    marginBottom: "30px"
  },
  inputField: {
    border: "1px solid rgba(33, 53, 95, 1)",
    padding: "5px 0px 5px 0px",
    borderRadius: "98px",
    background: "none",
    "&:before" :{
      border: "none !important" 
    },
    "&:after" :{
      border: "none" 
    },
    "&:hover" :{
      border: "2px solid #0075BF"
    }
  },
  clinicianCard: {
    display: "flex",
    height: "100px",
    padding: "8px 12px 8px 5px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "6px",
    borderLeft: "4px solid var(--REVUE_PINK, #E7205D)",
    background: "#FFF", 
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    marginBottom: "37px",
  },
  departmentBlock: {
    display: "flex", 
    alignItems: "center", 
    height: "95%",
    justifyContent: "center", 
    flexDirection: "column", 
    gap: 8, 
    width: "20%", 
    borderLeft: "2px solid #21355F",
    borderRight: "2px solid #21355F"
  },
  roleBlock: {
    display: "flex",
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column", 
    gap: 8, 
    width: "20%",
    height: "95%",
    borderRight: "2px solid #21355F"
  },
  patientBlock: {
    display: "flex",
    justifyContent: "center", 
    width: "20%"
  },
  emailBlock: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column", 
    gap: 8, 
    width: "35%"
  },
  cardText: {
    fontSize: "14px", 
    color: "#21355F",
    fontFamily: 'Inter-Bold, sans-serif',
  },
  tableName: {
    color: "#21355F", 
    fontSize: "24px", 
    marginBottom: "11px",
    fontFamily: 'Inter-Bold, sans-serif',
  },
  tableCellStyles: {
    backgroundColor: "#21355F", 
    color: "fff", 
    fontSize: "14px", 
    lineHeight: "22px",  
    fontFamily:'Inter-Bold, sans-serif'
  },
  tableContainerStyle: {
    borderRadius:'8px 8px 0px 0px',
    width: "100%",
    overflow: "hidden",
    marginBottom: "50px"
  },
  buttonStyle: {
    height: "44px",
    width: "180px",
    border: "none",
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: "8px",
    color: "white",
    textTransform: "none" as const,
    marginLeft: "20px",
    padding: "6px 10px",
    fontFamily: 'Inter-Bold, sans-serif',
  },
  urgent: {
    width: "66px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "2px 8px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: 'Inter-Bold, sans-serif',
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    borderRadius: "40px"
  },
  testsDataStyles: {
    cursor:'pointer',
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Inter-Regular, sans-serif",
    lineHeight: "22px",
    color: "#21355F",
    borderBottom: '1px solid #0075BF'
  },
  breadcrumbLink: {
    color: "#21355F", 
    fontSize: "24px", 
    cursor: "pointer", 
    textDecoration: "none",
    fontFamily: 'Inter-regular, sans-serif',
  },

};
// Customizable Area End
