import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Account, Group, GroupAccount } from "./types";
import { FormikErrors, FormikProps, FormikTouched, FormikValues } from "formik";
import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import * as Yup from 'yup';


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isVisibleDeleteAccountModal: boolean

  // Customizable Area End
}


export interface S {
  id: string;
  // Customizable Area Start
  patientDataSearchAns: any[];
  patientViewSearchAns: any[];
  dropDowndata: any,
  selectedOptions: any,
  selectedOptions2: any,
  selectedOptions3: any,
  openSearchBox: boolean,
  dataHandle:any,
  popupcancel:boolean,
  popupview:boolean,
  datans: string,
  handlePatient: boolean;
  name: string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  groupList: Group[];
  getComplete: [],
  isVisiblePopUp: boolean;
  isVisibleAddAccountModal: boolean;
  editModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  accountsData: Account[];
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  apiToken: string;
  activeStep: number,
  openModal: boolean,
  editQueryId: any,
  deleteApiId: any
  viewSpecificList: any,
  selectedOption: string,
  loading: boolean,
  editDataResponse: any,
  institutionName: string,
  allValues: {
    firstName: string,
    surName: string,
    dateofBirth: string,
    NHSNumber: string,
    email: string,
    SexAtBirth: string,
    institutionNumber: string,
    mobileNumber: string,
  },
  stepperTwoValues: {
    street1: string,
    street2: string,
    city: string,
    country: string,
    postCode: string
  }
  edtiApiData: any,
  changeDropdown: any,
  institutionId: any,
  instituteNumber: string,
  buttonDisable: boolean,
  isTestButtonClicked: string
  nhsNumberValidation: any
  showErrorModal: boolean
  nhsNumberErrorMessage: string
  mobileMessage:string,
  instName: string
  canceledTestId: number
  isDisabledAccept: boolean
  isVisibleErrorModal: boolean
  errorMessageText: string
  isLoader: boolean
  dataFetching: boolean
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PatientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  patientGetData = "";
  patientGetDataDropdown = "";
  getDropdown = "";
  postAddAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId: string = "";
  getAutoComplete: string = "";
  getFilter:string="";
  selectInstitutionNameApiCallId: string = "";
  putGroupApiCallId = "";
  getViewSpecificApiCallId = "";
  postRemoveAccountsApiCallId = "";
  deleteGroupApiCallId = "";
  checkNHSnumberApiCallId = "";
  checkMobile="";
  cancelTestApiCalId = "";
  formikRef: React.RefObject<FormikProps<FormikValues>>
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];


    this.state = {
      // Customizable Area Start
      id: "0",
      name: "",
      openSearchBox: false,
      datans: '',
      patientDataSearchAns: [],
      patientViewSearchAns:[],
      dropDowndata: [],
      selectedOptions: [],
      selectedOptions2: [],
      selectedOptions3: [],
      dataHandle:[],
      handlePatient: false,
      createdAt: "",
      updatedAt: "",
      editMode: false,
      popupcancel:false,
      popupview:false,
      token: "",
      groupList: [],
      getComplete: [],
      isVisiblePopUp: false,
      isVisibleAddAccountModal: false,
      isVisibleDeleteAccountModal: false,
      editModal: false,
      accountsData: [],
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      viewSpecificList: [],
      deleteApiId: [],
      openModal: false,
      fieldError: false,
      editQueryId: "",
      apiToken: "",
      activeStep: 0,
      loading: false,
      editDataResponse: [],
      selectedOption: '',
      institutionName: '',
      allValues: {
        firstName: "",
        surName: "",
        dateofBirth: "",
        NHSNumber: "",
        email: "",
        SexAtBirth: "",
        institutionNumber: "",
        mobileNumber: "",
      },
      stepperTwoValues: {
        street1: "",
        street2: "",
        city: "",
        country: "United Kingdom",
        postCode: ""
      },
      edtiApiData: [],
      changeDropdown: 0,
      institutionId: localStorage.getItem('institutionId') || 'Initial Data',
      instituteNumber: localStorage.getItem('hospitalNumber') || 'Second Data',
      buttonDisable: false,
      isTestButtonClicked: "no",
      nhsNumberValidation: [],
      showErrorModal: false,
      nhsNumberErrorMessage: "",
      mobileMessage:"",
      instName: "",
      canceledTestId: NaN,
      isDisabledAccept: false,
      errorMessageText: "",
      isVisibleErrorModal: false,
      isLoader: false,
      dataFetching: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formikRef = React.createRef();

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const searchQuery = this.props.navigation.getParam("id");
    this.setState({ editQueryId: searchQuery })


    const result = localStorage.getItem('data')
    // @ts-ignore
    const parseData = JSON.parse(result)
    /* istanbul ignore next */
    this.setState({
      apiToken: parseData?.meta?.token,
      institutionName: parseData?.data?.attributes?.institute_information?.institution_number,
      isTestButtonClicked: localStorage.getItem("isOrderTestButtonClicked") || 'no',
      instName: parseData?.data?.attributes?.institute_information?.institute_name
    }, () => {
      const { editQueryId, institutionId } = this.state;
      window.addEventListener('storage', this.handleStorageChange);
      this.setState({ changeDropdown: institutionId })
      if (editQueryId !== "" && editQueryId !== undefined) {
        this.setState({ loading: true })
      }
      if(searchQuery){
      this.viewGroups(searchQuery)
      }
    })
  }
  // @ts-ignore
  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  /* istanbul ignore next */
  handleStorageChange = () => {
    this.setState({
      institutionId: localStorage.getItem('institutionId') || 'Initial Data',
      instituteNumber: localStorage.getItem('hospitalNumber') || 'Second Data',
      isTestButtonClicked: localStorage.getItem('isOrderTestButtonClicked') || "no"
    });
  }

  /* istanbul ignore next */
  componentDidUpdate(_: any, prevState: any) {
    if ((prevState.changeDropdown !== this.state.institutionId || this.state.apiToken !== prevState.apiToken) && this.state.apiToken !== "") {
      this.setState({ changeDropdown: this.state.institutionId })
      this.getGroups(this.state.institutionId)
      if(prevState.changeDropdown!=0 ){
        this.dropDownData()
        this.autoSuggestionsPatient()
      }
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
      this.handlePatientGetData(responseData, responseJson);
      this.handleViewPatientGetData(responseData, responseJson);
      this.handleGetGroups(responseData, responseJson);
      this.handlePostPutGroup(responseData, responseJson);
      this.handleDeleteGroup(responseData, responseJson);
      this.handleDropdownData2(responseData, responseJson);
      this.handleViewSpecific(responseData, responseJson);
      this.handleCheckNHS(responseData, responseJson)
      this.handleCancelPatientTest(responseData, responseJson)
      this.handleAutocomplete(responseData, responseJson)
    }
  };
  /* istanbul ignore next */
  handlePatientGetData = (responseData: any, responseJson: any) => {
    if (this.patientGetData && this.patientGetData === responseData && (!responseJson?.errors && responseJson?.data)) {
      this.setState({ patientDataSearchAns: [...responseJson?.data] });
    }
  };
   /* istanbul ignore next */
   handleViewPatientGetData = (responseData: any, responseJson: any) => {
    if (this.getFilter && this.getFilter === responseData && (!responseJson?.errors && responseJson?.data)) {
      this.setState({ patientViewSearchAns: [...responseJson?.data] });
    }
  };
  /* istanbul ignore next */
  handleGetGroups = (responseData: any, responseJson: any) => {
    if (this.getGroupsApiCallId === responseData && !responseJson.errors) {
      this.setState({
        groupList: responseJson.patients?.data,
        dataFetching: false
      });
    }
  };
  handleAutocomplete = (responseData: any, responseJson: any) => {

    if (this.getAutoComplete === responseData && !responseJson.errors) {
      this.setState({
        getComplete: responseJson
      });
    }
  };
  /* istanbul ignore next */
  handlePostPutGroup = (responseData: any, responseJson: any) => {
    if ((this.postGroupApiCallId || this.putGroupApiCallId) === responseData) {
      if (responseJson?.data) {
        this.getGroups(this.state.institutionId);
        this.setState({ isVisibleAddAccountModal: false, isLoader: false });
        this.props.navigation.navigate("Patient");
      } else if (responseJson?.errors) {
        this.setState({ 
          nhsNumberErrorMessage: responseJson?.errors[0],
          showErrorModal: true,
          isLoader: false 
        });
      }
    }
  };
  /* istanbul ignore next */
  handleDeleteGroup = (responseData: any, responseJson: any) => {
    if (this.deleteGroupApiCallId === responseData && !responseJson.errors) {
      this.getGroups(this.state.institutionId);
      this.setState({ isVisibleDeleteAccountModal: false, isLoader: false });
    }
  };
  /* istanbul ignore next */
  handleDropdownData2 = (responseData: any, responseJson: any) => {
    if (this.getDropdown === responseData && !responseJson?.errors) {
      this.setState({ dropDowndata: responseJson.data });
    }
  };
  handlePopup=()=>{
    this.setState({popupcancel:false})
  }
  handlePopupView=()=>{
    this.setState({popupview:false})
  }
  /* istanbul ignore next */
  handleViewSpecific = (responseData: any, responseJson: any) => {
    if (this.getViewSpecificApiCallId === responseData && !responseJson.errors) {
      const data = responseJson?.data?.attributes;
      this.setState({
        loading: false,
        viewSpecificList: data,
        editDataResponse: responseJson?.data,
        allValues: {
          firstName: data?.first_name,
          SexAtBirth: data?.gender,
          institutionNumber: data?.institution_number,
          email: data && data.email && data.email !== "null" ? data.email : "",
          surName: data?.last_name,
          mobileNumber: data && data.phone_number ? data.phone_number.toString(): "",
          dateofBirth: data?.dob,
          NHSNumber: data?.nhs_number,
        },
        stepperTwoValues: {
          street1: data?.address,
          postCode: data?.zip_code,
          city: data?.city,
          street2: data && data.address1 && data.address1 !== "null" ? data.address1 : "",
          country: data?.country,
        }
      });
    }
  };

  /* istanbul ignore next */
  handleCheckNHS = async (responseData: any, responseJson: any) => {

    if (this.checkNHSnumberApiCallId === responseData) {
      if (responseJson.message === "NHS Number already registered!") {
        await new Promise((resolve: any) => {
          this.setState({
            nhsNumberErrorMessage: responseJson.message,
            showErrorModal: true,
            mobileMessage: '',
            isLoader: false
          }, resolve);
        });
      }
      else {
        await new Promise((resolve: any) => {
          this.setState({
            nhsNumberErrorMessage: responseJson.message,
            isLoader: false
          }, resolve);
        });
      }
    }
    if (this.checkMobile === responseData) {
      if (responseJson.error) {
        await new Promise((resolve: any) => {
          this.setState({
            nhsNumberErrorMessage: '',
            mobileMessage: responseJson.error,
            showErrorModal: true,
            isLoader: false
          }, resolve);
        })
      }
      else {
        await new Promise((resolve: any) => {
          this.setState({
            mobileMessage: responseJson.message,
            isLoader: false
          }, resolve);
        })
      }
    }
  setTimeout(()=>{
    if (this.checkMobile === responseData || this.checkNHSnumberApiCallId === responseData) {
      if (this.state.nhsNumberErrorMessage == "NHS Number not registered!" && this.state.mobileMessage == 'Valid Phone Number') {
        this.handleNext();
        this.setState({ isLoader: false });
      }
    }
  },1000)
  };

  /* istanbul ignore next */
  handleCancelPatientTest = (responseData: any, responseJson: any) => {
    if (this.cancelTestApiCalId === responseData) {
      if (responseJson?.data) {
        this.setState({
          isDisabledAccept: false,
          isLoader: false
        })
        this.viewGroups(this.props.navigation.getParam("id"))
      } else {
        this.setState({
          errorMessageText: responseJson.message,
          isVisibleErrorModal: true,
          isDisabledAccept: false,
          isLoader: false
        })
      }
    }
  };

  personalValidationSchema() {
    return Yup.object().shape({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only characters and spaces are allowed")
        .required("This field is required"),
      surName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only characters and spaces are allowed")
        .required("This field is required"),
      dateofBirth: Yup.string().required("This field is required"),
      NHSNumber: Yup.string()
        .test(
          'is-valid-nhs',
          'NHS number is invalid',
          value => (value ? this.isValidNHSNumber(value) : true)
        )
        .required("This field is required"),
      SexAtBirth: Yup.string().required("This field is required"),
      institutionNumber: Yup.string().nullable(),
      email: Yup.string().email("Invalid email format"),
      mobileNumber: Yup.string()
        .required('This field is required')
        .matches(/^\+?\d+$/, "Only numeric characters are allowed")
        .transform(value => value.replace(/\s+/g, '')),
    })
  }

  addressValidationSchema() {
    return Yup.object().shape({
      street1: Yup.string()
      .max(50, "Street can be at most 50 symbols")
      .required("This field is required"),
      city: Yup.string()
      .max(35, "City can be at most 35 characters")
      .required("This field is required"),
      postCode: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
    })
  }

  renderNHSNumber = (item: any) => {
    return item && item.attributes.nhs_number
  }

  getDataSource = () => {
    return this.state.handlePatient ? this.state.patientDataSearchAns : this.state.groupList;
  }
  getFilterSource = () => {
    return this.state.datans ? this.state.patientViewSearchAns : this.state.viewSpecificList?.test_orders?.data;
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg)
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: true
    });
  };

  dropDownData() {
    const data = JSON.parse(localStorage.getItem("data") || "{}");
    const headerData = {
      "Content-Type": configJSON.apiContentType,
      /* istanbul ignore next */ token: data.meta?.token
    };
    const checkClinic = data?.data?.attributes?.common_information.user_type === "clinician";
    /* istanbul ignore next */
    const finalData = checkClinic && this.state.institutionId ? `${configJSON.editDropdownEndPoint2}&institution_id=${this.state.institutionId}` : `${configJSON.editDropdownEndPoint2}`
    const getTestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDropdown = getTestData.messageId;

    getTestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalData
    );

    getTestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    getTestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getTestData.id, getTestData);
  }
  editAccountModal = () => {
    this.setState({
      editModal: true,
      editMode: true,
    });
  };

  allCondition = (condition: any, truth: any, falsy: any) => {
    return condition ? truth : falsy
  }

  formatInstitutionDetails = (detail: string | undefined) => {
    return detail ? `${detail.slice(0, 3)} ${detail.slice(3, 6)} ${detail.slice(6)}` : '';
  };

  isValidNHSNumber(nhsNumber: string): boolean {
    if (!/^[1-9]\d{9}$/.test(nhsNumber)) {
      return false;
    }

    const digits = nhsNumber.split('').map(Number);
    let sum = 0;

    for (let i = 0; i < 9; i++) {
      sum += digits[i] * (10 - i);
    }

    const remainder = sum % 11;
    const checkDigit = (11 - remainder) % 11;

    return checkDigit === digits[9]
  }

  renderPersonalInformation = (editQueryId: string, activeStep: number) => {
    return editQueryId || activeStep === 0
  }

  handleNavigateToResultTest(testId: number) {
    this.props.navigation.navigate('ResultTest', { id: testId });
  }

  handleDownloadTest = (link: string) => {
    if(link){
    window.open(link, '_blank')
    }
  };

  hideEditAccountModal = () => {
    this.setState({ editModal: false });
  };
  handleSearch() {
    this.setState({ openSearchBox: true }, () => {
      this.dropDownData();
      this.autoSuggestionsPatient();
    });
  }
  handleSearch2() {
    this.setState({ openSearchBox: false })
  }

  searchPatient = (eventOrValue: any) => {

    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.handleDropdownData();
      this.autoSuggestionsPatient();
    });
  }
  searchPatient2 = (eventOrValue: any) => {
    let value = eventOrValue?.target?.value || eventOrValue;
    if (eventOrValue?.target?.value == "") {
      value = "";
    }
    this.setState({ datans: value }, () => {
      this.autoSuggestionsPatient2();
    });
  }

  showHideDeleteAccountModal = (item: any) => {
    this.setState({
      isVisibleDeleteAccountModal: true,
      deleteApiId: item
    });
  };

  hideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: false, isVisibleAddAccountModal: false,
      buttonDisable: false,
      nhsNumberValidation: []
    });
  };
  removeOption = () => {
    this.setState({ selectedOptions: null }, this.handleDropdownData);
  }
  removeOption3 = () => {
    this.setState({ selectedOptions3: null }, this.handleDropdownData);
  }
  removeOption2 = () => {
    this.setState({ selectedOptions2: null }, this.handleDropdownData);
  }

  removeAllOptions = () => {
    this.setState({
      selectedOptions: null,
      selectedOptions2: null,
      selectedOptions3: null,
    }, this.handleDropdownData);
  };


  redirectPatientPage = () => {
    this.props.navigation.navigate("Patient")
  }
  customStyles = {
    //this is to not show options in that particular box
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: '#DBEAFE',
    }),
    multiValue: (base: any) => ({
      ...base,
      display: 'none',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#21355F',
      fontFamily: 'Inter-Regular,sans-serif',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#21355F',
    }),
    option: (provided: any, state: any) => {
      /* istanbul ignore next */
      const fnalValueCheck = [
        this.state.selectedOptions?.value,
        this.state.selectedOptions3?.value,
        this.state.selectedOptions2?.value,
      ];
      /* istanbul ignore next */
      const isSelected = fnalValueCheck?.includes(state?.data?.value);

      return {
        ...provided,
        marginTop: '5px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        marginBottom: '5px',
        /* istanbul ignore next */
        color: isSelected ? 'white' : '#21355F',
        fontFamily: 'Inter-Regular,sans-serif',
        fontWeight: "normal",
        minWidth:'150px',
        /* istanbul ignore next */
        backgroundColor: isSelected ? '#0075BF' : 'white',
        "&:hover": {
          cursor: "pointer",
          backgroundColor: '#0075BF',
          color: 'white'
        },
      }
    },
    menu: (base: any) => ({
      ...base,
      width: 'fit-content',
      overflow: 'auto',
      maxHeight: '200px'
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: '20px',
      color: 'black',
      border: "1px solid #DBEAFE",
      backgroundColor: "#DBEAFE",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus,&:focus-within, &:active": {
        cursor: "pointer",
        borderColor: "none",
        boxShadow: "0 0 5px 2px #DBEAFE",
      },
      width: "160px",
      height: '40px',
    }),
  };
  addPatient = () => {
    this.props.navigation.navigate("CreatePatient")
  }
  handleChange = (selected: any) => {
    this.setState({ selectedOptions: selected }, this.handleDropdownData);
  };
  hasSelectedOptions = (
    selectedOptions: { value: string | any[]; },
    selectedOptions2: { value: string | any[]; },
    selectedOptions3: { value: string | any[]; },
    datans: string | any[]
  ) => {
    return (
      selectedOptions?.value?.length > 0 ||
      selectedOptions2?.value?.length > 0 ||
      selectedOptions3?.value?.length > 0 ||
      datans?.length > 0
    );
  };

  buildPatientDataSearch = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    return {
      "Content-Type": configJSON.apiContentType,
      token: data.meta?.token,
    };
  };

  buildFinalUrl = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician =
      data?.data?.attributes?.common_information.user_type === "clinician";

    const queryParams = this.buildQueryParams();

    const endPoint = isClinician && this.state.institutionId ? this.state.institutionId : null;

    let baseUrl = `${configJSON.searchArea}`;
    if (queryParams.length > 0 && endPoint != null) {
      return `${baseUrl}?${queryParams.join("&")}&type=patient&institution_id=${endPoint}`;
    } else if (queryParams.length > 0) {
      return `${baseUrl}?${queryParams.join("&")}&type=patient`;
    }
    return baseUrl;
  };

  autoSuggestionsPatient = () => {
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician =
      data?.data?.attributes?.common_information.user_type === "clinician";

    const endPoint = isClinician && this.state.institutionId ? this.state.institutionId : null;
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.autoCompleteSearch}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    if (endPoint != null) {
      baseUrl += `&institution_id=${endPoint}`;
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutoComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  autoSuggestionsPatient2 = () => {
    const pathArray = window.location.pathname.split('/');  
    const ids = pathArray[pathArray.length - 1]; 
    let data = JSON.parse(localStorage.getItem("data") || "{}");
    const isClinician =
      data?.data?.attributes?.common_information.user_type === "clinician";

    const endPoint = isClinician && this.state.institutionId ? this.state.institutionId : null;
    const queryParams = this.buildQueryParamsAuto();
    let baseUrl = `${configJSON.filterPoint}`;
    if (queryParams.length > 0) {
      baseUrl += `&${queryParams.join("&")}`;
    }
    baseUrl+=`&patient_id=${ids}`
    if (endPoint != null) {
      baseUrl += `&institution_id=${endPoint}`;
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilter = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  sendPatientDataRequest = (finalUrl: string, patientDataSearch: { "Content-Type": any; token: any; }) => {
    const getPatientData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patientGetData = getPatientData.messageId;

    getPatientData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalUrl
    );
    getPatientData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      patientDataSearch
    );
    getPatientData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getPatientData.id, getPatientData);
  };

  buildQueryParams = () => {
    const {
      selectedOptions,
      selectedOptions2,
      selectedOptions3,
      datans,
    } = this.state;

    let queryParams = [];
    if (selectedOptions?.value?.length > 0) {
      const [first, ...rest] = selectedOptions.value.trim().split(/\s+/);
      const last = rest.join(' ');
      
      if (first) queryParams.push(`first_name=${first}`);
      if (last) queryParams.push(`last_name=${last}`);
    }

    if (selectedOptions3?.value?.length > 0) {
      queryParams.push(`gender=${selectedOptions3.value}`);
    }

    if (datans) {
      queryParams.push(`query=${decodeURIComponent(datans)}`);
    }

    if (selectedOptions2?.value?.length > 0) {
      queryParams.push(`dob=${selectedOptions2.value}`);
    }

    return queryParams;
  };

  buildQueryParamsAuto = () => {
    const {
      datans,
    } = this.state;
    let queryParams = [];

    if (datans) {
      queryParams.push(`query=${decodeURIComponent(datans)}`);
    }

    return queryParams;
  };
  handleDropdownData = () => {
    const {
      selectedOptions,
      selectedOptions2,
      selectedOptions3,
      datans,
    } = this.state;

    const hasOptions = this.hasSelectedOptions(
      selectedOptions,
      selectedOptions2,
      selectedOptions3,
      datans
    );

    if (!hasOptions) {
      this.setState({ handlePatient: false });
      return;
    }

    this.setState({ handlePatient: true });

    const patientDataSearch = this.buildPatientDataSearch();

    const finalUrl = this.buildFinalUrl();

    this.sendPatientDataRequest(finalUrl, patientDataSearch);
  };

  handleChange2 = (selected: any) => {
    this.setState({ selectedOptions2: selected }, this.handleDropdownData);
  };
  handleChange3 = (selected: any) => {
    this.setState({ selectedOptions3: selected }, this.handleDropdownData);
  };

  orderTest = (patientId: string, fullName: string) => {
     /* istanbul ignore next */
    localStorage.removeItem("editModeActive")
    localStorage.removeItem("deviceandsample")
    localStorage.setItem("patientId", patientId)
    localStorage.setItem("fullName", JSON.stringify(fullName))
    this.props.navigation.navigate("OrderTest")
  }

  viewPatient = (patientId: number) => {
    this.props.navigation.navigate("PatientView", {
      id: patientId
    })
  }

  editPatientScreen = (patientId: number) => {
    this.props.navigation.navigate(`EditPatient`, {
      id: patientId
    })
  }

handleRow=(statuss:any,id:any)=>{
  if(statuss.status?.toLowerCase() === "reviewed"){
    this.handleNavigateToResultTest(id)
   }
   else if(statuss.status?.toLowerCase()==='cancelled'){
    this.setState({popupcancel:true})
   }
   else{
    this.setState({dataHandle:statuss})
    this.setState({popupview:true})
   }
  }
  editPatientTest = (testId: number) => {
    this.props.navigation.navigate(`TestEdit`, {
      id: testId
    })
  }

  handleCloseErrorModal = () => {
    this.setState({
      showErrorModal: false,
      nhsNumberErrorMessage:'',
      mobileMessage:''
    
    })
  }

  handleValidatePatient = (values: any) => {
    this.setState({
      allValues: values
    })
    this.shouldCreatePatientMobile()
    this.shouldCreatePatient()
  }

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: 1
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ 
      ...this.state, 
      stepperTwoValues: { ...this.state.stepperTwoValues, [name]: value }, 
      allValues: { ...this.state.allValues, [name]: value } 
    })
  }

  openCancelTestModal = (testId: number) => {
    this.setState({
      isVisiblePopUp: true,
      canceledTestId: testId
    })
  }
   isDateInput = (target: any): boolean => {
    return target?.tagName === 'INPUT' && target?.type === 'date';
  };
  
   showDatePicker = (target: any): void => {
    target.focus();
    if (typeof target.showPicker === 'function') {
      target.showPicker();
    }
  };
  
   handleClick = (event: any): void => {
    const target = event?.target;
    
    if (this.isDateInput(target)) {
      this.showDatePicker(target);
    }
  };
  onCloseModal = () => {
    this.setState({ isVisiblePopUp: false });
  }

  handleVisibleErrorModal = () => {
    this.setState({
      isVisibleErrorModal: !this.state.isVisibleErrorModal
    })
  }

  showLoader = () => (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 117, 191, 0.2)"
      zIndex={15}>
      <CircularProgress size={50} />
    </Box>
  )

  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      errors[value as keyof object] &&
      touched[value as keyof object] &&
      (
        <div
          style={{
            fontFamily: 'Inter-Light, sans-serif',
            fontWeight: 300,
            color: "#E7205D",
            fontSize: "14px",
            lineHeight: "22px"
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  // Function to fetch the group list from API
  getGroups = (instituteId: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInstitutionDataApiEndPoint + "?" + `institution_id=${instituteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ dataFetching: true })
  };

  // Function to fetch the group list from API
  viewGroups = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getViewSpecificApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSpecificPatientApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  shouldCreatePatient = () => {
    const { NHSNumber } = this.state.allValues
    const instNumber = localStorage.getItem('hospitalNumber') as string
    const institutionNumber = this.state.institutionName

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkNHSnumberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkNHSnumberApiEndPoint + "?" + `nhs=${NHSNumber}&institution_number=${instNumber || institutionNumber}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  shouldCreatePatientMobile = () => {
    const { mobileNumber } = this.state.allValues
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkMobile = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkmobilenumber +  `${mobileNumber}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  // Function to add new group and send it to API
  addGroup = () => {
    const { email, mobileNumber, firstName, surName, dateofBirth, SexAtBirth, NHSNumber } = this.state.allValues
    const { street1, street2, city, postCode, country } = this.state.stepperTwoValues
    const instNumber = localStorage.getItem('hospitalNumber') as string
    const instId = localStorage.getItem('institutionId') as string
    const instName = localStorage.getItem('dropdownValue') as string
    const formattedNumber = `${mobileNumber}`

    this.setState({ buttonDisable: true, isLoader: true })
    const header = {
      token: this.state.apiToken,
    };

    const formData = new FormData();
    formData.append("form[last_name]", surName)
    email && formData.append("form[email]", email);
    formData.append("form[institution_name]", instName)
    formData.append("form[dob]", dateofBirth)
    country && formData.append("form[country]", country)
    formData.append("form[first_name]", firstName)
    formData.append("form[gender]", SexAtBirth)
    formData.append("form[address]", street1)
    street2 && formData.append("form[address1]", street2);
    formData.append("form[phone_number]", formattedNumber)
    formData.append("form[zip_code]", postCode)
    formData.append("form[city]", city)
    formData.append("form[nhs_number]", NHSNumber)
    formData.append("form[institution_number]", instNumber || this.state.institutionName);
    formData.append("form[institution_id]", instId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsAddApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to edit group and send it to API
  editGroup = (groupId: string) => {
    const { email, mobileNumber, firstName, SexAtBirth, surName, dateofBirth, NHSNumber } = this.state.allValues
    const { city, country, street2, street1, postCode } = this.state.stepperTwoValues
    const instNumber = localStorage.getItem('hospitalNumber') as string
    const instId = localStorage.getItem('institutionId') as string
    const instName = localStorage.getItem('dropdownValue') as string
    const formattedNumber = `${mobileNumber}`

    const header = {
      token: this.state.apiToken,
    };

    const formData = new FormData();
    formData.append("form[phone_number]", formattedNumber)
    formData.append("form[dob]", dateofBirth)
    formData.append("form[gender]", SexAtBirth)
    formData.append("form[address]", street1)
    formData.append("form[address1]", street2);
    formData.append("form[last_name]", surName)
    formData.append("form[zip_code]", postCode)
    formData.append("form[city]", city)
    formData.append("form[first_name]", firstName)
    formData.append("form[country]", country)
    formData.append("form[nhs_number]", NHSNumber)
    formData.append("form[email]", email);
    formData.append("form[institution_name]", instName)
    formData.append("form[institution_number]", instNumber || this.state.institutionName);
    formData.append("form[institution_id]", instId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoader: true })
    this.hideEditAccountModal()
  };

  // Function to delete group and send it to API
  /* istanbul ignore next */
  deleteGroup = (groupId: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    this.setState({ isLoader: true })
  };

  cancelPatientTest = (testId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.apiToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.cancelTestApiCalId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelTestApiEndPoint + `?id=${testId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ 
      isDisabledAccept: true,
      isLoader: true,
      isVisiblePopUp: false 
    })
  };

  // Customizable Area End
}
