import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as Yup from "yup";
import { Formik } from "formik";
import { revueLogo } from './assets'
import GenericCard from "../../../components/src/GenericCard.web";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {resetPasswordEmail, wrongEmailError} = this.state
    return (
      // Customizable Area Start
     <GenericCard>
      {
        !resetPasswordEmail ?  (<Formik
        initialValues={{
          email: "",
        }}
        data-test-id="forgotPasswordForm"
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        })}
      
        onSubmit={(values, actions) => {
          actions.resetForm({
            //@ts-ignore
            email: "",
          });
          this.getForgotPasswordOtp()
        }}
      >
        {(props) => {
          return(
          <form onSubmit={props.handleSubmit}>
            {this.state.isLoader && this.displayLoader()}
            <Box style={{padding: '50px'}}>
              <Box style={{textAlign: 'center'}} id="Logo" onClick={this.goToLoginPage}>
                <img src={revueLogo} style={{cursor: "pointer"}}/>
              </Box>
              <Typography style={{marginTop: '40px', fontWeight: 'bold', fontSize: '13px', marginBottom: '10px'}} variant="h6" align="center">
              Enter the email address asociated with your account and we’ll send you a link to reset your password
              </Typography>
              <Box sx={webStyle.inputWrapper}>
                <InputLabel style={webStyle.label}>Email</InputLabel>
                <TextField
                  data-test-id={"email"}
                  style={webStyle.inputField}
                  name="email"
                  id="email"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={props.values.email}
                  onChange={(event) => {
                    props.setFieldValue("email", event.target.value);
                    this.onValueChange("email", event.target.value);
                  }}
                  placeholder="Your Email"
                  fullWidth={true}
                  type="text"
                />
                {this.getErrorMessage(
                  props.touched,
                  props.errors,
                  "email"
                )}
              </Box>
              <Box 
                style={{
                  color: "#f94b4b",
                  fontSize: "15px",
                  marginTop:'23px',
                  marginBottom:'20px',
                  fontWeight: 400,
                }}>
                {wrongEmailError}
              </Box>
             
            
              <Button
                type="submit"
                style={webStyle.buttonStyle}
                data-test-id="LoginBtn"
              >
                Send email
              </Button>
            </Box>
          </form>
        )}}
      </Formik>) :  (<Box style={{textAlign: 'center', padding: '30px 0'}}>
        <Box style={{textAlign: 'center', margin: '20px 0'}}>
           <img src={revueLogo} />
          </Box>
          <Box style={webStyle.confirmationEmail}>
        <Typography style={webStyle.confirmationText}>
          If an account exists, an email with a link to reset your password has been sent to
        </Typography>
        <Typography style={{fontStyle: 'italic', fontSize: '13px', margin: '23px 0', textDecoration: 'underline'}}>
          {this.state.email}
        </Typography>
        <Typography style={webStyle.confirmationText}>
        Not the right email? <span style={{cursor: 'pointer', textDecoration: 'underline'}} data-test-id={'btnGoBack'} onClick={this.goBack}>Go back</span>
  
        </Typography>
        </Box>
        <Typography style={{fontSize: '13px', padding: '0 35px', margin: '20px 0', fontWeight: 600, color: '#21355F'}}>
        Click the link contained in the email and you will be forwarded to a page where you can reset your password
  
        </Typography>
        <Button
          data-test-id={'btnFinish'}
          onClick={this.goToLoginPage}
          style={webStyle.buttonStyle}
        >
          Finish
        </Button>
        </Box> )
      }
     
     
     </GenericCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    marginBottom: '20px'
  },

  inputField: {
    border: '1px solid rgba(203, 213, 225, 1)',
    padding: '10px 10px',
    borderRadius: '8px',
    background: 'none',
    width: "95%",
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },

  confirmationEmail: {
    background: '#c9eecf',
    padding: '16px'
  },

  confirmationText: {
    fontSize: '13px',
    color: '#1D7A27',
    fontWeight: 600,
    padding: '0 20px'
  },
 
  buttonStyle: {
    height: "45px",
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: 'none' as const
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  },
};
// Customizable Area End
