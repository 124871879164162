export const plusIcon = require("../assets/Vector.svg");
export const editIcon = require("../assets/edit.png");
export const cancelIcon = require("../assets/delete.png");
export const arrowIcon = require("../assets/arrow.svg");
export const copyIcon = require("../assets/copy.svg");
export const editDataIcon = require("../assets/edit.svg");
export const actionIcon = require("../assets/action.svg");
export const calenderIcon = require("../assets/calender.svg");
export const autoComplete = require("../assets/image_search.png");
export const downArrowIcon = require("../assets/downArrow.svg");
export const download = require("../assets/download.png");
export const image_eye = require("../assets/image_eye.png");
export const checkbox = require("../assets/checkbox.svg");
export const warning = require("../assets/Warning.svg");
