import React, {createRef} from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Breadcrumbs,
  Stepper,
  Step,
  Grid,
  Select,
  MenuItem,
  Tooltip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NavigateNext } from '@material-ui/icons';
import { configJSON } from "./AccountGroupsController";
import CommonModal from "../../../components/src/CommonModal"
import { info } from './assets'
import { Formik } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import PatientController, {
  Props,
} from "./PatientController";
import EditModal from "../../../components/src/EditModal";
import ErrorModal from "./ErrorModal";

export default class ManagePatient extends PatientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.formikRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  conditionShort = (editQueryId: string, activeStep: number) => {
    return (
      <Box
        style={{
          width: '100%',
          backgroundColor:'unset',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {editQueryId ? (
          ""
        ) : (
          <Stepper style={{ width: '30%',backgroundColor:'unset' }} activeStep={activeStep}>
            <Step>
              <Box style={activeStep === 0 ? webStyle.personalInformation : webStyle.nonActiveStepper}>
                1
              </Box>
            </Step>
            <Step>
              <Box style={activeStep === 1 ? webStyle.personalInformation : webStyle.nonActiveStepper}>
                2
              </Box>
            </Step>
          </Stepper>
        )}
      </Box>
    );
  }

  // Customizable Area End

  render() {
    const answerFinal=(this.state.nhsNumberErrorMessage && this.state.nhsNumberErrorMessage !== 'NHS Number not registered!') 
    ? this.state.nhsNumberErrorMessage 
    : this.state.mobileMessage
    const { activeStep, instituteNumber, nhsNumberValidation, editQueryId, institutionName, allValues, 
            viewSpecificList, stepperTwoValues, editDataResponse, buttonDisable } = this.state;
    const breadcrumbs = [
      <Typography 
        onClick={this.redirectPatientPage} data-test-id="redirectPatientBtn"
        style={{...webStyle.breadcrumbsLink, cursor: "pointer"}} 
        key="1" >
        Patients
      </Typography>,
      editQueryId ? 
      <Typography 
        onClick={() => this.viewPatient(editDataResponse?.id)}
        style={{...webStyle.breadcrumbsLink, cursor: "pointer"}}  
        key="2" 
        data-test-id="redirectViewPatientBtn" >
          {viewSpecificList?.first_name} {" "} {viewSpecificList?.last_name}
      </Typography> : 
      <Typography 
        key="2" 
        style={{...webStyle.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>
          Create
      </Typography>,
      editQueryId && 
      <Typography
        key="3"
        style={{...webStyle.breadcrumbsLink, fontFamily: "Inter-Bold, sans-serif"}}>
          Edit
      </Typography>,
    ];

    const formattedInstitutionNumber = this.formatInstitutionDetails(instituteNumber);
    const formattedInstitutionName = this.formatInstitutionDetails(institutionName);
    const infoText = "An NHS Number must be 10 digits long and consist only of numbers which should not start with 0"
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={{overflow: 'scroll',paddingBottom:'30px',marginBottom:'50px',maxWidth:'100%'}}>
        <Breadcrumbs
        style={{margin: '20px 0 30px 0'}}
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs> 
       <Container style={{width:'100%',maxWidth:'100%'}}>
       {this.state.isLoader && this.showLoader()}
        {this.conditionShort(editQueryId, activeStep )}
          <Box>
            {this.renderPersonalInformation(editQueryId, activeStep) && (
              <Box>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                  <Typography style={ webStyle.personalInformation}>1</Typography>
                  <Typography style={{
                    fontSize: '24px',
                    fontFamily: "Inter-Bold, sans-serif",
                    color: 'rgba(33, 53, 95, 1)',
                    marginLeft: '15px'
                  }}>Personal Information</Typography>
                </Box>
                <Formik
                  initialValues={{ ...allValues }}
                  data-test-id="createForms"
                  validationSchema={this.personalValidationSchema()}
                  onSubmit={(values) => {
                    this.handleValidatePatient(values);
                  }}
                >
                  {(props) => {
                    return (
                      <form onSubmit={props.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box style={{  padding: '0 50px', display: 'flex', flexDirection: 'column' }}>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>First Name*</InputLabel>
                                <Input
                                  id="password"
                                  data-test-id={"password"}
                                  style={webStyle.inputField}
                                  disableUnderline={true}
                                  name="firstName"
                                  value={allValues.firstName}
                                  onChange={(event) => {
                                    props.setFieldValue("firstName", event.target.value);
                                    this.onValueChange("firstName", event.target.value);
                                  }}
                                  placeholder="First Name"
                                  fullWidth={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "firstName")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Date of Birth*</InputLabel>
                                <Input
                                  id="dateofBirth"
                                  data-test-id={"dateofBirth"}
                                  type="date"
                                  style={{...webStyle.inputField,height:'56px',paddingBottom:'0px',paddingTop:'0px'}}
                                  name="dateofBirth"
                                  disableUnderline={true}
                                  value={allValues.dateofBirth}
                                  inputProps={{ max: new Date().toISOString().split("T")[0],  style: { height: '100%', padding: '8px 0', boxSizing: 'border-box' }  }}
                                  onChange={(event) => {
                                    props.setFieldValue("dateofBirth", event.target.value);
                                    this.onValueChange("dateofBirth", event.target.value);
                                  }}
                                  onClick={this.handleClick}
                                  placeholder="Date of Birth"
                                  fullWidth={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "dateofBirth")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <InputLabel style={webStyle.label}>NHS Number*</InputLabel>
                                  <Tooltip arrow disableFocusListener disableTouchListener title={infoText}>
                                    <img style={{ marginLeft: '10px' }} src={info} />
                                  </Tooltip>
                                </Box>
                                <Input
                                  name="NHSNumber"
                                  data-test-id={"NHSNumber"}
                                  style={webStyle.inputField}
                                  id="NHSNumber"
                                  value={allValues.NHSNumber}
                                  onChange={(event) => {
                                    props.setFieldValue("NHSNumber", event.target.value);
                                    this.onValueChange("NHSNumber", event.target.value);
                                  }}
                                  placeholder="NHS Number"
                                  fullWidth={true}
                                  disableUnderline={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "NHSNumber")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Email</InputLabel>
                                <Input
                                  name="email"
                                  data-test-id={"email"}
                                  style={webStyle.inputField}
                                  disableUnderline={true}
                                  id="email"
                                  value={allValues.email}
                                  onChange={(event) => {
                                    const trimmedValue = event.target.value.trim();
                                    props.setFieldValue("email", trimmedValue);
                                    this.onValueChange("email", trimmedValue);
                                  }}
                                  placeholder="Email"
                                  fullWidth={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "email")}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box style={{ padding: '0 50px', display: 'flex', flexDirection: 'column' }}>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Last Name*</InputLabel>
                                <Input
                                  id="surName"
                                  data-test-id={"surName"}
                                  disableUnderline={true}
                                  style={webStyle.inputField}
                                  name="surName"
                                  value={allValues.surName}
                                  onChange={(event) => {
                                    props.setFieldValue("surName", event.target.value);
                                    this.onValueChange("surName", event.target.value);
                                  }}
                                  placeholder="Last Name"
                                  fullWidth={true}
                                />
                                {this.getErrorMessage(props.touched, props.errors, "surName")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Sex at Birth*</InputLabel>
                                <Select
                                  labelId="gender-label"
                                  id="SexAtBirth"
                                  data-test-id="SexAtBirth"
                                  style={webStyle.inputField}
                                  placeholder="Sex at Birth"
                                  name="SexAtBirth"
                                  value={allValues.SexAtBirth}
                                  onChange={(event: any) => {
                                    this.onValueChange("SexAtBirth", event.target.value);
                                    props.setFieldValue("SexAtBirth", event.target.value);
                                  }}
                                  disableUnderline
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>
                                    Sex at Birth
                                  </MenuItem>
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                </Select>
                                {this.getErrorMessage(props.touched, props.errors, "SexAtBirth")}
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Institution Number</InputLabel>
                                <Input
                                  name="institutionNumber"
                                  data-test-id={"institutionNumber"}
                                  disableUnderline={true}
                                  readOnly
                                  style={{
                                    ...webStyle.inputField,
                                    backgroundColor: "#EAE8E8",
                                    color: "#0F172A",
                                    fontSize: "16px",
                                    fontFamily: 'Inter-Regular, sans-serif',
                                  }}
                                  id="institutionNumber"
                                  value={formattedInstitutionName || formattedInstitutionNumber}
                                  placeholder="Institution Number"
                                  fullWidth={true}
                                />
                              </Box>
                              <Box sx={webStyle.inputWrapper}>
                                <InputLabel style={webStyle.label}>Mobile Number*</InputLabel>
                                <Box style={{ display: "flex" }}>
                                 
                                  <Input
                                    style={{
                                      ...webStyle.inputField, 
                                    }}
                                    disableUnderline={true}
                                    name="mobileNumber"
                                    data-test-id={"mobileNumber"}
                                    id="mobileNumber"
                                    value={allValues.mobileNumber}
                                    placeholder="Mobile number with country code"
                                    fullWidth={true}
                                    onChange={(event) => {
                                      const numericValue = event.target.value.replace(/\D/g, '')
                                      this.onValueChange("mobileNumber", numericValue)
                                      props.setFieldValue("mobileNumber", numericValue)
                                    }}
                                  />                           
                                </Box>
                                {this.getErrorMessage(props.touched, props.errors, "mobileNumber")}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        {editQueryId ? (
                          ""
                        ) : (
                          <Box style={webStyle.stepperBottom}>
                            <Button style={webStyle.cancelButton} onClick={this.redirectPatientPage}>
                              Cancel
                            </Button>
                            <Button data-test-id="nextButton" type="submit" style={webStyle.buttonStyle} variant="contained" color="primary">
                              Next
                            </Button>
                          </Box>
                        )}
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            )}
            {(editQueryId || activeStep === 1 )&& (
            <Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={webStyle.personalInformation}>2</Typography>
                <Typography
                  style={{
                    fontSize: '24px',
                    fontFamily: "Inter-Bold, sans-serif",
                    color: 'rgba(33, 53, 95, 1)',
                    marginLeft: '15px'
                  }}
                >
                  Address details
                </Typography>
              </Box>
            <Formik
              initialValues={{
                street1: stepperTwoValues.street1,
                city: stepperTwoValues.city,
                postCode: stepperTwoValues.postCode,
                country: stepperTwoValues.country,
              }}
              validationSchema={this.addressValidationSchema()}
              data-test-id="changePasswordForm"
              onSubmit={() => {
                {
                  /* istanbul ignore next */
                  {
                    editQueryId ? this.editAccountModal() : this.showHideAddAccountModal();
                  }
                }
              }}
            >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box style={{ padding: '0 50px' }}>
                        <Box sx={webStyle.inputWrapper}>
                          <InputLabel style={webStyle.label}>Street 1*</InputLabel>
                          <Input
                            id="street1"
                            data-test-id={"street1"}
                            style={webStyle.inputField}
                            disableUnderline={true}
                            name="street1"
                            value={stepperTwoValues.street1}
                            onChange={(event) => {
                              props.setFieldValue("street1", event.target.value);
                              this.onValueChange("street1", event.target.value);
                            }}
                            placeholder="Street 1"
                            fullWidth={true}
                          />
                          {this.getErrorMessage(props.touched, props.errors, "street1")}
                        </Box>
                        <Box sx={webStyle.inputWrapper}>
                          <InputLabel style={webStyle.label}>Town/City*</InputLabel>
                          <Input
                            id="town"
                            data-test-id={"town"}
                            style={webStyle.inputField}
                            name="town"
                            disableUnderline={true}
                            value={stepperTwoValues.city}
                            onChange={(event) => {
                              props.setFieldValue("city", event.target.value);
                              this.onValueChange("city", event.target.value);
                            }}
                            placeholder="Town/City*"
                            fullWidth={true}
                          />
                          {this.getErrorMessage(props.touched, props.errors, "city")}
                        </Box>
                        <Box sx={webStyle.inputWrapper}>
                          <InputLabel style={webStyle.label}>Postcode*</InputLabel>
                          <Input
                            name="postCode"
                            data-test-id={"postCode"}
                            style={webStyle.inputField}
                            id="postCode"
                            disableUnderline={true}
                            value={stepperTwoValues.postCode}
                            onChange={(event) => {
                              props.setFieldValue("postCode", event.target.value);
                              this.onValueChange("postCode", event.target.value);
                            }}
                            placeholder="Postcode"
                            fullWidth={true}
                          />
                          {this.getErrorMessage(props.touched, props.errors, "postCode")}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box style={{ padding: '0 50px' }}>
                        <Box sx={webStyle.inputWrapper}>
                          <InputLabel style={webStyle.label}>Street 2</InputLabel>
                          <Input
                            id="street2"
                            data-test-id={"street2"}
                            disableUnderline={true}
                            style={webStyle.inputField}
                            name="street2"
                            value={stepperTwoValues.street2}
                            onChange={(event) => {
                              props.setFieldValue("street2", event.target.value);
                              this.onValueChange("street2", event.target.value);
                            }}
                            placeholder="Street 2"
                            fullWidth={true}
                          />
                        </Box>
                        <Box sx={webStyle.inputWrapper}>
                          <InputLabel style={webStyle.label}>Country*</InputLabel>
                          <Select
                            id="country"
                            style={webStyle.selectStyles}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  maxHeight: 220
                                },
                              },
                            }}
                            name="country"
                            value={stepperTwoValues.country}
                            onChange={(event: any) => {
                              this.onValueChange("country", event.target.value);
                              props.setFieldValue("country", event.target.value);
                            }}
                            placeholder="Country"
                            disableUnderline
                            fullWidth
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              Country
                            </MenuItem>
                            {configJSON.countries.map((country: {name: string}) => {
                              return(
                                <MenuItem key={country.name} value={country.name}>
                                  {country.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          {this.getErrorMessage(props.touched, props.errors, "country")}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={webStyle.stepperBottom}>
                    <Button
                      style={webStyle.cancelButton}
                      onClick={editQueryId ? this.redirectPatientPage : this.handleBack}
                    >
                      {this.allCondition(editQueryId, 'Cancel', 'Previous')}
                    </Button>
                    <Button
                      type="submit"
                      onClick={
                        /* istanbul ignore next */
                        () => this.formikRef.current?.submitForm()
                      }
                      style={webStyle.saveInformationButton}
                      variant="contained"
                      color="primary"
                    >
                      {this.allCondition(editQueryId, 'Save Information', "Create patient")}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
        )} 
      </Box>
      </Container>
       {/* @ts-ignore */}
            <EditModal data-test-id="editModal" allValues={allValues} nhsNumberValidation={nhsNumberValidation} hideEditAccountModal={this.hideEditAccountModal} editQueryId={editQueryId} editModal={this.state.editModal} editGroup={this.editGroup} />
        {/* @ts-ignore */}
        <CommonModal
          data-test-id="addModal"
          buttonDisable={buttonDisable}
          nhsNumberValidation={nhsNumberValidation}
          addGroup={this.addGroup}
          isOpen={this.state.isVisibleAddAccountModal}
          hideDeleteAccountModal={this.hideDeleteAccountModal}
          title={`Are you sure you want to create ${allValues.firstName} ${allValues.surName}?`}
        />
        <ErrorModal
          isVisible={this.state.showErrorModal}
          onAccept={this.handleCloseErrorModal}
          message={
            answerFinal
          }
          data-test-id="errorModal"
         />
          </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    marginTop: '15px'
  },
  stepperBottom: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'baseline',
  },
  personalInformation: {
    fontFamily: "Inter-Bold, sans-serif",
    fontSize: '24px',
    color: '#fff',
    width: '40px', 
    height: '40px', 
    background:'rgba(231, 32, 93, 1)', 
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nonActiveStepper: {
    fontSize: '24px',
    fontFamily: 'Inter-Regular, sans-serif',
    color: '#000000',
    width: '40px', 
    height: '40px', 
    border:'1px solid #000000', 
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    fontSize: "14px",
    fontFamily: 'Inter-Bold, sans-serif',
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  }, 
  inputField: {
    borderRadius: '8px',
    padding: '10px 10px',
    background: 'none',
    border: '1px solid rgba(203, 213, 225, 1)',
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },
  buttonStyle: {
    width: '167px',
    height: "45px",
    border: "none",
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: '8px',
    color: "white",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: 'none' as const,
    marginLeft: '30px'
  },
  saveInformationButton: {
    height: "45px",
    border: "none",
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: '8px',
    color: "white",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: 'none' as const,
    marginLeft: '30px'
  },
  cancelButton: {
    height: "45px",
    width: "166px",
    border: "1px solid rgba(0, 117, 191, 1)",
    cursor: "pointer",
    marginTop: "25px",
    borderRadius: '8px',
    color: "rgba(0, 117, 191, 1)",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: 'none' as const
  },
  breadcrumbsLink: {
    fontSize: "24px", 
    fontFamily: "Inter-Regular, sans-serif",
    color: "#21355F"
  },
  selectStyles: {
    border: "1px solid rgba(203, 213, 225, 1)",
    padding: "10px 10px",
    borderRadius: "8px",
    width: "100%",
    background: "none",
    height: "56px",
    color: "#21355F"
  },
};
// Customizable Area End
