import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    TextField,
    IconButton,
    Breadcrumbs,
    Modal,
    Fade,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SwitchComponent from './SwitchComponent.web';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import { NavigateNext, SearchOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { configJSON } from './CustomFormController.web';

import OrderSummaryController, {
    Props,
} from './OrderSummaryController'
import { editDataIcon, copyIcon, calenderIcon } from './assets'

export default class OrderSummary extends OrderSummaryController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        dayjs.extend(updateLocale);
        dayjs.updateLocale('en', {
            weekStart: 1,
        });

        const patientFullName = localStorage.getItem('fullName') as string
        const patientId = localStorage.getItem('patientId') as string

        const breadcrumbs = [
            <Typography 
                onClick={() => this.viewPatientScreen()} 
                data-test-id="navigateToTest"
                style={{...webStyle.breadcrumbsLink, cursor: "pointer"}}  
                key="1">
                Patients
            </Typography>,
            <Typography 
                onClick={() => this.viewPatient(JSON.parse(patientId))}
                style={{...webStyle.breadcrumbsLink, cursor: "pointer"}} 
                key="2" data-test-id="patientFullName" >
                {JSON.parse(patientFullName)}
            </Typography>,
            <Typography 
                key="3" 
                style={{...webStyle.breadcrumbsLink, fontFamily: 'Inter-Bold, sans-serif'}}>
                Order Test
            </Typography>,
        ]

        const dataFromStorage = localStorage.getItem("summaryData") as string;
        const selectedTests = JSON.parse(dataFromStorage)
        const patientTests = selectedTests?.filter((test: any) => Number(test.patientId) === Number(patientId))

        return (
            <div style={{overflow:'auto',width:'100%'}}>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px", 
                    padding: "20px 20px 40px", width: "100%", marginBottom:'60px',height: "fit-content"}}>
                    <Breadcrumbs
                        style={{marginLeft: "20px"}}
                        separator={<NavigateNext fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                    {this.state.isLoader && this.displayLoader()}
                    <Box style={{ width: "fit-content", height: "40px", display: "flex", gap: "8px", alignItems: "center"}}>
                        <div style={{ height: "40px", width: "30px", borderRadius: "50%",
                                padding: "0px 6px", backgroundColor: "#E7205D", display: "flex",
                                alignItems: "center", justifyContent: "center"
                            }}
                        >
                            <Typography style={{ color: "#FFFFFF", fontFamily: "Inter-Bold, sans-serif", fontSize: "24px"}}
                            > 3
                            </Typography>
                        </div>
                        <Typography style={{ height: "40px", width: "fit-content", padding: "4px 8px",
                                color: "#21355F", fontFamily: "Inter-Bold, sans-serif", fontSize: "24px"
                            }}
                        > Checkout
                        </Typography>
                    </Box>
                    {patientTests.length >= 1 && patientTests.map((item: any, itemKey: number) => (
                        <Box key={itemKey}>
                            { this.shouldShowError(itemKey, item) && this.state.showAllErrors && (
                                <Typography style={{ width: "fit-content", height: "fit-content", color: "#E7205D",
                                    fontFamily: "Inter-Regular, sans-serif", fontSize: "18px"
                                }}>
                                    {"Enter all the fields required"}
                                </Typography>
                            )}
                            
                            <Card style={{ width: "90%", height: "auto", borderRadius: "6px", borderLeft: "4px solid #0075BF", background: "#FFFFFF",
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: "15px 10px 0px 10px",
                                display: "flex", flexDirection: "column", gap: "20px", overflow:"auto"
                            }}>
                                <CardContent style={{ display: "flex", gap: "12px", alignItems: "center"}}>
                                    <Typography style={{width: "fit-content", height: "32px", color: "#21355F",
                                        fontFamily: "Inter-Bold, sans-serif", fontSize: "24px"
                                    }}
                                    id='copyTextName'
                                    >
                                        {`Test ${itemKey+1}`}
                                    </Typography>
                                    <div style={{height: "30px", width: "30px", borderRadius: "50%",
                                        background: "#0075BF", display: "flex", alignItems: "center",
                                        justifyContent: "center", cursor: "pointer"
                                    }}
                                    data-test-id="copyTextBtn"
                                    onClick={() => this.handleCopy(`Test ${itemKey+1}`)}
                                    >
                                        <img src={copyIcon} alt="copy"/>
                                    </div>
                                    <div style={{ height: "30px", width: "30px", borderRadius: "50%",
                                        background: "#0075BF", display: "flex", alignItems: "center",
                                        justifyContent: "center", cursor: "pointer"
                                    }}
                                    data-test-id="navigatetoOrderTestBtn"
                                    onClick={() => this.handleNavigationToOrderTest(item)}
                                    >
                                        <img src={editDataIcon} alt="edit"/>
                                    </div>
                                    <div style={{ height: "30px", width: "30px", borderRadius: "50%",
                                        background: "#0075BF", display: "flex", alignItems: "center",
                                        justifyContent: "center", cursor: "pointer"
                                    }}
                                    data-test-id="deleteCardBtn"
                                    onClick={() => this.handleOpenModal(item, itemKey)}
                                    >
                                        <DeleteIcon style={{color: "#FFFFFF"}}/>
                                    </div>
                                </CardContent>
                                <CardContent style={{ display: "flex", gap: "12px", justifyContent: "space-between"}}>
                                    <Box style={{ display: "flex", gap: "10px", opacity: this.handleDisabledOpacity(itemKey, item.revueSwitcher)}}>
                                        <Typography style={{width: "fit-content", height: "22px", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px", marginTop:"6px"
                                        }}>
                                            {"Test ID*"}
                                        </Typography>
                                        <Box style={{display:"flex", flexDirection:"column"}}>
                                            <TextField style={{width: "140px", height: "30px", borderRadius: "7px",
                                                    padding: "0px 6px", color: "#21355F", 
                                                    border: `${this.handleTestIdBorder(this.getTestId(itemKey, item.testId), item.revueSwitcher, itemKey)}`,
                                                    fontFamily: "Inter-Regular, sans-serif", fontSize: "14px"
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: this.checkSwitcherState(itemKey, item.revueSwitcher)
                                                }}
                                                value={this.getValueFromStateOrFallback(this.state.testid, itemKey,item.testId)}
                                                id="outlined-error-helper-text"
                                                variant="standard"
                                                placeholder="Enter Test ID"
                                                data-test-id="testIdCheck"
                                                onChange={(event) => this.handleTestId(event.target.value, itemKey, item.tempId)}  
                                            />
                                            {this.showTestIdError(itemKey, item.testId, item.revueSwitcher) && (
                                                <Typography style={{ width: "fit-content", height: "fit-content", color: "#E7205D",
                                                    fontFamily: "Inter-Regular, sans-serif", fontSize: "12px"
                                                }}>
                                                    {"Required field"}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Typography style={{ width: "fit-content", height: "22px", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Test Type*"}
                                        </Typography>
                                        <Typography style={{
                                            width: "fit-content", 
                                            minWidth: '100px', 
                                            height: "22px", 
                                            color: "#FFF", 
                                            background: "#E7205D", 
                                            borderRadius: "4px",
                                            padding: "4px 8px 4px 12px",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            backgroundColor: `#E7205D`,
                                            fontFamily: "Inter-Bold, sans-serif"}}>
                                                {this.getType(item.testType, item.sampleType) }
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Typography style={{ width: "fit-content", height: "22px", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Date of Test*"}
                                        </Typography>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Button variant="contained"
                                                style={{ 
                                                    height: "30px", 
                                                    width: "140px", 
                                                    borderRadius: "8px", 
                                                    textTransform: "none",
                                                    padding: "6px 10px", 
                                                    background: `${this.handleDataColor(this.getFormattedDateOfTest(item.tempId))}`, 
                                                    color: "#FFF",
                                                    fontFamily: "Inter-Bold, sans-serif", 
                                                    fontSize: "14px", 
                                                    border: `${this.handleBorderBtns(this.getFormattedDateOfTest(item.tempId))}`
                                                }}
                                                data-test-id="dateTestBtn"
                                                onClick={() => this.openCalPopup(itemKey, item.tempId)}
                                            >
                                                <img style={{ marginRight: '10px' }} src={calenderIcon} />
                                                {this.handleData4(this.getFormattedDateOfTest(item.tempId))}
                                                
                                                
                                            </Button>
                                            { this.showRequireError(this.getFormattedDateOfTest(item.tempId)) && (
                                                <Typography style={{ width: "fit-content", height: "fit-content", color: "#E7205D",
                                                    fontFamily: "Inter-Regular, sans-serif", fontSize: "12px"
                                                }}>
                                                    {"Required field"}
                                                </Typography>
                                            )}
                                        </div>
                                    </Box>
                                    <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Typography style={{ width: "fit-content", height: "22px", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Clinician*"}
                                        </Typography>
                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Button variant="contained"
                                                style={{...webStyle.clinicianButton,
                                                    background: `${this.handleDataColor(this.getClinicianFullName(this.state.openAssignClinicianConfirmPopupName[itemKey], item.clinicianFullName))}`, 
                                                    border: `${this.handleBorderBtns(this.getClinicianFullName(this.state.openAssignClinicianConfirmPopupName[itemKey], item.clinicianFullName))}`
                                                }}
                                                data-test-id="calTestBtn"
                                                onClick={() => this.handleAssignClinician(itemKey, item.tempId)}
                                                >
                                                <img style={{ marginRight: '10px' }}  
                                                    src={this.handleData6(this.getClinicianFullName(this.state.openAssignClinicianConfirmPopupName[itemKey], item.clinicianFullName))}
                                                />
                                                 <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    {this.handleData7(this.getClinicianFullName(this.state.openAssignClinicianConfirmPopupName[itemKey], item.clinicianFullName))}
                                                </span>
                                            </Button>
                                            { this.showRequireError(this.getClinicianFullName(this.state.openAssignClinicianConfirmPopupName[itemKey], item.clinicianFullName)) && ( 
                                                <Typography style={{ width: "fit-content", height: "fit-content", color: "#E7205D",
                                                    fontFamily: "Inter-Regular, sans-serif", fontSize: "12px"
                                                }}>
                                                    {"Required field"}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </CardContent>
                                <CardContent style={{
                                    display: "flex", alignItems: "center", justifyContent: "space-between"
                                }} >
                                    <Box style={{display: "flex", flexDirection: "column",
                                        gap: "12px", alignItems: "flex-start", flexGrow: 1, justifyContent:"center",
                                    }}>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Sample Type"}
                                        </Typography>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#21355F",
                                            fontFamily: "Inter-Regular, sans-serif", fontSize: "14px"
                                        }}>
                                            {item.sampleType}
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", flexDirection: "column", minHeight:"80px",
                                        flexGrow: 1, borderLeft: "2px solid #21355F", justifyContent:"center",
                                        padding: "0px 0px 0px 20px", gap: "12px", alignItems: "flex-start",
                                        height: `${this.handleHeightSample(itemKey)}px`,
                                    }}>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Sample Amount"}
                                        </Typography>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#21355F",
                                            fontFamily: "Inter-Regular, sans-serif",  fontSize: "14px"
                                        }}>
                                            {this.handleGetSampleAmount(item)}
                                        </Typography>
                                    </Box>
                                    <Box style={{
                                            display: "flex", 
                                            borderLeft: "2px solid #21355F", 
                                            flexGrow: 2, 
                                            height: "fit-content",
                                            gap: "6px", 
                                            alignItems: "center", 
                                            flexDirection: "column", minHeight:"80px",
                                            justifyContent: `${this.handleData8(this.state.openRevueToFill[itemKey])}`
                                    }}>
                                        <Box style={{ display: "flex", gap: "12px", alignItems: "center",}}>
                                            <Typography style={{position:'relative',bottom:'-2px', width: "fit-content", height: "fit-content", color: "#21355F",
                                                fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                            }}>
                                                {"Revue to fulfil"}
                                            </Typography>
                                            <SwitchComponent data-test-id="switchBtn"
                                                checked={this.checkSwitcherState(itemKey, item.revueSwitcher)}
                                                onChange={(event: any) => this.handleSwitch(itemKey, item.tempId, event)} 
                                            />
  
                                        </Box>
                                        {this.checkSwitcherState(itemKey, item.revueSwitcher) && 
                                            <textarea style={{ width: "90%", overflow:"hidden",
                                                resize: "none", border: "1px solid #CBD5E1", 
                                                color: "#21355F", borderRadius: "8px", outline:"none",
                                                fontFamily: "Inter-Regular, sans-serif", fontSize: "14px",
                                                boxSizing:'border-box',padding: "4% 2% 2% 3%"
                                            }}
                                                rows={2}
                                                id={"textboxRevue" + itemKey}
                                                data-test-id="revueToFillInfoBtn"
                                                onInput={() => this.handleTextAreaRevue(itemKey)}
                                                value={this.getValueFromStateOrFallback(this.state.revueToFillInfo, itemKey,item.revueNote)}
                                                onChange={(event) => this.handleRevueToFillInfoInfo(event.target.value, itemKey, item.tempId)}
                                                placeholder="Type here..."
                                            >
                                            </textarea>
                                        }
                                    </Box>
                                    <Box style={{ display: "flex", borderLeft: "2px solid #21355F", flexGrow: 3, 
                                        minHeight:"80px", justifyContent:"center",
                                        padding: "0px 0px 0px 20px", flexDirection: "column", 
                                        height: `${this.handleHeightSample(itemKey)}px`,
                                    }}>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#21355F",
                                            fontFamily: "Inter-Bold, sans-serif", fontSize: "14px"
                                        }}>
                                            {"Any additional information?"}
                                        </Typography>
                                        <textarea style={{ width: "100%", resize: "none", border: "1px solid #CBD5E1",
                                            color: "#21355F", borderRadius: "8px", outline:"none", overflow:"hidden",
                                            fontFamily: "Inter-Regular, sans-serif", fontSize: "14px",
                                            boxSizing:'border-box', padding: "4% 2% 2% 3%"
                                        }}
                                            maxLength={100}
                                            id={"textboxInfo" + itemKey}
                                            data-test-id="aditionalInfoBtn"
                                            onInput={() => this.handleTextAreaAddInfo(itemKey)}
                                            value={this.getValueFromStateOrFallback(this.state.aditionalInfo, itemKey,item.additionalInfo)}
                                            onChange={(event) => this.handleAdditionalInfo(event.target.value, itemKey, item.tempId)}
                                            placeholder="Type here..."
                                        >
                                        </textarea>
                                        <Typography style={{ width: "fit-content", height: "fit-content", color: "#334155",
                                            fontFamily: "Inter-Regular, sans-serif", fontSize: "12px", alignSelf:"flex-end"
                                            
                                        }}>
                                            {`${!this.state.aditionalInfo[itemKey]?.length ? 0 : this.state.aditionalInfo[itemKey]?.length}/${100}`}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    ))}
                    {this.state?.summaryData?.length >= 1 && (
                        <Box style={{ width: "100%",position:'relative',right:'90px', display: "flex", gap: "8px", justifyContent: "flex-end"}}>
                            <Button variant="contained"
                                style={{ height: "44px", width: "167px", borderRadius: "8px", textTransform: "none",
                                    padding: "6px 10px", background: "#0075BF", color: "#FFF",
                                    fontFamily: "Inter-Bold, sans-serif", fontSize: "16px",
                                }}
                                data-test-id="backGoBackBtn"
                                onClick={() => { this.props.navigation.goBack() }}
                            > Add more tests
                            </Button>
                            <Button variant="contained" 
                                style={{ height: "44px", width: "167px", borderRadius: "8px",
                                    padding: "6px 10px", background: "#E7205D", color: "#FFF",
                                    fontFamily: "Inter-Bold, sans-serif", fontSize: "16px", textTransform: "none",
                                }}
                                data-test-id="placeOrderBtn"
                                onClick={() => this.handlePlaceOrder()}> Place order
                            </Button>
                        </Box>
                    )}
                </Box>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openCalenderPopup)} >
                    {this.state.openCalenderPopup && (
                        <Card style={{width: '599px', height: '538px', display: 'flex', flexDirection: 'column',
                                borderRadius: '0px 8px 32px 8px', padding: '25px 10px 15px 10px',
                                gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                            }}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column',
                                    gap: '35px', alignItems: 'center', height: "400px"
                                }}>
                                <Typography style={{color: '#21355F', height: '24px', width: '512px',
                                        fontFamily: 'Inter-SemiBold, sans-serif', fontSize: '20px', textAlign: 'center'
                                    }}>
                                    Select the Date of Test
                                </Typography>
                                <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterDayjs}>
                                    <DatePicker open data-test-id="calenderPopupBtn"
                                        onChange={(event) => this.handleDatePicker(event)}
                                        dayOfWeekFormatter={(weekday) => `${weekday.format('ddd')}`}
                                        minDate={dayjs()}
                                        showDaysOutsideCurrentMonth
                                        value={dayjs(this.getDateFromStorage(this.state.selectedTestId))}
                                        shouldDisableDate={this.shouldDisableDate}
                                        slotProps={{
                                            popper: {
                                                sx: {
                                                    ".MuiPaper-root": { marginTop: "-60px" },
                                                },
                                            },
                                            nextIconButton: {
                                                sx: {
                                                    left:"190px"
                                                }
                                            },
                                            calendarHeader: {
                                                sx: {
                                                    flexDirection:"row-reverse",
                                                    color:'#0F172A', fontSize: '14px',
                                                    fontFamily: 'Inter-Bold, sans-serif'
                                                    
                                                }
                                            },
                                            switchViewButton: {
                                                sx: {
                                                    display:'none'
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </CardContent>
                            <CardActions style={{ display: 'flex', gap: '26px' }}>
                                <Button style={{...webStyle.commonBtnWhite, textAlign: 'center', textTransform: 'none',}}
                                    data-test-id="closeCalBtn"
                                    onClick={() => this.closeCal()}
                                > Back
                                </Button>
                                <Button style={{...webStyle.commonBtnBlue, textAlign: 'center', textTransform: 'none',}}
                                    data-test-id="confirmCalBtn"
                                    onClick={() => this.confirmCal()}
                                > Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    )}
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openAssignClinicianPopup)} >
                {   this.state.openAssignClinicianPopup && (
                        <Card style={{ width: '599px', height: '500px', display: 'flex', flexDirection: 'column',
                                borderRadius: '8px', padding: '18px 40px 24px 9px',
                                gap: '10px', backgroundColor: '#FFFFFF', alignItems: 'flex-start'
                            }}>
                            <CardContent style={{width:"100%", display: 'flex', flexDirection: 'column', gap: '20px',  height: "400px"}}>
                                <Typography style={{
                                        color: '#21355F', height: '32px', width: '225px',
                                        fontFamily: 'Inter-Bold, sans-serif', fontSize: '24px'
                                }}>
                                    Assign Clinician
                                </Typography>
                                <TextField id="search" variant="standard"
                                    data-test-id="searchClinician"
                                    placeholder='Search by Name, Department or Role '
                                    onChange={(event) => this.handleAssignClinitionSearch(event.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: { color: "#8F939C", height:"43px", width:"100%", background:"#EFEFEF",
                                            border:"1px solid #0075BF", outline:"none", fontFamily:"Inter-Regular, sans-serif",
                                            borderRadius:"23px", padding:"10px 15px", fontSize:"16px"
                                        },
                                        startAdornment: (
                                            <IconButton>
                                                <SearchOutlined style={{ color: '#21355F' }} />
                                            </IconButton>
                                        )
                                    }}
                                />
                                 <TableContainer style={{height:"100%"}}>
                                    <Table aria-label="simple table">
                                        <TableHead style={webStyle.tableHead}>
                                            <TableRow>
                                                <TableCell style={{...webStyle.tableCellRow, textAlign:"center"}}>Name</TableCell>
                                                <TableCell style={{...webStyle.tableCellRow, textAlign:"center"}} align="center">Department</TableCell>
                                                <TableCell style={{...webStyle.tableCellRow, textAlign:"center"}} align="center">Role</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                        {this.state.allAssignClinician.filter((data: any) => this.filterClinicians(data, this.state.searchClinicianText)).map((clinician: any, itemKey) => (
                                            <TableRow 
                                                data-test-id="checkClinicianFromTable" 
                                                key={itemKey} 
                                                style={{background: `${this.handleRowColor(itemKey)}`, cursor:"pointer"}} 
                                                onClick={() => this.handleAssignClinicianConfirmPopup(
                                                    clinician.id, clinician.attributes.common_information.first_name +" "+  clinician.attributes.common_information.last_name, 
                                                    clinician.attributes.clinician_information.department)}>
                                                <TableCell style={{
                                                        ...webStyle.tableCellBody, 
                                                        textAlign:"center", 
                                                        textDecoration: `${this.handleData11(clinician.attributes.common_information.first_name +" "+  clinician.attributes.common_information.last_name, 
                                                                        this.state.selectedClinicianName)}`}} 
                                                        component="th" 
                                                        scope="row">
                                                            {clinician.attributes.common_information.first_name +" "+  clinician.attributes.common_information.last_name}
                                                </TableCell>
                                                <TableCell style={{...webStyle.tableCellBody, textAlign:"center"}} align="center">{clinician.attributes.clinician_information.department}</TableCell>
                                                <TableCell style={{...webStyle.tableCellBody, textAlign:"center"}} align="center">{clinician.attributes.clinician_information.role}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                 </TableContainer>
                            </CardContent>
                            <CardActions style={{ display: 'flex', gap: '10px', alignSelf:"flex-end" }}>
                                <Button style={{...webStyle.commonBtnWhite, textAlign: 'center', textTransform: 'none'}}
                                    data-test-id="closeAssignCliBtn"
                                    onClick={() => this.closeAssignCli()}
                                > Back
                                </Button>
                                <Button
                                    disabled={!this.state.selectedClinicianId}
                                    style={{...webStyle.commonBtnBlue, textAlign: 'center', textTransform: 'none', opacity: this.handleConfirmButtonOpacity()}}
                                    data-test-id="confirmAssignCliBtn"
                                    onClick={() => this.confirmAssignCli()}
                                > Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    )}
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openAssignClinicianConfirmPopup)} >
                    {(this.state.openAssignClinicianConfirmPopup) && (
                        <Card style={{ width: '600px', display: 'flex', flexDirection: 'column',
                                borderRadius: '0px 8px 32px 8px', padding: '20px', justifyContent:"space-evenly"
                            }}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Typography style={{ color: '#21355F', padding: "30px 0",
                                        fontFamily: 'Inter-SemiBold, sans-serif', fontSize: '20px', textAlign: 'center'
                                    }}>
                                    {`Assign ${this.state.selectedClinicianName} (${this.state.selectedClinicianDepartment}) as Consultant?`}
                                </Typography>
                            </CardContent>
                            <CardActions style={{ display: 'flex', gap: '15px', justifyContent:"center", marginBottom: "30px" }}>
                                <Button style={{...webStyle.commonBtnWhite, textAlign: 'center', textTransform: 'none'}}
                                    data-test-id="backFromAssignCliBtn"
                                    onClick={() => this.closeAssignedClinician()}
                                > Back
                                </Button>
                                <Button style={{...webStyle.commonBtnBlue, textAlign: 'center', textTransform: 'none'}}
                                    data-test-id="acceptAssignCliBtn"
                                    onClick={() => this.acceptAssignedClinician()}
                                > Accept
                                </Button>
                            </CardActions>
                        </Card>
                    )}
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.placeOrderPopup)} >
                    {(this.state.placeOrderPopup) && (
                        <Card style={{ width: '400px', minHeight: '300px', display: 'flex', flexDirection: 'column',
                                borderRadius: '8px', padding: '20px', gap:"20px",
                            }}>
                            <CardContent style={{ display: 'flex', flexDirection: 'column', gap:"20px",
                                    alignItems: 'flex-start', flexGrow: 1, minHeight: "150px"}}>
                                <Typography style={{ color: '#21355F', height: '32px', width: '354px',
                                        fontFamily: 'Inter-Bold, sans-serif', fontSize: '24px', textAlign: 'center'
                                    }}>
                                    Please confirm Test IDs
                                </Typography>
                                    <TableContainer style={{height:"100%"}}>
                                        <Table aria-label="simple table">
                                            <TableHead style={webStyle.tableHead}>
                                                <TableRow >
                                                    <TableCell style={{...webStyle.tableCellRow, padding:"20px 10px", textAlign:"center"}}>Test Type</TableCell>
                                                    <TableCell style={{...webStyle.tableCellRow, padding:"20px 10px", textAlign:"center"}} align="center">Test ID</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {patientTests.map((item: any, index: number) => (
                                                    <TableRow key={index} data-test-id="checkClinicianFromTable" style={{background: `${this.handleRowColor(index)}`}}>
                                                        <TableCell 
                                                            style={{...webStyle.tableCellBody, textAlign:"center"}} 
                                                            component="th" 
                                                            scope="row">
                                                            {item.testType || item.sampleType}
                                                        </TableCell>
                                                        <TableCell width="200px"
                                                            style={{...webStyle.tableCellBodyPlaceOrderTestId, textAlign:"center"}} 
                                                            align="center">
                                                            {this.getPopUpData(item.testId, item.revueSwitcher)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                                <CardActions style={{ display: 'flex', gap: '15px', justifyContent:"center" }}>
                                    <Button style={{...webStyle.commonBtnPlaceOrder, textAlign: 'center', 
                                            textTransform: 'none', color: "#0075BF", background: "#FFF", 
                                        }}
                                        data-test-id="backFromPlaceOrderBtn"
                                        onClick={() => this.closePlaceOrder()}> 
                                        Back
                                    </Button>
                                    <Button 
                                        style={{
                                            ...webStyle.commonBtnPlaceOrder, 
                                            textAlign: 'center', 
                                            textTransform: 'none', 
                                            color:"#FFFFFF", 
                                            background:"#E7205D",
                                            opacity: this.buttonDisabledOpacity()}}
                                        data-test-id="placeOrderConfimBtn"
                                        onClick={() => this.confirmPlaceOrder()}
                                        disabled={this.state.isDisabed}> 
                                        Confirm
                                    </Button>
                                </CardActions>     
                        </Card>
                    )}
                </Backdrop>
                <Modal
                    style={webStyle.modal}
                    open={this.state.openDeleteConfirmModal}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 100 }}>
                    <Fade in={this.state.openDeleteConfirmModal}>
                        <Box sx={webStyle.paper}>
                            <Box style={{display: "flex", alignItems: "center", gap: 5}}>
                                <Typography style={webStyle.modalTitle}>
                                    {`Are you sure you want to delete Test ${this.state.deletedItemIndex + 1}?`}
                                </Typography> 
                            </Box>
                            <Box style={webStyle.modalButtonsGroup}>
                                <Button onClick={this.handleCloseModal} style={webStyle.modalBackButton}>
                                    {configJSON.backDeleteItemModal}
                                </Button>
                                <Button
                                    data-test-id="deleteItem"
                                    disabled={this.state.isDisabed} 
                                    onClick={() => this.handleDelete()} 
                                    style={webStyle.modalAcceptButton}>
                                    {configJSON.confirmDeleteItemModal}
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
                <Modal
                    style={webStyle.modal}
                    open={this.state.openErrorModal}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 100 }}>
                    <Fade in={this.state.openErrorModal}>
                        <Box sx={webStyle.paper}>
                            <Typography style={webStyle.modalTitle}>
                                Please fill all the required fields
                            </Typography> 
                            <Button
                                data-test-id="closeErrorModal"
                                onClick={() => this.handleCloseErrorsModal()} 
                                style={{...webStyle.modalAcceptButton, marginBottom: "20px"}}>
                                Ok
                            </Button>
                        </Box>
                    </Fade>
                </Modal>
              <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.openError} >
                    {
                        this.state.openError && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',
                                    borderRadius: '0px 8px 33px 8px', padding: '20px 6px 20px 6px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Regular,sans-serif' }}>
                                        {this.state.sampleAns}
                                    </span><br />
                                    <span style={{ fontFamily: 'Inter-Regular,sans-serif' }}>
                                        {this.state.duplicatedIds}
                                    </span>
                                </Typography> 
                                <CardActions style={{ display: 'flex', gap: '26px' }}>
                                   
                                    <Button
                                        data-test-id='acceptBtn'
                                        onClick={() => this.handleError()}
                                        style={{
                                            width: '100px', height: '45px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px', background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>

                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
            </div>
        )
    }
}

const webStyle = { 
    tableHead: {
      backgroundColor:'#FFFFFF', 
    },
    tableCellRow: {
      color:'#0075BF',
      width:'fit-content',
      height:'24px',
      fontFamily:'Inter-Bold, sans-serif', 
      fontSize:'16px', 
      padding:'10px',
      borderBottom:"none"
    },
    tableCellBody: {
      color:'#21355F',
      height:'24px',
      fontFamily:'Inter-Regular, sans-serif', 
      fontSize:'16px', 
      padding:'10px',
      borderBottom:"none"
    },
    tableCellBodyPlaceOrderTestId: {
      color:'#21355F',
      height:'24px',
      fontFamily:'Inter-Bold, sans-serif', 
      fontSize:'16px', 
      padding:'10px',
      borderBottom:"none"
    },
    commonBtnWhite: {
        width: '120px', 
        height: '56px', 
        border: '1px solid #E1E1E1',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', 
        color: '#0075BF',
        borderRadius: '8px', 
        background: '#FFFFFF', 
        fontFamily: 'Inter-Bold,sans-serif',
        fontSize: '16px',
    }, 
    commonBtnBlue: {
        width: '120px', 
        height: '56px', 
        border: '1px solid #E1E1E1',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', 
        color: '#FFFFFF',
        borderRadius: '8px', 
        background: '#0075BF', 
        fontFamily: 'Inter-Bold, sans-serif',
        fontSize: '16px',
    },
    commonBtnPlaceOrder: {
        width: '161px', 
        height: '56px',
        fontSize: '16px',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', 
        borderRadius: '8px', 
        fontFamily: 'Inter-Bold, sans-serif'
    },
    breadcrumbsLink: {
        fontSize: "24px", 
        fontFamily: 'Inter-Regular, sans-serif',
        color: "#21355F"
      },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        width: "600px",
        backgroundColor: "#fff",
        padding: "18px 40px 24px 9px",
        borderRadius: "0px 8px 32px 8px"
    },
    modalBackButton: {
        display: "flex",
        width: "120px",
        height: "56px",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #E1E1E1",
        background: "#FFF",
        fontFamily:'Inter-Bold, sans-serif',
        fontSize: "16px",
        color: "#0075BF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "none" as const,
    },
    modalAcceptButton: {
        display: "flex",
        padding: "16px 40.5px",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: '56px',
        border: "none",
        backgroundColor: "#0075BF",
        borderRadius: '8px',
        color: "white",
        cursor: "pointer",
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        textTransform: "none" as const,
        fontFamily:'Inter-Bold, sans-serif',
    },
    modalTitle: {
        fontSize: "20px", 
        padding: "32px 10px",
        fontFamily: "Inter-Medium",
        color: "#21355F"
    },
    modalButtonsGroup: {
        display: "flex", 
        gap: 26, 
        paddingBottom: 24
    },
    clinicianButton: {
        height: "30px", 
        margin: "0 auto",
        width: "175px", 
        borderRadius: "8px", 
        textTransform: "none" as const,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "6px 10px",
        color: "#FFF",
        fontFamily: "Inter-Bold, sans-serif", 
        fontSize: "14px",
    },
}
