import React, { FunctionComponent, ReactNode } from "react";
import {
    Container,
    Box,
    Typography,
    InputLabel,
    Button,
    TextField,
    withStyles,
    createStyles,
    Modal
  } from "@material-ui/core";

// @ts-ignore
interface Props {
  title?: string;
  classes: Record<string, string>;
  hideAddAccountModal: () => void;
  hideDeleteAccountModal: () => void
  deleteGroup: (id:1) => void,
  deleteApiId: number; 
  addGroup:() => void;
  isOpen: boolean;
  type?: string;
  buttonDisable: boolean;
  nhsNumberValidation: any
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '599px',
    bgcolor: 'background.paper',
    padding: '18px 40px 24px 9px;',
    borderRadius: "0px 8px 32px 8px",
    minHeight: '224px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

const CommonModal: FunctionComponent<Props> = ({ addGroup, nhsNumberValidation, buttonDisable, deleteApiId, classes, deleteGroup,  hideDeleteAccountModal, isOpen, title, type="add" }) => {
  return (
   
    <Modal
    // @ts-ignore
      // open={isVisibleDeleteAccountModal}
      open={isOpen}
      onClose={hideDeleteAccountModal}
      data-test-id= "commonModal"
    >
       <Box sx={{ ...style }} >
          <Typography style={{fontSize: '20px',
            color: '#21355F', textAlign: 'center', marginTop:'50px'}}>
            {title}
          </Typography>
          <Box style={{marginTop: '5px'}}>
          {
            nhsNumberValidation && nhsNumberValidation.map((item : string) => {
              return <Box style={{ color: "#f94b4b",
              fontSize: "15px",
              fontWeight: 400}}>{item}</Box>
            })
          }
          </Box>
      
            
          <Box className={classes.buttonAlign}>
           
              <button onClick={hideDeleteAccountModal} className={classes.cancelStyle} data-test-id = "backBtn">Back</button>
              {
                type === "add" ? <button onClick={addGroup} disabled={buttonDisable} className={buttonDisable ? classes.disableButton :classes.acceptButton} data-test-id="createApiBtn" >Accept</button> :
                // @ts-ignore
                <button data-test-id="btndeleteApi" onClick={() => deleteGroup(deleteApiId?.id)}  className={classes.acceptButton}>Accept</button>

              }

          </Box>
      </Box>
    </Modal>
  );
};


const webStyle = () =>  createStyles({
 
    buttonAlign: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: "0px 120px",
        alignContent: 'baseline',
        marginTop: '30px'
      },
      cancelStyle: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#ffffff",
        borderRadius: '8px',
        color: "#0075BF",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      },
      acceptButton: {
        width: "120px",
        height: '56px',
        marginTop: "25px",
        border: "none",
        backgroundColor: "#0075BF",
        borderRadius: '8px',
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        },
        disableButton: {
          width: "120px",
          height: '56px',
          marginTop: "25px",
          border: "none",
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
          color: 'rgba(0, 0, 0, 0.26)',
          cursor: "pointer",
          fontWeight: 700,
          fontSize: '16px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        }
  });
  
  export default withStyles(webStyle)(CommonModal);