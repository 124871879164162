import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";
import {
  AppBar, 
  Toolbar, 
  Box, 
  MenuItem, 
  Menu, 
  IconButton, 
  Modal,
  Typography
} from '@material-ui/core';
import logo from "./image_logo.svg"
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import LogoutModal from './LogoutModal'

const CLINICIAN = "clinician" 

const TopNav = ({ history }) => {
  const[data, setData] = useState({})
  const [open, setOpen] = React.useState(false);
  const [isVisible, setIsVisible] = useState(false)
  const [isDropdownVisible, setisDropdownVisible] = useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [_, setValue] = useState(localStorage.getItem("dropdownValue"));
  const userType = JSON.parse(localStorage.getItem("userType"))
  const isGuidePage = new URL(window.location.href).pathname === '/Guide'
  const currentPath = new URL(window.location.href).pathname
  const [potentialPath, setPotentialPath] = useState("")
  const isProtectedPath = currentPath === '/OrderTest'
  const logoClickPath = userType === CLINICIAN ? '/Patient' : '/Clinicians'
  const isClinician = JSON.parse(localStorage.getItem("userType")) === CLINICIAN
  const modalTitle = (isClinician && localStorage.getItem('dropdownValue') === "") ? 
        "Please select an institution" : "You're leaving this page. Changes will not be saved"
  const clinicianWithoutHospital = isClinician && localStorage.getItem('dropdownValue') === "" 

  useEffect(() => {
    const result = localStorage.getItem('data')
    const parseData = JSON.parse(result)
    setData(parseData?.data)
  }, [])

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear()
    history.push('/Login')
  }

  const handleContinue = () => {
    setIsVisible(false)
    history.push(potentialPath)
  }

  const handleNavigationWay= (path) => {
    let clinicianRedirect = (path === logoClickPath) && clinicianWithoutHospital
    let discardRedirect = isProtectedPath && JSON.parse(localStorage.getItem('isDataSelected'))
    
    if(clinicianRedirect || discardRedirect){
      setPotentialPath(path);
      setIsVisible(true);
    } else {
      history.push(path);
    }
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelectChangePasswdClick = () => {
    history.push('/Settings2')
    setAnchorElUser(null)
  }

  const handleSelectLogoutClick = () => {
    setOpen(true)
    setAnchorElUser(null)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setValue(localStorage.getItem('dropdownValue') || 'Initial Data');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <View style={{...styles.main, display: isGuidePage ? "none" : undefined}}>
    <AppBar component="nav" style={styles.nav}>
      <Toolbar>
        <IconButton
          onClick={() => handleNavigationWay(logoClickPath)}
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
            <img src={logo} />
        </IconButton>
        {
           new URL(window.location.href).pathname !== '/SelectInstitute' ? <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            onClick={() => handleNavigationWay("Guide")}
               style={{backgroundColor:'white', color: 'rgba(33, 53, 95, 1)', fontFamily: 'sans-serif', fontWeight: 700, marginLeft: 5, cursor: "pointer" }}
           >
               Guide
           </Typography>
              <Typography
              onClick={() => handleNavigationWay("ContactUs")}
              style={{backgroundColor:'white', color: 'rgba(33, 53, 95, 1)', fontFamily: 'sans-serif', cursor: "pointer", fontWeight: 700, marginLeft: 20 }}
          >
              Contact Revue
          </Typography>
        </Box> :  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
                  <Box onClick={() => handleNavigationWay("ContactUs")}
                      sx={{ color: 'rgba(33, 53, 95, 1)', cursor: "pointer", fontFamily: 'sans-serif', fontWeight: 700, ml: 5 }}
                  >
                     Contact Revue
                  </Box>
                  <Box
                  onClick={() => handleNavigationWayWay("Guide")}
                  sx={{ color: 'rgba(33, 53, 95, 1)', fontFamily: 'sans-serif', cursor: "pointer", fontWeight: 700, ml: 5 }}
              >
                  Step by Step Guide
              </Box>
          </Box>
        }
          
          
         
          {
            new URL(window.location.href).pathname !== '/SelectInstitute' && <Box style={{display: 'flex', justifyContent: 'right', width: '100%'}}>
            <IconButton  style={styles.menuDropdown}
            onClick={handleOpenUserMenu}>
            <PersonOutlinedIcon />&nbsp;
            {data?.attributes?.institute_information?.institute_name ? <>{data?.attributes?.institute_information?.institute_name}</> : <>{data?.attributes?.common_information?.first_name} {data?.attributes?.common_information?.last_name}</>}
            </IconButton>
          <Menu
            
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
             <MenuItem onClick={handleSelectChangePasswdClick} value="">Change Password</MenuItem>
          <MenuItem onClick={handleSelectLogoutClick} value="">Logout</MenuItem>
          </Menu>
        
      </Box>
    }
          
      </Toolbar>
        </AppBar>
       
    
    <LogoutModal open={open} handleCloseModal={handleCloseModal} handleLogout={handleLogout} />
    <Modal open={isVisible} onClose={() => setIsVisible(false)}>
      <Box sx={styles.modal}>
        <Typography style={styles.modalTitle}>
          {modalTitle}
        </Typography>
        <Box style={styles.modalButtonsGroup}>
          {!clinicianWithoutHospital &&  
            <button onClick={() => setIsVisible(false)} style={styles.modalBackButton}>
              Back
            </button>
          }
          <button 
            onClick={clinicianWithoutHospital ? () => setIsVisible(false) : handleContinue} 
            style={styles.modalAcceptButton}>
            {clinicianWithoutHospital ? "Ok" : "Continue"} 
          </button>
        </Box>
      </Box>
    </Modal>
  </View>
  );
};


const styles = StyleSheet.create({
  nav: {
    backgroundColor: '#fff',
    position: 'relative'
  },
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc",
  },
  menuDropdown: {
    color: 'rgba(33, 53, 95, 1)', 
    fontWeight: 700, 
    fontSize: '16px', 
    display: 'flex', 
    alignItems: 'center',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    width: "600px",
    backgroundColor: "#fff",
    paddingTop: "18px",
    paddingRight: "40px",
    paddingBottom: "24px",
    paddingLeft: "9px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "32px",
    borderBottomLeftRadius: "8px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalBackButton: {
    display: "flex",
    width: "120px",
    height: "56px",
    paddingTop: "16px",
    paddingRight: "40.5px",
    paddingBottom: "16px",
    paddingLeft: "40.5px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid #E1E1E1",
    backgroundColor: "#FFF",
    fontFamily:'Inter-Bold,sans-serif',
    fontSize: "16px",
    fontWeight: 700,
    color: "#0075BF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    cursor: "pointer",
  },
  modalAcceptButton: {
    display: "flex",
    paddingTop: "16px",
    paddingRight: "40.5px",
    paddingBottom: "16px",
    paddingLeft: "40.5px",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: '56px',
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    textTransform: "none",
  },
  modalTitle: {
    fontWeight: 500, 
    fontSize: "20px", 
    paddingTop: "32px",
    paddingRight: "25px",
    paddingBottom: "32px",
    paddingLeft: "25px",
    fontFamily: "Inter-Medium",
    color: "#21355F"
  },
  modalButtonsGroup: {
    display: "flex", 
    gap: 26, 
    paddingBottom: 24
  },
});

export default withRouter(TopNav);
