import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";

// Customizable Area End

import NavigationMenuController, {
  configJSON,
  Props,
} from "./NavigationMenuController";

export default class ContactUs extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  

  render() {
    // Customizable Area Start
    const { email, institutionName, fullName, isDisabled, successMessage } = this.state
    const isClinician = this.state.userType === 'clinician'

    return (
      <Container style={webStyles.mainContainer} maxWidth="xl">
        {this.state.isLoader && this.showLoader()}
        <Container style={webStyles.formWrapper} maxWidth="lg">
          <Formik
            enableReinitialize
            data-test-id="createClinicianForm"
            initialValues={{
              clinicianName: fullName,
              email: email,
              institution: institutionName,
              message: '',
            }}
            validationSchema={Yup.object().shape({
              message: Yup.string().required("This field is required"),
              institution: Yup.string().required("This field is required"),
            })}
            onSubmit={(values, actions) => {
              // @ts-ignore
              actions.resetForm({ message: '' }); 
              this.sendMessage();
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Typography style={webStyles.title}>Contact Revue</Typography>
                {isClinician ? (
                  <>
                    <Box style={{ marginBottom: 20 }}>
                      <InputLabel style={webStyles.label}>Clinician Name</InputLabel>
                      <TextField
                        data-test-id="clinicianName"
                        name="clinicianName"
                        id="clinicianName"
                        InputProps={{
                          disableUnderline: true,
                          style: { color: "#0F172A" },
                        }}
                        style={{
                          ...webStyles.inputField,
                          backgroundColor: "#EAE8E8",
                        }}
                        value={props.values.clinicianName}
                        fullWidth
                        type="text"
                        disabled
                      />
                    </Box>
                    <Box>
                      <InputLabel style={webStyles.label}>Email address</InputLabel>
                      <TextField
                        data-test-id="email"
                        name="email"
                        id="email"
                        InputProps={{
                          disableUnderline: true,
                          style: { color: "#0F172A" },
                        }}
                        style={{
                          ...webStyles.inputField,
                          backgroundColor: "#EAE8E8",
                        }}
                        value={props.values.email}
                        fullWidth
                        type="text"
                        disabled
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 17,
                        marginBottom: 20,
                      }}
                    >
                      <InputLabel style={webStyles.label}>Institution</InputLabel>
                      <Field
                        as={TextField}
                        data-test-id="institution"
                        name="institution"
                        id="institution"
                        InputProps={{
                          disableUnderline: true,
                          style: { color: "#0F172A" },
                          readOnly: true,
                        }}
                        style={{
                          ...webStyles.inputField,
                          border: this.getBorder(props.errors.institution),
                          backgroundColor: "#EAE8E8",
                        }}
                        value={props.values.institution}
                        fullWidth
                        type="text"
                        error={Boolean(props.errors.institution)}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "institution"
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={{ marginTop: 17, marginBottom: 20 }}>
                      <InputLabel style={webStyles.label}>Institution Name</InputLabel>
                      <TextField
                        data-test-id="institution"
                        name="institution"
                        id="institution"
                        InputProps={{
                          disableUnderline: true,
                          style: { color: "#0F172A" },
                        }}
                        style={{
                          ...webStyles.inputField,
                          backgroundColor: "#EAE8E8",
                          color: "red",
                        }}
                        value={props.values.institution}
                        fullWidth
                        type="text"
                      />
                    </Box>
                    <Box>
                      <InputLabel style={webStyles.label}>Email address</InputLabel>
                      <TextField
                        data-test-id="email"
                        name="email"
                        id="email"
                        InputProps={{
                          disableUnderline: true,
                          style: { color: "#0F172A" },
                          readOnly: true,
                        }}
                        style={{
                          ...webStyles.inputField,
                          backgroundColor: "#EAE8E8",
                        }}
                        value={props.values.email}
                        fullWidth
                        type="text"
                      />
                    </Box>
                  </>
                )}
                <Box
                  style={{
                    marginTop: 17,
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <InputLabel style={webStyles.label}>Message</InputLabel>
                  <Field
                    as={TextField}
                    data-test-id="message"
                    name="message"
                    id="message"
                    style={webStyles.textarea}
                    value={props.values.message}
                    fullWidth
                    type="text"
                    placeholder="Your message"
                    multiline
                    minRows={10}
                    variant="outlined"
                    onChange={(event: any) => {
                      props.setFieldValue("message", event.target.value);
                      this.onValueChange("message", event.target.value);
                    }}
                    error={Boolean(props.errors.message)}
                  />
                  {this.getErrorMessage(props.touched, props.errors, "message")}
                  <Typography
                    style={{
                      display: "flex",
                      color: "#22bb33",
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    {successMessage}
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  data-test-id="sendMessage"
                  style={webStyles.button}
                  disabled={isDisabled}
                >
                  {configJSON.sendMessage}
                </Button>
              </form>
            )}
          </Formik>
        </Container>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    margin: "30px", 
    width: "100%", 
    paddingBottom: "55px", 
    overflow: "auto"
  },
  formWrapper: {
    display: "flex", 
    justifyContent: "center",

    overflow:'auto', 
  },
  label: {
    fontSize: "14px",
    marginTop: "8px",
    color: "#21355F",
    marginBottom :"10px",
    fontFamily:'Inter-Bold, sans-serif', 
  }, 
  inputField: {
    border: "1px solid rgba(203, 213, 225, 1)",
    padding: "10px 10px",
    borderRadius: "8px",
    width: "500px",
    color: "#21355F",
    fontFamily:'Inter-Bold, sans-serif',
    background: "none",
    "&:before" :{
      border: "none !important" 
    },
    "&:after" :{
      border: "none" 
    },
    "&:hover" :{
      border: "2px solid #0075BF"
    },
  },
  textarea: {
    width: "522px",
    color: "#21355F",
  },
  button: {
    height: "44px",
    width: "200px",
    border: "none",
    background: "#0075BF",
    cursor: "pointer",
    marginTop: "0px",
    borderRadius: "8px",
    color: "fff",
    textTransform: "none" as const,
    fontFamily:'Inter-Bold, sans-serif', 
  },
  title: {
    fontFamily:'Inter-Bold,sans-serif', 
    color: "#21355F", 
    fontSize: "24px", 
    lineHeight: "40px", 
    paddingBottom: "30px"
  }
};
// Customizable Area End
