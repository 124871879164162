import React from "react";

// Customizable Area Start
import Backdrop from '@material-ui/core/Backdrop';
import SearchIcon from '@material-ui/icons/Search';
import {
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Edit, DeleteOutlineOutlined, GetApp, AddCircleOutline, NavigateNext, VisibilityOutlined, MoreVert } from '@material-ui/icons';
import dayjs from 'dayjs';
import ErrorModal from "./ErrorModal";
import CommonCustomModal from "./CommonCustomModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import PatientController, {
  configJSON,
  Props,
} from "./PatientController";


export default class PatientView extends PatientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {viewSpecificList, editQueryId} = this.state
    const dataSource = this.getFilterSource()
    const breadcrumbs = [
      <Typography 
        onClick={this.redirectPatientPage} 
        data-test-id="redirectPatientBtn"
        style={webStyles.breadcrumbsLink} 
        key="1" >
        Patients
      </Typography>,
      <Typography 
        style={{...webStyles.breadcrumbsLink, fontFamily: 'Inter-Bold, sans-serif'}} 
        key="2">
        {viewSpecificList?.first_name} {viewSpecificList?.last_name}
      </Typography>
    ];
    
    return (
      <ThemeProvider theme={theme}>
        <Container style={{maxWidth:'100%',overflow:'auto', paddingBottom:'80px', marginBottom:'50px'}} >
        {this.state.isLoader && this.showLoader()}
        <Breadcrumbs
          style={{marginTop: '20px'}}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
         <Box style={{
          display: 'flex',
          width: '100%',
          alignItems: 'baseline',
          marginBottom: '30px'
         }}>
          <TextField
            placeholder="Search"
            data-test-id={"passwordConfirmation"}
            name="passwordConfirmation"
            style={webStyles.inputField}
            onChange={
              /* istanbul ignore next */
              (event) => { this.searchPatient2(event) }}
            InputProps={{
              disableUnderline:true,
              startAdornment: (
                <InputAdornment position="end">
                <IconButton
                  style={{fontSize: '16px'}}
                  edge="end"
                  aria-label="toggle password visibility"
                  >
            <SearchIcon style={{color: 'rgba(148, 163, 184, 1)', marginRight: '5px'}} />
                </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth={true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            data-test-id="orderTestBtn"
            onClick={
              () => this.orderTest(editQueryId, `${viewSpecificList?.first_name} ${viewSpecificList?.last_name}`)}
            style={webStyles.buttonStyle}>
              <AddCircleOutline style={{marginRight: "10px"}} />
            Order Test
           </Button>
          </Box>
          <Card style={{ display: 'flex', borderLeft: '4px solid rgba(231, 32, 93, 1)', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', padding: '15px 12px 15px 5px', borderRadius: '6px' }}>
            <Box sx={{ display: 'flex', alignItems: "center", width: '25%', flexDirection: 'column', borderRight: '2px solid rgba(33, 53, 95, 1)' }}>
              <CardContent>
                <Typography style={{fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px'}} >
                  {viewSpecificList?.first_name} {viewSpecificList?.last_name}
                </Typography>
              </CardContent>
            </Box>
            <Box style={{width: '25%',borderRight: '2px solid rgba(33, 53, 95, 1)'}}>
              <CardContent style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                <Typography style={{fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px'}} >
                  Sex at Birth
                </Typography>
                <Typography style={{fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px'}}>
                  {viewSpecificList?.gender} 
                </Typography>
              </CardContent>
            </Box>
            <Box style={{width: '25%',borderRight: '2px solid rgba(33, 53, 95, 1)'}}>
              <CardContent style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                <Typography style={{fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px'}} >
                    Date at Birth
                </Typography>
                <Typography style={{fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px'}}>
                  {dayjs(viewSpecificList?.dob).format('DD/MM/YYYY')}
                </Typography>
              </CardContent>
            </Box>
            <Box style={{width: '25%'}}>
              <CardContent style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                <Typography style={{fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px'}} >
                    Address
                </Typography>
                <Typography style={{fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px', textAlign: "center"}}>
                  {`${viewSpecificList?.address}, ${viewSpecificList?.city}, ${viewSpecificList?.zip_code}`}
                </Typography>
              </CardContent>
            </Box>
            <Box>
              <CardContent>
                <Select
                    labelId="dropdown-label"
                    id="dropdown"
                    disableUnderline={true}
                    IconComponent={MoreVert}>
                    <MenuItem value="" style={{fontSize: '14px'}} 
                      data-test-id="handleEditPatient"
                      onClick={() => this.editPatientScreen(Number(editQueryId))}>
                      <Edit style={{fontSize: '16px', marginRight: '15px'}}/>
                      Edit Patient
                    </MenuItem>
                </Select>
              </CardContent>
            </Box>
         </Card>
          <TableContainer style={webStyles.tableContainerStyle}>
            <Table stickyHeader>
              <TableHead >
                <TableRow >
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.sampleType}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.dateOfTest}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.dateLabReceived}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.requested}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.department}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.markers}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.status}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}>{configJSON.priority}</TableCell>
                  <TableCell style={webStyles.tableCellStyles}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {dataSource?.map((test: any) => {
                 /* istanbul ignore next */
                return(
                  <TableRow key={test.id}>
                    <TableCell data-test-id="row1"  onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>{test.attributes.sample_type}</TableCell>
                    <TableCell data-test-id="row2"  onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {dayjs(test.attributes?.test_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell data-test-id="row3" onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {test.attributes.date_received ? dayjs(test.attributes.date_received).format('DD/MM/YYYY') : "-"}
                    </TableCell>
                    <TableCell data-test-id="row4"  onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {test.attributes.clinician?.first_name} {" "}
                      {test.attributes.clinician?.last_name}
                    </TableCell>
                    <TableCell  data-test-id="row5" onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {test.attributes.department}
                    </TableCell>
                    <TableCell data-test-id="row6" onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {test.attributes.marker_count}
                    </TableCell>
                    <TableCell data-test-id="row7" onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      {test.attributes.status}
                    </TableCell>
                    <TableCell data-test-id="row8" onClick={() => { this.handleRow(test.attributes, test.id) }} style={webStyles.testsDataStyles}>
                      <Typography 
                        style={test.attributes.priority?.toLowerCase() === "urgent" ? 
                              {...webStyles.urgent ,position:'relative',left:'10px'}: {...webStyles.testsDataStyles, borderBottom: "none",position:'relative',left:'10px'}} >
                        {test.attributes?.priority?test.attributes?.priority:'-'}
                      </Typography>
                    </TableCell>
                    <TableCell style={webStyles.testsDataStyles} align="right">
                      <Select
                        labelId="dropdown-label"
                        id="dropdown"
                        disableUnderline={true}
                        IconComponent={MoreVert}
                        disabled={test.attributes.status?.toLowerCase() === "cancelled"}>
                        {test.attributes.status?.toLowerCase() === "requested" ? (
                          [
                            <MenuItem
                              key="editTestButton"
                              data-test-id="editTestButton"
                              value=""
                              style={{ fontSize: '14px' }}
                              onClick={() => this.editPatientTest(test.id)}>
                              <Edit style={{ fontSize: '16px', marginRight: '15px' }} />
                              Edit Test
                            </MenuItem>,
                            <MenuItem
                              key="cancelTestButton"
                              data-test-id="cancelTest"
                              value=""
                              style={{ fontSize: '14px' }}
                              onClick={() => this.openCancelTestModal(test.id)}>
                              <DeleteOutlineOutlined style={{ fontSize: '16px', marginRight: '15px' }} />
                              Cancel Test
                            </MenuItem>
                          ]
                        ) : (
                          [
                            <MenuItem
                              data-test-id="viewResultTest"
                              key="viewResultsButton"
                              value=""
                              style={{ fontSize: '14px' }}
                              onClick={() => this.handleNavigateToResultTest(test.id)}>
                              <VisibilityOutlined style={{ fontSize: '16px', marginRight: '15px' }} />
                              View Results
                            </MenuItem>,
                            <MenuItem
                              data-test-id="downloadResultTest"
                              key="downloadResultsButton"
                              value=""
                              style={{ fontSize: '14px' }}
                              onClick={() => this.handleDownloadTest(test.attributes.result_pdf)}>
                              <GetApp style={{ fontSize: '16px', marginRight: '15px' }} />
                              Download Results
                            </MenuItem>
                          ]
                        )}
                      </Select>
                    </TableCell>
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
          </TableContainer>
          <CommonCustomModal
            isVisible={this.state.isVisiblePopUp}
            onClose={this.onCloseModal}
            title= {configJSON.cancelTestTitle}
            onCancel={this.onCloseModal}
            onAccept={() => this.cancelPatientTest(this.state.canceledTestId)}
            disabled={this.state.isDisabledAccept}
          />
          <ErrorModal
            isVisible={this.state.isVisibleErrorModal}
            onAccept={this.handleVisibleErrorModal}
            message={this.state.errorMessageText}
            data-test-id="errorModal"
          />
        </Container>
        <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popupcancel} >
        {
                        this.state.popupcancel && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',
                                    borderRadius: '0px 8px 33px 8px',
                                    padding: '30px 30px 24px 30px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Bold, sans-serif' }}>
                                        Test has been cancelled
                                    </span>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopup()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
        </Backdrop>
        <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popupview} >
                    {
                        this.state.popupview && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',minHeight:'auto',maxHeight:'481px',
                                    padding: '30px 30px 24px 14px',overflow:'auto',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{ minWidth:'340px',
                                        width: 'min-content',color: '#21355F', height: 'fit-content',
                                        fontSize: '20px', display:'flex',flexDirection:'column'
                                    }}>
                                    {
                                    <span style={{minWidth:'230px', paddingBottom: '15px',fontSize:'22px', fontFamily: 'Inter-Bold, sans-serif', textAlign: 'center', }}>
                                        Awaiting for results for below
                                    </span>
                                    }
                                    
                                    {this.state.dataHandle?.marker_text && <span style={ {textAlign:'center',paddingBottom:'15px',fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Markers'}
                                    </span>}
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${Math.min(Math.max(Math.ceil(this.state.dataHandle?.marker_text?.split(',').length / 2), 2), 3)}, 1fr)`, 
                                            gridAutoRows: 'auto',
                                            gap: '20px',
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'left',
                                            columnGap: '30px',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {this.state.dataHandle?.marker_text?.split(',').map((marker: any, index: any) => (
                                            <span
                                                style={{
                                                    width: 'fit-content',
                                                     paddingLeft: '15px',
                                                    paddingRight: '10px',
                                                    paddingBottom: '3px',
                                                }}
                                                key={index}
                                            >
                                                {marker.trim()}
                                            </span>
                                        ))}
                                    </div>
                                    {this.state.dataHandle?.panel_text && <span style={{ fontSize:'22px',textAlign: 'center', paddingBottom: '15px', fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Panel'}
                                    </span>}
                                    <div
                                        style={{
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'center',
                                        }}>
                                        {this.state.dataHandle?.panel_text?.split(',').map((panel: any, index: any) => (
                                             <span style={{width:'max-content',paddingLeft:'15px', paddingRight: '10px',paddingBottom:'3px' }} key={index}>{panel.trim()}</span>
                                        ))}
                                    </div>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopupView()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
            </Backdrop>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  inputField: {
    padding: '5px 0px 5px 0px',
    borderRadius: '98px',
    border: '1px solid rgba(33, 53, 95, 1)',
    background: 'none',
    '&:before' :{
      border: 'none !important' 
    },
    '&:after' :{
      border: 'none' 
    },
    '&:hover' :{
      border: '2px solid #0075BF'
    }
  },
  tableContainerStyle: {
    borderRadius:'8px 8px 0px 0px',
    width: "100%",
    overflow: "hidden",
    marginBottom: "50px"
  },
  tableCellStyles: {
    backgroundColor: "#21355F", 
    color: "fff", 
    fontSize: "14px", 
    lineHeight: "22px",  
    fontFamily:'Inter-Bold, sans-serif'
  },
  testsDataStyles: {
    cursor: "pointer",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily:'Inter-Regular, sans-serif',
    lineHeight: "22px",
    color: "#21355F",
    borderBottom: '1px solid #0075BF'
  },
  urgent: {
    width: "66px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "2px 8px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily:'Inter-Bold, sans-serif',
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    borderRadius: "40px"
  },
  buttonStyle: {
    height: "44px",
    borderRadius: '8px',
    width: "180px",
    border: "none",
    textTransform: 'none' as const,
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    marginTop: "25px",
    color: "white",
    fontFamily:'Inter-Bold, sans-serif',
  },
  breadcrumbsLink: {
    fontSize: "24px", 
    fontFamily: "Inter-Regular, sans-serif",
    color: "#21355F"
  },
}
// Customizable Area End
