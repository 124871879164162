import React from "react"

// Customizable Area Start
import { Container,Box, Typography, CircularProgress } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { logo } from "./assets"


// Customizable Area End

import NavigationMenuController, {
  configJSON,
  Props,
} from "./NavigationMenuController"

export default class Guide extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  

  render() {
    // Customizable Area Start

    return (
      <Container style={webStyle.container as React.CSSProperties} maxWidth="xl">
        <Box sx={webStyle.wrapper}>
          <Box style={webStyle.content}>
            <Box style={webStyle.headerBlock} >
              <Box 
                style={{display: "flex", alignItems: "center"}}
                onClick={() => { this.props.navigation.goBack() }}
                data-test-id= "goBack">
                <ArrowBack style={webStyle.arrow}  />
              </Box>
              <Box style={webStyle.logo}>
                <img src={logo}/>
              </Box>
              <Box style={{width: 40, height: 25}} /> 
            </Box>
            {this.state.dataFetching ? 
            <Box style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
              <CircularProgress />
            </Box> : 
            <Box style={webStyle.guide}>
              <Typography style={webStyle.title}>
                {configJSON.title}
              </Typography>
              <div dangerouslySetInnerHTML={{__html: this.state.guide?.description}}></div>
            </Box>}
          </Box>
        </Box>
      </Container>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    padding: 0, 
    margin: 0, 
    width: "100%", 
    position: "absolute", 
    top: 0, 
    bottom: 0, 
    left: 0, 
    right: 0
  },
  wrapper: {
    background: "linear-gradient(249deg, #FFF 0%, #0075BF 47.6%, #21355F 100%);",
    display: 'flex',
    justifyContent: 'center',
    placeItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    width: '100%',
    position:'relative',
  },
  content: {
    width: "70%",
    height: "80vh",
    background: "#fff",

    borderRadius: "38px",
    padding: "24px",
    border: "none",
  },
  title: {
    fontSize: '24px',
    fontFamily: "Inter-Bold, sans-serif",
    color: 'rgba(33, 53, 95, 1)',
    lineHeight: "32px",
    marginLeft: '15px',
    textAlign: "center" as const,
    fontWeight: 700,
    margin: "5px 0"
  },
  arrow: {
    width: 40, 
    height: 25, 
    cursor: "pointer"
  },
  headerBlock: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between"
  },
  logo: {
    display: "flex", 
    justifyContent: "center", 
    flex: 1,
    marginBottom: "10px",
  },
  guide: {
    overflow:'auto',
    height: "70vh"
  }
}
// Customizable Area End
