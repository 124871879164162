import React from "react";
import { View } from "react-native";
import { Typography} from '@material-ui/core';


const Footer = () => {
  return (
    <View style={{marginTop:"auto", bottom: '10px'}}>
        <Typography
          variant="h6"
          style={{textAlign: 'center',
            fontSize: '14px',
            fontWeight: 400, 
            marginTop: '20px', color:'#fff', 
        }}
        > 
        @ Copyright REVUE 2024 all rights reserved
        </Typography>
    </View>
   
  );
};


export default Footer;
