Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.editDropdownEndPoint="bx_block_advanced_search/advance_search?type=clinician";
exports.editDropdownEndPoint2="bx_block_advanced_search/advance_search?type=patient";
exports.editDropdownEnd="bx_block_advanced_search/advance_search";
exports.apiContentType = "application/json";
exports.filterPoint2="bx_block_advanced_search/detail_page_search?page=clinician"
exports.filterPoint="bx_block_advanced_search/detail_page_search?page=patient"
exports.autoCompleteClinicianSearch="bx_block_advanced_search/autocomplete?type=clinician"
exports.getApiMethod = "GET";
exports.autoCompleteSearch="bx_block_advanced_search/autocomplete?type=patient"
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.patchApiMethod = "PATCH";
exports.deleteApiMethod = "DELETE";

exports.groupsAddApiEndPoint = "bx_block_custom_forms/custom_forms";
exports.groupsApiEndPoint = "account_groups/groups";
exports.fetchCliniciansApiEndPoint = "account_block/accounts/clinician_listings";
exports.getClinicianApiEndPoint = "account_block/accounts/show_clinician"
exports.createClinicianApiEndPoint = "account_block/accounts";
exports.destroyClinicianApiEndPoint = "account_block/accounts/destroy_clinician";
exports.editClinicianApiEndPoint = "account_block/accounts/update_clinician"
exports.fetchRolesApiEndPoint = 'bx_block_roles_permissions/roles'
exports.fetchClinicianTestsEndPoint = 'bx_block_custom_form/test_orders'
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.getPatientApiEndPoint = "bx_block_custom_forms/custom_forms";
exports.viewSpecificPatientApiEndPoint = "bx_block_custom_forms/custom_forms";
exports.deleteApiEndPoint = "bx_block_custom_forms/custom_forms"
exports.getInstitutionDataApiEndPoint = "bx_block_custom_forms/custom_forms"
exports.resendInvitationApiEndPoint = "account_block/resend_invite_email"
exports.checkNHSnumberApiEndPoint = "bx_block_custom_forms/validate_nhs_number"
exports.checkmobilenumber="bx_block_custom_forms/check_phone_number?phone_number="
exports.cancelTestApiEndPoint = "bx_block_custom_form/cancel_test"

exports.editApiEndPoint = "bx_block_custom_forms/custom_forms"
exports.sampleType = "Sample Type"
exports.dateOfTest = "Date of Test"
exports.dateLabReceived = "Date lab Received"
exports.requested = "Cinican Requested"
exports.department = "Department"
exports.markers = "Markers"
exports.status = "Status"
exports.priority = "Priority"
exports.cancelTestTitle = "Are you sure you want to cancel this Test?"
exports.infoNHSNumber = "An NHS Number must be 10 digits long and consist only of numbers which should not start with 0"

exports.searchArea="bx_block_advanced_search/advance_search"
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";
exports.textNhsNumber = "NHS Number"
exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textDateOfBirth = "DOB";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textSexAtBirth = "Sex At Birth";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.orderTest = "Order Test";
exports.department = "Department";
exports.role = "Role";
exports.email = "Email";
exports.editClinician = "Edit Clinician";
exports.viewResults= "View Results";
exports.downloadResults = "Download Results";
exports.testsRequested = "Tests Requested";
exports.addClinician = "Add Clinician";
exports.clinicians = "Clinicians";
exports.viewClinician = "View Clinician";
exports.resendInvitation = "Resend Invitation";
exports.editClinician = "Edit Clinician";
exports.deleteClinician = "Delete Clinician"
exports.tableHead = ["Name", "Department", "Role", "Email", "Last activity", "Institution ID", ""];
exports.clinicianProfileTableHead = [
  "Patient Name", 
  "Sample Type", 
  "Date of Test", 
  "Date Lab Received",
  "Department", 
  "Markers",
  "Status",
  "Priority",
  ""
];
exports.textModalButtonBack = "Back";
exports.textModalButtonAccept = "Accept";
exports.subtitleEditClinicianModal = "Are you sure you want to save changes on the Patient: ";
exports.subtitleAddClinicianModal = "Are you sure you want to create ";

exports.countries = [
  { "name": "Afghanistan" },
  { "name": "Åland Islands" },
  { "name": "Albania" },
  { "name": "Algeria" },
  { "name": "American Samoa" },
  { "name": "Andorra" },
  { "name": "Angola" },
  { "name": "Anguilla" },
  { "name": "Antarctica" },
  { "name": "Antigua and Barbuda" },
  { "name": "Argentina" },
  { "name": "Armenia" },
  { "name": "Aruba" },
  { "name": "Australia" },
  { "name": "Austria" },
  { "name": "Azerbaijan" },
  { "name": "Bahamas" },
  { "name": "Bahrain" },
  { "name": "Bangladesh" },
  { "name": "Barbados" },
  { "name": "Belarus" },
  { "name": "Belgium" },
  { "name": "Belize" },
  { "name": "Benin" },
  { "name": "Bermuda" },
  { "name": "Bhutan" },
  { "name": "Bolivia" },
  { "name": "Bosnia and Herzegovina" },
  { "name": "Botswana" },
  { "name": "Bouvet Island" },
  { "name": "Brazil" },
  { "name": "British Indian Ocean Territory" },
  { "name": "Brunei Darussalam" },
  { "name": "Bulgaria" },
  { "name": "Burkina Faso" },
  { "name": "Burundi" },
  { "name": "Cambodia" },
  { "name": "Cameroon" },
  { "name": "Canada" },
  { "name": "Cape Verde" },
  { "name": "Cayman Islands" },
  { "name": "Central African Republic" },
  { "name": "Chad" },
  { "name": "Chile" },
  { "name": "China" },
  { "name": "Christmas Island" },
  { "name": "Cocos (Keeling) Islands" },
  { "name": "Colombia" },
  { "name": "Comoros" },
  { "name": "Congo" },
  { "name": "Congo, The Democratic Republic of the" },
  { "name": "Cook Islands" },
  { "name": "Costa Rica" },
  { "name": "Croatia" },
  { "name": "Cuba" },
  { "name": "Cyprus" },
  { "name": "Czech Republic" },
  { "name": "Denmark" },
  { "name": "Djibouti" },
  { "name": "Dominica" },
  { "name": "Dominican Republic" },
  { "name": "Ecuador" },
  { "name": "Egypt" },
  { "name": "El Salvador" },
  { "name": "Equatorial Guinea" },
  { "name": "Eritrea" },
  { "name": "Estonia" },
  { "name": "Ethiopia" },
  { "name": "Falkland Islands (Malvinas)" },
  { "name": "Faroe Islands" },
  { "name": "Fiji" },
  { "name": "Finland" },
  { "name": "France" },
  { "name": "French Guiana" },
  { "name": "French Polynesia" },
  { "name": "French Southern Territories" },
  { "name": "Gabon" },
  { "name": "Gambia" },
  { "name": "Georgia" },
  { "name": "Germany" },
  { "name": "Ghana" },
  { "name": "Gibraltar" },
  { "name": "Greece" },
  { "name": "Greenland" },
  { "name": "Grenada" },
  { "name": "Guadeloupe" },
  { "name": "Guam" },
  { "name": "Guatemala" },
  { "name": "Guernsey" },
  { "name": "Guinea" },
  { "name": "Guinea-Bissau" },
  { "name": "Guyana" },
  { "name": "Haiti" },
  { "name": "Heard Island and Mcdonald Islands" },
  { "name": "Holy See (Vatican City State)" },
  { "name": "Honduras" },
  { "name": "Hong Kong" },
  { "name": "Hungary" },
  { "name": "Iceland" },
  { "name": "India" },
  { "name": "Indonesia" },
  { "name": "Iran, Islamic Republic Of" },
  { "name": "Iraq" },
  { "name": "Ireland" },
  { "name": "Isle of Man" },
  { "name": "Italy" },
  { "name": "Jamaica" },
  { "name": "Japan" },
  { "name": "Jersey" },
  { "name": "Jordan" },
  { "name": "Kazakhstan" },
  { "name": "Kenya" },
  { "name": "Kiribati" },
  { "name": "Korea, Republic of" },
  { "name": "Kuwait" },
  { "name": "Kyrgyzstan" },
  { "name": "Latvia" },
  { "name": "Lebanon" },
  { "name": "Lesotho" },
  { "name": "Liberia" },
  { "name": "Libyan Arab Jamahiriya" },
  { "name": "Liechtenstein" },
  { "name": "Lithuania" },
  { "name": "Luxembourg" },
  { "name": "Macao" },
  { "name": "North Macedonia" },
  { "name": "Madagascar" },
  { "name": "Malawi" },
  { "name": "Malaysia" },
  { "name": "Maldives" },
  { "name": "Mali" },
  { "name": "Malta" },
  { "name": "Marshall Islands" },
  { "name": "Martinique" },
  { "name": "Mauritania" },
  { "name": "Mauritius" },
  { "name": "Mayotte" },
  { "name": "Mexico" },
  { "name": "Micronesia, Federated States of" },
  { "name": "Moldova, Republic of" },
  { "name": "Monaco" },
  { "name": "Mongolia" },
  { "name": "Montserrat" },
  { "name": "Morocco" },
  { "name": "Mozambique" },
  { "name": "Myanmar" },
  { "name": "Namibia" },
  { "name": "Nauru" },
  { "name": "Nepal" },
  { "name": "Netherlands" },
  { "name": "Netherlands Antilles" },
  { "name": "New Caledonia" },
  { "name": "New Zealand" },
  { "name": "Nicaragua" },
  { "name": "Niger" },
  { "name": "Nigeria" },
  { "name": "Niue" },
  { "name": "Norfolk Island" },
  { "name": "Northern Mariana Islands" },
  { "name": "Norway" },
  { "name": "Oman" },
  { "name": "Pakistan" },
  { "name": "Palau" },
  { "name": "Palestinian Territory, Occupied" },
  { "name": "Panama" },
  { "name": "Papua New Guinea" },
  { "name": "Paraguay" },
  { "name": "Peru" },
  { "name": "Philippines" },
  { "name": "Pitcairn Islands" },
  { "name": "Poland" },
  { "name": "Portugal" },
  { "name": "Puerto Rico" },
  { "name": "Qatar" },
  { "name": "Reunion" },
  { "name": "Romania" },
  { "name": "Russian Federation" },
  { "name": "Rwanda" },
  { "name": "Saint Helena" },
  { "name": "Saint Kitts and Nevis" },
  { "name": "Saint Lucia" },
  { "name": "Saint Pierre and Miquelon" },
  { "name": "Saint Vincent and the Grenadines" },
  { "name": "Samoa" },
  { "name": "San Marino" },
  { "name": "Sao Tome and Principe" },
  { "name": "Saudi Arabia" },
  { "name": "Senegal" },
  { "name": "Serbia and Montenegro" },
  { "name": "Seychelles" },
  { "name": "Sierra Leone" },
  { "name": "Singapore" },
  { "name": "Slovakia" },
  { "name": "Slovenia" },
  { "name": "Solomon Islands" },
  { "name": "Somalia" },
  { "name": "South Africa" },
  { "name": "South Georgia and the South Sandwich Islands" },
  { "name": "Spain" },
  { "name": "Sri Lanka" },
  { "name": "Sudan" },
  { "name": "Suriname" },
  { "name": "Svalbard and Jan Mayen" },
  { "name": "Swaziland" },
  { "name": "Sweden" },
  { "name": "Switzerland" },
  { "name": "Syrian Arab Republic" },
  { "name": "Taiwan" },
  { "name": "Tajikistan" },
  { "name": "Tanzania, United Republic of" },
  { "name": "Thailand" },
  { "name": "Timor-Leste" },
  { "name": "Togo" },
  { "name": "Tokelau" },
  { "name": "Tonga" },
  { "name": "Trinidad and Tobago" },
  { "name": "Tunisia" },
  { "name": "Turkey" },
  { "name": "Turkmenistan" },
  { "name": "Turks and Caicos Islands" },
  { "name": "Tuvalu" },
  { "name": "Uganda" },
  { "name": "Ukraine" },
  { "name": "United Arab Emirates" },
  { "name": "United Kingdom" },
  { "name": "United States" },
  { "name": "Uruguay" },
  { "name": "Uzbekistan" },
  { "name": "Vanuatu" },
  { "name": "Venezuela" },
  { "name": "Viet Nam" },
  { "name": "Western Sahara" },
  { "name": "Yemen" },
  { "name": "Zambia" },
  { "name": "Zimbabwe" }
]



// Customizable Area End
