import React from "react";

// Customizable Area Start
import { autoComplete } from "./assets";
import CommonModal from '../../../components/src/CommonModal'
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Select as MUiselect,
  MenuItem,
  Card,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});


// Customizable Area End

import PatientController, {
  configJSON,
  Props,
} from "./PatientController";
import dayjs from "dayjs";

export default class Patient extends PatientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSelect = (key: any, placeholder: any, options: any, onChange: any) => (
    <Select
      isSearchable={false}
      value={null}
      onChange={onChange}
      key={key}
      placeholder={placeholder}
      closeMenuOnSelect={true}
      options={options}
      hideSelectedOptions={false}
      styles={this.customStyles}
      menuPortalTarget={document.body}
    />
  );

  renderSelectedOption = (selectedOption: any, key: any, removeOption: any) => (
    selectedOption?.label && (
      <div
        data-test-id={`div${key}`}
        style={webStyles.selectOptions}
        key={selectedOption.value}
      >
        <CloseIcon
          data-test-id={`checkicon${key}`}
          onClick={removeOption}
          style={{ cursor: 'pointer', fontWeight: '700', height: '16px', position: 'relative', top: '2px' }}
        />
        {selectedOption.label}
      </div>
    )
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedOptions, selectedOptions2, selectedOptions3 } = this.state;
    const dropdownList = this.state.dropDowndata;
    const nameOptions = [...new Set(dropdownList?.map((option: { attributes: { first_name: string; last_name: string; }; }) => option.attributes.first_name + " " + option.attributes.last_name))]
      .map(fullName => ({ value: fullName, label: fullName }));
    const dobOptions = [...new Set(dropdownList?.map((option: { attributes: { dob: any; }; }) => option.attributes.dob))]
      .map(dob => ({ value: dob, label: dob }));
    const genderOptions = [...new Set(dropdownList?.map((option: { attributes: { gender: any; }; }) => option.attributes.gender))]
      .map(gender => ({ value: gender, label: gender }));
    const dataSource = this.getDataSource()
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            width: '100%', marginBottom: '100px', overflow: 'auto', display: 'flex', flexDirection: 'column',
            padding: '20px 20px', gap: '20px'
          }}>
          {this.state.isLoader && this.showLoader()}
          <Typography 
            style={{ fontSize: '24px', fontFamily: 'Inter-Bold, sans-serif', color: "rgb(33, 53, 95)" }} 
            align="left">
              Patients
          </Typography>
          <Box style={{
            display: 'flex',
            width: '100%',
            alignItems: 'baseline',

          }}>
            <TextField
              autoComplete='off'
              name="passwordConfirmation"
              data-test-id="passwordConfirmation"
              style={webStyles.inputField}
              value={this.state.datans}
              onClick={() => { this.handleSearch() }}
              onChange={
                /* istanbul ignore next */
                (event) => { this.searchPatient(event) }}
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ fontSize: '16px' }}>
                      <SearchIcon style={{ color: "rgb(33, 53, 95)", marginRight: '5px' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth={true}
            />&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              data-test-id="btnAddButton"
              onClick={this.addPatient}
              style={webStyles.buttonStyle}>
              <AddCircleOutlineIcon style={{ marginRight: "10px" }} />
              Add Patients
            </Button>
          </Box>
          <div>
            <Card style={{ zIndex: 100, color: '#FFF' }}>
              {this.state.openSearchBox && (
                <>
                  <Card
                    style={{
                      position: 'absolute',
                      height: 'fit-content',
                      maxHeight: '300px',
                      overflow: 'auto',
                      borderRadius: '8px', padding: '22px 20px 24px 20px',
                      gap: '15px', backgroundColor: '#FFFFFF', alignItems: 'center',
                      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                    }}>
                    {/* istanbul ignore next */}
                    <CloseIcon data-test-id="checkicon" style={{ cursor: 'pointer', height: '16px', position: 'relative', top: '-18px', right: '10px' }} onClick={() => this.handleSearch2()} />
                    <div>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                        {this.renderSelect('Name', 'Name', nameOptions, this.handleChange)}
                        {this.renderSelect('DOB', 'DOB', dobOptions, this.handleChange2)}
                        {this.renderSelect('Sex At Birth', 'Sex At Birth', genderOptions, this.handleChange3)}
                      </div>
                      <div style={{ display: 'flex', paddingTop: '15px' }}>
                        {this.renderSelectedOption(selectedOptions, 1, this.removeOption)}
                        {this.renderSelectedOption(selectedOptions2, 2, this.removeOption2)}
                        {this.renderSelectedOption(selectedOptions3, 3, this.removeOption3)}

                        {(selectedOptions?.label || selectedOptions2?.label||selectedOptions3?.label) && ( 
                          <div
                            onClick={this.removeAllOptions}
                            style={{
                              margin: '5px',
                              borderRadius: '20px',
                              padding: '10px',
                              width: 'fit-content',
                              color: '#64748B',
                              textAlign: 'center',
                              overflow: 'hidden',
                              cursor: 'pointer',
                              fontFamily: 'Inter-Bold, sans-serif',
                            }}
                          >
                            Clear all
                          </div>
                      )}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                      {this.state.getComplete?.map((item: any, index: any) => {
                        const searchText = typeof this.state.datans === 'string'
                          ? this.state.datans.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                          : '';

                        const regex = new RegExp(searchText, 'i');
                        const isMatch = regex.test(item);
                        const highlightedText = item.replace(regex, (match: any) => `<span style="font-weight: 900; color: #000; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">${match}</span>`);

                        return (
                          <div
                            data-test-id="testest"
                            onClick={() => this.searchPatient(item)}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '10px', margin: '5px', fontFamily: 'Inter-Regular, sans-serif' }}
                            key={index}
                          >
                            <img src={autoComplete} alt="icon" style={{ marginRight: '10px' }} />
                            {isMatch ? (
                              <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
                            ) : (
                              <span data-test-id="searchSpan">{item}</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </>
              )}
            </Card>
          </div>
          {this.state.dataFetching ? 
            <Box style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
              <CircularProgress />
            </Box> :
            <TableContainer style={webStyles.tableContainerStyle}>
            <Table aria-label='simple table'>
              <TableHead >
                <TableRow >
                  <TableCell style={webStyles.tableHeading}>{configJSON.textName}</TableCell>
                  <TableCell style={webStyles.tableHeading}>{configJSON.textDateOfBirth}</TableCell>
                  <TableCell style={webStyles.tableHeading}>{configJSON.textSexAtBirth}</TableCell>
                  <TableCell style={webStyles.tableHeading}>{configJSON.textNhsNumber}</TableCell>
                  <TableCell style={webStyles.tableHeading}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataSource?.map((item: any, index: number) => {
                    const handleClick = this.state.isTestButtonClicked === "yes" ?
                      () => this.orderTest(item?.id, `${item?.attributes?.first_name} ${item?.attributes?.last_name}`) : () => this.viewPatient(item?.id)
                    const nhsNumber = this.renderNHSNumber(item)
                    const formattedNhsNumber = nhsNumber && `${nhsNumber.slice(0, 3)} ${nhsNumber.slice(3, 6)} ${nhsNumber.slice(6)}`

                    return (
                      <TableRow key={item?.id} style={webStyles.tableBody}>
                        <TableCell scope="row" style={webStyles.tableBody}>
                          <Typography
                            data-test-id="onPatientClick"
                            style={{ color: "#21355F", fontFamily: 'Inter-Regular, sans-serif', textDecoration: "underline", cursor: "pointer" }}
                            variant="body2"
                            onClick={handleClick}
                          >
                            {item?.attributes?.first_name} {item?.attributes?.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell style={webStyles.tableBody}>{dayjs(item?.attributes?.dob).format("DD/MM/YYYY")}</TableCell>
                        <TableCell style={webStyles.tableBody}>
                          {item?.attributes?.gender}
                        </TableCell>
                        <TableCell style={webStyles.tableBody}>
                          {formattedNhsNumber}
                        </TableCell>
                        <TableCell style={webStyles.tableBody}>
                          <MUiselect
                            labelId="dropdown-label"
                            id="dropdown"
                            disableUnderline={true}
                            data-test-id={`btnDropdownButton${index}`}
                            IconComponent={MoreVertIcon}
                          >
                            <MenuItem
                              data-test-id={`btnViewButton${index}`}
                              onClick={() => {
                                this.viewPatient(item?.id)

                              }} value="" style={{ fontSize: '14px' }}>
                              <VisibilityOutlinedIcon style={{ fontSize: '16px', marginRight: '15px' }} />
                              View Patient
                            </MenuItem>
                            <MenuItem data-test-id={`btnEditButton${index}`}
                              onClick={() => this.editPatientScreen(item?.id)} value="" style={{ fontSize: '14px' }}>
                              <EditIcon style={{ fontSize: '16px', marginRight: '15px' }} />
                              Edit Patient</MenuItem>
                            <MenuItem
                              onClick={
                                () => this.showHideDeleteAccountModal(item)}
                              value=""
                              style={{ fontSize: '14px' }}
                              data-test-id={`deletModalPopUp${index}`}
                            >
                              <DeleteOutlineIcon style={{ fontSize: '16px', marginRight: '15px' }} />
                              Delete Patient
                            </MenuItem>
                          </MUiselect>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          }
          {/* @ts-ignore */}
          <CommonModal
            deleteApiId={this.state.deleteApiId}
            deleteGroup={this.deleteGroup}
            type="delete"
            hideDeleteAccountModal={this.hideDeleteAccountModal}
            title={`Are you sure you want delete ${this.state.deleteApiId?.attributes?.first_name} ${this.state.deleteApiId?.attributes?.last_name} ?`}
            isOpen={this.state.isVisibleDeleteAccountModal}
          />
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  tableBody: {
    fontFamily: 'Inter-Regular, sans-serif',
    borderBottom: '1px solid #0075BF',
    padding: '10px 15px'
  },
  tableHeading: {
    background: 'rgba(33, 53, 95, 1)',
    fontFamily: 'Inter-Bold, sans-serif',
    color: '#fff',
  },
  inputField: {
    border: '1px solid rgba(33, 53, 95, 1)',
    padding: '5px 0px 5px 0px',
    borderRadius: '98px',
    background: 'none',
    '&:before': {
      border: 'none !important'
    },
    '&:after': {
      border: 'none'
    },
    '&:hover': {
      border: '2px solid #0075BF'
    }
  },
  tableContainerStyle: {
    maxHeight: 600,
    borderRadius: '8px 8px 0px 0px',
  },
  selectOptions: {
    margin: '5px',
    borderRadius: '20px',
    padding: '10px',
    width: 'fit-content',
    backgroundColor: '#0075BF',
    color: 'white',
    overflow: 'hidden',
    fontFamily: 'Inter-Bold, sans-serif'
  },
  buttonStyle: {
    height: "44px",
    width: "180px",
    border: "none",
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    borderRadius: '8px',
    color: "white",
    padding: "6px 10px",
    fontFamily: 'Inter-Bold, sans-serif',
    textTransform: 'none' as const
  },
};
// Customizable Area End