Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.deleteEndPoint="bx_block_custom_form/cancel_test?id=";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.editDropdownEndPoint="bx_block_advanced_search/advance_search?type=test_order"
exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.searchArea="bx_block_advanced_search/advance_search"

exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";
exports.backDeleteItemModal = "Back"
exports.confirmDeleteItemModal = "Delete"
exports.confirmPatchItemModal = "PATCH"
exports.sample_type = ["Blood", "Saliva", "Urine", "Faecal", "Swab"];
exports.getApiForTestPanel = "/bx_block_content_management/contents/panels_list"
exports.getApiForMakersList = "bx_block_content_management/contents/makers_list"
exports.fetchCliniciansApiEndPoint = "account_block/accounts/clinician_listings";
exports.getSampleTypeApiEndPoint = "/bx_block_content_management/contents/test_kits";
exports.postPlaceOderApiEndPoint = "/bx_block_custom_form/bulk_create";
exports.autoCompleteSearch="bx_block_advanced_search/autocomplete?type=test_order"
exports.getMethod = "GET"
exports.postMethod = "POST"
exports.getApiContentType = "application/json";


exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.TestsEndPoint = "/bx_block_custom_form/test_orders";
exports.EditEndPoint="/bx_block_custom_form/test_orders/"

exports.warningSubtitle = "Please select Sample Type and Panel/Makers or Continue without creating the test"
exports.warningTitle = "Test cannot be added"
// Customizable Area End