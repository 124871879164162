import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Select,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import GenericCard from "../../../components/src/GenericCard.web";
import LoginController, { Props } from "./LoginController.web";
// Customizable Area End

export default class SelectInstitute extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {hospitalName, selectHospitalName} = this.state
    return (
      // Customizable Area Start
     <GenericCard navigation={this.props.navigation}>
      <Box style={{padding: '10px 50px 50px 50px'}}>
        <Typography variant="h6" align="center" style={webStyle.heading}>
            SELECT AN INSTITUTION
        </Typography>
        <Select
          labelId="hospital"
          id="Hospital"
          data-test-id="btnInstituteName"
          name="Hospital"
          displayEmpty
          style={webStyle.inputField}
          disableUnderline={true}
          value={selectHospitalName}
          onChange={this.handleChange}
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null, // This is important to ensure it opens below
          }}
        >
              <MenuItem value="" disabled>
                Please Choose
              </MenuItem>
             
              {  
                hospitalName.clinician_information?.institutes?.data.map((item: any) => {
                  return(
                    <MenuItem  key={item.id} data-test-id="btnSelectInstitute" onClick={() => {
                        this.redirectToDashboard(item.id)
                        this.getInstituteNumber(item.attributes.institute_information.institution_number)
                    }}  value={item.attributes.institute_information.institute_name}>
                      {item.attributes.institute_information.institute_name}
                    - {item.attributes.institute_information.institution_number}
                    </MenuItem>
                  ) 
                })
              }
            
              
        </Select>
      </Box>
     
     
     </GenericCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    heading : {
        fontWeight: 'bold', 
        marginBottom: '10px', 
        marginTop: '20px',
        fontSize: '24px',
        color: 'rgb(33, 53, 95)'
    },
    inputField: {
      borderRadius: '8px',
      marginTop: '30px',
      padding: '10px 10px',
      background: 'none',
      border: '1px solid rgba(203, 213, 225, 1)',
      '&:before' :{
        border: 'none !important' 
      },
      '&:after' :{
        border: 'none' 
      },
      '&:hover' :{
        border: '2px solid #0075BF'
      }
      },
};
// Customizable Area End
