import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";

import { revueLogo } from './assets'
import GenericCard from "../../../components/src/GenericCard.web";

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {termsCondsList} = this.state
    return (
      <GenericCard>
      
      <Box style={{padding: '10px 50px'}}>
       
        <Box style={{textAlign: 'center'}}>
        <img src={revueLogo} />
        </Box>
        <Typography style={{marginTop: '10px', fontWeight: 'bold', marginBottom: '10px', color: '#21355F', fontSize: '24px'}} align="center">
          {configJSON.termsAndCondition}
        </Typography>
        <Box style={{height: '455px', overflowY: 'auto'}}>
        <Typography style={{marginTop: '10px', fontWeight: 400, marginBottom: '10px', fontSize: '16px', textAlign: 'justify'}}>
          {termsCondsList[0]?.description}
        </Typography>
        </Box>
       
        
        <Box style={{textAlign: 'center'}}>
        <Button
          onClick={this.cancelTermsConditions}
          data-test-id={'btnCancelButton'}
          style={webStyle.cancelButtonStyle}
        >
          Cancel
        </Button>&nbsp;&nbsp;
        <Button
          style={webStyle.buttonStyle}
          data-test-id={'btnNavigate'}
          onClick={this.tncStatusResponse}
        >
          Accept
        </Button>
        </Box>
      </Box>
   
</ GenericCard>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    marginBottom: '20px',
    marginTop: '40px'
  },
  buttonStyle: {
    height: "45px",
    width: "80px",
    marginTop: "25px",
    border: "none",
    backgroundColor: "#0075BF",
    borderRadius: '8px',
    color: "white",
    textTransform: 'none' as const,
    cursor: "pointer",
    fontWeight: 700,
    padding: '10px 16px',
  },
  cancelButtonStyle: {
    height: "45px",
    width: "80px",
    marginTop: "25px",
    border: "1px solid #DBDBDB",
    backgroundColor: "#fff",
    borderRadius: '8px',
    color: "#0075BF",
    cursor: "pointer",
    textTransform: 'none' as const,
    fontWeight: 700,
    padding: '10px 16px',
  },
  label: {
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "8px",
    color: '#21355F',
    marginBottom :'10px'
  },
};
// Customizable Area End
