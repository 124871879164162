import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { editDataIcon, plusIcon } from './assets'
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export interface Props {
  navigation: any;
  id: string;

}

interface S {
  testid: NumberIndexedObject;
  testType: NumberIndexedObject;
  testTypeId: NumberIndexedObject;
  currDate: NumberIndexedObject;
  newDateData: string;
  summaryData: any[];
  newDataSummary: any[];
  openCalenderPopup: string;
  openAssignClinicianPopup: string;
  openRevueToFill: NumberIndexedObject;
  openAssignClinicianConfirmPopup: boolean;
  openAssignClinicianConfirmPopupName: NumberIndexedObject;
  openAssignClinicianConfirmPopupId: NumberIndexedObject;
  openAssignClinicianConfirmPopupDeparment: NumberIndexedObject;
  allAssignClinician: [];
  sampleAns:string,
  openError:boolean,
  allSampleTypeList: [];
  searchClinicianText: string;
  aditionalInfo: NumberIndexedObject;
  revueToFillInfo: NumberIndexedObject;
  anchorEl: null;
  keySetForTestType: string;
  placeOrderPopup: boolean;
  placeOrderData: object[];
  showAllErrors: boolean;
  heightForRevueText: NumberIndexedObject
  heightForAddText: NumberIndexedObject
  openDeleteConfirmModal: boolean
  isDisabed: boolean
  deletedItem: any
  selectedTestId: string
  anyError: boolean[]
  selectedClinicianName: string
  selectedClinicianDepartment: string
  selectedClinicianId: number
  isLoader: boolean
  deletedItemIndex: number
  duplicatedIds: string
  openErrorModal: boolean
}

interface NumberIndexedObject {
  [key: string]: string;
}

interface SS {
  id: any;
}

export default class TetsController extends BlockComponent<Props, S, SS> {

  getAllAssignClinicianAPICallId = "";
  getAllDataAns="";
  getAllSampleTypeAPICallId = "";
  postRequestApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      testid: {},
      testType: {},
      testTypeId: {},
      currDate: {},
      newDateData: "",
      summaryData: [],
      newDataSummary: [],
      openCalenderPopup: "",
      openAssignClinicianPopup: "",
      openRevueToFill: {},
      openAssignClinicianConfirmPopup: false,
      openAssignClinicianConfirmPopupName: {},
      openAssignClinicianConfirmPopupId: {},
      openAssignClinicianConfirmPopupDeparment: {},
      allAssignClinician: [],
      sampleAns:"",
      openError:false,
      allSampleTypeList: [],
      searchClinicianText: "",
      aditionalInfo: {},
      revueToFillInfo: {},
      anchorEl: null,
      keySetForTestType: "",
      placeOrderPopup: false,
      placeOrderData: [],
      showAllErrors: false,
      heightForRevueText: {},
      heightForAddText: {},
      openDeleteConfirmModal: false,
      isDisabed: false,
      deletedItem: {},
      selectedTestId: "",
      anyError: [],
      selectedClinicianName: "",
      selectedClinicianDepartment: "",
      selectedClinicianId: NaN,
      isLoader: false,
      deletedItemIndex: NaN,
      duplicatedIds: "",
      openErrorModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    await this.getSummaryData()
    this.collectErrors()
    window.addEventListener('storage', this.handleStorageChange);
  }

  async componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange = () => {
    this.setState({
      summaryData: localStorage.getItem('summaryData') as any,
    });
  }

  componentDidUpdate(_: any, prevState: any) {
    const { summaryData, testType, testid, currDate, openAssignClinicianConfirmPopupName, openRevueToFill } = this.state;
    
    if (
      prevState.summaryData !== summaryData ||
      prevState.testType !== testType ||
      prevState.testid !== testid ||
      prevState.currDate !== currDate ||
      prevState.openAssignClinicianConfirmPopupName !== openAssignClinicianConfirmPopupName ||
      prevState.openRevueToFill !== openRevueToFill
    ) {
      this.collectErrors();
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCall = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (apiRequestCall === this.postRequestApiCallId) {
        this.handlePostRequest(responseJson);
      } else if (responseJson && !responseJson.errors) {
        this.handleGetRequest(apiRequestCall, responseJson);
      }
      
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  updateLocalStorageSummaryData(testId: string, updateCallback: (test: any) => void) {
    const patientId = JSON.parse(localStorage.getItem("patientId") || "{}");
    const dataFromStorage = localStorage.getItem("summaryData") as string;
    const selectedTests = JSON.parse(dataFromStorage);
    const patientTests = selectedTests?.filter((test: any) => Number(test.patientId) === Number(patientId));
    const updatedTest = patientTests?.find((test: any) => test.tempId === testId);

    if (updatedTest) {
      updateCallback(updatedTest);
      const updatedTestsArray = selectedTests.map((test: any) =>
        test.tempId === testId ? updatedTest : test
      )
      
      localStorage.setItem("summaryData", JSON.stringify(updatedTestsArray));
    }
  }

  getDateFromStorage(testId: string){
    const patientId = JSON.parse(localStorage.getItem("patientId") as string)
    const dataFromStorage = localStorage.getItem("summaryData");
    
    if (!dataFromStorage) {
      return null;
    }

    const tests = JSON.parse(dataFromStorage);
    const patientTests = tests.filter((test: any) => Number(test.patientId) === Number(patientId));

    if (!patientTests || patientTests.length === 0) {
      return null;
    }

    const test = patientTests.find((test: any) => test.tempId === testId);

    return test ? test.dateOfTest : null;
}

  getFormattedDateOfTest(testId: string){
    const result = this.getDateFromStorage(testId)

    if(result){
      return dayjs(result).format('DD/MM/YYYY')
    }else{
      return ''
    }
  }

  handleCloseErrorsModal(){
    this.setState({
      openErrorModal: false
    })
  }
  
  handlePostRequest(responseJson: any) {
    const { error, data, test_ids } = responseJson || {};
    const patientId = localStorage.getItem('patientId') as string
    const summaryData = JSON.parse(localStorage.getItem("summaryData") as string);
  
    if (error) {
      this.setState({
        sampleAns: error,
        duplicatedIds: test_ids || "",
        openError: true,
        isLoader: false,
        placeOrderPopup: false,
        isDisabed: false, 
      })
      return;
    }
  
    if (data?.length) {
      const newSummaryData = summaryData.filter((test: any) => Number(test.patientId) !== Number(patientId))
  
      localStorage.setItem("summaryData", JSON.stringify(newSummaryData));
      this.setState({
        isLoader: false,
        placeOrderData: [],
        placeOrderPopup: false,
        isDisabed: false
      });
  
      this.props.navigation.navigate('Tests');
    }
  }
  
  handleGetRequest(apiRequestCall:any, responseJson:any) {
    if (apiRequestCall === this.getAllAssignClinicianAPICallId && responseJson.data.length !== 0) {
      this.setState({ allAssignClinician: responseJson.data });
    } else if (apiRequestCall === this.getAllSampleTypeAPICallId && responseJson.test_kits.length !== 0) {
      this.setState({ allSampleTypeList: responseJson.test_kits });
    }
  }
  

  viewPatient = (navigationId: number) => {
    this.props.navigation.navigate("PatientView", {
      id: navigationId
    })
  }

  viewPatientScreen = () => {
    this.props.navigation.navigate('Patient')
  }
  handleError=()=>{
    this.setState({openError:false})
  }
  handleNavigationToOrderTest(item: any) {
     /* istanbul ignore next */
    localStorage.setItem("deviceandsample", "true")
    localStorage.setItem("editModeActive", "true")
    localStorage.setItem("editableTest", JSON.stringify(item))
    this.props.navigation.navigate('OrderTest')
  }

  handleTestId(value: string, itemKey: number, tempId: string) {
    let dataObject: any = { ...this.state.testid }
    dataObject[itemKey.toString()] = value 

    this.updateLocalStorageSummaryData(tempId, (updatedTest) => {
        updatedTest.testId = value
    });

    this.setState({
        testid: { ...dataObject }
    });
}

  async handleCopy(value: string) {
    try {
      await navigator.clipboard.writeText(value);
    } catch (error) {
      
    }
  }

  displayLoader = () => (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 117, 191, 0.2)"
      zIndex={15}>
      <CircularProgress size={50} />
    </Box>
  )

  handleDelete = () => {
    this.setState({ isDisabed: true });

    let dataSummary = localStorage.getItem("summaryData") as string;
    let parsedSummaryData = JSON.parse(dataSummary);
    const itemIndex = parsedSummaryData.findIndex((test: any) => test.tempId === this.state.deletedItem.tempId);

    if (itemIndex !== -1) {
      parsedSummaryData.splice(itemIndex, 1);
      localStorage.setItem("summaryData", JSON.stringify(parsedSummaryData));
    }

    this.setState({
      summaryData: parsedSummaryData,
      isDisabed: false,
      openDeleteConfirmModal: false,
      isLoader: true, 
    });

    setTimeout(() => {
      window.location.reload(); 
    }, 1000); 
};


  handleSwitch(itemKey: number, tempId: string, event: any) {
    let dataObject: any = { ...this.state.openRevueToFill };
    dataObject[itemKey.toString()] = event.target.checked

    this.setState({
      openRevueToFill: { ...dataObject }
    })

    this.updateLocalStorageSummaryData(tempId, (updatedTest) => {
      updatedTest.revueSwitcher = event.target.checked;
    });
  }

  openCalPopup(itemKey: number, tempId: string) {
    this.setState({
      openCalenderPopup: itemKey.toString(),
      selectedTestId: tempId
    });
  }

  closeCal() {
    this.setState({
      openCalenderPopup: ""
    });
  }

  closeAssignCli() {
    this.setState({
      openAssignClinicianPopup: "",
    });
  }

  confirmAssignCli() {
    const {selectedClinicianId, selectedClinicianName, selectedClinicianDepartment } = this.state
     /* istanbul ignore next */
     this.updateLocalStorageSummaryData(this.state.selectedTestId, (updatedTest) => {
      updatedTest.clinicianFullName = selectedClinicianName
      updatedTest.clinicianId = selectedClinicianId
      updatedTest.clinicianDepartment = selectedClinicianDepartment
    })

    this.setState({
      openAssignClinicianConfirmPopup: true,
      searchClinicianText: ""
    });
  }

  acceptAssignedClinician() {
    let dataObject: any = { ...this.state.openAssignClinicianConfirmPopupName };
    let dataId: any = { ...this.state.openAssignClinicianConfirmPopupId };
    let dataDep: any = { ...this.state.openAssignClinicianConfirmPopupDeparment };

    dataObject[this.state.openAssignClinicianPopup] = this.state.selectedClinicianName;
    dataId[this.state.openAssignClinicianPopup] = this.state.selectedClinicianId;
    dataDep[this.state.openAssignClinicianPopup] = this.state.selectedClinicianDepartment;
    
    this.setState({
      openAssignClinicianConfirmPopupName: { ...dataObject },
      openAssignClinicianConfirmPopupDeparment: { ...dataDep },
      openAssignClinicianConfirmPopupId: { ...dataId },
      openAssignClinicianConfirmPopup: false,
      openAssignClinicianPopup: "",
      selectedClinicianName: "", 
      selectedClinicianId: NaN,
      selectedClinicianDepartment: ""
    });
  }

  closeAssignedClinician() {
    this.setState({
      openAssignClinicianConfirmPopup: false,
    });
  }

  handleOpenModal = (item: any, index: number) => {
    this.setState({
      openDeleteConfirmModal: true,
      deletedItem: item,
      deletedItemIndex: index
    })
  }

  handleCloseModal = () => {
    this.setState({
      openDeleteConfirmModal: false,
    })
  }

  handleConfirmButtonOpacity(){
    return !this.state.selectedClinicianId ? "0.5" : "1"
  }

  handleGetSampleAmount(item: any){
    if(item?.sampleAmountUnit === "μL") {
      return `${item?.totalSampleData}${item?.sampleAmountUnit}/${item?.sampleAmount}${item?.sampleAmountUnit}`
    }else {
      return `${item?.totalSampleData / 1000}${item?.sampleAmountUnit}/${item?.sampleAmount}${item?.sampleAmountUnit}`
    }
    
  }

  handleAssignClinicianConfirmPopup(idData: number, name: string, department: string) {
   

    this.setState({
      selectedClinicianId: idData,
      selectedClinicianName: name,
      selectedClinicianDepartment: department
    })
  }

  confirmCal() {
    let dataObject: any = { ...this.state.currDate };
    dataObject[this.state.openCalenderPopup] = this.state.newDateData;
    this.setState({
      currDate: { ...dataObject },
    });
    /* istanbul ignore next */
    this.updateLocalStorageSummaryData(this.state.selectedTestId, (updatedTest) => {
      updatedTest.dateOfTest = this.state.newDateData
    })

    this.getDateFromStorage(this.state.selectedTestId)

    this.setState({ 
      openCalenderPopup: "", 
    });
  }

  handleAssignClinician(itemKey: number, tempId: string) {
    this.getAllAssignClinician()
    this.setState({ 
      openAssignClinicianPopup: itemKey.toString(),
      selectedTestId: tempId
    });
  }

  handleAssignClinitionSearch(value: string) {
    this.setState({
      searchClinicianText: value
    })
  }

  shouldDisableDate = (date: Dayjs) => {
    const day = date.day();
    return day === 0 || day === 5 || day === 6;
  };


  handleAdditionalInfo(value: string, itemKey: number, tempId: string) {
    let dataObject: any = { ...this.state.aditionalInfo };
    dataObject[itemKey.toString()] = value;
    /* istanbul ignore next */
    this.updateLocalStorageSummaryData(tempId, (updatedTest) => {
      updatedTest.additionalInfo = value
    })

    this.setState({
      aditionalInfo: { ...dataObject }
    });
  }

  handleRevueToFillInfoInfo(value: string, itemKey: number, tempId: string) {
    let dataObject: any = { ...this.state.revueToFillInfo };
    dataObject[itemKey.toString()] = value;
    this.setState({
      revueToFillInfo: { ...dataObject }
    });

    /* istanbul ignore next */
    this.updateLocalStorageSummaryData(tempId, (updatedTest) => {
      updatedTest.revueNote = value
    })
  }

  handleDatePicker(event: any) {
    let date = event["$d"];
    this.setState({
      newDateData: date
    });
    
  }

  getPopUpData(testId: string, revueSwitcher: boolean){
    return revueSwitcher ? "REVUE to Fulfil" : testId
  }

  handlePlaceOrder() {
    this.setState({
      showAllErrors: true
    })
    const testType = { ...this.state.testType };
    const testId = { ...this.state.testid };
    const hasErrors = this.hasAnyErrors()

    let testTypeObj = [];
    let testIdObj: any = [];

    for (const value in testType) {
      testTypeObj.push(testType[value]);
    }
    for (const value in testId) {
      testIdObj.push(testId[value]);
    }

    testTypeObj.forEach((num1, index) => {
      const num2 = testIdObj[index];
      let dataObject = { testType: num1, testId: num2 };

      this.state.placeOrderData.push(dataObject);
    });
    this.setState({
      placeOrderPopup: !hasErrors,
      newDataSummary: this.state.summaryData,
      openErrorModal: hasErrors
    });
  }

  closePlaceOrder() {
    this.setState({
      placeOrderPopup: false,
      placeOrderData: []
    });
  }

  confirmPlaceOrder() {
    const institute_id = Number(localStorage.getItem("institutionId"));
    const patient_id = Number(localStorage.getItem('patientId'));

    this.createOrder(institute_id, patient_id);
  }

  async createOrder(institute_id: number, patient_id: number) {
    const patientId = JSON.parse(localStorage.getItem("patientId") || "{}");
    const dataFromStorage = localStorage.getItem("summaryData") as string;
    const selectedTests = JSON.parse(dataFromStorage);
    const patientTests = selectedTests?.filter((test: any) => Number(test.patientId) === Number(patientId));
    const preparedTests = patientTests.map((test: any) => {
    /* istanbul ignore next */
    const includeMarkers = test.testPanel && test.testPanel!=0;
     /* istanbul ignore next */
      return {
        sample_type: test.sampleType,
        panel_id: test.testPanel,
        clinician_id: Number(test.clinicianId),
        consultant_id: Number(test.clinicianId),
        test_kit_id: Number(test.testKitId),
        test_id: Boolean(test.revueSwitcher) ? null : test.testId,
        test_date: dayjs(test.dateOfTest).format("DD/MM/YYYY"),
        department: test.clinicianDepartment,
        priority: "Normal",
        revue_fulfill: Boolean(test.revueSwitcher),
        revue_note: test.revueNote || "",
        additional_info: test.additionalInfo,
        institute_id,
        patient_id,
         /* istanbul ignore next */
        ...(includeMarkers ? {}:{ maker_ids: test.markers })
      };
    });

    await this.postPlaceOrder(preparedTests);
  }

  async getSummaryData() {
    let dataObject = localStorage.getItem("summaryData") as string;
    let dataNew = JSON.parse(dataObject);
    const patientFullNameData = localStorage.getItem('fullName') as string;
    
    this.setState({
      summaryData: dataNew?.filter((item: any) => item.patientName == JSON.parse(patientFullNameData))
    });
  }

  getAllAssignClinician() {
    let dataObject = localStorage.getItem("data") as string
    let dataApi = JSON.parse(dataObject);

    let instituteIdData = localStorage.getItem("institutionId") as string
    let instituteId = JSON.parse(instituteIdData)

    const PCheaders = {
      "Content-Type": configJSON.getApiContentType,
      token: dataApi.meta?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllAssignClinicianAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchCliniciansApiEndPoint}?institute_id=${instituteId}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  async postPlaceOrder(test_orders: any) {
    let dataObject = localStorage.getItem("data") as string
    let dataApi = JSON.parse(dataObject)

    const PCheaders = {
      "Content-Type": configJSON.getApiContentType,
      token: dataApi.meta?.token
    };

    const httpBody = {
      test_orders
    };

    const getRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRequestApiCallId = getRequest.messageId;

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPlaceOderApiEndPoint
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    getRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getRequest.id, getRequest);
    this.setState({
      placeOrderPopup: false,
      isLoader: true,
      isDisabed: true 
     })
  }

  handleTextAreaRevue(itemKey: any) {
    let dataObject: any = { ...this.state.heightForRevueText };

    const element = document.getElementById("textboxRevue"+itemKey);

    if(element) {
      element.style.height = "auto"
      dataObject[itemKey.toString()] = `auto`;
      this.setState({ heightForRevueText: { ...dataObject } })

      element.style.height = `${element.scrollHeight}px`;
      dataObject[itemKey.toString()] = `${element.scrollHeight}px`;
      this.setState({ heightForRevueText: { ...dataObject } });
    }

  }

  handleTextAreaAddInfo(itemKey: any) {
    let dataObject: any = { ...this.state.heightForAddText };
    const element = document.getElementById("textboxInfo"+itemKey);

    if(element) {
      element.style.height = "auto"
      dataObject[itemKey.toString()] = `auto`;
      this.setState({ heightForAddText: { ...dataObject } })

      element.style.height = `${element.scrollHeight}px`;
      dataObject[itemKey.toString()] = `${element.scrollHeight}px`;
      this.setState({ heightForAddText: { ...dataObject } });
    }

  }

  handleHeightSample(itemKey: any) {
    if(this.state.heightForRevueText[itemKey]) {
      const dataFirst = Number(this.state.heightForRevueText[itemKey].substring(0, 2));
      const dataSecond = Number(this.state.heightForRevueText[itemKey].substring(0, 3));

      if(isNaN(dataSecond)) {
        return dataFirst + 52;
      } else {
        return dataSecond + 52;
      }
    }
  }

  handleData4(itemKey: any) {
    if (!itemKey) {
      return "Select Date";
    } else {
      return itemKey
    }
  }

  handleDataColor(itemKey: any) {
    if (!itemKey) {
      return "#0075BF";
    } else {
      return "#E7205D";
    }
  }

  getClinicianFullName(localValue: string, savedValue: string){
    return localValue || savedValue
  }

  handleData6(itemKey: any) {
    if (!itemKey) {
      return plusIcon;
    } else {
      return editDataIcon;
    }
  }

  handleData7(itemKey: any) {
    if (!itemKey) {
      return "Clinician";
    } else {
      return itemKey;
    }
  }

  handleData8(itemKey: any) {
    if (itemKey) {
      return "center";
    } else {
      return "start";
    }
  }

  handlePopupData(itemKey: any) {
    if (itemKey) {
      return true;
    } else {
      return false;
    }
  }

  handleData11(name1: string, name2: string) {
    if (name1 !== name2) {
      return "none";
    } else {
      return "underline";
    }
  }

  handleRowColor(itemKey: any) {
    if ((itemKey + 1) % 2 !== 0) {
      return "#FFFFFF";
    } else {
      return "#EFEFEF";
    }
  }

  handleBorder(value: string) {
    if (!value && this.state.showAllErrors) {
      return "1px solid red";
    }
    return "1px solid #ccc"; 
  }

  handleDisabledOpacity(itemKey: number, revueSwitcher: string){
    return this.checkSwitcherState(itemKey, revueSwitcher) ? 0.5 : 1
  }

  buttonDisabledOpacity(){
    return this.state.isDisabed ? 0.5 : 1
  }

  isTestIdEmpty(testId: string, testIdFromState: string) {
    return !testId || (testIdFromState && !testIdFromState);
  }

  handleBorderBtns(itemKey1: string) {
    if(!itemKey1 && this.state.showAllErrors) {
      return"2px solid #E7205D"
    } else {
      return "none"
    }
  }

  showRequireError(value: string){
    return !value && this.state.showAllErrors
  }

  getTestId = (itemKey: number, savedValue: string) => {
    return this.state.testid[itemKey] || savedValue;
  }

  showTestIdError(itemKey: number, savedValue: string,revueSwitcher: string){
    if(this.checkSwitcherState(itemKey, revueSwitcher)){
      return
    }
    const testId = this.getTestId(itemKey, savedValue);
   
    return testId === "" && this.state.showAllErrors
  }

  handleTestIdBorder(value: string, revueSwitcher: string, itemKey: number) {
    const isSwitcherActive = this.checkSwitcherState(itemKey, revueSwitcher)
    
    if (isSwitcherActive) {
      return "1px solid #ccc";
    }
    
    const isValueEmpty = !value;
    const shouldShowError = isValueEmpty && this.state.showAllErrors;
    
    return shouldShowError ? "1px solid red" : "1px solid #ccc";
}
 
  shouldShowError(itemKey: number, item: any) {
    const isSwitcherActive = this.checkSwitcherState(itemKey, item.revueSwitcher);
    const { testid, currDate, openAssignClinicianConfirmPopupName, openRevueToFill } = this.state;
    const isChecked = openRevueToFill[itemKey] === undefined ? isSwitcherActive : openRevueToFill[itemKey]

    const hasError = (
      (!isChecked && (!testid[itemKey] && !item.testId)) || 
      (!currDate[itemKey] && !item.dateOfTest) || 
      (!openAssignClinicianConfirmPopupName[itemKey] && !item.clinicianFullName)
    )

    return hasError;
  }

  collectErrors() {
    const errors = this.state.summaryData.map((item, itemKey) => {
      return this.shouldShowError(itemKey, item)
    })
   
    this.setState({ anyError: errors })
  }

  hasAnyErrors() {
    return this.state.anyError.some(Boolean)
  }

  getValueFromStateOrFallback(stateObject: any, itemKey: number, fallbackValue: string) {
    return stateObject[itemKey] !== undefined ? stateObject[itemKey] : fallbackValue
  }

  getType(testType: string, sampleType: string ) {
    return testType || sampleType
  }

  checkSwitcherState(itemKey: number, revueSwitcher: string) {
    if(this.state.openRevueToFill[itemKey] === undefined) return Boolean(revueSwitcher)
    return Boolean(this.state.openRevueToFill[itemKey])
  }

  filterClinicians(clinician: any, searchText: string) {
    if (!searchText) {
        return true;
    }
    const lowercasedSearchText = searchText.toLowerCase().trim();
    const { first_name, last_name } = clinician.attributes.common_information;
    const { department, role } = clinician.attributes.clinician_information;

    return (
        (first_name?.toLowerCase().trim().includes(lowercasedSearchText)) || 
        (last_name?.toLowerCase().trim().includes(lowercasedSearchText)) || 
        (department?.toLowerCase().trim().includes(lowercasedSearchText)) || 
        (role?.toLowerCase().trim().includes(lowercasedSearchText))
    );
  } 

}
