import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { FormikErrors, FormikTouched } from "formik";
import React from "react";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  imgPasswordVisible: any;
  enablePasswordField: boolean,
  resetFormApi: any
  confirmPassword: string;
  enableConfirmPasswordField: boolean,
  password: string;
  imgPasswordInVisible: any,
  token: string
  displayPopup: boolean
  popupTitle: string
  isError: boolean
  isLoader: boolean
  createPassword: string
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  label: string;
  value: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  verifyTokenApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      enablePasswordField: true,
      email: "",
      confirmPassword: '',
      imgPasswordVisible: configJSON.imgPasswordVisible,
      resetFormApi: [],
      enableConfirmPasswordField: true,
      imgPasswordInVisible: configJSON.imgPasswordInVisible,
      token: '',
      displayPopup: false,
      popupTitle: "",
      isError: false,
      isLoader: false,
      createPassword: "false"
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const url = new URL(window.location.href);
    const searchParams = url.searchParams
    const token = searchParams.get('token')
    const email = searchParams.get('email')
    const createPassword = searchParams.get('create_password') || "false"
    
    if(token && email) {
      this.setState({ 
        token: token, 
        email: email, 
        createPassword: createPassword
      }, () => {
        this.verifyToken();
      });
      localStorage.setItem('token', token)
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (apiRequestCallId === this.resetPasswordApiCallId) {
        if(!responseJson.errors) {
          this.setState({
            displayPopup: true,
            popupTitle: responseJson.message,
            isError: false,
            isLoader: false
          })
        }else{
          this.setState({
            displayPopup: true,
            popupTitle: responseJson.errors[0].base,
            isError: true,
            isLoader: false
          })
        }
      } else if (apiRequestCallId === this.verifyTokenApiCallId) {
        if(!responseJson.errors) {
          this.setState({
            isError: false,
            isLoader: false
          })
        }else{
          this.setState({
            displayPopup: true,
            popupTitle: responseJson.errors[0].base,
            isError: true,
            isLoader: false
          })
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      errors[value as keyof object] &&
      touched[value as keyof object] &&
       (
        <div
          style={{
            top: "65px",
            fontFamily: 'Inter-Light, sans-serif',
            color: "#E7205D",
            fontSize: "15px",
            lineHeight: "19px",
            fontWeight: 400
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  onOpenPopup = () => {
    this.setState({
      displayPopup: true
    })
  }

  handleClosePopup = () => {
    const navigatedPath = this.state.isError ? "ForgotPassword" : "Login"

    this.props.navigation.navigate(navigatedPath)
    this.setState({
      displayPopup: false,
      isError: false,
      popupTitle: "",
    })
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      enablePasswordField: !this.state.enablePasswordField
    }))
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleConfirmClickShowPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };


  handleResetPassword = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        token: this.state.token,
        new_password: this.state.password,
        confirm_password: this.state.confirmPassword,
        create_password: this.state.createPassword
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordAPiEndPoint
    );

    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      isLoader: true
    })
    return true;
  };

  verifyToken = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        token: this.state.token,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyTokenApiEndPoint
    );

    this.verifyTokenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      isLoader: true
    })
  };

  // Customizable Area End
}
